import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { apiUrl } from 'app/common/url-resolver.service';
import { BPermission, BRole } from './role';
import { BUser } from '../user/user';
import { BUserTemplate } from '../data-model.module';

@Injectable({ providedIn: 'root' })
export class RolesService {
  private readonly URL: string = apiUrl('roles');
  private readonly URL_LISTINGS: string = apiUrl('roles', 'listings');

  constructor(private http: HttpClient) {}

  get(): Observable<RoleDefinitions> {
    return this.http.get<RoleDefinitions>(this.URL).pipe(
      map((resp: RoleDefinitions) => {
        resp.roles = BRole.fromRestArray(resp.roles);
        resp.permissions = BPermission.fromRestArray(resp.permissions);
        return resp;
      })
    );
  }

  getSingle(permissionName: string): Observable<SinglePermission> {
    return this.http.get<SinglePermission>(apiUrl('roles', permissionName)).pipe(
      map((resp: SinglePermission) => {
        resp.permission = BPermission.fromRest(resp.permission);
        resp.roles = BRole.fromRestArray(resp.roles);
        return resp;
      })
    );
  }

  getTeamMemberListingRoles(): Observable<ListingRoles> {
    return this.http.get<ListingRoles>(this.URL_LISTINGS).pipe(
      map((resp) => {
        resp.enquiry = BRole.fromRestArray(resp.enquiry);
        resp.document = BRole.fromRestArray(resp.document);
        return resp;
      })
    );
  }

  addRole(name: string, description: string): Observable<void> {
    return this.http.post<void>(this.URL, { name, description });
  }

  removeRole(id: number, removeUsages: boolean): Observable<RoleUsages | void> {
    return this.http.delete<void>(this.URL, { body: { id, removeUsages } }).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 428) {
          const usages: RoleUsages = err.error;
          usages.users = BUser.fromRestArray(usages.users);
          usages.templates = BUserTemplate.fromRestArray(usages.templates);
          return of(usages);
        }
        throw err;
      })
    );
  }

  save(changes: RolePermissionChange[]): Observable<void> {
    return this.http.put<void>(this.URL, changes);
  }
}

export type RoleDefinitions = {
  roles: BRole[];
  permissions: BPermission[];
  rolePermissions: RolePermissions;
};

export type ListingRoles = {
  enquiry: BRole[];
  document: BRole[];
};

export type SinglePermission = {
  permission: BPermission;
  roles: BRole[];
  enabledForRoleIds: number[];
};

export type RolePermissions = { [roleId: number]: number[] };

export class RolePermissionChange {
  constructor(public permId: number, public roleId: number, public isAdded: boolean) {}
}

export type RoleUsages = {
  users: BUser[];
  templates: BUserTemplate[];
  licenseCounterRole: boolean;
};
