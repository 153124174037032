import { EMailImportDomainSettingsType } from '@mi-tool/enums';

export type TMailImportDomainSettingsData = {
  id: number;
  domainRegex: string;
  type: EMailImportDomainSettingsType;
};

export type TMailImportDomainSettingsDialogData = {
  mailImportDomainSettings: BMailImportDomainSettings;
};

export type TMailImportDomainSettingsParams = {
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  id?: number;
  domainRegex?: string;
  type?: string;
};

export class BMailImportDomainSettings {
  id: number;
  name?: string;
  domainRegex: string;
  type: EMailImportDomainSettingsType;

  static fromRest(json: any): BMailImportDomainSettings {
    return Object.assign(new BMailImportDomainSettings(), json);
  }

  static fromRestArray(json: any[]): BMailImportDomainSettings[] {
    return json && json.map((v) => BMailImportDomainSettings.fromRest(v));
  }
}
