import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MedisSidebarService } from '@mi-tool/components/sidebar/sidebar.service';
import { Helpers } from '@mi-tool/utils/helpers';
import { AuthService } from 'app/auth/auth.service';
import { NavItem, resolveNavItems } from 'app/common/util/navigation';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
  isInquiryDetailView = false;
  navigationRoots: NavItem[];
  navyChildren: NavItem[] = [];
  selectedNavyItem: NavItem;
  singleUsableItem: NavItem = undefined;
  isOpen: boolean;

  private subscription: Subscription = new Subscription();
  private allRoutes: NavItem[];

  constructor(
    private medisSidebarService: MedisSidebarService,
    private router: Router,
    private helpers: Helpers,
    private authService: AuthService
  ) {
    this.helpers.layoutDependsOnRouterEvent$$.subscribe((view) => {
      this.isInquiryDetailView = view === 'inquiryDetailView';
    });
  }

  ngOnInit(): void {
    this.subscription.add(
      this.authService.permissions.subscribe((handler) => {
        const routes = resolveNavItems(handler);
        this.navigationRoots = routes;
        this.allRoutes = routes
          .map((route) => (route.href ? route : route.children.flat(Infinity)))
          .flat();

        const usableItems = this.allRoutes.filter((r) => r.href);
        this.singleUsableItem = usableItems.length === 1 ? usableItems[0] : undefined;
        this.selectedNavyItem = usableItems[0];
      })
    );

    this.subscription.add(
      this.router.events
        .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
        .subscribe((arg: NavigationEnd) => {
          if (this.medisSidebarService.getSidebar('navbar')) {
            this.medisSidebarService.getSidebar('navbar').close();
            this.isOpen = this.medisSidebarService.getSidebar('navbar').folded;
          }

          this.selectedNavyItem =
            this.allRoutes.find((route) => route.allRefs.some((r) => arg.url.includes(r))) ||
            this.navigationRoots[0];
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleSidebarFolded(): void {
    this.medisSidebarService.getSidebar('navbar').toggleFold();
    this.isOpen = this.medisSidebarService.getSidebar('navbar').folded;
  }

  setChildren(item: NavItem): void {
    this.navyChildren = item.children;
  }

  setSelectedItem(item: NavItem): void {
    this.selectedNavyItem = item;
  }
}
