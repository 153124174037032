<span cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <mat-chip-option
    *ngIf="entity"
    [selectable]="selectable"
    [removable]="removable"
    (removed)="unselect()"
    class="no-padded-chip"
    (mouseover)="entity['moreInfo'] = !entity.isExternal"
    (mouseout)="entity['moreInfo'] = false"
  >
    <span
      class="entity-avatar {{ entity.class }}"
      matTooltip="{{ entity.avatarDescription }}"
      [ngClass]="{ external: entity.isExternal }"
    >
      {{ entity.avatarCode }}
    </span>
    {{ entity.value || entity.email }}
    <span matChipRemove><i class="fas fa-times medis-icon-reject"></i></span>
  </mat-chip-option>
</span>
<ng-template
  *ngIf="entity"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="entity['moreInfo']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' }
  ]"
>
  <app-entity-preview [entity]="entity"></app-entity-preview>
</ng-template>
