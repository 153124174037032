import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ENTER } from '@angular/cdk/keycodes';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import {
  BInteraction,
  EntitySearchParams,
  EntityService,
} from 'app/modules/data-model/data-model.module';
import { BEntity } from 'app/modules/data-model/entity/entity';
import { AdvancedEntitySearchComponent } from 'app/modules/advanced-entity-search/advanced-entity-search.component';
import { BRole } from 'app/modules/data-model/role/role';
import { BTeam } from 'app/modules/data-model/user/user';

@Component({
  selector: 'app-entity-search',
  templateUrl: './entity-search.component.html',
  styleUrls: ['./entity-search.component.scss'],
})
export class EntitySearchComponent implements OnInit {
  searching: boolean;
  items: BEntity[];

  term: UntypedFormControl;
  visible: boolean = true;
  selectable: boolean = true;
  addOnBlur: boolean = true;
  separatorKeysCodes = [ENTER];

  @Input() set disabled(val: boolean) {
    setTimeout(() => {
      val ? this.term.disable({ emitEvent: false }) : this.term.enable({ emitEvent: false });
    });
  }

  @Input() interaction: BInteraction;
  @Input() inquirerId: number;
  @Input() placeholder: string;
  @Input() isMultiselect: boolean;
  @Input() layoutStyle: string;
  // to handle external user insertion, create entity only with email
  @Input() allowExternalEntity: boolean;

  @Input() useSearch: boolean = true;

  @Input() selectedEntity: BEntity;
  @Output() selectedEntityChange: EventEmitter<BEntity>;

  @Input() selectedEntities: BEntity[] = [];
  @Output() selectedEntitiesChange: EventEmitter<BEntity[]>;

  @Input() advancedMode: string;
  @Output() advancedView: EventEmitter<boolean>;
  @Input() allowedTypes: string[];
  @Input() allowedRoles: BRole[];
  @Input() allowedTeam: BTeam;
  @Input() isActiveUsersSearch: boolean;
  @Input() isPersonIdentitySearch: boolean;
  @Input() closeOnEscapePressedKey: boolean;

  _initialSelectedEntities: BEntity[];

  pasted: string;

  constructor(private entityService: EntityService, private modalService: MatDialog) {
    this.items = new Array<BEntity>();
    this.term = new UntypedFormControl();
    this.selectedEntityChange = new EventEmitter();
    this.selectedEntitiesChange = new EventEmitter();
    this.advancedView = new EventEmitter();
    this.isMultiselect = false;
    this.layoutStyle = 'standard';
    this.placeholder = '';
    this.inquirerId = undefined;
    this.allowExternalEntity = false;
    this.searching = false;
    this.advancedMode = 'modal';
    this.allowedTypes = [];
    this.allowedRoles = [];
    this.allowedTeam = undefined;
  }

  ngOnInit() {
    this.term.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((term) => term !== ''),
        switchMap((term) => {
          this.search();
          return this.entityService.values;
        })
      )
      .subscribe((res) => {
        if (this.searching) {
          this.items = res;
          this.searching = false;
        }
      });

    //periodically check
    this.term.valueChanges.subscribe((value) => {
      if (value == '') {
        this.items = [];
      }
    });

    this.entityService.values.subscribe((entities) => {
      if (this.pasted) {
        this.handlePastedEmail(this.pasted, entities);
      } else {
        if (this.searching) {
          this.items = entities;
          this.searching = false;
        }
      }
    });
  }

  search() {
    this.searching = true;
    let params = new EntitySearchParams();
    if (this.allowedTypes.length > 0) {
      params.entityTypes = this.allowedTypes.join(',');
    }
    params.terms = this.term.value;
    params.isActiveUsersSearch = this.isActiveUsersSearch;
    params.isPersonIdentitySearch = this.isPersonIdentitySearch;
    if (this.inquirerId != undefined) {
      params.inquirerId = this.inquirerId;
    }
    if (this.allowedRoles.length > 0) {
      let roleIds = [];
      this.allowedRoles.forEach((element) => {
        roleIds.push(element.pk());
      });
      params.roleIds = roleIds.join(',');
    }
    if (this.allowedTeam != undefined) {
      params.teamId = this.allowedTeam.pk();
    }
    this.entityService.search(params);
  }

  select(item: BEntity, forceInsert: boolean = false) {
    let actualItem = this.items.filter(
      (x) =>
        (x.id == item.id && x.class == item.class) || (x.email == item.email && this.isMultiselect)
    )[0];
    if (!actualItem && forceInsert) {
      actualItem = item;
    }
    this.items = [];
    if (this.isMultiselect) {
      if (
        forceInsert ||
        this.selectedEntities.filter((x) => x.id == actualItem['id']).length == 0
      ) {
        this.selectedEntities.push(actualItem);
        this.selectedEntitiesChange.emit(this.selectedEntities);
      }
    } else {
      this.selectedEntity = actualItem;
      this.selectedEntityChange.emit(this.selectedEntity);
    }
    this.term.setValue('');
  }

  unselect(entity?: BEntity) {
    this.term.setValue('');
    if (this.isMultiselect) {
      this.selectedEntities = this.selectedEntities.filter((x) => {
        if (x.id) {
          return x.id != entity.id;
        }
        return x.email != entity.email;
      });
      this.selectedEntitiesChange.emit(this.selectedEntities);
    } else {
      this.selectedEntity = undefined;
      this.selectedEntityChange.emit(this.selectedEntity);
    }
  }

  cancel(): void {
    this.items = [];
    this.term.setValue('');
  }

  searchKeyDown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.cancel();
    }
  }

  @Input()
  set initialSelectedEntities(entities: BEntity[]) {
    if (this.selectedEntities.length == 0) {
      this.selectedEntities = entities;
    }
  }

  triggerPaste(value: string) {
    this.pasted = value.trim();
  }

  handlePastedEmail(value: string, results: BEntity[]) {
    // check if the pasted email is in the results -> select the entity
    let matches = results.filter((e) => e.email == value);
    if (matches.length > 0) {
      this.select(matches[0]);
    } else {
      // if not matches is found and value is an email -> create the entity object to be able to use it
      if (value.indexOf('@') >= 0) {
        this.select(new BEntity({ email: value }), true);
      }
    }
    // hack with timeout due to the refresh problem with the shareCC = "" and paste event
    setTimeout(() => {
      this.items = [];
      this.term.setValue('');
      this.pasted = undefined;
    }, 100);
  }

  insertExternalEmail() {
    if (!this.allowExternalEntity) {
      return;
    }
    let value = this.term.value;
    // check, if value is an email (TODO use a more robust check) insert the email
    if (value && value.indexOf('@') >= 0) {
      this.select(BEntity.newExternal(value), true);
    }
  }

  openBrowse() {
    if (this.advancedMode == 'modal') {
      const modalRef = this.modalService.open(AdvancedEntitySearchComponent, {
        minWidth: '95%',
        width: '80%',
        height: 'fit-content',
      });
      modalRef.componentInstance.dialogRef = modalRef;
      modalRef.componentInstance.interaction = this.interaction;
      modalRef.componentInstance.allowedMulti = this.isMultiselect;
      modalRef.componentInstance.selectedEntity = this.selectedEntity;
      modalRef.componentInstance.selectedEntities = this.selectedEntities;
    } else {
      this.advancedView.emit(true);
    }
  }
}
