import { gql } from 'apollo-angular';

import { BaseModel, rProperty, parseAttr, base64ToPK } from '../data-model';

import { BLanguage } from '../language/language';
import { BRegion } from '../region/region';

export class BCountry extends BaseModel {
  isActive: boolean = true;
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() code: string;
  @rProperty() timezoneCode: string;

  defaultLanguage: BLanguage;
  region: BRegion;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    // manually deserialize relations
    this.defaultLanguage = parseAttr<BLanguage>(json, BLanguage, 'defaultLanguage');
    this.region = parseAttr<BRegion>(json, BRegion, 'region');
    this.timezoneCode = json.timezone_code || null;
  }

  static fromRest(json: any): BCountry {
    if (json) {
      json.timezoneCode = json.timezone_code || null;
      json.defaultLanguage = BLanguage.fromRest(json.defaultLanguage);
    }
    return json && Object.assign(new BCountry({}), json);
  }

  static fromRestArray(json: any[]): BCountry[] {
    return json && json.map((v) => BCountry.fromRest(v));
  }

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }
}

export const fragment = gql`
  fragment countryFragment on CountryNode {
    id
    name
    code
    timezoneCode
  }
`;

export const fragmentConnection = gql`
  fragment countryConnectionFragment on CountryNodeConnection {
    edges {
      node {
        ...countryFragment
      }
    }
  }
  ${fragment}
`;
