<mat-chip-listbox
  class="chip-wrapper"
  [ngClass]="isInvalid() ? 'invalid' : style"
  [disabled]="disabled"
>
  @for (choice of choices; track choice.value; let first = $first; let last = $last) {
  <mat-basic-chip
    [ngClass]="{
      'chip-first': first,
      'chip-last': last,
      'chip-inner': !first && !last,
      'chip-selected-basic': value === choice.value
    }"
    [attr.data-testid]="choice.value"
    (click)="!disabled && selectValue(choice.value)"
  >
    {{ (choice.translationKey ? choice.translationKey : choice.name) | translate }}
  </mat-basic-chip>
  }
</mat-chip-listbox>
