<mat-form-field
  *ngIf="control"
  class="w-100 form-field-wrapper"
  [class.invalid]="validateOnLoad && control.errors"
  [hideRequiredMarker]="true"
>
  <mat-label>
    {{ 'PASSWORD' | translate }}
    <span *ngIf="isRequired()"> ({{ 'REQUIRED' | translate }})</span>
  </mat-label>
  <input
    matInput
    [formControl]="control"
    [type]="hide ? 'password' : 'text'"
    autocomplete="off"
    (focus)="showHintPass = true"
    (blur)="showHintPass = false"
  />
  <i
    class="fas medis-icon medis-pr-10"
    [ngClass]="hide ? 'fa-eye' : 'fa-eye-slash'"
    matSuffix
    (click)="toggleVisibility()"
  ></i>
  <mat-error *ngIf="hasAnyError('required')">{{ 'PASS_IS_REQUIRED' | translate }}</mat-error>
  <mat-error *ngIf="hasAnyError('recent')">{{ 'PASS_IS_RECENT' | translate }}</mat-error>
  <mat-hint *ngIf="showVerifyingToken">{{ 'VERIFYING_TOKEN' | translate }}</mat-hint>
</mat-form-field>
<div *ngIf="showHintPass" class="hint-msg">
  @for (error of errorType; track error.name) { @if (hasAnyError(error.name) || !control.value) {
  <div class="text-13-800-coral">
    <i class="fas fa-times-circle"></i> {{ error.msg | translate }}
  </div>
  } @else {
  <div class="text-13-800-green">
    <i class="fas fa-check-circle"></i> {{ error.msg | translate }}
  </div>
  } }
</div>
