import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { manuals, videoTutorials } from '@mi-tool/consts';
import {
  Locale,
  MiiToolTranslationLoaderService,
} from '@mi-tool/services/translation-loader.service';
import { MedisSidebarService } from '@mi-tool/components/sidebar/sidebar.service';
import { Helpers } from '@mi-tool/utils/helpers';
import { toolbarNavigation } from '@mi-tool/navigation/profile-navigation';
import { MedisNavigationItem } from '@mi-tool/types';
import { miToolLanguages } from 'locale/language-list';
import { AuthService } from 'app/auth/auth.service';
import { environment } from 'app/../environments/environment';
import { BUser } from 'app/modules/data-model/user/user';
import { Subscription } from 'rxjs';
import { InquiriesStateService } from 'app/modules/inquiries/services/inquiries-state.service';
import { LATEST_MESSAGES } from 'app/common/common/message-handler/message-handler.service';
import { PermissionEditService } from 'app/common/common/permission-edit/permission-edit.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  manuals = manuals;
  videoTutorials = videoTutorials;
  toolbarNavigation: MedisNavigationItem[];
  languageList: Locale[] = miToolLanguages;
  isInquiriesView: boolean;
  isAuthRequestsView: boolean;
  isRolesAdmin: boolean;
  isSpamOrDraftsView: boolean;
  loggedUser: BUser;
  supportEmail = environment.supportEmail;
  enableExportButton: boolean;
  latestMessages = LATEST_MESSAGES;

  private subs: Subscription = new Subscription();

  constructor(
    public permissionEdit: PermissionEditService,
    private _miiToolTranslationLoaderService: MiiToolTranslationLoaderService,
    private _translateService: TranslateService,
    private _router: Router,
    private _medIsSidebarService: MedisSidebarService,
    private _helpers: Helpers,
    private _authService: AuthService,
    private inquiriesStateService: InquiriesStateService
  ) {
    this._miiToolTranslationLoaderService.loadTranslations(this.languageList);
    this._translateService.setDefaultLang('en');
    this._translateService.use(localStorage.getItem('medis_language') || 'en');

    this._helpers.layoutDependsOnRouterEvent$$.subscribe((routerUrl) => {
      if (routerUrl) {
        this.isInquiriesView = routerUrl === 'inquiriesView';
        this.isAuthRequestsView = routerUrl === 'authRequestsView';
        this.isRolesAdmin = routerUrl === 'rolesAdmin';
        this.isSpamOrDraftsView = ['/drafts', '/spam/list'].includes(this._router.url);
      }
    });
    this.subs.add(
      this.inquiriesStateService.interactions.subscribe((int) => {
        this.enableExportButton = int.length > 0;
      })
    );
  }

  ngOnInit() {
    this._authService.user.subscribe((user) => {
      if (user) {
        this.loggedUser = user;
      }
    });
    this._authService.permissions.subscribe((handler) => {
      this.toolbarNavigation = toolbarNavigation(handler);
    });
  }

  setLanguage(langId: string): void {
    this._translateService.use(langId);
    localStorage.setItem('medis_language', langId);
  }

  navigateUser(item: MedisNavigationItem): void {
    if (item.id === 'Preferences') {
      this._router.navigate(['/profile/out-of-office']);
    } else if (item.id === 'Auto_assign') {
      this._router.navigate(['/profile/auto-assign']);
    } else if (item.id === 'Approval_assignment') {
      this._router.navigate(['/profile/approval-assignment']);
    } else if (item.id === 'Password_reset') {
      this._router.navigate(['/profile/password-reset']);
    } else if (item.id === 'Role_settings') {
      this._router.navigate(['/profile/role-settings']);
    } else if (item.id === 'Notification_settings') {
      this._router.navigate(['/profile/notification-settings']);
    } else if (item.id === 'Template_settings') {
      this._router.navigate(['/profile/template-settings']);
    } else {
      this._router.navigate(['/profile/profile-settings']);
    }
  }

  logoutClicked(): void {
    this._medIsSidebarService.getSidebar('rightPanel').fold();
    this._authService.logout();
    this._router.navigate(['/auth/login']);
  }

  toggleRightSidebarFolded(): void {
    this._medIsSidebarService.getSidebar('rightPanel').toggleFold();
  }
}
