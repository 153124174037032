import { gql } from 'apollo-angular';

import {
  base64ToModel,
  base64ToPK,
  BaseModel,
  parseArray,
  parseAttr,
  rProperty,
} from '../data-model';
import { BMedicalDocumentType } from '../medical-document-type/medical-document-type';
import { BMedicalDocumentVersionFile } from '../medical-document-version-file/medical-document-version-file';
import { BMedicalDocumentAudience } from '../medical-document-audience/medical-document-audience';
import { BProduct } from '../region/region';
import { BLanguage } from '../language/language';
import { BCategory } from '../category/category';
import { BTopic } from '../topic/topic';
import { BTeam, BUser } from '../user/user';
import { BCountry } from '../country/country';
import { DateUtility } from 'app/common/date-utility';
import { environment } from 'app/../environments/environment';
import { BMDWord } from '../md-word/md-word';

export class BMedicalDocument extends BaseModel {
  @rProperty() id: string;
  @rProperty() title: string;
  @rProperty() shortTitle: string;
  @rProperty() documentId: string;
  @rProperty() keywords: string;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() internalTitle: string;
  // convenient property to handle locally the link removal
  @rProperty() historyObjectId: string;
  @rProperty() outputFormat: string;

  // relations
  type: BMedicalDocumentType;
  targetAudiences: BMedicalDocumentAudience[];

  owner: BTeam;
  language: BLanguage;

  usableBy: BTeam[];
  products: BProduct[];
  categories: BCategory[];
  topics: BTopic[];
  localizationOf: BMedicalDocument;
  localizedMd: BMedicalDocument[];
  currentVersion: BMedicalDocumentVersion;
  lastVersionForUser: BMedicalDocumentVersion;
  versions: BMedicalDocumentVersion[];
  createdBy: BUser;
  editedBy: BUser;
  literature: BLiterature;
  smpcpil: BSmpcPil;
  usableByCountries: BCountry[];
  therapeuticArea: BTherapeuticArea;

  constructor(json?: any) {
    super(json);
    this.init(json);
  }

  init(json: any): void {
    this.type = parseAttr<BMedicalDocumentType>(json, BMedicalDocumentType, 'type');
    this.targetAudiences = parseArray<BMedicalDocumentAudience>(
      json,
      BMedicalDocumentAudience,
      'targetAudiences'
    );
    this.owner = parseAttr<BTeam>(json, BTeam, 'owner');
    this.language = parseAttr<BLanguage>(json, BLanguage, 'language');
    this.usableBy = parseArray<BTeam>(json, BTeam, 'usableBy');
    this.products = parseArray<BProduct>(json, BProduct, 'products');
    this.categories = parseArray<BCategory>(json, BCategory, 'categories');
    this.topics = parseArray<BTopic>(json, BTopic, 'topics');
    this.localizationOf = parseAttr<BMedicalDocument>(json, BMedicalDocument, 'localizationOf');
    this.localizedMd = parseArray<BMedicalDocument>(json, BMedicalDocument, 'localizedMd');
    this.currentVersion = parseAttr<BMedicalDocumentVersion>(
      json,
      BMedicalDocumentVersion,
      'currentVersion'
    );
    this.lastVersionForUser = parseAttr<BMedicalDocumentVersion>(
      json,
      BMedicalDocumentVersion,
      'lastVersionForUser'
    );
    this.versions = parseArray<BMedicalDocumentVersion>(json, BMedicalDocumentVersion, 'versions');
    this.createdBy = parseAttr<BUser>(json, BUser, 'createdBy');
    this.editedBy = parseAttr<BUser>(json, BUser, 'editedBy');
    this.literature = parseAttr<BLiterature>(json, BLiterature, 'literatureDetails');
    this.smpcpil = parseAttr<BSmpcPil>(json, BSmpcPil, 'smpcpilDetails');
    this.therapeuticArea = parseAttr<BTherapeuticArea>(json, BTherapeuticArea, 'therapeuticArea');
    this.usableByCountries = parseArray<BCountry>(json, BCountry, 'usableByCountries');
    let areas = parseArray<BTherapeuticArea>(json, BTherapeuticArea, 'therapeuticAreas');
    if (areas) {
      this.therapeuticArea = areas[0];
    }
  }

  static fromRest(json: any): BMedicalDocument {
    if (json) {
      json.currentVersion = BMedicalDocumentVersion.fromRest(json.currentVersion);
      json.type = BMedicalDocumentType.fromRest(json.type);
      return Object.assign(new BMedicalDocument({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]): BMedicalDocument[] {
    return json && json.map((v) => BMedicalDocument.fromRest(v));
  }

  pk(): number {
    return base64ToPK(this.id);
  }

  get categoriesDescription() {
    return this.categories.map((c) => c.name).join(', ');
  }

  get topicsDescription() {
    return this.topics.map((t) => t.name).join(', ');
  }

  get productsDescription() {
    return this.products.map((p) => p.name).join(', ');
  }

  get historyObjectPk(): number {
    return base64ToPK(this.historyObjectId);
  }

  get isHistoryNew(): boolean {
    return base64ToModel(this.historyObjectId) === 'NewHistoryNode';
  }

  isBinaryFile(): boolean {
    if (!this.type) {
      return false;
    }
    return ['smpc', 'pil', 'lit'].includes(this.type.code);
  }
}

export namespace MMedicalDocument {
  export const fragment = gql`
    fragment medicalDocumentFragment on MedicalDocumentNode {
      id
      title
      shortTitle
      internalTitle
      documentId
      keywords
      createdTs
      editedTs
      outputFormat
    }
  `;
}

export class DocumentCreationData {
  /**
   * Lightweight wrapper to handle in a simpler way the document data during creation process
   */
  id: string;
  title: string;
  shortTitle: string;
  documentId: string;
  keywords: string;
  internalTitle: string;
  typeId: string;
  ownerId: string;
  languageId: string;

  targetAudiencesId: string[];
  usableById: string[];
  productsId: string[];
  categoriesId: string[];
  topicsId: string[];
  countriesId: string[];

  therapeuticAreaId: string;
  localizationOfId: string;
  file: string; // base64 encoded
  url: string;

  versionId: string;
  versionNumber: number = 1;
  scheduledPublishingDate: Date = new Date();
  scheduledExpirationDate: Date = DateUtility.newDateAfterYears(2);

  serialize(): Object {
    let dump = {};
    for (let key of Object.keys(this)) {
      if (this[key] instanceof Array) {
        dump[key] = JSON.stringify(this[key]);
      } else {
        dump[key] = this[key];
      }
    }
    return dump;
  }

  setFromDocument(document: BMedicalDocument) {
    this.id = document.id;
    this.title = document.title;
    this.shortTitle = document.shortTitle;
    this.documentId = document.documentId;
    this.keywords = document.keywords;
    this.internalTitle = document.internalTitle;
    this.typeId = document.type.id;
    if (document.owner) {
      this.ownerId = document.owner.id;
    }
    if (document.language) {
      this.languageId = document.language.id;
    }
    if (document.targetAudiences) {
      this.targetAudiencesId = document.targetAudiences.map((t) => t.id);
    }
    if (document.usableBy) {
      this.usableById = document.usableBy.map((t) => t.id);
    }
    if (document.products) {
      this.productsId = document.products.map((t) => t.id);
    }
    if (document.categories) {
      this.categoriesId = document.categories.map((t) => t.id);
    }
    if (document.topics) {
      this.topicsId = document.topics.map((t) => t.id);
    }
    if (document.usableByCountries) {
      this.countriesId = document.usableByCountries.map((t) => t.id);
    }
    if (document.therapeuticArea) {
      this.therapeuticAreaId = document.therapeuticArea.id;
    }
    if (document.localizationOf) {
      this.localizationOfId = document.localizationOf.id;
    }
  }
}

export class BTherapeuticArea extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() description: string;
  @rProperty() image: string;

  isActive: boolean;
  subAreas: BTherapeuticArea[];
  products: BProduct[];
  documents: BMedicalDocument[];
  parentArea: BTherapeuticArea;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    if (this.image) {
      this.image = environment.apiBaseUrl + this.image;
    }
    this.products = parseArray<BProduct>(json, BProduct, 'products');
    this.documents = parseArray<BMedicalDocument>(json, BMedicalDocument, 'documents');
    this.subAreas = parseArray<BTherapeuticArea>(json, BTherapeuticArea, 'subAreas');
    this.parentArea = parseAttr<BTherapeuticArea>(json, BTherapeuticArea, 'parentArea');
  }

  static fromRest(json: any): BTherapeuticArea {
    return Object.assign(new BTherapeuticArea({}), json);
  }

  static fromRestArray(json: any[]): BTherapeuticArea[] {
    return json && json.map((v) => BTherapeuticArea.fromRest(v));
  }

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }
}

export namespace MTherapeuticArea {
  // fragments will contain only actual values of the object, no references to other tables
  export const fragment = gql`
    fragment therapeuticAreaFragment on TherapeuticAreaNode {
      id
      name
      description
      image
    }
  `;

  export const fragmentConnection = gql`
    fragment therapeuticAreaConnectionFragment on TherapeuticAreaNodeConnection {
      edges {
        node {
          ...therapeuticAreaFragment
        }
      }
    }
    ${fragment}
  `;
}

export class BMedicalDocumentVersion extends BaseModel {
  @rProperty() id: string;
  @rProperty() versionNumber: number;
  @rProperty(Date) scheduledPublishingDate: Date;
  @rProperty(Date) scheduledExpirationDate: Date;
  @rProperty(Date) scheduled_expiration_date?: Date;
  @rProperty(Date) published_date?: Date;
  @rProperty(Date) publishedDate: Date;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() sharepointUrl: string;
  @rProperty() url: string;
  @rProperty() extractedText: string;
  @rProperty() documentId: string;
  @rProperty() statusName: string;

  // relations
  document: BMedicalDocument;
  files: BMedicalDocumentVersionFile[];
  localizationOf: BMedicalDocumentVersion;
  createdBy: BUser;
  editedBy: BUser;
  word: BMDWord;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    // manually deserialize relations
    this.document = parseAttr<BMedicalDocument>(json, BMedicalDocument, 'document');
    this.files = parseArray<BMedicalDocumentVersionFile>(
      json,
      BMedicalDocumentVersionFile,
      'files'
    );
    this.localizationOf = parseAttr<BMedicalDocumentVersion>(
      json,
      BMedicalDocumentVersion,
      'localizationOf'
    );
    this.createdBy = parseAttr<BUser>(json, BUser, 'createdBy');
    this.editedBy = parseAttr<BUser>(json, BUser, 'editedBy');
    this.word = parseAttr<BMDWord>(json, BMDWord, 'firstWord');
  }

  static fromRest(json: any): BMedicalDocumentVersion {
    if (json) {
      json.files = BMedicalDocumentVersionFile.fromRestArray(json.files);
      return Object.assign(new BMedicalDocumentVersion({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]): BMedicalDocumentVersion[] {
    return json && json.map((v) => BMedicalDocumentVersion.fromRest(v));
  }

  pk(): number {
    return base64ToPK(this.id);
  }

  pkDocument(): number {
    if (!this.documentId) {
      return undefined;
    }
    return base64ToPK(this.documentId);
  }

  sharepointUrlEdit(): string {
    return this.sharepointUrl.replace('action=default', 'action=edit');
  }
}

export namespace MMedicalDocumentVersion {
  export const fragment = gql`
    fragment medicalDocumentVersionFragment on MedicalDocumentVersionNode {
      id
      versionNumber
      documentId
      scheduledPublishingDate
      scheduledExpirationDate
      publishedDate
      createdTs
      editedTs
      sharepointUrl
      extractedText
      url
      statusName
    }
  `;

  export const fragmentConnection = gql`
    fragment medicalDocumentVersionConnectionFragment on MedicalDocumentVersionNodeConnection {
      edges {
        node {
          ...medicalDocumentVersionFragment
        }
      }
    }
    ${fragment}
  `;
}

export class BSmpcPil extends BaseModel {
  @rProperty() id: string;
  @rProperty() authorisationNumbers: string;
  @rProperty() authorisationHolder: string;
  @rProperty(Date) publishedDate: Date;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() url: string;

  //relations
  version: BMedicalDocumentVersion;
  createdBy: BUser;
  editedBy: BUser;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.version = parseAttr<BMedicalDocumentVersion>(json, BMedicalDocumentVersion, 'version');
  }
}

export namespace MSmpcPil {
  export const fragment = gql`
    fragment smpcpilFragment on SmPCAndPILNode {
      id
      authorisationNumbers
      authorisationHolder
      publishedDate
      publishedDate
      editedTs
      createdTs
      url
    }
  `;

  export const fragmentConnection = gql`
    fragment smpcpilConnectionFragment on SmPCAndPILNodeConnection {
      edges {
        node {
          ...smpcpilFragment
        }
      }
    }
    ${fragment}
  `;
}

export class BLiterature extends BaseModel {
  @rProperty() id: string;
  @rProperty() authors: string;
  @rProperty() publishedOn: string;
  @rProperty(Date) publishedDate: Date;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() volume: string;
  @rProperty() issue: string;
  @rProperty() pages: string;
  @rProperty() pubmedId: number;
  @rProperty() doi: string;
  @rProperty() reference: string;
  @rProperty() redistributable: boolean;
  @rProperty() citationCode: string;
  @rProperty() fileId: number;
  isExternal: boolean; //reference not in DB, downloaded from internet (pubmed, etc etc)

  //relations
  version: BMedicalDocumentVersion;
  createdBy: BUser;
  editedBy: BUser;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.version = parseAttr<BMedicalDocumentVersion>(json, BMedicalDocumentVersion, 'version');
  }
}

export namespace MLiterature {
  export const fragment = gql`
    fragment literatureFragment on LiteratureNode {
      id
      authors
      publishedOn
      publishedDate
      createdTs
      editedTs
      volume
      issue
      pages
      pubmedId
      doi
      reference
      redistributable
      citationCode
      fileId
    }
  `;

  export const fragmentConnection = gql`
    fragment literatureConnectionFragment on LiteratureNodeConnection {
      edges {
        node {
          ...literatureFragment
        }
      }
    }
    ${fragment}
  `;
}
