import { Component, Input } from '@angular/core';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { base64ToPK } from 'app/modules/data-model/data-model';

import { BMedicalDocument } from 'app/modules/data-model/medical-document/medical-document';
import { BMDWordSection } from 'app/modules/data-model/md-word-section/md-word-section';

@Component({
  selector: 'app-slide-link',
  templateUrl: './slide-link.component.html',
  styleUrls: ['./slide-link.component.scss'],
})
export class SlideLinkComponent {
  @Input() adminMode: boolean = false;

  currentIndex: number;
  allSlides: BMDWordSection[] = [];
  countSelected: number = 0;
  checkAttachSelected: boolean;

  private _document: BMedicalDocument;

  constructor(private urlResolver: UrlResolverService) {}

  @Input()
  set document(doc: BMedicalDocument) {
    this._document = doc;
    if (this.document.currentVersion.word && this.document.currentVersion.word.sections) {
      this.allSlides = this.document.currentVersion.word.sections;
      this.currentIndex = 0;
    }
  }

  get document(): BMedicalDocument {
    return this._document;
  }

  getSlideImgLink(slide: BMDWordSection): string {
    if (slide && slide.id) {
      const slideId = base64ToPK(slide.id);
      return this.urlResolver.apiUrlForPath(['api-rest/repository/browser/slide/' + slideId + '/']);
    }

    return undefined;
  }

  select(slide: BMDWordSection, isChecked: boolean): void {
    slide['selected'] = isChecked;
    this.refreshSelected();
  }

  selectAll(isChecked: boolean): void {
    this.allSlides.forEach((s) => {
      s['selected'] = isChecked;
    });
    this.refreshSelected();
  }

  private refreshSelected(): void {
    this.countSelected = this.allSlides.filter((s) => s['selected']).length;
    this.checkAttachSelected = this.countSelected > 0;
  }
}
