<h2 mat-dialog-title>
  <span *ngIf="mode === 'internal_comm'">
    {{ 'INTERNAL_COMM' | translate }}
  </span>
  <span *ngIf="mode === 'history' && !historyObject">
    <i class="fa fa-history" aria-hidden="true"></i> {{ 'HISTORY' | translate }}
  </span>
  <span
    *ngIf="
      mode === 'history' &&
      historyObject?.category &&
      leftBarHistoryCategories.includes(historyObject.category)
    "
  >
    {{ leftBarHistoryCategoriesMap[historyObject.category] | translate }}
    <ng-container *ngIf="historyObject.category === 'fu_response'">
      {{ 'VIA' | translate }} {{ historyObject.channel }}
    </ng-container>
    {{ 'ON' | translate }}
    {{ historyObject.createdTs | dateChecker }}
  </span>
  <i class="far fa-times-circle medis-icon-large" mat-dialog-close></i>
</h2>
<mat-dialog-content *ngIf="visualize != 'detail' && interaction" class="history-content">
  <div class="medis-flex-container-between medis-pb-20" *ngIf="mode === 'history'">
    <div *ngIf="visualize === 'inquirer'">
      <app-permission-edit [permissionName]="'View Enquirer'">
        <span class="text-14-500-dark">
          <i class="fa fa-user"></i> {{ 'INQUIRER' | translate }}:
          {{ interaction.inquirer | displayInfo }}
        </span>
      </app-permission-edit>
    </div>
    <app-search-select
      *ngIf="visualize === 'interaction'"
      [config]="interactionConfig"
      [(selectedValue)]="selectedInteractionFilter"
      [multiple]="true"
    ></app-search-select>
    <div class="medis-toggle-group-wrapper medis-ml-10">
      <mat-button-toggle-group [(ngModel)]="visualize">
        <mat-button-toggle value="interaction">{{ 'INQUIRY' | translate }}</mat-button-toggle>
        <mat-button-toggle value="inquirer">{{ 'INQUIRER' | translate }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="medis-flex-container-even medis-pb-20" *ngIf="mode === 'internal_comm' && !hideAdd">
    <button mat-button class="medis-button-main" (click)="openAddNote()">
      {{ 'ADD_A_NOTE' | translate }}
    </button>
  </div>
  <app-loading-indicator
    [loading]="isInquirerHistoryLoading || isInteractionHistoryLoading"
  ></app-loading-indicator>
  <app-message [message]="message"></app-message>
  <app-detail-history-interaction
    *ngIf="visualize == 'interaction'"
    [interaction]="interaction"
    [inquiry]="inquiry"
    [interactionFilter]="selectedInteractionFilter"
    [history]="interactionHistory"
    [mode]="mode"
    [loggedInUserIsSysAdmin]="loggedInUserIsSysAdmin"
  ></app-detail-history-interaction>
  <app-detail-history-inquirer
    *ngIf="visualize == 'inquirer'"
    [history]="inquirerHistory"
  ></app-detail-history-inquirer>
</mat-dialog-content>
<ng-container *ngIf="historyObject && visualize === 'detail'">
  <div *ngIf="historyMessage">
    <p class="text-14-700-dark medis-mr-5">
      {{ 'From' | translate }}:
      <span class="medis-tag-13-azure selected">{{ historyMessage.fromHeader }}</span>
    </p>
    <p class="text-14-700-dark medis-mr-5">
      {{ 'To' | translate }}:
      <span class="medis-tag-13-azure selected">{{ historyMessage.toHeader }}</span>
    </p>
    <p class="text-14-700-dark medis-mr-5">
      {{ 'SUBJECT' | translate }}: {{ historyMessage.subject }}
    </p>
  </div>
  <p class="text-14-700 mb-2">{{ 'MESSAGE' | translate }}:</p>
  <div [innerHTML]="historyObject?.text | sanitizeHtml"></div>
  <div *ngFor="let link of historyObject.attachments">
    <app-link-visualization
      [link]="link"
      [allowRemove]="false"
      [canOpenFile]="loggedInUserIsSysAdmin || !link.isDeleted"
    ></app-link-visualization>
  </div>
</ng-container>
