import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEqual } from 'lodash';
import { BaseModel } from 'app/modules/data-model/data-model';
import { BDomain } from 'app/modules/data-model/domain/domain';
import { BMailImportDomainSettings } from 'app/modules/data-model/mail-import-domain-settings/mail-import-domain-settings';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('passwordConfirm').value;

    if (password !== confirmPassword) {
      control.get('passwordConfirm').setErrors({ NoPassswordMatch: true });
    }
    return null;
  }

  static uniquePassword(control: AbstractControl): ValidationErrors | null {
    const password: string = control.get('password').value;
    const email: string = control.get('email').value.toLowerCase();
    const firstName: string = control.get('firstName').value.toLowerCase();
    const lastName: string = control.get('lastName').value.toLowerCase();

    const pass = password.toLowerCase();
    if (
      (email && pass.includes(email)) ||
      (firstName && pass.includes(firstName)) ||
      (lastName && pass.includes(lastName))
    ) {
      control.get('password').setErrors({ identifier: true });
    }
    return null;
  }

  static truthyArrayValues(control: AbstractControl) {
    const hasEmptyValues = control.value.some((val) => !val);

    return hasEmptyValues ? { required: true } : null;
  }

  static hasChanges(initialObjValues: Object): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      if (!form.valid) {
        return null;
      }
      return isEqual(initialObjValues, form.value) ? { noChanges: true } : null;
    };
  }

  static isDuplicate(
    arrayOfObjects: BaseModel[] | BDomain[] | BMailImportDomainSettings[],
    excludeByField: string,
    uniqueField: string
  ): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      if (!form.valid) {
        return null;
      }
      return arrayOfObjects.some(
        (object) =>
          object?.[excludeByField] != form.get(excludeByField).value &&
          object?.[uniqueField].toLowerCase() === form.get(uniqueField).value.toLowerCase()
      )
        ? { duplicate: true }
        : null;
    };
  }
}
