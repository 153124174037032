<div class="row">
  <div class="col">
    <mat-form-field
      class="w-100"
      [class.cursor-not-allowed]="term.disabled"
      (keydown)="closeOnEscapePressedKey ? searchKeyDown($event) : null"
    >
      <input
        type="text"
        autocomplete="off"
        matInput
        [placeholder]="
          isMultiselect
            ? placeholder + 'Type by name or copy an email, use enter to add an external recipient.'
            : placeholder
        "
        [formControl]="term"
        (keyup.enter)="insertExternalEmail()"
        (focusout)="insertExternalEmail()"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (paste)="triggerPaste($event.clipboardData.getData('text/plain'))"
        [class.cursor-not-allowed]="term.disabled"
      />
      <mat-chip-grid #chipList>
        <ng-container *ngIf="isMultiselect">
          <app-entity-chip
            *ngFor="let entity of selectedEntities"
            [entity]="entity"
            (removed)="unselect(entity)"
          ></app-entity-chip>
        </ng-container>
        <ng-container *ngIf="!isMultiselect">
          <app-entity-chip
            *ngIf="selectedEntity !== undefined && layoutStyle == 'standard'"
            [selectable]="!term.disabled"
            [removable]="!term.disabled"
            [entity]="selectedEntity"
            (removed)="unselect()"
          ></app-entity-chip>
          <mat-chip-row
            *ngIf="selectedEntity !== undefined && layoutStyle == 'userProfile'"
            [selectable]="selectable"
            (removed)="unselect()"
          >
            {{ selectedEntity.firstName }} {{ selectedEntity.lastName }}
            <mat-icon matChipRemove>&#xE5C9;</mat-icon>
          </mat-chip-row>
        </ng-container>
      </mat-chip-grid>
    </mat-form-field>
  </div>
  <div
    *ngIf="useSearch && term.enabled"
    (click)="openBrowse()"
    class="col-auto icon-link-container"
  >
    <i class="fa fa-search medis-icon"></i>
  </div>
</div>

<mat-list [hidden]="items.length == 0" class="suggest-list">
  <i
    class="far fa-times-circle medis-icon-large suggestion-list-close-button"
    (click)="cancel()"
  ></i>
  <mat-list-item *ngFor="let item of items" (click)="select(item)">
    <div class="entity-entry">
      <div
        class="entity-avatar {{ item.class }}"
        matTooltip="{{ item.avatarDescription }}"
        [ngClass]="{ external: item.isExternal }"
      >
        {{ item.avatarCode }}
      </div>
      <div>{{ item.value }}</div>
      <div>{{ item.email }}</div>
      <div class="info-container">
        <i
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          class="far fa-question-circle medis-icon"
          (mouseenter)="item['moreInfo'] = true"
          (mouseleave)="item['moreInfo'] = false"
        ></i>
      </div>
    </div>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="item['moreInfo']"
      [cdkConnectedOverlayPositions]="[
        { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top' }
      ]"
    >
      <app-entity-preview [entity]="item"></app-entity-preview>
    </ng-template>
  </mat-list-item>
</mat-list>
