import { Component, OnInit } from '@angular/core';
import {
  NomenclaturesService,
  Scopes,
} from 'app/modules/data-model/nomenclature/nomenclatures.service';
import {
  FIELD_CONFIG,
  FIELD_TO_CRITERIA_CONFIG,
  FIELD_TO_TYPE,
  FType,
  loadConfig,
  OPERATOR_OPTIONS,
} from 'app/common/common/constants/rules';
import { RulesService } from 'app/modules/data-model/rule/rule.service';
import { BRule, Cond, newCond, newGroup } from 'app/modules/data-model/rule/rule';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DateRange } from 'app/common/types';
import { cloneDeep, isEqual } from 'lodash';
import { newNameIdConfig } from 'app/common/common/search-select/search-select.config';
import { Helpers } from '@mi-tool/utils/helpers';

@Component({
  selector: 'app-rule-dialog',
  templateUrl: './rule-dialog.component.html',
  styleUrl: './rule-dialog.component.scss',
})
export class RuleDialogComponent implements OnInit {
  rule: BRule;
  fType = FType;
  typeConfig = newNameIdConfig([], 'Type');
  fieldConfig = FIELD_CONFIG;
  fieldToType = FIELD_TO_TYPE;
  fieldToCriteriaConfig = FIELD_TO_CRITERIA_CONFIG;
  fieldToDropdownConfig = loadConfig();
  operatorOptions = OPERATOR_OPTIONS;
  isRuleInvalid = true;
  noChanges: boolean;
  selectedTeamsWithApproval: string;
  preselectTypeId: number;

  private initialRule: BRule;
  private teamsWithApprovalMap: Record<number, string> = {};

  constructor(
    private dialogRef: MatDialogRef<RuleDialogComponent>,
    private msgService: MessageHandlerService,
    private nomenclatureService: NomenclaturesService,
    private rulesService: RulesService,
    private helpers: Helpers
  ) {}

  ngOnInit(): void {
    this.noChanges = !!this.rule.id;
    this.initialRule = cloneDeep(this.rule);

    this.nomenclatureService
      .get(
        new Scopes()
          .ruleTypes()
          .teamsWithEnquiryApprovalInfo()
          .activeInqProcessingUsers()
          .activeCategories()
          .activeTopics()
      )
      .subscribe((response) => {
        response.teams.forEach(
          (t) => t.enquiryAnswerApproval && (this.teamsWithApprovalMap[t.pk()] = t.name)
        );
        this.validateSelectedTeams();
        this.fieldToDropdownConfig = loadConfig(response);
        this.typeConfig = newNameIdConfig(response.ruleTypes, 'Type');
        if (this.preselectTypeId && !this.rule.id) {
          this.rule.typeId = this.preselectTypeId;
        } else if (response.ruleTypes.length === 1 && !this.rule.id) {
          this.rule.typeId = response.ruleTypes[0].id;
        }
      });
  }

  validateRule(): void {
    this.isRuleInvalid = this.rule.isInvalid();
    this.noChanges = this.rule.id && isEqual(this.rule, this.initialRule);
    this.validateSelectedTeams();
  }

  onDateRangeChange(event: DateRange): void {
    this.rule.validFrom = event?.from;
    this.rule.validTo = event?.to;
    this.validateRule();
  }

  onFieldChange(cond: Cond): void {
    cond.criteria = this.fieldToCriteriaConfig[cond.field].rawItems[0].id;
    cond.values = [];
    this.validateRule();
  }

  addCond(groupIndex: number): void {
    const newId = Math.max(...this.rule.groups[groupIndex].conds.map((c) => c.id)) + 1;
    this.rule.groups[groupIndex].conds.push(newCond(newId));
    this.validateRule();
  }

  removeCond(groupIndex: number, condIndex: number): void {
    if (this.rule.groups[groupIndex].conds.length === 1) {
      this.removeGroup(groupIndex);
    } else {
      this.rule.groups[groupIndex].conds.splice(condIndex, 1);
      this.validateRule();
    }
  }

  addGroup(): void {
    const newId = Math.max(...this.rule.groups.map((g) => g.id)) + 1;
    this.rule.groups.push(newGroup(newId));
    this.validateRule();
  }

  removeGroup(groupIndex: number): void {
    this.rule.groups.splice(groupIndex, 1);
    this.validateRule();
  }

  create(): void {
    this.rulesService.create(this.rule).subscribe({
      next: () => {
        this.msgService.info('The rule has been successfully created!');
        this.dialogRef.close(true);
      },
      error: (error) =>
        this.msgService.httpError('An error occurred while creating the rule', error),
    });
  }

  edit(): void {
    this.rulesService.edit(this.rule).subscribe({
      next: () => {
        this.msgService.info('The rule has been successfully edited!');
        this.dialogRef.close(true);
      },
      error: (error) =>
        this.msgService.httpError('An error occurred while editing the rule', error),
    });
  }

  private validateSelectedTeams(): void {
    const selectedTeamsWithApproval = [];
    this.rule.groups.forEach((g) =>
      g.conds.forEach((c) => {
        if (c.field != 'teamIds') return;
        c.values.forEach((teamId) => {
          const teamName = this.teamsWithApprovalMap[teamId];
          if (teamName && !selectedTeamsWithApproval.includes(teamName)) {
            selectedTeamsWithApproval.push(teamName);
          }
        });
      })
    );
    this.selectedTeamsWithApproval =
      selectedTeamsWithApproval.length &&
      this.helpers.arrayOfItemsToText(selectedTeamsWithApproval);
  }
}
