<div
  class="preview"
  [style.minWidth]="data.minWidth || minWidth"
  [style.minHeight]="data.minHeight || minHeight"
>
  <div class="aligned-right">
    <i class="far fa-times-circle medis-icon-large" (click)="dialogRef.close()"></i>
  </div>
  <div class="content-container">
    <div *ngIf="!content" class="preview-content">
      <h5 id="loading-title">
        <i class="fa fa-spinner fa-spin"></i> {{ 'LOADING' | translate }}...
      </h5>
    </div>
    <pre
      *ngIf="content"
      [innerHtml]="content | sanitizeHtml"
      class="preview-content"
      [ngClass]="{ 'd-flex justify-content-end': centered }"
    ></pre>
  </div>
</div>
