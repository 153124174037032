import { Pipe, PipeTransform } from '@angular/core';
import { Config } from '../common/search-select/search-select.config';
import { Type } from '../../modules/inquiries/inquiries-list/inquiries-filter/configs/filter-configs';
import { Helpers } from '../../../@mi-tool/utils/helpers';

@Pipe({ name: 'displayTerm' })
export class DisplayTerm implements PipeTransform {
  constructor(private helpers: Helpers) {}
  transform(value: any, type: Type, config: Config): string {
    const { displayTerm } = this.helpers.transformFilter(value, type, config);
    return displayTerm;
  }
}
