import { filter, group, item, MedisNavigationItem } from '../types';
import {
  PERM_ADMINISTRATE_SYSTEM,
  PERM_APPROVE_ENQUIRIES,
  PERM_PROCESS_ENQUIRIES,
} from 'app/common/common/constants/permissions';
import { PermissionHandler } from 'app/auth/auth.service';

const ENQ_PROCESS = [PERM_PROCESS_ENQUIRIES];
const SYS_ADMIN = [PERM_ADMINISTRATE_SYSTEM];
const ENQ_PROCESS_APPROVE = [PERM_PROCESS_ENQUIRIES, PERM_APPROVE_ENQUIRIES];

export function userProfileNavigation(permissions: PermissionHandler): MedisNavigationItem[] {
  const profileNavigation: MedisNavigationItem[] = [
    group('User_profile', 'User_profile', 'account_circle', [
      item('Profile_settings', 'CONTACT_INFORMATION', 'fa-user-circle'),
      item('Preferences', 'MedIS_Out_Of_Office', 'fa-plane-departure', ENQ_PROCESS),
      item('Auto_assign', 'AUTO_ASSIGNMENTS', 'fa-share', ENQ_PROCESS),
      item(
        'Approval_assignment',
        'APPROVAL_ASSIGNMENTS',
        'fa-envelope-open-text',
        ENQ_PROCESS_APPROVE
      ),
      item('Password_reset', 'PASSWORD_RESET', 'fa-sync-alt'),
      item('Role_settings', 'Role_settings', 'fa-user-graduate', SYS_ADMIN),
      item('Notification_settings', 'Notification_settings', 'fa-bell', ENQ_PROCESS),
      item('Template_settings', 'TEMPLATES', 'fa-file-signature', ENQ_PROCESS),
    ]),
  ];
  return filter(profileNavigation, permissions);
}

export function teamNavigation(permissions: PermissionHandler): MedisNavigationItem[] {
  const teamSetupChildren: MedisNavigationItem[] = [
    item('Team_edit', 'TEAM_SET_UP', 'fa-edit'),
    item('List_teams', 'LIST_TEAMS', 'fa-file-signature ms-4'),
    item('Companies', 'COMPANIES', 'fa-users ms-4'),
    item('Domains', 'DOMAINS', 'fa-user-tag ms-4'),
  ];
  const teamNavigation: MedisNavigationItem[] = [
    group('Team_profile', 'TEAM_SETTINGS', 'account_circle', [
      group('TEAM_SET_UP', '', '', teamSetupChildren, SYS_ADMIN),
      item('Team_template_settings', 'TEMPLATES', 'fa-file-signature'),
      item('Team_members', 'TEAM_MEMBERS', 'fa-users'),
      item('User_templates', 'USER_TEMPLATES', 'fa-user-tag'),
      item('External_comm', 'RECEIPT_AND_SALES_NOTIFICATIONS', 'fa-satellite-dish', SYS_ADMIN),
      item('Departments', 'DEPARTMENTS', 'fa-landmark', SYS_ADMIN),
      item('Documents_approval', 'DOCUMENTS_APPROVAL', 'fa-file-signature', SYS_ADMIN),
      item(
        'Enquiry_answer_approval',
        'ENQUIRY_ANSWER_APPROVAL',
        'fa-envelope-open-text',
        SYS_ADMIN
      ),
      item('Follow_up', 'FOLLOW_UP', 'fa-reply', SYS_ADMIN),
    ]),
  ];
  return filter(teamNavigation, permissions);
}

export function toolbarNavigation(permissions: PermissionHandler): MedisNavigationItem[] {
  return userProfileNavigation(permissions).find((ng) => ng.id === 'User_profile').children;
}
