import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import {
  Config,
  newBTeamConfig,
  newBUserBTeamConfig,
  newBUserConfig,
  SelectedOption,
} from 'app/common/common/search-select/search-select.config';
import { Action, InquiriesService } from 'app/modules/inquiries/services/inquiries.service';
import { BTeam, BUser } from 'app/modules/data-model/user/user';
import {
  NomenclaturesService,
  Scopes,
} from 'app/modules/data-model/nomenclature/nomenclatures.service';
import { ConfirmDialogComponent } from '@mi-tool/components/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reassign',
  templateUrl: './reassign.component.html',
})
export class ReassignComponent implements OnDestroy {
  @Input() style: string;
  @Input() selectedItems: any[];
  @Input() preselectUnassigned = true;
  @Input() disabled: boolean;
  @Input() hideResetIcon: boolean = false;
  @Input() isDraft: boolean = false;
  @Input() emitExternallyLandingAction: boolean = false;
  @Output() reassign = new EventEmitter<string>();
  @Output() landingActionPerform = new EventEmitter<{
    action: Action;
    selection: number[];
    actionParams: object;
  }>();
  selectedOption: SelectedOption;
  config: Config;
  private _mode: 'users' | 'teams' | 'users-teams';
  private _selectedUserId: number;
  private _selectedUser: BUser;
  private _selectedTeamId: number;
  private _label: string;
  private users: BUser[] = [];
  private teams: BTeam[] = [];
  private teamIdsWithProductGroups: number[] = [];
  private changeMustBeConfirmed: boolean = true;
  private urlSegmentsForAllTeamMenuLinks = ['all-inq-for-all-team', 'all-teams-open-inq'];
  private subs: Subscription = new Subscription();

  constructor(
    private inqService: InquiriesService,
    private matDialog: MatDialog,
    private nomenclatures: NomenclaturesService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  @Input() set mode(val: 'users' | 'teams' | 'users-teams') {
    if (val && val !== this._mode) {
      this._mode = val;
      this.loadNomenclatures();
    }
  }

  @Input() set label(label: string) {
    this._label = label;
    this.initLabel();
  }

  @Input() set selectedUser(user: BUser) {
    this._selectedUser = user;
    this._selectedUserId = user
      ? user.pk()
      : this.preselectUnassigned
      ? BUser.UNASSIGNED.pk()
      : undefined;
    this.initSelectedOption();
  }

  @Input() set selectedTeamId(id: number) {
    this._selectedTeamId = id;
    this.initSelectedOption();
  }

  set reassignSelected(selection: SelectedOption) {
    if (selection && selection.option) {
      if (selection.typeId === 'teams') {
        this.changeTeam(selection.option.value);
      } else if (selection.option.value.pk() === BUser.UNASSIGNED.pk()) {
        this.unassign();
      } else {
        this.openAssigneeDialog(selection.option.value as BUser);
      }
    } else {
      this.initSelectedOption();
    }
  }

  get reassignSelected(): SelectedOption {
    return this.selectedOption;
  }

  private openAssigneeDialog(user: BUser): void {
    const teams = user
      .availableTeams()
      .filter((t) => this.teamIdsWithProductGroups.includes(t.getMId()))
      .map((t) => t.name);
    const confirmModalContent = { message: '', array: teams, dropdownTitle: 'TEAM' };
    confirmModalContent.message = `Please select a team for ${user.fullName()}`;
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, { data: confirmModalContent });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.changeUser(user, result.selected);
      } else {
        this.initSelectedOption();
      }
    });
  }

  private unassign(): void {
    if (this.selectedItems && this.selectedItems.length) {
      const selection = this.selectedItems.map(
        (i) => (i.landingInteraction && i.landingInteraction.pk()) || i.pk()
      );
      if (this.emitExternallyLandingAction) {
        this.landingActionPerform.next({
          action: 'unassign',
          selection: selection,
          actionParams: { isDraft: this.isDraft },
        });
      } else {
        this.inqService
          .setLandingAction('unassign', selection, { isDraft: this.isDraft })
          .subscribe(() => {
            this.reassign.next('users');
          });
      }
    }
  }

  private changeUser(user: BUser, teamName: string): void {
    if (this.selectedItems && this.selectedItems.length) {
      const selection = this.selectedItems.map(
        (i) => (i.landingInteraction && i.landingInteraction.pk()) || i.pk()
      );
      if (this.emitExternallyLandingAction) {
        this.landingActionPerform.next({
          action: 'assign_to',
          selection: selection,
          actionParams: { user_pk: user.pk(), team_name: teamName, isDraft: this.isDraft },
        });
      } else {
        this.inqService
          .setLandingAction('assign_to', selection, {
            user_pk: user.pk(),
            team_name: teamName,
            isDraft: this.isDraft,
            changeMustBeConfirmed: this.shouldConfirmChangeBasedOnUrl(),
          })
          .subscribe({
            next: () => {
              this.reassign.next('users');
              this.changeMustBeConfirmed = true;
            },
            error: (err) => {
              if (err.status === 409) {
                this.handleSubscription(undefined, user, teamName);
              }
            },
          });
      }
    }
  }

  private changeTeam(team: BTeam): void {
    if (this.selectedItems && this.selectedItems.length) {
      const selection = this.selectedItems.map(
        (i) => (i.landingInteraction && i.landingInteraction.pk()) || i.pk()
      );
      if (this.emitExternallyLandingAction) {
        this.landingActionPerform.next({
          action: 'change_team',
          selection: selection,
          actionParams: { team_name: team.name, isDraft: this.isDraft },
        });
      } else {
        this.inqService
          .setLandingAction('change_team', selection, {
            team_name: team.name,
            isDraft: this.isDraft,
            changeMustBeConfirmed: this.shouldConfirmChangeBasedOnUrl(),
          })
          .subscribe({
            next: () => {
              this.reassign.next('teams');
              this.changeMustBeConfirmed = true;
            },
            error: (err) => {
              if (err.status === 409) {
                this.handleSubscription(team, undefined, undefined);
              }
            },
          });
      }
    }
  }

  private shouldConfirmChangeBasedOnUrl(): boolean {
    if (this.urlSegmentsForAllTeamMenuLinks.includes(this.getLastUrlSegment())) {
      return false;
    } else {
      return this.changeMustBeConfirmed;
    }
  }

  private getLastUrlSegment(): string {
    return this.router.url.split('/').pop() || '';
  }

  private handleSubscription(team?: BTeam, user?: BUser, teamName?: string) {
    if (team) {
      this.openConfirmDialog(team, undefined, undefined);
    } else {
      this.openConfirmDialog(undefined, user, teamName);
    }
  }

  private openConfirmDialog(team?: BTeam, user?: BUser, teamName?: string): void {
    const confirmModalContent = {
      message: '',
      secondParagraph: 'change_the_team_',
      title: 'confirm_team_change',
      thirdParagraph: 'can_not_see_it_anymore',
      icon: 'exclamation-triangle',
      fourthParagraph: 'filtered_view',
      fifthParagraph: `${!team ? teamName : team.name}?`,
    };
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, { data: confirmModalContent });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.changeMustBeConfirmed = false;
        if (team) {
          this.changeTeam(team);
        } else {
          this.changeUser(user, teamName);
        }
      } else {
        this.initSelectedOption();
      }
    });
  }

  private initConfig(): void {
    if (this._mode === 'users') {
      this.config = newBUserConfig(this.users);
    } else if (this._mode === 'teams') {
      this.config = newBTeamConfig(this.teams);
    } else {
      this.config = newBUserBTeamConfig(this.users, this.teams);
    }
    this.initSelectedOption();
    this.initLabel();
  }

  private initSelectedOption(): void {
    if (this._selectedUserId) {
      this.selectedOption = this.resolveSelectedOption('users', this._selectedUserId);
    } else if (this._selectedTeamId) {
      this.selectedOption = this.resolveSelectedOption('teams', this._selectedTeamId);
    }
  }

  private resolveSelectedOption(typeId: string, optionId: number): SelectedOption {
    const typeConfig = this.config && this.config.getItem(typeId);
    const option = typeConfig && typeConfig.options.find((o) => o.value.pk() === optionId);
    return option && { typeId: typeId, option: option };
  }

  private initLabel(): void {
    if (this._label && this.config) {
      this.config.label = this._label;
    }
  }

  private loadNomenclatures(): void {
    const scopes = new Scopes();
    if (this._mode === 'users' || this._mode === 'users-teams') {
      this._selectedUserId = this._selectedUserId
        ? this._selectedUserId
        : this.preselectUnassigned
        ? BUser.UNASSIGNED.pk()
        : undefined;
      scopes.activeInqProcessingUsers().teamIdsWithProductGroups();
    }
    if (this._mode === 'teams' || this._mode === 'users-teams') {
      scopes.teams();
    }
    this.subs.add(
      this.nomenclatures.get(scopes).subscribe((data) => {
        if (data.users) {
          if (this._selectedUser && !data.users.find((u) => u.pk() === this._selectedUser.pk())) {
            data.users = [this._selectedUser].concat(data.users);
          }
          this.users = [BUser.UNASSIGNED].concat(data.users);
          this.teamIdsWithProductGroups = data.teamIdsWithProductGroups;
        }
        if (data.teams) {
          this.teams = data.teams;
        }
        this.initConfig();
      })
    );
  }
}
