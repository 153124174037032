import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { parseAttr, pkToBase64 } from '../data-model';
import {
  retrieveMedDocInformation,
  createDocumentWithApproval,
  createMedicalDocumentVersion,
} from './queries';
import { createGuid } from '../../../common/uuid-generator';
import { BMedicalDocument } from './medical-document';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

@Injectable({
  providedIn: 'root',
})
export class MedicalDocumentService {
  response: Subject<BMedicalDocument> = new Subject<BMedicalDocument>();
  createResponse: Subject<BMedicalDocument> = new Subject<BMedicalDocument>();

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {}

  private _genericQueryWithParams(data: any, query: any) {
    return this.apollo.mutate({
      mutation: query,
      variables: { params: data },
    });
  }

  private _parseAttrResponse(response: any, mutationField: string): BMedicalDocument {
    if (response && response['data']) {
      let responseData = response['data'][mutationField];
      return parseAttr<BMedicalDocument>(responseData, BMedicalDocument, 'medicalDocument');
    } else {
      this.messageService.error('Document Edit: empty response');
      return undefined;
    }
  }

  private handleMutationError(error: any) {
    this.messageService.error('An error occurred');
    console.error('error: ', error);
    this.createResponse.next(undefined);
  }

  createDocumentWithApproval(data: Object) {
    let mutateData = Object.assign({}, data);
    mutateData['clientMutationId'] = createGuid();
    this._genericQueryWithParams(mutateData, createDocumentWithApproval).subscribe(
      (response) => {
        this.createResponse.next(this._parseAttrResponse(response, 'createApprovalProcess'));
      },
      (error) => {
        this.handleMutationError(error);
      }
    );
  }

  createVersion(data: Object) {
    let mutateData = Object.assign({}, data);
    mutateData['clientMutationId'] = createGuid();
    this._genericQueryWithParams(mutateData, createMedicalDocumentVersion).subscribe(
      (response) => {
        this.createResponse.next(this._parseAttrResponse(response, 'createMedicalDocumentVersion'));
      },
      (error) => {
        this.handleMutationError(error);
      }
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class SingleMedicalDocumentService {
  response: Subject<BMedicalDocument> = new Subject<BMedicalDocument>();

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {}

  performQueryWithID(realId: number) {
    let pk = pkToBase64('MedicalDocumentNode', realId);
    this.performQuery(pk);
  }

  performQuery(pk: string) {
    this.apollo
      .query({
        query: retrieveMedDocInformation,
        variables: {
          id: pk,
        },
        fetchPolicy: 'network-only',
      })
      .subscribe({
        next: (response) => {
          if (response) {
            let medDoc = parseAttr<BMedicalDocument>(
              response.data,
              BMedicalDocument,
              'medicaldocument'
            );
            this.response.next(medDoc);
          } else {
            this.messageService.error('Empty response for Medical Document');
          }
        },
        error: (error) => {
          this.messageService.error('(SingleMedicalDocumentService) ' + error);
        },
      });
  }
}
