import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import {
  errorType,
  hasCapitalCase,
  hasNumber,
  hasSmallCase,
  hasSpecialCharacters,
} from '@mi-tool/consts';
import { CustomValidators } from '@mi-tool/utils/custom-validators';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrl: './password-field.component.scss',
})
export class PasswordFieldComponent {
  showHintPass: boolean = false;
  errorType = errorType;

  @Input() hide: boolean = true;
  @Output() hideChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() showVerifyingToken: boolean = false;
  @Input() validateOnLoad: boolean = true;

  @Input() set control(val: FormControl) {
    this.setupValidators(val);
    this._control = val;
  }

  get control() {
    return this._control;
  }

  private _control: FormControl;

  constructor() {}

  hasAnyError(errorName: string): boolean {
    const errorKeys = Object.keys(this.control.errors || {});
    return errorKeys.some((errKey) => errorName === errKey);
  }

  isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  toggleVisibility(): void {
    this.hide = !this.hide;
    this.hideChange.emit(this.hide);
  }

  private setupValidators(control: FormControl): void {
    control.setValidators([
      Validators.required,
      CustomValidators.patternValidator(hasNumber, { hasNumber: true }),
      CustomValidators.patternValidator(hasCapitalCase, { hasCapitalCase: true }),
      CustomValidators.patternValidator(hasSmallCase, { hasSmallCase: true }),
      CustomValidators.patternValidator(hasSpecialCharacters, { hasSpecialCharacters: true }),
      Validators.minLength(8),
    ]);
    control.updateValueAndValidity({ emitEvent: false });
  }
}
