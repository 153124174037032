import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BInquirer, BUser } from 'app/modules/data-model/data-model.module';
import { TranslateService } from '@ngx-translate/core';
import { NgbdModalUser } from 'app/common/common/modal/modal-user/modal-user.component';

type HistoryChange = { field?: string; from?: string; to?: string; preparedText?: string | null };

type HistoryDataEvent = { createdTs: Date; createdBy: BUser; changes?: HistoryChange[] };

const fieldToTranslation = {
  firstName: 'FIRST_NAME',
  lastName: 'LAST_NAME',
  title: 'Title',
  gender: 'Gender',
  city: 'CITY',
  country: 'COUNTRY',
  specialization: 'SPECIALIZATION',
  hcpVerified: 'HCP_VERIFIED',
  keyOpinionLeader: 'KEY_OPINION_LEADER',
  hospitalBased: 'HOSPITAL_BASED',
  institution: 'Institution',
  department: 'DEPARTMENT',
  language: 'LANGUAGE',
  email: 'Email',
  phone: 'PHONE',
  address: 'Address',
  deletePii: 'DELETE_INQUIRER_PII',
};

const valueToDisplay = (val) => {
  switch (true) {
    case val === true:
      return 'YES';
    case val === false:
      return 'NO';
    case Array.isArray(val) && val.length === 0:
      return 'BlANK';
    default:
      return val || 'BLANK';
  }
};

@Component({
  selector: 'app-detail-history-inquirer',
  templateUrl: './detail-history-inquirer.component.html',
})
export class DetailHistoryInquirerComponent {
  historyData: HistoryDataEvent[];
  textNew = this.translateService.instant('NEW');
  textFrom = this.translateService.instant('FROM');
  textTo = this.translateService.instant('To').toLowerCase();
  textLabelFor = this.translateService.instant('LABEL_FOR');
  textLabelFax = this.translateService.instant('FAX');

  readonly piFields = ['FIRST_NAME', 'LAST_NAME', 'Email', 'PHONE', 'Address'];

  constructor(private modalService: MatDialog, private translateService: TranslateService) {}

  @Input()
  set history(history: BInquirer[]) {
    this.refreshHistoryData(history);
  }

  previewUser(userId: number): void {
    this.modalService.open(NgbdModalUser, { data: { userId: userId } });
  }

  private refreshHistoryData(historyInquirerData: BInquirer[]): void {
    this.historyData = new Array<HistoryDataEvent>();
    if (historyInquirerData && historyInquirerData.length) {
      const emptyBInquirer = <BInquirer>{
        emails: [],
        phones: [],
        addresses: [],
        firstName: '',
      };
      for (let i = 0; i < historyInquirerData.length; ++i) {
        const inq = historyInquirerData[i];
        const event = {
          createdBy: inq.editedBy,
          createdTs: inq.editedTs,
          changes: this.getInquirerDelta(i == 0 ? emptyBInquirer : historyInquirerData[i - 1], inq),
        };
        if (event.changes == undefined || event.changes.length > 0) {
          this.historyData.push(event);
        }
      }
      this.historyData = this.historyData.reverse();
    }
  }

  private getInquirerDelta(oldInq: BInquirer, newInq: BInquirer): HistoryChange[] {
    const changes: HistoryChange[] = [];
    for (let field of [
      'title',
      'firstName',
      'lastName',
      'gender',
      'city',
      'hcpVerified',
      'keyOpinionLeader',
      'hospitalBased',
      'institution',
      'department',
      'deletePii',
    ]) {
      this.fieldChange(changes, field, oldInq[field], newInq[field]);
    }
    this.fieldChange(
      changes,
      'country',
      oldInq.defaultCountry && oldInq.defaultCountry.name,
      newInq.defaultCountry && newInq.defaultCountry.name
    );
    this.fieldChange(
      changes,
      'specialization',
      oldInq.specialization && oldInq.specialization.name,
      newInq.specialization && newInq.specialization.name
    );
    this.fieldChange(
      changes,
      'language',
      oldInq.language && oldInq.language.name,
      newInq.language && newInq.language.name
    );
    this.updateContactChanges(
      changes,
      'email',
      oldInq.emails.map((e) => e.val),
      newInq.emails.map((e) => e.val),
      oldInq.emails.map((e) => e.label?.toUpperCase()),
      newInq.emails.map((e) => e.label?.toUpperCase())
    );
    this.updateContactChanges(
      changes,
      'phone',
      oldInq.phones.map((e) => e.val),
      newInq.phones.map((e) => e.val),
      oldInq.phones.map((e) => e.label?.toUpperCase()),
      newInq.phones.map((e) => e.label?.toUpperCase())
    );
    this.updateContactChanges(
      changes,
      'address',
      oldInq.addresses.map((e) => e.addressDescription),
      newInq.addresses.map((e) => e.addressDescription),
      oldInq.addresses.map((e) => e.label?.toUpperCase()),
      newInq.addresses.map((e) => e.label?.toUpperCase())
    );
    return changes;
  }

  private updateContactChanges(
    changes: HistoryChange[],
    field: string,
    oldContactVals: string[],
    newContactVals: string[],
    oldPreText: string[],
    newPreText: string[]
  ): void {
    const translatedField = this.translateService.instant(fieldToTranslation[field]);
    for (let i = 0; i < Math.max(oldContactVals.length, newContactVals.length); i++) {
      let preText = '';
      let fromText = '';
      let toText = '';
      const translatedOldPreText = this.translateService.instant(valueToDisplay(oldPreText[i]));
      const translatedNewPreText = this.translateService.instant(valueToDisplay(newPreText[i]));
      const translatedOldContactVals = this.translateService.instant(
        valueToDisplay(oldContactVals[i])
      );
      const translatedNewContactVals = this.translateService.instant(
        valueToDisplay(newContactVals[i])
      );
      switch (true) {
        case Boolean(newContactVals[i] && !oldContactVals[i]):
          // New contact created
          preText = `${this.textNew} ${this.removePhoneFromFax(
            translatedNewPreText.toLowerCase(),
            translatedField.toLowerCase()
          )}: ${translatedNewContactVals}`;
          break;
        case Boolean(!newContactVals[i] && oldContactVals[i]):
          // Deleted contact
          preText = `${this.removePhoneFromFax(translatedOldPreText, translatedField)}:`;
          fromText = `${translatedOldContactVals}`;
          toText = `${translatedNewContactVals}`;
          break;
        case Boolean(newContactVals[i] == oldContactVals[i] && newPreText[i] != oldPreText[i]):
          // Changed contact label
          preText = `${translatedField} ${this.textLabelFor.toLowerCase()} ${translatedNewContactVals.toLowerCase()}:`;
          fromText = `${translatedOldPreText}`;
          toText = `${translatedNewPreText}`;
          break;
        case Boolean(newContactVals[i] != oldContactVals[i] && newPreText[i] == oldPreText[i]):
          // Changed contact but label kept
          preText = `${this.removePhoneFromFax(translatedNewPreText, translatedField)}:`;
          fromText = `${translatedOldContactVals}`;
          toText = `${translatedNewContactVals}`;
          break;
        case Boolean(newContactVals[i] != oldContactVals[i] && newPreText[i] != oldPreText[i]):
          // Both label and contact changed
          preText = `${translatedField}:`;
          fromText = `${this.removePhoneFromFax(
            translatedOldPreText,
            translatedField
          )}: ${translatedOldContactVals}`;
          toText = `${this.removePhoneFromFax(
            translatedNewPreText,
            translatedField
          )}: ${translatedNewContactVals}`;
          break;
      }
      this.fieldChange(
        changes,
        field,
        oldContactVals[i] ? oldContactVals[i] : [],
        newContactVals[i],
        this.boldedDiff(preText, fromText, toText)
      );
    }
  }

  private toBolded = (str: string): string => str && `<b>${str}</b>`;
  private boldedDiff = (preText: string, from: string, to: string): string =>
    `${this.toBolded(preText)} ${from.length ? this.textFrom + ': ' : ''} ${this.toBolded(from)} ${
      to.length ? this.textTo + ': ' : ''
    } ${this.toBolded(to)}`.trim();
  private removePhoneFromFax = (preText: string, field: string): string =>
    `${
      preText.toLowerCase() === this.textLabelFax.toLowerCase()
        ? preText
        : preText + ' ' + field.toLowerCase()
    }`;

  private fieldChange(
    changes: HistoryChange[],
    name: string,
    oldVal: any,
    newVal: any,
    preparedText: string = null
  ): void {
    if (preparedText) {
      changes.push({ field: fieldToTranslation[name] || name, preparedText: preparedText });
    } else if (oldVal != newVal) {
      changes.push({
        field: fieldToTranslation[name] || name,
        from: valueToDisplay(oldVal),
        to: valueToDisplay(newVal),
      });
    }
  }
}
