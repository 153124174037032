import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { Config, ConfigItem, ConfigItemOption, SelectedOption } from './search-select.config';
import { DebounceSubject } from 'app/common/util/rx';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent {
  @Input() default;
  @Input() style: string = '';
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() multiple: boolean;
  @Input() trans: boolean;
  @Input() lazy: boolean;
  @Input() extendable: boolean;
  @Input() dataTestId?: string;
  @Input() appearance?: MatFormFieldAppearance;
  @Input() validateOnLoad: boolean = true;
  @Input() placeholder: string;
  @Input() showHeader: boolean = true;
  @Input() hideResetIcon: boolean = false;
  @Output() selectedChange: EventEmitter<SelectedOption> = new EventEmitter();
  @Output() selectedValueChange: EventEmitter<any> = new EventEmitter();
  @Output() optionSelected: EventEmitter<any> = new EventEmitter();
  @Output() searchChange: EventEmitter<any> = new EventEmitter();
  activeOptions$ = new BehaviorSubject<ConfigItemOption<any>[]>([]);
  loading = false;

  @ViewChild(MatSelect)
  private selectField: MatSelect;
  private _config: Config;
  private _activeItem: ConfigItem<any>;
  private _selected: SelectedOption;
  private _selectedValue: any;
  private searchText = '';
  private multipleSelectedValue: any[];
  private searchChangeDebounce = new DebounceSubject<any>();

  constructor(private translateService: TranslateService) {
    this.searchChangeDebounce.subscribe((val) => this.searchChange.emit(val));
  }

  @Input() set config(config: Config) {
    if (config) {
      this._config = config;
      this.activeItem = config.items && config.items[0];
      this.loading = false;
    }
  }

  get config(): Config {
    return this._config;
  }

  @Input() set selected(selected: SelectedOption) {
    this._selected = selected;
    this._selectedValue = selected && selected.option && selected.option.value;
  }

  get selected(): SelectedOption {
    return this._selected;
  }

  @Input() set selectedValue(value: any) {
    if (this.default && !value) {
      this._selectedValue = this.default;
    } else {
      this._selectedValue = value;
    }
  }

  get selectedValue(): any {
    return this._selectedValue;
  }

  get showResetIcon(): boolean {
    return this.showResetIconConditions(this._selectedValue);
  }

  private showResetIconConditions(value): boolean {
    if (this.hideResetIcon) {
      return false;
    }
    if (!value) {
      return false;
    }
    if (typeof value === 'string') {
      return value.trim().length !== 0;
    }
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return value.length !== 0;
      } else {
        return Object.keys(value).length !== 0;
      }
    }
    return true;
  }

  set activeItem(item: ConfigItem<any>) {
    if (item) {
      this._activeItem = item;
      this.activeOptions$.next(item.options);
    }
  }

  get activeItem(): ConfigItem<any> {
    return this._activeItem;
  }

  set search(val: string) {
    this.searchText = val;
    if (this.multiple) {
      this.multipleSelectedValue = this.multipleSelectedValue || this.selectedValue;
    }
    if (this.lazy) {
      this.searchChangeDebounce.next(val);
      this.loading = true;
    }
    if (!val.trim()) {
      this.activeOptions$.next(this.activeItem.options);
      return;
    }

    const activeOptions = this.activeItem.options
      .filter((o) => this.stringIncludes(o.name, val))
      .sort((item, secondItem) => {
        if (this.stringStartsWith(item.name, val) && this.stringStartsWith(secondItem.name, val)) {
          return item.name.localeCompare(secondItem.name);
        }
        return this.stringStartsWith(item.name, val) ? -1 : 1;
      });

    this.activeOptions$.next(activeOptions);
  }

  get search(): string {
    return this.searchText;
  }

  reset(): void {
    this.selectionChanged(this.multiple ? [] : undefined);
  }

  selectAll(): void {
    this.selectionChanged(this.activeOptions$.getValue().map((option) => option.value));
  }

  selectionChanged(val: any): void {
    if (this.multiple && val && val.includes) {
      const multipleSelectedValue = this.multipleSelectedValue
        ? this.multipleSelectedValue.concat(val)
        : val;
      this.selectedValue = val = multipleSelectedValue;
      this.multipleSelectedValue = undefined;
    }
    this.selectedValueChange.emit(val);
    const option = this.activeOptions$.getValue().find((o) => o.value === val);
    this.selectedChange.emit((this.selected = new SelectedOption(this._activeItem.id, option)));
    this.searchText = undefined;
    this.activeOptions$.next(this.activeItem.options);
  }

  searchKeyDown(event: KeyboardEvent): void {
    if (event.code === 'Space' || event.ctrlKey) {
      event.stopPropagation();
    }
  }

  scrollToTop(): void {
    const panel = document.getElementsByClassName('ng-trigger-transformPanel')[0];
    if (panel) {
      panel.scrollTop = 0;
    }
  }

  resolveExtraClasses(): string {
    let extraClasses = '';
    if (this.validateOnLoad || (!this.validateOnLoad && this.selectField?.ngControl?.touched)) {
      if (this.required && (!this.selectedValue || (this.multiple && !this.selectedValue.length))) {
        extraClasses += 'invalid ';
      }
    }
    if (this.style) {
      extraClasses += this.style;
    }
    return extraClasses;
  }

  private stringStartsWith(str: string, query: string, caseSensitive = false): boolean {
    return caseSensitive
      ? str.startsWith(query) || this.translateService.instant(str).startsWith(query)
      : str.toLocaleLowerCase().startsWith(query.toLocaleLowerCase()) ||
          this.translateService
            .instant(str)
            .toLocaleLowerCase()
            .startsWith(query.toLocaleLowerCase());
  }

  private stringIncludes(str: string, query: string, caseSensitive = false): boolean {
    return caseSensitive
      ? str.includes(query) || this.translateService.instant(str).includes(query)
      : str.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
          this.translateService
            .instant(str)
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase());
  }
}
