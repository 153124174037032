import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  EntitySearchParams,
  RelatedContentService,
} from 'app/modules/data-model/entity/entity.service';
import { SingleMedicalDocumentService } from 'app/modules/data-model/medical-document/medical-document.service';

import { BMedicalDocument } from 'app/modules/data-model/medical-document/medical-document';
import { BRelatedContent } from 'app/modules/data-model/entity/entity';
import { BHistory, BInquiry, BInteraction } from 'app/modules/data-model/interaction/interaction';
import { SingleInteractionService } from 'app/modules/data-model/interaction/interaction.service';
import { RelatedContentLinkComponent } from './related-content-link/related-content-link.component';
import { Subject, Subscription } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { BCountry, BEvent, NomenclaturesService, Scopes } from '../data-model/data-model.module';
import { DebounceSubject } from 'app/common/util/rx';
import { MED_DOCS, MED_DOCS_AND_SIMILAR } from 'app/common/common/constants/related-content';
import {
  Config,
  newBCategoryIdConfig,
  newBEventConfig,
  newBProductIdConfig,
  newBTopicIdConfig,
  newCountryIdConfig,
  newLanguageIdConfig,
  newMedicalDocumentAudienceIdConfig,
  newOnlyMedicalDocumentTypeConfig,
  newTeamIdConfig,
  newTherapeuticAreaIdConfig,
} from 'app/common/common/search-select/search-select.config';
import { MetadataFilter } from 'app/common/util/metadata-filter';
import { NameValue } from '@mi-tool/consts';
import _ from 'lodash';
import { PageEvent } from '@angular/material/paginator';

const SLIDE_OUTPUT_FORMAT = [
  { name: 'PDF', value: 'pdf' },
  { name: 'Slides', value: 'pptx' },
];
const WORD_OUTPUT_FORMAT = [
  { name: 'PDF', value: 'pdf' },
  { name: 'Word', value: 'docx' },
];

@Component({
  selector: 'app-related-content',
  animations: [
    trigger('detailAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
    trigger('listAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
  templateUrl: './related-content.component.html',
  styleUrls: ['./related-content.component.scss'],
})
export class RelatedContentComponent implements OnInit, OnDestroy {
  dialogRef: MatDialogRef<RelatedContentComponent>;
  _relatedInquiry: BInquiry;
  event: BEvent;

  _uploadTo: BHistory;
  @Input() noAttach: boolean = false;
  @Input() noModal: boolean = false;
  @Input() adminMode: boolean = false;
  @Input() onlyMedDocuments: boolean = false;
  @Output() attachedDocument: EventEmitter<BMedicalDocument>;
  @Input() answerMode: boolean = false;

  totalRecords: number;
  pageSizeOptions: number[];
  pageSize: number;
  page: number = 0;

  suggestionResults: BRelatedContent[];

  // check if initial search is already running
  initialSearch = false;
  entityLinkMode = false;
  documentUnderLink: BMedicalDocument;
  interactionUnderLink: BInteraction;
  showSuggestions = true;

  // document type to visualize
  documentTypeToSearch: string[] = ['srd', 'faq', 'slide', 'smpc,binary', 'pil', 'lit'];

  @ViewChild('uploadAttachmentLink', { static: false })
  uploadAttachmentLink: RelatedContentLinkComponent;
  copiedText: Subject<string> = new Subject<string>();

  // data source
  displayedColumns = ['id', 'title', 'type', 'product', 'team'];
  relatedContents: BRelatedContent[] = [];
  dataOrderBy: string;

  // filters
  searchParams: EntitySearchParams;
  textSearchDebounce = new DebounceSubject<string>();
  searchId = new DebounceSubject<string>();
  searchTitle = new DebounceSubject<string>();
  activeStatus: string;
  metadata = new MetadataFilter(false);
  eventConfig: Config = newBEventConfig([]);
  countryConfig: Config = newCountryIdConfig([]);
  teamConfig: Config = newTeamIdConfig([]);
  languageConfig: Config = newLanguageIdConfig([]);
  therapeuticAreaConfig: Config = newTherapeuticAreaIdConfig([]);
  medicalDocumentAudiencesConfig: Config = newMedicalDocumentAudienceIdConfig([]);
  typeConfigMedOnly: Config = newOnlyMedicalDocumentTypeConfig(MED_DOCS);
  typeConfigAll: Config = newOnlyMedicalDocumentTypeConfig(MED_DOCS_AND_SIMILAR);
  productConfig: Config = newBProductIdConfig([], '');
  categoryConfig: Config = newBCategoryIdConfig([]);
  topicConfig: Config = newBTopicIdConfig([]);

  selectedEvents: BEvent[] = [];
  isLoading = false;

  statusSelection = { Approved: true, Archived: false };
  typeSelection = [];
  showHelpMessage: boolean = false;
  outputFormat: string;
  outputFormatOptions: NameValue<string>[] = [];
  private changeLanguageByDefault: boolean = true;
  private lastAppliedFilters: EntitySearchParams;
  private allCountries: BCountry[] = [];
  private languageIdEnglish: number;

  private subs = new Subscription();

  constructor(
    private relatedContentService: RelatedContentService,
    private singleMedDocService: SingleMedicalDocumentService,
    private singleInteractionService: SingleInteractionService,
    private nomenclaturesService: NomenclaturesService
  ) {
    this.attachedDocument = new EventEmitter<BMedicalDocument>();
    this.searchParams = new EntitySearchParams();

    this.totalRecords = 0;
    this.pageSizeOptions = [5, 10, 25, 50, 100];
    this.pageSize = this.pageSizeOptions[2];
  }

  ngOnInit() {
    this.searchId.subscribe(() => {
      this.search();
    });
    this.searchTitle.subscribe(() => {
      this.search();
    });
    this.textSearchDebounce.subscribe(() => {
      this.search();
    });

    this.subs.add(
      this.singleMedDocService.response.subscribe((medDoc) => {
        this.setLinkEntity(undefined, medDoc);
      })
    );
    this.subs.add(
      this.singleInteractionService.relatedContentInteractionResult.subscribe((result) => {
        this.setLinkEntity(result, undefined);
      })
    );
    this.subs.add(
      this.relatedContentService.values.subscribe((documents) => {
        this.relatedContents = documents[0]['data'];
        this.totalRecords = documents[0]['total_records'];
      })
    );
    const scopes = new Scopes()
      .events()
      .countries()
      .teams()
      .languages()
      .products()
      .categories()
      .topics()
      .allActiveTherapeuticAreas()
      .medicalDocumentAudiences();
    this.nomenclaturesService.get(scopes).subscribe((resp) => {
      this.allCountries = resp.countries;
      this.languageIdEnglish = resp.languages.find((lang) => lang.code === 'en').pk();
      this.eventConfig = newBEventConfig(resp.events);
      this.countryConfig = newCountryIdConfig(resp.countries);
      this.teamConfig = newTeamIdConfig(resp.teams);
      this.languageConfig = newLanguageIdConfig(resp.languages);
      this.therapeuticAreaConfig = newTherapeuticAreaIdConfig(resp.therapeuticAreas);
      this.medicalDocumentAudiencesConfig = newMedicalDocumentAudienceIdConfig(
        resp.medicalDocumentAudiences
      );
      this.productConfig = newBProductIdConfig(resp.products, '');
      this.categoryConfig = newBCategoryIdConfig(resp.categories);
      this.topicConfig = newBTopicIdConfig(resp.topics);

      if (this.event) {
        this.selectedEvents = resp.events.filter((e) => e.id === this.event.id);
      }
      if (!this.initialSearch) {
        this.search();
      }
    });
  }

  ngOnDestroy() {
    //deselect previously selected rows
    if (this.relatedContents) {
      this.relatedContents.forEach((d) => {
        d['active'] = false;
      });
    }
    this.subs.unsubscribe();
  }

  set uploadTo(history: BHistory) {
    this._uploadTo = history;
  }

  get uploadTo(): BHistory {
    return this._uploadTo;
  }

  search(): void {
    let params: EntitySearchParams = Object.assign(new EntitySearchParams(), this.searchParams);
    params.terms = '';
    if (this.textSearchDebounce.val) {
      params.terms = params.terms.concat(this.textSearchDebounce.val.trim());
    } else if (this.typeSelection.includes('similar') && this.relatedInquiry != undefined) {
      if (this.relatedInquiry.question && this.relatedInquiry.question.plainText) {
        params.terms = this.relatedInquiry.question.plainText.split(' ').slice(0, 100).join(' ');
      }
      params.inquiryPk = this.relatedInquiry.id;
    }

    this.activeStatus = Object.keys(this.statusSelection)
      .filter((k) => this.statusSelection[k])
      .join(',');
    if (this.searchId.val) {
      params.documentId = this.searchId.val.trim();
    }
    if (this.searchTitle.val) {
      params.documentTitle = this.searchTitle.val.trim();
    }
    if (this.typeSelection && this.typeSelection.length > 0) {
      params.entityType = this.typeSelection.join(',');
    } else if (this.onlyMedDocuments) {
      params.entityType = this.documentTypeToSearch.join(',');
    }
    if (this.activeStatus) {
      params.entityStatus = this.activeStatus;
    }
    if (this.dataOrderBy) {
      params.orderBy = this.dataOrderBy.trim();
    }
    if (this.selectedEvents.length) {
      params.events = this.selectedEvents.map((e) => e.id).join(',');
    }
    params.adminMode = this.adminMode;
    params.limit = 214748364;

    const isAnyFilterChanged = !_.isEqual(
      _.omit(params, ['pageNumber', 'pageSize']),
      _.omit(this.lastAppliedFilters, ['pageNumber', 'pageSize'])
    );
    if (isAnyFilterChanged) {
      this.page = 0;
    }
    this.lastAppliedFilters = _.cloneDeep(params);

    params.pageNumber = this.page + 1;
    params.pageSize = this.pageSize;
    this.isLoading = true;

    this.subs.add(
      this.relatedContentService.search(params).subscribe(() => {
        this.isLoading = false;
      })
    );
    this.goToTop();
  }

  onCountryChange(countryIds: number[]): void {
    // set default language
    if (this.changeLanguageByDefault) {
      if (countryIds.length === 0) {
        this.searchParams.languageIds = undefined;
      } else {
        const defLangIds = this.allCountries
          .filter((country) => countryIds.includes(country.pk()))
          .map((country) => country.defaultLanguage.pk());
        const langIds = [new Set(defLangIds).size === 1 ? defLangIds[0] : this.languageIdEnglish];
        this.searchParams.languageIds = langIds;
      }
    }
  }

  changeLanguage(langIds: number[]): void {
    this.changeLanguageByDefault = langIds.length === 0;
  }

  onPageOptionsChange(event: PageEvent): void {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.search();
  }

  orderBy(event): void {
    const allFields = {
      id: 't_id',
      title: 'title',
      type: 'type_code',
    };
    const field = event.active;
    const direction = event.direction;
    if (direction === 'asc') {
      this.dataOrderBy = allFields[field];
    } else if (direction === 'desc') {
      this.dataOrderBy = '-' + allFields[field];
    } else {
      this.dataOrderBy = '';
    }
    this.search();
  }

  attachLink(): void {
    if (this.noAttach) {
      this.attachedDocument.emit(this.uploadAttachmentLink.document);
    } else {
      this.uploadAttachmentLink.attachDocumentLink(this.outputFormat, () => {
        this.close();
      });
    }
  }

  copyAnswer() {
    this.uploadAttachmentLink.copiedText.subscribe((text) => {
      // forward copied text to listener to updated the editor and close
      this.copiedText.next(text);
      this.close();
    });
    this.uploadAttachmentLink.copyAnswer();
  }

  loadDataRow(row: BRelatedContent) {
    if (row.class === 'medicaldocument') {
      this.singleMedDocService.performQueryWithID(parseInt(row.id));
    } else if (row.class === 'interaction') {
      this.singleInteractionService.performRelatedInteractionQueryWithId(parseInt(row.id));
    }
  }

  cancelLinkMode() {
    if (this.adminMode) {
      this.search();
    }
    this.documentUnderLink = undefined;
    this.entityLinkMode = false;
  }

  close() {
    if (this.dialogRef == undefined) {
      this.attachedDocument.emit(undefined);
    } else {
      this.dialogRef.close();
    }
  }

  isStatusSelected(entityType: string): boolean {
    return this.statusSelection[entityType];
  }

  setStatusSelected(entityType) {
    this.statusSelection[entityType] = !this.statusSelection[entityType];
    this.search();
  }

  set relatedInquiry(inquiry: BInquiry) {
    this._relatedInquiry = inquiry;
    if (inquiry && inquiry.product) {
      this.searchParams.productIds = [inquiry.product.pk()];
    }
    if (inquiry && inquiry.category) {
      this.searchParams.categoryIds = [inquiry.category.pk()];
    }
    if (inquiry && inquiry.topic) {
      this.searchParams.topicIds = [inquiry.topic.pk()];
    }
  }

  get relatedInquiry(): BInquiry {
    return this._relatedInquiry;
  }

  resetFilters(): void {
    this.statusSelection['Approved'] = true;
    this.statusSelection['Archived'] = false;
    this.textSearchDebounce.val = '';
    this.searchId.val = '';
    this.searchTitle.val = '';
    this.typeSelection = [];
    this.page = 0;
    this.searchParams.teamIds = undefined;
    this.searchParams.countryIds = undefined;
    this.searchParams.therapeuticAreaIds = undefined;
    this.searchParams.languageIds = undefined;
    this.searchParams.productIds = undefined;
    this.searchParams.categoryIds = undefined;
    this.searchParams.topicIds = undefined;
    this.searchParams.targetAudienceIds = undefined;
    if (!this.onlyMedDocuments) {
      this.selectedEvents = [];
    }

    this.search();
  }

  private goToTop(): void {
    setTimeout(() => {
      document
        .getElementById('topDiv')
        ?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }, 500);
  }

  private setLinkEntity(interaction: BInteraction, document: BMedicalDocument): void {
    this.interactionUnderLink = interaction;
    this.documentUnderLink = document;
    if (document && document.type && document.type.code === 'slide') {
      this.outputFormatOptions = SLIDE_OUTPUT_FORMAT;
      this.outputFormat = SLIDE_OUTPUT_FORMAT[0].value;
    } else if (document && document.type && ['srd', 'faq'].includes(document.type.code)) {
      this.outputFormatOptions = WORD_OUTPUT_FORMAT;
      this.outputFormat = WORD_OUTPUT_FORMAT[0].value;
    } else {
      this.outputFormatOptions = [];
      this.outputFormat = undefined;
    }
    this.entityLinkMode = true;
  }
}
