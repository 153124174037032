import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';

export class BNotificationFrequency extends BaseModel {
  @rProperty() id: string;
  @rProperty() order: number;
  @rProperty() name: string;
  @rProperty() description: string;
  @rProperty() textGuide: string;
  @rProperty() oneTime: boolean;
  @rProperty() frequency: number;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {}

  static fromRest(json: any): BNotificationFrequency {
    return Object.assign(new BNotificationFrequency({}), json);
  }

  static fromRestArray(json: any[]): BNotificationFrequency[] {
    return json && json.map((v) => BNotificationFrequency.fromRest(v));
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment notificationFrequencyFragment on NotificationFrequencyNode {
    id
    order
    name
    description
    textGuide
    oneTime
    frequency
  }
`;
