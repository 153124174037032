import { Apollo } from 'apollo-angular';
import * as MInquirer from './inquirer';
import { BInquirer } from './inquirer';
import { parseAttr, pkToBase64 } from '../data-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { basicInquirerQuery, inquirerQuery, mutateInquirerQuery } from './queries';
import { createGuid } from 'app/common/uuid-generator';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { ErrorMessages } from '@mi-tool/enums/error.enum';

@Injectable({ providedIn: 'root' })
export class InquirerService {
  inquirer: Subject<BInquirer>;
  basicInquirer: Subject<BInquirer>;
  permissionDenied: Subject<boolean>;

  constructor(private apollo: Apollo) {
    this.inquirer = new BehaviorSubject<BInquirer>(null);
    this.basicInquirer = new Subject<BInquirer>();
    this.permissionDenied = new Subject<boolean>();
  }

  performQueryWithID(id: number) {
    var pk = pkToBase64('InquirerNode', id);
    this.performQuery(pk);
  }

  performQuery(pk: string) {
    this.apollo
      .query({
        query: inquirerQuery,
        variables: {
          id: pk,
        },
        fetchPolicy: 'network-only',
      })
      .subscribe((response) => {
        this.inquirer.next(new BInquirer(response.data['inquirer']));
      });
  }

  performBasicQuery(id: number): void {
    this.apollo
      .query({
        query: basicInquirerQuery,
        variables: { id: pkToBase64('InquirerNode', id), 'X-NO-LOADING': true },
      })
      .subscribe(
        (res) => {
          if (res.data) {
            let inquirer = parseAttr<MInquirer.BInquirer>(
              res.data,
              MInquirer.BInquirer,
              'inquirer'
            );
            this.basicInquirer.next(inquirer);
          } else {
            this.basicInquirer.next(undefined);
            console.log('Response with no data!');
          }
        },
        (error) => {
          if (
            error?.graphQLErrors &&
            Array.isArray(error.graphQLErrors) &&
            error.graphQLErrors.some((graphQLError) =>
              graphQLError?.message?.includes(ErrorMessages.PermissionDenied)
            )
          ) {
            this.permissionDenied.next(true);
          } else {
            this.basicInquirer.next(undefined);
            console.error('Failed to load inquirer', error);
          }
        }
      );
  }
}

@Injectable({ providedIn: 'root' })
export class InquirerEditService {
  inquirer: Subject<Object> = new Subject<Object>();
  response: Subject<BInquirer> = new Subject<BInquirer>();
  needSaveUnsavedChanges: Subject<BInquirer> = new Subject<BInquirer>();

  constructor(apollo: Apollo, private messageService: MessageHandlerService) {
    this.inquirer.subscribe((toMutate) => {
      const mutationData = Object.assign({}, toMutate);
      mutationData['clientMutationId'] = createGuid();
      apollo
        .mutate({
          mutation: mutateInquirerQuery,
          variables: { params: mutationData },
        })
        .subscribe({
          next: (response) => {
            if (response) {
              this.messageService.info('Inquirer updated correctly.');
              let inquirerResponseData = response.data['updateInquirer'];
              let field = 'inquirer';
              if (!inquirerResponseData['inquirer']) {
                field = 'newInquirer';
              }
              let changedInquirer = parseAttr<BInquirer>(inquirerResponseData, BInquirer, field);
              this.response.next(changedInquirer);
            } else {
              this.response.next(undefined);
              this.messageService.error('Inquirer Edit: empty response');
            }
          },
          error: (error) => {
            this.messageService.error('(Inquirer Edit) ' + error);
          },
        });
    });
  }
}
