import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';

import {
  Config,
  newBCategoryIdConfig,
  newBProductIdConfig,
  newBTopicIdConfig,
  newChannelsConfig,
  newCountryIdConfig,
  newEventConfig,
  newInquirerIdConfig,
  newLanguageIdConfig,
  newSpecializationsConfig,
  newStringArrayConfig,
  newTeamNameConfig,
  newTerritoryConfig,
  newUserIdConfig,
} from 'app/common/common/search-select/search-select.config';
import {
  FConfigs,
  filterConfigLabels,
  filterConfigMapping,
  filterSelection,
} from '../inquiries-list/inquiries-filter/configs/filter-configs';
import { Nomenclatures } from '../../data-model/nomenclature/nomenclatures.service';
import { BInquirer } from '../../data-model/inquirer/inquirer';
import { InquirersService } from '../../data-model/inquirer/inquirers.service';
import { BAlignment } from '../../data-model/user/user';
import { Channels } from '@mi-tool/consts';

export type TAllMultipleDropdowns = { [key: string]: Config };

@Injectable({ providedIn: 'root' })
export class InquiriesStateService {
  allMultipleDropdownsDataForInboxAndAdvancedSearch$ = new BehaviorSubject<TAllMultipleDropdowns>(
    {}
  );
  interactions: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  constructor(private inquirersService: InquirersService) {}

  private populateDropdownsData(noms: Nomenclatures, inq: BInquirer[], ter: BAlignment[]): void {
    const allConfigs = {};
    filterSelection
      .filter((f) => f.multiple)
      .map((f) => f.selector)
      .forEach((prop) => {
        if (prop == FConfigs.INCOMING || prop == FConfigs.OUTGOING) {
          const options = prop == FConfigs.INCOMING ? Channels.AS_INCOMMING : Channels.AS_OUTGOING;
          allConfigs[prop] = newChannelsConfig(options, filterConfigLabels[prop]);
        } else if (prop == FConfigs.TEAM) {
          allConfigs[prop] = newTeamNameConfig(noms.teams, filterConfigLabels[prop]);
        } else if (prop == FConfigs.PRODUCT) {
          allConfigs[prop] = newBProductIdConfig(noms.products, filterConfigLabels[prop]);
        } else if (prop == FConfigs.LANGUAGE) {
          allConfigs[prop] = newLanguageIdConfig(noms.languages);
        } else if (prop == FConfigs.COUNTRY) {
          allConfigs[prop] = newCountryIdConfig(noms.countries);
        } else if (prop == FConfigs.STATUS) {
          allConfigs[prop] = newStringArrayConfig(noms.interactionStatuses, 'Status');
        } else if (prop == FConfigs.CATEGORY) {
          allConfigs[prop] = newBCategoryIdConfig(noms.categories, filterConfigLabels[prop]);
        } else if (prop == FConfigs.TOPIC) {
          allConfigs[prop] = newBTopicIdConfig(noms.topics, filterConfigLabels[prop]);
        } else if (prop == FConfigs.EVENT) {
          const events = noms.events.sort((a, b) => (a.name < b.name ? -1 : 1));
          allConfigs[prop] = newEventConfig(events, filterConfigLabels[prop]);
        } else if (prop == FConfigs.HCP || prop == FConfigs.NON_HCP) {
          const hcpTypeName = prop == FConfigs.HCP ? 'hcp' : 'cons';
          const specializations = noms.specializations.filter((s) => s.typeName === hcpTypeName);
          allConfigs[prop] = newSpecializationsConfig(specializations, filterConfigLabels[prop]);
        } else if (
          prop == FConfigs.ASSIGNED_TO_ANY ||
          prop == FConfigs.ASSIGNED_TO ||
          prop == FConfigs.ANSWERED_BY ||
          prop == FConfigs.APPROVED_BY ||
          prop == FConfigs.INT_COMM_USER ||
          prop == FConfigs.INSERTED_BY ||
          prop == FConfigs.LAST_EDITED_BY
        ) {
          const users =
            prop == FConfigs.INSERTED_BY
              ? noms.users
              : noms.users.filter((user) => user.hasProcessPermissions);
          allConfigs[prop] = newUserIdConfig(users, filterConfigLabels[prop]);
        } else if (prop == FConfigs.INQUIRER) {
          allConfigs[prop] = newInquirerIdConfig(inq, '');
        } else if (prop == FConfigs.TERRITORY) {
          allConfigs[prop] = newTerritoryConfig(ter);
        } else {
          allConfigs[prop] = filterConfigMapping[prop];
        }
      });

    this.allMultipleDropdownsDataForInboxAndAdvancedSearch$.next(allConfigs);
  }

  setDropdownsState(nomenclaturesData: Nomenclatures): void {
    /* Get inquires and territories and finally call populate dropdowns method */
    const fetchData = [this.inquirersService.get(''), this.inquirersService.getTerritory('')];

    forkJoin(fetchData).subscribe(([inquirersData, territoriesData]: any) => {
      this.populateDropdownsData(nomenclaturesData, inquirersData, territoriesData);
    });
  }

  getDropdownDisplayTerm(fieldName: string, values: any[]): string {
    let result = '';

    const fieldConfig = this.allMultipleDropdownsDataForInboxAndAdvancedSearch$.getValue();
    const valuesArr = [];

    if (fieldConfig[fieldName]) {
      values.forEach((value) => {
        const item = fieldConfig[fieldName].items[0].options.find((option) => {
          return value === option.value;
        });
        if (item) {
          valuesArr.push(item);
        }
      });
    }

    result = valuesArr.map((configItem) => configItem.name).join(',');

    return result;
  }
}
