import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IPreviewTemplateDialog {
  content: string;
  minWidth?: string;
  minHeight?: string;
  centered?: boolean;
}

@Component({
  selector: 'app-preview-template-dialog',
  templateUrl: './preview-template-dialog.component.html',
  styleUrls: ['./preview-template-dialog.component.scss'],
})
export class PreviewTemplateDialogComponent implements IPreviewTemplateDialog {
  public content = '';
  public minWidth = '50vw';
  public minHeight = '50vh';
  public centered = false;

  constructor(
    public dialogRef: MatDialogRef<PreviewTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPreviewTemplateDialog
  ) {
    this.content = this.data.content;
    this.centered = Boolean(this.data.centered);
  }
}
