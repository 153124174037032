import { HttpLink } from '@apollo/client/link/http';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { Apollo, ApolloModule } from 'apollo-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';

import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { AppComponent } from './app.component';
import {
  HttpAuthorizationInterceptor,
  HttpLoadingInterceptor,
  ServerErrorInterceptor,
} from './http.interceptor';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AppRoutingModule } from './app-routing.module';

import { StorageService } from './common/storage.service';
import { UrlResolverService } from './common/url-resolver.service';
import { LoadingService } from './loading.service';

import { environment } from '../environments/environment';
import { AppCommonModule } from './common/common/common.module';
import { MyDraftsComponent } from './modules/my-drafts/my-drafts.component';
import { GlobalErrorHandler } from '../@mi-tool/services/global-error.service';
import { SharedModule } from '../@mi-tool/shared.module';
import { NavbarVerticalStyle1Module } from './layout/default-layout/components/navbar/vertical/app-navbar/app-navbar.module';
import { MedisSidebarModule } from '../@mi-tool/components';
import { RightPanelModule } from './layout/default-layout/components/right-navbar/right-panel.module';
import { ToolbarModule } from './layout/default-layout/components/toolbar/toolbar.module';
import { ActiveInquiryService } from 'app/modules/inquiry-detail/active-inquiry.service';
import { DatePipe } from '@angular/common';
import { PageAccessDeniedComponent } from './page-access-denied/page-access-denied.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslatedMatPaginator } from '@mi-tool/services/translated-mat-paginator.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AuthService } from './auth/auth.service';
import { MedicalDocumentsApprovalService } from './modules/data-model/medical-documents-approval/medical-documents-approval.service';
import { CategoryRestService } from './modules/data-model/category/category.service';
import { TerritoryRestService } from './modules/data-model/territory/territory.service';
import { InteractionsService } from './modules/data-model/interaction/interactions.service';
import { ProductRestService } from './modules/data-model/product/product.service';
import { TopicRestService } from './modules/data-model/topic/topic.service';
import { TherapeuticAreaRestService } from './modules/data-model/therapeutic-area/therapeutic-area.service';
import { FollowUpService } from './common/follow-up-service/follow-up.service';
import { AuditTrailService } from './modules/data-model/audit-trail/audit-trail.service';
import { TaskRestService } from './modules/data-model/task/task.service';
import { TaskLogRestService } from './modules/data-model/task-log/task-log.service';
import { LicenseCounterRestService } from './modules/data-model/license-counter/license-counter.service';
import { MigrationErrorRestService } from './modules/data-model/migration-error/migration-error.service';
import { InteractionMergeService } from './modules/data-model/interaction/interaction-merge.service';
import { RollbarHandler } from './common/rollbar-handler';
import { EditInteractionService } from './modules/data-model/interaction/interaction.service';
import { MailboxService } from './modules/data-model/mailbox/mailbox.service';
import { MailImportDomainSettingsService } from './modules/data-model/mail-import-domain-settings/mail-import-domain-settings.service';
import { RulesService } from './modules/data-model/rule/rule.service';

RollbarHandler.setCodeVersion(environment.version);

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, MyDraftsComponent, PageAccessDeniedComponent],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    AppCommonModule,
    NgbModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgIdleKeepaliveModule,
    TranslateModule.forRoot(),
    SharedModule,
    NavbarVerticalStyle1Module,
    MedisSidebarModule,
    RightPanelModule,
    ToolbarModule,
    PdfViewerModule,
    ApolloModule,
    FlatpickrModule.forRoot(),
  ],
  providers: [
    DatePipe,
    AuditTrailService,
    AuthService,
    StorageService,
    UrlResolverService,
    ActiveInquiryService,
    LoadingService,
    MedicalDocumentsApprovalService,
    ProductRestService,
    CategoryRestService,
    TopicRestService,
    TerritoryRestService,
    TherapeuticAreaRestService,
    TaskRestService,
    TaskLogRestService,
    MigrationErrorRestService,
    LicenseCounterRestService,
    InteractionsService,
    FollowUpService,
    InteractionMergeService,
    EditInteractionService,
    MailboxService,
    MailImportDomainSettingsService,
    RulesService,
    {
      provide: MatPaginatorIntl,
      useClass: TranslatedMatPaginator,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthorizationInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoadingInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    apollo: Apollo,
    loadingService: LoadingService,
    storage: StorageService,
    authService: AuthService
  ) {
    // Creation of the shared apollo client
    let apolloLink = new HttpLink({
      uri: environment.apiBaseUrl.concat('graphql'),
      fetch: fetch,
      headers: { 'X-Version': environment.version },
    });

    const middleware = new ApolloLink((operation, forward) => {
      if (operation.variables && operation.variables['X-NO-LOADING']) {
        operation.setContext({
          headers: {
            Authorization: storage.getToken() ? 'JWT ' + storage.getToken() : null,
            'X-NO-LOADING': true,
          },
        });
      } else {
        operation.setContext({
          headers: { Authorization: storage.getToken() ? 'JWT ' + storage.getToken() : null },
        });
        loadingService.enter();
      }
      return forward(operation);
    });

    // afterWare for loading end
    const afterWareApollo = new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        if (
          !(
            response['options'] &&
            response['options']['headers'] &&
            response['options']['headers']['X-NO-LOADING']
          )
        ) {
          loadingService.exit();
        }
        if (
          response?.errors?.some((error) =>
            error?.message.includes(authService.USER_ACCOUNT_IS_DISABLED)
          )
        ) {
          authService.logoutAndRedirect(true);
        }
        return response;
      });
    });

    apollo.create({
      link: afterWareApollo.concat(middleware.concat(apolloLink)),
      cache: new InMemoryCache(),
    });

    if (authService.hasToken()) {
      authService.fetchLoggedInUser();
    }
  }

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: AppModule,
      providers: [AuthService],
    };
  }
}
