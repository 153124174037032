<div *ngIf="interaction" class="detail-left-bar">
  <div *ngFor="let dateString of groupedDates">
    <div *ngIf="state.value !== 'folded'">
      <div class="date-navigation-container text-14-500-dark">
        {{ dateString }}
      </div>
      <div
        *ngFor="let inquiry of getInquiriesForDate(dateString)"
        class="question-preview"
        [class.selected]="inquiry.id === activeInquiry?.id"
        (click)="switchInquiry(inquiry)"
      >
        <div *ngIf="loggedInUserIsSysAdmin && inquiry.question" class="question-id">
          {{ 'COLUMN_QUESTION_ID' | translate }} {{ inquiry.question | pk }}
        </div>
        <div
          *ngIf="inquiry.question?.mergeOrDuplicateParam"
          class="question-merged-from medis-flex-container-start"
        >
          <i class="fa fa-info-circle text-14-azure me-1" aria-hidden="true"></i>
          <a routerLink="/detail/{{ inquiry.question.mergeOrDuplicateParam }}" class="clickable">
            {{ 'MERGED_FROM' | translate }} {{ 'INTERACTION' | translate }}
            {{ inquiry.question.mergeOrDuplicateParam }}
          </a>
        </div>
        <div class="question-body medis-pr-15">
          <div class="medis-pr-25">
            <app-permission-edit [permissionName]="'View Enquiry Question'">
              <div
                *ngIf="inquiry.question?.plainText"
                class="text-16-800-dark question-text"
                matTooltip="{{ inquiry.question?.plainText }}"
                [class.truncated-text]="isTruncated(inquiry.question.id)"
              >
                {{ inquiry.question?.plainText }}
              </div>
            </app-permission-edit>
            <div>
              <div *ngIf="inquiry.needSrd" class="text-11-600-dark text-uppercase">
                <i class="text-14-azure fas fa-flag me-1"></i>{{ 'SRD_NEEDED' | translate }}
              </div>
              <div
                *ngIf="inquiry.answerUnavailable"
                class="text-11-600-dark text-uppercase"
                [class.mt-1]="inquiry.needSrd"
              >
                <i class="text-14-azure fas fa-flag me-1"></i>{{ 'ANSWER_UNAVAILABLE' | translate }}
              </div>
            </div>
          </div>
          <app-permission-edit [permissionName]="'View Enquiry Question'">
            <div class="question-actions" [class.disabled]="interactionLockedByAnotherUser">
              <i
                *ngIf="inquiry.question?.plainText?.length >= questionPlainTextMinLengthForTruncate"
                class="fas text-preview-icon text-16-900-azure"
                [ngClass]="isTruncated(inquiry.question.id) ? 'fa-eye' : 'fa-eye-slash'"
                (click)="toggleTruncation(inquiry.question.id); $event.stopPropagation()"
              ></i>
              <i
                *ngIf="inquiry.hasInternalCommunication"
                class="far fa-comments medis-mt-5 text-16-400-azure"
                (click)="openInternalComm(inquiry)"
                matTooltip="{{ 'INTERNAL_COMM' | translate }}"
              ></i>
              <i
                *ngIf="
                  !interaction.isClosedOrInReview() &&
                  !interaction.isNew() &&
                  inquiry.question?.plainText &&
                  !disabled
                "
                class="medis-mt-5 split-icon"
                [ngClass]="
                  interactionLockedByAnotherUser
                    ? 'medis-svg-split-grey'
                    : inquiry.id === activeInquiry?.id
                    ? 'medis-svg-split'
                    : 'medis-svg-split-azure'
                "
                (click)="preSplitInquiry(inquiry)"
                matTooltip="{{ 'SPLIT_INQ' | translate }}"
              ></i>
              <i
                *ngIf="
                  !interaction.isClosedOrInReview() &&
                  !interaction.isInApproval() &&
                  interaction.inquiries.length > 1 &&
                  !disabled
                "
                class="fas fa-trash medis-mt-5 text-16-900-coral"
                [class.disabled]="deleteInquiryInProcess"
                (click)="deleteInquiry(inquiry)"
                matTooltip="{{ 'DELETE_INQ' | translate }}"
              ></i>
            </div>
          </app-permission-edit>
        </div>
        <app-permission-edit [permissionName]="'View Enquiry Question'">
          <div *ngFor="let link of getAttachmentForInquiry(inquiry)" class="attachment-link">
            <app-link-visualization
              [link]="link"
              [allowRemove]="false"
              [style]="'compact'"
            ></app-link-visualization>
          </div>
          <!-- Clarification questions -->
          <div *ngIf="inquiry.clarificationQuestions.length" class="clarification-questions">
            <hr />
            <p class="text-16-800-dark title mb-0">
              {{ 'CLARIFICATION' | translate }}
              {{
                (inquiry.clarificationQuestions.length === 1 ? 'QUESTION' : 'QUESTIONS')
                  | translate
                  | lowercase
              }}:
            </p>
            <ng-container *ngFor="let clarificationQuestion of inquiry.clarificationQuestions">
              <div class="question-body medis-pr-15 medis-mv-10">
                <div
                  class="text-16-800-dark question-text medis-pr-25 pb-0"
                  matTooltip="{{ clarificationQuestion?.plainText }}"
                  [class.truncated-text]="isTruncated(clarificationQuestion.id)"
                >
                  {{ clarificationQuestion?.plainText }}
                </div>
                <i
                  *ngIf="
                    clarificationQuestion?.plainText?.length >=
                    questionPlainTextMinLengthForTruncate
                  "
                  class="fas text-preview-icon text-16-900-azure"
                  [ngClass]="isTruncated(clarificationQuestion.id) ? 'fa-eye' : 'fa-eye-slash'"
                  (click)="toggleTruncation(clarificationQuestion.id); $event.stopPropagation()"
                ></i>
              </div>
              <div *ngFor="let link of clarificationQuestion.attachments" class="attachment-link">
                <app-link-visualization
                  [link]="link"
                  [allowRemove]="false"
                  [style]="'compact'"
                ></app-link-visualization>
              </div>
            </ng-container>
          </div>
        </app-permission-edit>
      </div>
      <app-history-visualization
        *ngFor="let history of getHistoryForDate(dateString)"
        [history]="history"
        [attachments]="getAttachmentForInquiry(history)"
        [interaction]="interaction"
        [loggedInUserIsSysAdmin]="loggedInUserIsSysAdmin"
      >
      </app-history-visualization>
    </div>
  </div>
  <div *ngIf="state.value === 'folded'">
    <div
      *ngFor="let inquiry of getInquiriesForAllDates(); let i = index"
      class="question-folded text-16-800-dark"
      [ngClass]="inquiry.id === activeInquiry?.id ? 'selected' : ''"
      (click)="switchInquiry(inquiry)"
    >
      Q{{ i + 1 }}
    </div>
  </div>

  <div class="action-buttons" *ngIf="interaction.isNew()">
    @if (state.value !== 'folded') {
    <button mat-button (click)="createNewInquiry()" class="medis-button-basic">
      <i class="fas fa-plus"></i>
      <span>{{ 'NEW_QUESTION' | translate }}</span>
    </button>
    } @else {
    <i class="fas fa-plus question-folded add-button" (click)="createNewInquiry()"></i>
    }
  </div>
</div>
