<h2 *ngIf="!noModal" mat-dialog-title>
  <div *ngIf="!entityLinkMode; else linkHeader" class="header-bar">
    <i class="fas fa-search medis-icon search-icon"></i>
    <span class="header-bar-item text-18-800-dark">{{ 'Search' | translate }}</span>
    <span class="header-bar-item text-14-500-dark">
      {{ 'SEARCH_FOR_MEDICAL_SIMILAR_INQ' | translate }}
    </span>
  </div>
  <i class="far fa-times-circle medis-icon-large" aria-label="Close" (click)="close()"></i>
</h2>

<div class="related-content" *ngIf="uploadTo || noAttach" [matDialogContent]="!noModal">
  <div id="topDiv"></div>
  <div *ngIf="!entityLinkMode" [@listAnimation]>
    <div class="main-container">
      <div class="filters">
        <div class="filters-header">
          <span class="aligned-right">
            <i class="fas fa-filter medis-icon filter-icon"></i>
            <span class="text-14-500-light">{{ 'FILTER_BY' | translate }}</span>
          </span>
          <i
            class="fas fa-ban medis-icon"
            matTooltip="{{ 'RESET_FILTERS' | translate }}"
            (click)="resetFilters()"
          ></i>
        </div>
        <div *ngIf="!answerMode">
          <div class="text-14-500-dark">{{ 'Status' | translate }}</div>
          <div class="indent">
            <mat-checkbox
              [ngModel]="isStatusSelected('Approved')"
              (ngModelChange)="setStatusSelected('Approved')"
              >{{ 'APPROVED' | translate }}
            </mat-checkbox>
          </div>
          <div class="indent">
            <mat-checkbox
              [ngModel]="isStatusSelected('Archived')"
              (ngModelChange)="setStatusSelected('Archived')"
              >{{ 'ARCHIVED' | translate }}
            </mat-checkbox>
          </div>
        </div>
        <div>
          <app-search-select
            [multiple]="true"
            [config]="countryConfig"
            [(selectedValue)]="searchParams.countryIds"
            (selectedValueChange)="onCountryChange($event); search()"
          ></app-search-select>
          <app-search-select
            [multiple]="true"
            [config]="therapeuticAreaConfig"
            [(selectedValue)]="searchParams.therapeuticAreaIds"
            (selectedValueChange)="search()"
          ></app-search-select>
          <app-search-select
            [multiple]="true"
            [config]="languageConfig"
            [(selectedValue)]="searchParams.languageIds"
            (selectedValueChange)="changeLanguage($event); search()"
          ></app-search-select>
          <app-search-select
            [multiple]="true"
            [config]="categoryConfig"
            [(selectedValue)]="searchParams.categoryIds"
            (selectedValueChange)="search()"
          ></app-search-select>
          <app-search-select
            [multiple]="true"
            [config]="topicConfig"
            [(selectedValue)]="searchParams.topicIds"
            (selectedValueChange)="search()"
          ></app-search-select>
          <app-search-select
            [multiple]="true"
            [config]="medicalDocumentAudiencesConfig"
            [(selectedValue)]="searchParams.targetAudienceIds"
            (selectedValueChange)="search()"
          ></app-search-select>
          <app-search-select
            *ngIf="!onlyMedDocuments"
            [config]="eventConfig"
            [multiple]="true"
            [(selectedValue)]="selectedEvents"
            (selectedValueChange)="search()"
          ></app-search-select>
        </div>
      </div>
      <div class="content">
        <mat-form-field class="w-100 form-field-wrapper rounded-white">
          <i matPrefix class="fas fa-search medis-icon-prefix"></i>
          <mat-label>{{ 'SEARCH_REPOSITORY' | translate }}</mat-label>
          <input
            matInput
            [(ngModel)]="textSearchDebounce.val"
            type="search"
            autocomplete="off"
            (focus)="showHelpMessage = true"
            (focusout)="showHelpMessage = false"
          />
        </mat-form-field>
        <div [ngClass]="{ invisible: !showHelpMessage }" class="text-11-600-dark">
          " " {{ 'CAN_BE_USED_TO_SEARCH_FOR_AN_' | translate }}
        </div>
        <mat-table
          #table
          [dataSource]="relatedContents"
          matSort
          (matSortChange)="orderBy($event)"
          class="medis-table"
        >
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef class="mat-cell-70-fixed">
              <div mat-sort-header>ID</div>
              <mat-form-field class="form-field-wrapper fs-10">
                <input matInput [(ngModel)]="searchId.val" type="search" autocomplete="off" />
              </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="mat-cell-70-fixed">
              <div
                [ngClass]="
                  row.class === 'interaction' ? 'text-13-700-azure' : 'text-13-700-orange-yellow'
                "
              >
                {{ row.id }}
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef class="mat-cell-100">
              <div mat-sort-header>
                {{ 'TITLE' | translate }}{{ answerMode ? '/' + ('SUBJECT' | translate) : '' }}
              </div>
              <mat-form-field class="form-field-wrapper fs-10">
                <input matInput [(ngModel)]="searchTitle.val" type="search" autocomplete="off" />
              </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="mat-cell-100">
              <div class="text-13-700-dark">{{ row.title }}</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef class="mat-cell-100">
              <div mat-sort-header>{{ 'TYPE' | translate }}</div>
              <app-search-select
                *ngIf="!onlyMedDocuments"
                [style]="'fs-10 show-placeholder'"
                [config]="typeConfigAll"
                [(selectedValue)]="typeSelection"
                (selectedValueChange)="search()"
                [multiple]="true"
                [trans]="true"
                placeholder="{{ 'SELECT_ALL' | translate }} ({{ 'DEFAULT' | translate }})"
              ></app-search-select>
              <app-search-select
                *ngIf="onlyMedDocuments"
                [style]="'fs-10'"
                [config]="typeConfigMedOnly"
                [(selectedValue)]="typeSelection"
                (selectedValueChange)="search()"
                [multiple]="true"
                [trans]="true"
              ></app-search-select>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="mat-cell-100">
              <div *ngIf="row.class === 'interaction'" class="text-13-800-dark">
                <i class="far fa-paper-plane icon-enq"></i>{{ 'INQUIRY' | translate }}
              </div>
              <div *ngIf="row.subClass === 'srd' && !row.url" class="text-13-800-dark">
                <i class="far fa-file-word icon-wrd"></i>{{ 'SRD' | translate }}
              </div>
              <div *ngIf="row.subClass === 'faq' && !row.url" class="text-13-800-dark">
                <i class="far fa-file-word icon-wrd"></i>{{ 'FAQ' | translate }}
              </div>
              <div *ngIf="row.subClass === 'smpc' && !row.url" class="text-13-800-dark">
                <i class="far fa-file-pdf icon-pdf"></i>{{ 'SmPc' | translate }}
              </div>
              <div *ngIf="row.subClass === 'pil' && !row.url" class="text-13-800-dark">
                <i class="far fa-file-pdf icon-pdf"></i>{{ 'PIL' | translate }}
              </div>
              <div *ngIf="row.subClass === 'slide' && !row.url" class="text-13-800-dark">
                <i class="far fa-file-powerpoint icon-ppt"></i>{{ 'SLIDE_DECK' | translate }}
              </div>
              <div *ngIf="row.subClass === 'lit' && !row.url" class="text-13-800-dark">
                <i class="far fa-file-pdf icon-pdf"></i>{{ 'LITERATURE' | translate }}
              </div>
              <div *ngIf="row.url" class="text-13-800-dark">
                <i class="fa fa-link icon-link"></i>{{ 'DOCUMENT_EXTERNAL_URL' | translate }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="product">
            <mat-header-cell *matHeaderCellDef class="mat-cell-100">
              <div class="header-text">{{ 'PRODUCT' | translate }}</div>
              <app-search-select
                [multiple]="true"
                [style]="'fs-10 show-placeholder'"
                [config]="productConfig"
                [(selectedValue)]="searchParams.productIds"
                (selectedValueChange)="search()"
                [placeholder]="
                  onlyMedDocuments
                    ? ''
                    : ('SELECT_ALL' | translate) + ' (' + ('DEFAULT' | translate) + ')'
                "
              >
              </app-search-select>
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="mat-cell-100">
              <div class="text-13-700-dark">{{ row.products }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="team">
            <mat-header-cell *matHeaderCellDef class="mat-cell-100">
              <div class="header-text">{{ 'TEAM' | translate }}</div>
              <app-search-select
                [multiple]="true"
                [style]="'fs-10 show-placeholder'"
                [config]="teamConfig"
                [(selectedValue)]="searchParams.teamIds"
                (selectedValueChange)="search()"
                [placeholder]="
                  onlyMedDocuments
                    ? ''
                    : ('SELECT_ALL' | translate) + ' (' + ('DEFAULT' | translate) + ')'
                "
              >
              </app-search-select>
            </mat-header-cell>
            <i class="fas fa-bars"></i>
            <mat-cell *matCellDef="let row" class="mat-cell-100">
              <div class="text-13-700-dark">{{ row.team }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status_indicator">
            <mat-header-cell *matHeaderCellDef class="medis-flex-grow centered">
              <app-loading-indicator
                [loading]="isLoading"
                [empty]="relatedContents?.length === 0"
              ></app-loading-indicator>
            </mat-header-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-header-row
            *matHeaderRowDef="['status_indicator']; sticky: true"
            [ngClass]="{ 'medis-not-displayed': relatedContents?.length !== 0 && !isLoading }"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="loadDataRow(row)"
            class="cursor-pointer"
          ></mat-row>
        </mat-table>

        <mat-paginator
          *ngIf="totalRecords > 0"
          [length]="totalRecords"
          [pageSize]="pageSize"
          [pageIndex]="page"
          [pageSizeOptions]="pageSizeOptions"
          [showFirstLastButtons]="true"
          (page)="onPageOptionsChange($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  <div *ngIf="entityLinkMode" [@detailAnimation]>
    <ng-container *ngIf="!noModal; else linkHeader"></ng-container>
    <app-related-content-link
      #uploadAttachmentLink
      [document]="documentUnderLink"
      [interaction]="interactionUnderLink"
      [history]="uploadTo"
      [adminMode]="adminMode"
    ></app-related-content-link>
  </div>
</div>

<mat-dialog-actions *ngIf="!noModal && entityLinkMode">
  <div *ngIf="documentUnderLink">
    <button mat-button class="medis-button-basic" (click)="attachLink()">
      <i class="fas fa-paperclip"></i>
      {{ 'ATTACH' | translate }} {{ documentUnderLink.type.name }}
    </button>
    <span *ngIf="outputFormatOptions.length" class="medis-pl-25">
      <span class="text-14-700-dark">{{ 'FORMAT' | translate }}: </span>
      <app-multi-toggle
        [choices]="outputFormatOptions"
        [(value)]="outputFormat"
        [required]="true"
      ></app-multi-toggle>
    </span>
  </div>
  <button *ngIf="interactionUnderLink" mat-button class="medis-button-basic" (click)="copyAnswer()">
    <i class="far fa-copy"></i>
    {{ 'COPY_ANSWER' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #linkHeader>
  <div class="link-header medis-flex-container-between">
    <button mat-icon-button class="medis-icon-button" (click)="cancelLinkMode()">
      <i class="fas fa-chevron-left text-16-900-azure"></i>
    </button>
    <span class="text-18-800-dark title">
      {{ interactionUnderLink ? interactionUnderLink.title : documentUnderLink.shortTitle }}
    </span>
  </div>
</ng-template>
