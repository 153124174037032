import {
  newBCategoryIdConfig,
  newBProductIdConfig,
  newBTopicIdConfig,
  newChannelsConfig,
  newCountryIdConfig,
  newEventConfig,
  newInquirerIdConfig,
  newLanguageIdConfig,
  newSpecializationsConfig,
  newStringArrayConfig,
  newTeamNameConfig,
  newTerritoryConfig,
  newUserIdConfig,
  newYesNoConfig,
  SelectedOption,
} from 'app/common/common/search-select/search-select.config';
import { SearchOrigin } from 'app/common/common/enums/search.enum';

export enum Type {
  DROPDOWN = 'dropdown',
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
}

export enum FConfigs {
  INTERACTION = 'id',
  QUESTION = 'question_id',
  INT_COMM_DATE = 'internal_communication_date',
  INT_COMM_TEXT = 'internal_communication_text',
  QUESTION_TEXT = 'question_text',
  ANSWER_TEXT = 'answer_text',
  FOLLOW_UP_TEXT = 'follow_up_text',
  CREATED_ON = 'created_on',
  EDITED_ON = 'edited_on',
  DUE_DATE = 'due_date',
  FOLLOW_SENT_DATE = 'follow_up_sent_date',
  FOLLOW_RECEIVED_DATE = 'follow_up_received_date',
  ANSWER_DATE = 'answer_date',
  CLOSED_ON_DATE = 'closed_on_date',
  REOPENED_DATE = 'reopened_date',
  COUNTRY = 'country',
  PRODUCT = 'product',
  INQUIRER = 'inquirer',
  INSERTED_BY = 'inserted_by',
  LAST_EDITED_BY = 'edited_by',
  ASSIGNED_TO = 'assigned_to',
  ASSIGNED_TO_ANY = 'assigned_to_any',
  ANSWERED_BY = 'answered_by',
  APPROVED_BY = 'approved_by',
  INT_COMM_USER = 'internal_communication_user',
  CATEGORY = 'category',
  TOPIC = 'topic',
  TEAM = 'team',
  MY_TEAM = 'my_teams',
  STATUS = 'status',
  ANSWER = 'answer_unavailable',
  SRD = 'srd',
  ADR = 'adr_related',
  ADR_REF_ID = 'adr_related_ref_id',
  PQC = 'pqc',
  PQC_REF_ID = 'pqc_ref_id',
  INCOMING = 'incoming_channel',
  OUTGOING = 'outgoing_channel',
  EVENT = 'event',
  LANGUAGE = 'language',
  OFF_LABEL = 'off_label',
  HCP = 'hcp',
  NON_HCP = 'non_hcp',
  KEE = 'kee',
  HOSPITAL = 'hospital',
  CITY = 'city',
  INSTITUTION = 'institution',
  TERRITORY = 'territory',
  SELECT_ALL = 'selectAll',
  PRIORITY = 'priority',
  ID = 'id',
  INITIAL_QUESTION_TEXT = 'initial_question_text',
  TEXT = 'text',
}

export class BaseFilterSelection {
  value?: any;
  selector: FConfigs;
}

export class FilterSelection extends BaseFilterSelection {
  id: number;
  name: string;
  type: Type;
  selected?: string;
  exportName?: string;
  multiple?: boolean;
  checked?: boolean;
  translate?: boolean;
}

export const selectAll = {
  id: 0,
  type: Type.TEXT,
  selector: FConfigs.SELECT_ALL,
  name: 'SELECT_ALL',
};

export const filterSelection: Array<FilterSelection> = [
  {
    id: 1,
    name: 'COLUMN_INTERACTION_ID',
    selector: FConfigs.INTERACTION,
    type: Type.NUMBER,
  },
  {
    id: 2,
    name: 'COLUMN_QUESTION_ID',
    selector: FConfigs.QUESTION,
    type: Type.NUMBER,
  },
  {
    id: 46,
    name: 'QUESTION_TEXT',
    selector: FConfigs.QUESTION_TEXT,
    type: Type.TEXT,
  },
  {
    id: 3,
    name: 'COLUMN_CREATED_ON',
    selector: FConfigs.CREATED_ON,
    type: Type.DATE,
  },
  {
    id: 4,
    name: 'COLUMN_DUE_DATE',
    selector: FConfigs.DUE_DATE,
    type: Type.DATE,
  },
  {
    id: 5,
    name: 'COLUMN_FOLLOW_SENT_DATE',
    selector: FConfigs.FOLLOW_SENT_DATE,
    type: Type.DATE,
  },
  {
    id: 6,
    name: 'COLUMN_FOLLOW_RECEIVED_DATE',
    selector: FConfigs.FOLLOW_RECEIVED_DATE,
    type: Type.DATE,
  },
  {
    id: 45,
    name: 'FOLLOW_UP_TEXT',
    selector: FConfigs.FOLLOW_UP_TEXT,
    type: Type.TEXT,
  },
  {
    id: 8,
    name: 'COLUMN_CLOSED_ON_DATE',
    selector: FConfigs.CLOSED_ON_DATE,
    type: Type.DATE,
  },
  {
    id: 9,
    name: 'COLUMN_REOPENED_DATE',
    selector: FConfigs.REOPENED_DATE,
    type: Type.DATE,
  },
  {
    id: 10,
    name: 'COUNTRY',
    selector: FConfigs.COUNTRY,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 11,
    name: 'PRODUCT',
    selector: FConfigs.PRODUCT,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 12,
    name: 'INQUIRER',
    selector: FConfigs.INQUIRER,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 13,
    name: 'COLUMN_INSERTED_BY',
    selector: FConfigs.INSERTED_BY,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 14,
    name: 'COLUMN_LAST_EDITED_BY',
    selector: FConfigs.LAST_EDITED_BY,
    type: Type.DROPDOWN,
    multiple: true,
  },

  {
    id: 48,
    name: 'COLUMN_LAST_EDITED_DATE',
    selector: FConfigs.EDITED_ON,
    type: Type.DATE,
  },
  {
    id: 17,
    name: 'Assigned_to_Any_Time',
    selector: FConfigs.ASSIGNED_TO_ANY,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 51,
    name: 'Assigned_To',
    selector: FConfigs.ASSIGNED_TO,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 7,
    name: 'COLUMN_ANSWER_DATE',
    selector: FConfigs.ANSWER_DATE,
    type: Type.DATE,
  },
  {
    id: 19,
    name: 'COLUMN_ANSWERED_BY',
    selector: FConfigs.ANSWERED_BY,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 28,
    name: 'ANSWER_UNAVAILABLE',
    selector: FConfigs.ANSWER,
    type: Type.DROPDOWN,
    multiple: false,
    translate: true,
  },
  {
    id: 47,
    name: 'ANSWER_TEXT',
    selector: FConfigs.ANSWER_TEXT,
    type: Type.TEXT,
  },
  {
    id: 20,
    name: 'COLUMN_APPROVED_BY',
    selector: FConfigs.APPROVED_BY,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 23,
    name: 'Category',
    selector: FConfigs.CATEGORY,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 24,
    name: 'TOPIC',
    selector: FConfigs.TOPIC,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 25,
    name: 'TEAM',
    type: Type.DROPDOWN,
    multiple: true,
    selector: FConfigs.TEAM,
  },
  {
    id: 27,
    name: 'Status',
    selector: FConfigs.STATUS,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 29,
    name: 'SRD_NEEDED',
    selector: FConfigs.SRD,
    type: Type.DROPDOWN,
    multiple: false,
    translate: true,
  },
  {
    id: 30,
    name: 'ADR_RELATED',
    selector: FConfigs.ADR,
    type: Type.DROPDOWN,
    multiple: false,
    translate: true,
  },
  {
    id: 31,
    name: 'COLUMN_PQC_RELATED',
    selector: FConfigs.PQC,
    type: Type.DROPDOWN,
    multiple: false,
    translate: true,
  },
  {
    id: 32,
    name: 'COLUMN_INCOMING_CHANNEL',
    selector: FConfigs.INCOMING,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 33,
    name: 'COLUMN_OUTGOING_CHANNEL',
    selector: FConfigs.OUTGOING,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 34,
    name: 'Event',
    selector: FConfigs.EVENT,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 35,
    name: 'LANGUAGE',
    selector: FConfigs.LANGUAGE,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 36,
    name: 'COLUMN_OFF_LABEL',
    selector: FConfigs.OFF_LABEL,
    type: Type.DROPDOWN,
    multiple: false,
    translate: true,
  },
  {
    id: 37,
    name: 'COLUMN_HCP_SPEC',
    exportName: 'INQUIRER_TYPE',
    selector: FConfigs.HCP,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 38,
    name: 'COLUMN_NON_HCP_SPEC',
    exportName: 'SPECIALIZATION',
    selector: FConfigs.NON_HCP,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 39,
    name: 'COLUMN_KEE',
    selector: FConfigs.KEE,
    type: Type.DROPDOWN,
    multiple: false,
    translate: true,
  },
  {
    id: 40,
    name: 'COLUMN_HOSPITAL_BASED',
    selector: FConfigs.HOSPITAL,
    type: Type.DROPDOWN,
    multiple: false,
    translate: true,
  },
  {
    id: 41,
    name: 'COLUMN_CITY',
    selector: FConfigs.CITY,
    type: Type.TEXT,
  },
  {
    id: 42,
    name: 'COLUMN_INSTITUTION',
    selector: FConfigs.INSTITUTION,
    type: Type.TEXT,
  },
  {
    id: 43,
    name: 'COLUMN_TERRITORY',
    selector: FConfigs.TERRITORY,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 21,
    name: 'COLUMN_INT_COMM_USER',
    selector: FConfigs.INT_COMM_USER,
    type: Type.DROPDOWN,
    multiple: true,
  },
  {
    id: 22,
    name: 'COLUMN_INT_COMM_DATE',
    selector: FConfigs.INT_COMM_DATE,
    type: Type.DATE,
  },
  {
    id: 44,
    name: 'INT_COMM_TEXT',
    selector: FConfigs.INT_COMM_TEXT,
    type: Type.TEXT,
  },
  {
    id: 55,
    name: 'COLUMN_INIT_QUESTION_TEXT',
    selector: FConfigs.INITIAL_QUESTION_TEXT,
    type: Type.TEXT,
  },
];

export const multipleFilterSelectionCount = filterSelection.filter(
  (filter) => filter.multiple
).length;

export const filterConfigLabels = Object.assign(
  {
    [FConfigs.PRIORITY]: 'PRIORITY',
  },
  {
    [FConfigs.TEXT]: 'Search_inquiries',
  },
  ...filterSelection.map((object) => {
    return { [object.selector]: object.name };
  })
);

export const filterConfigTypes = Object.assign(
  {},
  ...filterSelection.map((object) => {
    return { [object.selector]: object.type };
  })
);

export const filterConfigMultiples = Object.assign(
  {},
  ...filterSelection.map((object) => {
    return { [object.selector]: object.multiple };
  })
);

export const filterConfigTranslate = Object.assign(
  {},
  ...filterSelection.map((object) => {
    return { [object.selector]: object.translate };
  })
);

export const filterConfigMapping = {
  [FConfigs.COUNTRY]: newCountryIdConfig([]),
  [FConfigs.PRODUCT]: newBProductIdConfig([], filterConfigLabels[FConfigs.PRODUCT]),
  [FConfigs.INQUIRER]: newInquirerIdConfig([], filterConfigLabels[FConfigs.INQUIRER]),
  [FConfigs.INSERTED_BY]: newUserIdConfig([], filterConfigLabels[FConfigs.INSERTED_BY]),
  [FConfigs.LAST_EDITED_BY]: newUserIdConfig([], filterConfigLabels[FConfigs.LAST_EDITED_BY]),
  [FConfigs.ASSIGNED_TO]: newUserIdConfig([], filterConfigLabels[FConfigs.ASSIGNED_TO]),
  [FConfigs.ASSIGNED_TO_ANY]: newUserIdConfig([], filterConfigLabels[FConfigs.ASSIGNED_TO_ANY]),
  [FConfigs.ANSWERED_BY]: newUserIdConfig([], filterConfigLabels[FConfigs.ANSWERED_BY]),
  [FConfigs.APPROVED_BY]: newUserIdConfig([], filterConfigLabels[FConfigs.APPROVED_BY]),
  [FConfigs.INT_COMM_USER]: newUserIdConfig([], filterConfigLabels[FConfigs.INT_COMM_USER]),
  [FConfigs.CATEGORY]: newBCategoryIdConfig([], filterConfigLabels[FConfigs.CATEGORY]),
  [FConfigs.TOPIC]: newBTopicIdConfig([]),
  [FConfigs.TEAM]: newTeamNameConfig([], ''),
  [FConfigs.MY_TEAM]: newYesNoConfig(filterConfigLabels[FConfigs.MY_TEAM]),
  [FConfigs.STATUS]: newStringArrayConfig([], filterConfigLabels[FConfigs.STATUS]),
  [FConfigs.ANSWER]: newYesNoConfig(filterConfigLabels[FConfigs.ANSWER]),
  [FConfigs.SRD]: newYesNoConfig(filterConfigLabels[FConfigs.SRD]),
  [FConfigs.ADR]: newYesNoConfig(filterConfigLabels[FConfigs.ADR]),
  [FConfigs.PQC]: newYesNoConfig(filterConfigLabels[FConfigs.PQC]),
  [FConfigs.INCOMING]: newChannelsConfig([], filterConfigLabels[FConfigs.INCOMING]),
  [FConfigs.OUTGOING]: newChannelsConfig([], filterConfigLabels[FConfigs.OUTGOING]),
  [FConfigs.EVENT]: newEventConfig([], filterConfigLabels[FConfigs.EVENT]),
  [FConfigs.LANGUAGE]: newLanguageIdConfig([]),
  [FConfigs.OFF_LABEL]: newYesNoConfig(filterConfigLabels[FConfigs.OFF_LABEL]),
  [FConfigs.HCP]: newSpecializationsConfig([], filterConfigLabels[FConfigs.HCP]),
  [FConfigs.NON_HCP]: newSpecializationsConfig([], filterConfigLabels[FConfigs.NON_HCP]),
  [FConfigs.KEE]: newYesNoConfig(filterConfigLabels[FConfigs.KEE]),
  [FConfigs.HOSPITAL]: newYesNoConfig(filterConfigLabels[FConfigs.HOSPITAL]),
  [FConfigs.TERRITORY]: newTerritoryConfig([]),
};
export const filterConfigDefault = {
  [FConfigs.MY_TEAM]: 'yes',
  [FConfigs.ANSWER]: 'yes',
  [FConfigs.SRD]: 'yes',
  [FConfigs.ADR]: 'yes',
  [FConfigs.PQC]: 'yes',
  [FConfigs.OFF_LABEL]: 'yes',
  [FConfigs.KEE]: 'yes',
  [FConfigs.HOSPITAL]: 'yes',
};

export class DateRange {
  from?: string;
  to?: string;
  new?: boolean;
}

export class Query {
  field?: string;
  value?: string | any[];
  displayTerm?: string;
  join?: string;
  origin?: SearchOrigin; // Used only by the filters sync
}

export class AdvancedSearch {
  selectedValue: FilterSelection;
  value: string | any[];
  selectedValues: { str };
  selectedJoin: string;
  query: Query[] = [];
  combinedQuery: string = '';
  currentSelection: { [id: string]: Array<string | DateRange | SelectedOption> } = {};
}

export enum FilterModalConfig {
  SHARE = 'share',
  SAVE = 'save',
  SAVEANDSHARE = 'saveAndShare',
  DELETE = 'delete',
  CLOSE = 'close',
}

export const allExports = filterSelection.reduce((previousValue, currentValue) => {
  previousValue[currentValue['selector']] = true;
  return previousValue;
}, {});
