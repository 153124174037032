import { BHistory } from 'app/modules/data-model/data-model.module';

export namespace HistoryFilters {
  export class HistoryFilter {
    constructor(
      public name: string,
      public value: string,
      public categoryNames: string[],
      public specialCaseHandler: (h: BHistory) => boolean = () => false
    ) {}

    includes(h: BHistory): boolean {
      return this.categoryNames.includes(h.category) || this.specialCaseHandler(h);
    }
  }

  export function getAllFilters(): HistoryFilter[] {
    return [
      new HistoryFilter('FORWARD', 'forward', ['forward_to_dept', 'forward_to_user']),
      new HistoryFilter('ERROR', 'error', ['error', 'bounce_detected']),
      new HistoryFilter('INTERNAL_COMM', 'internal_comm', ['other', 'internal']),
      new HistoryFilter('INTERACTION_REFERENCES', 'interaction_references', [
        'interaction_references',
      ]),
      new HistoryFilter('EVENTS', 'events', [
        'add_event_to_int',
        'update_event_within_int',
        'remove_event_from_int',
      ]),
      new HistoryFilter('ASSIGN', 'assign', [
        'personal_assignment',
        'team_assignment',
        'simultaneous_assignment',
        'update_team_and_assignment_after_inquirer_country_change',
        'personal_assignment_on_role_archive',
      ]),
      new HistoryFilter('Status', 'status', [
        'change_status',
        'change_status_to_answered',
        'add_answer_with_status',
        'reject_answer_with_status',
        'reject_answer_with_status_revert',
        'change_status_to_approved_and_send_answer',
      ]),
      new HistoryFilter(
        'SPLIT_MERGE',
        'split_merge_duplicate',
        [
          'interaction_split',
          'interaction_merged',
          'closed_as_merge',
          'merge_as_new_inquiry',
          'merge_as_inquiry',
          'merge_as_duplicate',
          'closed_as_duplicate',
          'duplicate_of_this',
        ],
        filterMerges
      ),
      new HistoryFilter('CREATE', 'create', ['create', 'reopen_answer', 'unspam']),
      new HistoryFilter('APPROVE', 'approve', [
        'add_answer',
        'reject_answer',
        'approve_answer',
        'approved_and_send_answer',
      ]),
      new HistoryFilter('UPDATE', 'update', [
        'change_details',
        'update_detail',
        'delete_attachment',
        'add_attachment',
        'link_repo_doc',
        'unlink_repo_doc',
        'delete_inquiry',
        'update_team_after_inquirer_country_change',
        'update_team_and_assignment_after_inquirer_country_change',
      ]),
      new HistoryFilter('ANSWER', 'answer', [
        'update_answer',
        'send_answer',
        'reopen_answer',
        'copy_answer',
        'approve_answer',
        'approved_and_send_answer',
        'add_answer',
        'add_answer_with_status',
        'reject_answer',
        'reject_answer_with_status',
        'reject_answer_note',
        'change_status_to_approved_and_send_answer',
        'change_status_to_answered',
        'saved_answer_without_email',
        'updated_answer_due_to_product_update',
        'updated_answer_due_to_incoming_channel_update',
        'updated_answer_due_to_deleted_question',
        'added_disclaimer',
        'removed_disclaimer',
        'updated_disclaimer',
      ]),
      new HistoryFilter('CLOSE', 'close', [
        'close_review',
        'send_answer',
        'approved_and_send_answer',
      ]),
      new HistoryFilter('FOLLOW_UP', 'followup', [
        'fu_request',
        'fu_response',
        'lost_fu',
        'resend_fu_request',
        'create_question_from_fu',
        'fu_attempt',
      ]),
      new HistoryFilter('WATCHERS', 'watchers', ['update_watchers']),
      new HistoryFilter('DELETE_INQUIRER_PII', 'deletePersonalInformation', ['delete_pii']),
      new HistoryFilter('QUESTION', 'question', ['create_question_from_fu']),
      new HistoryFilter('NOTIFICATION', 'notification', ['notification'], filterNotifications),
    ].sort((a, b) => a.value.localeCompare(b.value));
  }

  export function resolveFilters(values: string[]): HistoryFilter[] {
    return values ? getAllFilters().filter((hf) => values.includes(hf.value)) : [];
  }

  export function includes(filters: HistoryFilter[], history: BHistory): boolean {
    return !filters || !filters.length || filters.some((f) => f.includes(history));
  }

  function filterMerges(h: BHistory): boolean {
    return (
      ['fu_response', 'other', 'internal', 'error'].includes(h.category) &&
      !!h.mergeOrDuplicateParam
    );
  }

  function filterNotifications(h: BHistory): boolean {
    return (
      h.category === 'notification' ||
      (h.category === 'update_detail' && h.text?.startsWith('{"gdpr_notification":'))
    );
  }
}
