import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';

import { apiUrl, apiUrlForHost } from 'app/common/url-resolver.service';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

@Injectable({ providedIn: 'root' })
export class AuthRequestService {
  constructor(private http: HttpClient, private messageService: MessageHandlerService) {}

  performAction(request: AuthRequestActionRequest, host?: string): Observable<void> {
    const url = this.resolveUrl(host);
    return this.http.post<void>(url, request).pipe(
      catchError((err) => {
        this.messageService.httpError('Auth Request Action', err);
        return of(err);
      })
    );
  }

  create(userId: number, affiliations: AffiliationsRequest, host?: string): Observable<number[]> {
    const url = this.resolveUrl(host);
    const request = {
      userId: userId,
      affiliations: affiliations,
    };
    return this.http.put<number[]>(url, request).pipe(
      map((authRequestIds) => {
        if (authRequestIds?.length) {
          this.messageService.info('Authorization request created correctly.');
        } else {
          this.messageService.error('Authorization request creation: empty response');
        }
        return authRequestIds;
      }),
      catchError((err, _caught) => {
        this.messageService.httpError('Authorization request creation', err);
        return of(err);
      })
    );
  }

  private resolveUrl(host?: string): string {
    return host ? apiUrlForHost(host, 'users', 'auth-requests') : apiUrl('users', 'auth-requests');
  }
}

export type AuthRequestActionRequest = {
  authRequestId: number;
  deactivateUserId?: number;
  userHasAssignedInteractions?: boolean;
};

export type AffiliationsRequest = {
  [teamId: number]: number[];
};
