import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from 'app/common/url-resolver.service';
import { Observable } from 'rxjs';
import { SimplePage } from 'app/common/types';
import { Ajax } from 'app/common/ajax';
import { map } from 'rxjs/operators';
import {
  BMailImportDomainSettings,
  TMailImportDomainSettingsData,
  TMailImportDomainSettingsParams,
} from './mail-import-domain-settings';
import { isNil, omitBy } from 'lodash';

@Injectable({ providedIn: 'root' })
export class MailImportDomainSettingsService {
  private readonly URL = apiUrl('/mail-import-domains-settings');
  constructor(private http: HttpClient) {}

  fetchMailImportDomainSettings(
    params: TMailImportDomainSettingsParams
  ): Observable<SimplePage<BMailImportDomainSettings>> {
    return this.http
      .get<SimplePage<BMailImportDomainSettings>>(this.URL, {
        ...Ajax.X_NO_LOADING_OPTIONS,
        params: omitBy(params, isNil),
      })
      .pipe(
        map((response) => {
          response.records = BMailImportDomainSettings.fromRestArray(response.records);
          return response;
        })
      );
  }

  get(): Observable<SimplePage<BMailImportDomainSettings>> {
    return this.http.get<SimplePage<BMailImportDomainSettings>>(this.URL, {
      params: { mailImportDomainSettings: 'mailImportDomainSettings' },
    });
  }

  create(data: TMailImportDomainSettingsData): Observable<void> {
    return this.http.post<void>(this.URL, omitBy(data, isNil));
  }

  edit(data: TMailImportDomainSettingsData): Observable<void> {
    return this.http.put<void>(this.URL, omitBy(data, isNil));
  }

  delete(mailImportDomainSettingsId: number): Observable<void> {
    return this.http.delete<void>(this.URL + '/' + mailImportDomainSettingsId);
  }
}
