<div *ngIf="document && document.currentVersion">
  <app-document-available-actions
    *ngIf="adminMode"
    [document]="document"
  ></app-document-available-actions>
  <div class="slide-container">
    <div class="slide-nav-container">
      <div *ngIf="!adminMode" class="text-14-700-dark">
        <div class="medis-flex-container-even">
          {{ 'SELECTED_SLIDES' | translate }}: {{ countSelected }} {{ 'OUT_OF' | translate }}
          {{ allSlides.length }}
        </div>
        <div class="medis-flex-container-between">
          <span>{{ 'SELECT_SLIDE' | translate }}</span>
          <mat-checkbox
            [labelPosition]="'before'"
            [checked]="checkAttachSelected"
            (change)="selectAll($event.checked)"
          >
            {{ (checkAttachSelected ? 'DESELECT_ALL' : 'SELECT_ALL') | translate }}
          </mat-checkbox>
        </div>
      </div>
      <div class="slide-preview-container">
        <div *ngFor="let slide of allSlides; let i = index" class="medis-flex-container-between">
          <mat-checkbox
            *ngIf="!adminMode"
            [matTooltip]="'SELECT_SLIDE' | translate"
            [checked]="slide.selected"
            (change)="select(slide, $event.checked)"
          ></mat-checkbox>
          <img
            class="slide-preview medis-clickable"
            [ngClass]="slide === allSlides[currentIndex] ? 'medis-frame-4-azure' : ''"
            [src]="getSlideImgLink(slide)"
            (click)="currentIndex = i"
          />
        </div>
      </div>
    </div>
    <img class="full-slide" [src]="getSlideImgLink(allSlides[currentIndex])" />
  </div>
</div>
