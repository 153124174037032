import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AttachmentsService,
  BAttachment,
  BMedicalDocument,
  MedicalDocumentsService,
  RepositoryBrowserService,
} from 'app/modules/data-model/data-model.module';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { BDMMessageAttachment } from 'app/modules/data-model/message/message';
import { TranslateService } from '@ngx-translate/core';

const icon2Extensions = {
  'fa-file-powerpoint': ['pptx', 'ppt'],
  'fa-file-word': ['docx', 'doc'],
  'fa-file-pdf': ['pdf'],
  'fa-file-excel': ['xlsx', 'xls'],
  'fa-file-image': ['jpeg', 'jpg', 'png'],
};

const resolveIcon = (ext: string): string => {
  return Object.keys(icon2Extensions).find((key) => icon2Extensions[key].includes(ext));
};

@Component({
  selector: 'app-link-visualization',
  templateUrl: './link-visualization.component.html',
  styleUrls: ['./link-visualization.component.scss'],
})
export class LinkVisualizationComponent implements OnInit, OnDestroy {
  @Input() link: BAttachment | BMedicalDocument;
  @Input() allowRemove: boolean = true;
  @Input() onlyEventOnRemove: boolean = false;
  @Input() style: 'compact' | undefined = undefined;
  @Input() canOpenFile: boolean = true;

  @Output() removeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteAttachment = new EventEmitter<string>();

  typeClass: string;
  linkClass: string;
  tooltipText: string;
  isDeletedAttachment: boolean = false;
  isDocumentWithExternalSourceUrl: boolean = false;
  documentExternalSourceUrl: string;

  private subs: Subscription = new Subscription();

  constructor(
    private urlResolver: UrlResolverService,
    private attachmentsService: AttachmentsService,
    private repoBrowserService: RepositoryBrowserService,
    private medicalDocumentsService: MedicalDocumentsService,
    private messageService: MessageHandlerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    // set link icon class here for performance, depending from type and extension
    if (this.link) {
      if (this.link instanceof BMedicalDocument && this.link.currentVersion?.url) {
        this.documentExternalSourceUrl = this.link.currentVersion.url;
        this.isDocumentWithExternalSourceUrl = true;
      }
      this.initIconClasses(this.link);
      this.resolveTooltipText();
      this.isDeletedAttachment = this.link instanceof BAttachment && this.link.isDeleted;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  deleteLink(link: BAttachment | BMedicalDocument | BDMMessageAttachment): void {
    if (link instanceof BDMMessageAttachment) {
      this.messageService.error('Deleting an attachment from the message is not allowed!');
      return;
    }

    if (this.onlyEventOnRemove) {
      this.removeChange.emit(true);
    } else if (link instanceof BAttachment) {
      this.subs.add(
        this.attachmentsService.delete(link.pk()).subscribe({
          next: () => {
            this.link = undefined;
            this.deleteAttachment.emit(link.id);
          },
          error: (error) => {
            this.messageService.httpError('Delete Attachment', error);
          },
        })
      );
    } else {
      this.subs.add(
        this.medicalDocumentsService
          .removeLink(link.pk(), link.historyObjectPk, link.isHistoryNew)
          .subscribe({
            next: () => {
              this.link = undefined;
            },
            error: (error) => {
              this.messageService.httpError('Remove Document Reference', error);
            },
          })
      );
    }
  }

  visualizeLink(link: BAttachment | BMedicalDocument | BDMMessageAttachment): string {
    return link instanceof BAttachment || link instanceof BDMMessageAttachment
      ? link.name
      : link.shortTitle;
  }

  openLink(): void {
    if (!this.canOpenFile) {
      return;
    }
    if (this.link instanceof BAttachment || this.link instanceof BDMMessageAttachment) {
      this.downloadAttachment();
    } else {
      if (this.link.currentVersion.url) {
        window.open(this.link.currentVersion.url, '_blank');
      } else if (['smpc', 'pil', 'lit', 'other'].indexOf(this.link.type.code) >= 0) {
        if (this.link.currentVersion.files.length > 0) {
          this.repoBrowserService.downloadMedicalDocumentFile(this.link.currentVersion.files[0]);
        } else {
          console.error('File is not available');
        }
      } else {
        this.repoBrowserService.downloadHistoryMedicalDocumentVersion(
          this.link.currentVersion,
          this.link.historyObjectId,
          this.link.outputFormat
        );
      }
    }
  }

  private initIconClasses(link: BAttachment | BMedicalDocument | BDMMessageAttachment): void {
    if (link instanceof BAttachment || link instanceof BDMMessageAttachment) {
      this.typeClass = 'fas fa-paperclip';
      const extension = link.name.substr(link.name.lastIndexOf('.') + 1);
      this.linkClass = 'far ' + resolveIcon(extension) || 'fa-file';
    } else {
      this.typeClass = 'far fa-folder-open';
      if (link.currentVersion.url) {
        this.linkClass = 'fa fa-link';
      } else if (resolveIcon(link.outputFormat)) {
        this.linkClass = 'far ' + resolveIcon(link.outputFormat);
      } else if (link.type.code == 'srd' || link.type.code == 'faq') {
        this.linkClass = 'far fa-file-word';
      } else if (link.type.code == 'slide') {
        this.linkClass = 'far fa-file-powerpoint';
      } else if (
        link.type.code !== 'srd' &&
        link.type.code !== 'faq' &&
        link.type.code !== 'slide'
      ) {
        this.linkClass = 'far fa-file-pdf';
      }
    }
  }

  private resolveTooltipText(): void {
    if (this.isDocumentWithExternalSourceUrl) {
      this.tooltipText = `${this.translateService.instant('DOCUMENT_EXTERNAL_URL')}: ${
        this.documentExternalSourceUrl
      }`;
    } else {
      this.tooltipText = this.visualizeLink(this.link);
    }
  }

  private downloadAttachment(): void {
    const urlComponents =
      this.link instanceof BAttachment
        ? [this.link.file, '?objectId=' + encodeURI(this.link.id)]
        : ['mis', 'message-attachment', `${this.link.id}`];
    window.open(this.urlResolver.apiUrlForPath(urlComponents));
  }
}
