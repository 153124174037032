import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { shareTemplateForInteraction } from './queries';

@Injectable({ providedIn: 'root' })
export class ShareTemplateService {
  response: Subject<string> = new Subject<string>();

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {}

  performQueryWithInteraction(dataParams: any): void {
    this.apollo
      .query({
        query: shareTemplateForInteraction,
        variables: {
          params: JSON.stringify(dataParams),
        },
        fetchPolicy: 'network-only',
      })
      .subscribe({
        next: (response) => {
          this.response.next(response.data['shareTemplateForInteraction']);
        },
        error: (error) => {
          this.messageService.error('(ShareTemplate) ' + error);
        },
      });
  }
}
