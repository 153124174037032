export class BMailbox {
  id: number;
  name: string;
  uri: string;
  fromEmail: string;
  active: boolean;
  lastPolling: string;

  constructor(json: any) {}

  static fromRest(json: any): BMailbox {
    if (json) {
      return Object.assign(new BMailbox({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]) {
    return json && json.map((v) => BMailbox.fromRest(v));
  }
}
