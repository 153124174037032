import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { DocumentCreateComponent } from 'app/modules/repository/document-create/document-create.component';
import { filter, Subscription } from 'rxjs';

import { NomenclaturesService, Scopes } from 'app/modules/data-model/data-model.module';
import {
  Config,
  newBCategoryIdConfig,
  newBTopicIdConfig,
  newLanguageIdConfig,
  newMedicalDocumentAudienceIdConfig,
} from 'app/common/common/search-select/search-select.config';
import { MedicalDocumentsAppliedFilters } from 'app/modules/data-model/medical-documents-approval/medical-documents-approval';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { STATUSES_MAP, STATUSES_TRANSLATION_KEYS } from 'app/common/common/constants/statuses';
import { MedicalDocumentsApprovalService } from 'app/modules/data-model/medical-documents-approval/medical-documents-approval.service';
import { AuthService } from 'app/auth/auth.service';
import { canApproveDocuments } from 'app/common/util/permission.guard';

@Component({
  selector: 'app-documents-left-bar-view',
  templateUrl: './documents-left-bar-view.component.html',
  styleUrls: ['./documents-left-bar-view.component.scss'],
})
export class DocumentsLeftBarViewComponent implements OnInit, OnDestroy {
  managementUrl = 'repository/management';
  approvalsUrl = 'repository/approvals';
  DOCUMENTS_COUNT: Record<typeof STATUSES_TRANSLATION_KEYS[number] | 'TOTAL', number | null> = {
    TOTAL: null,
    ARCHIVED: null,
    DRAFT: null,
    APPROVED: null,
    SUBMITTED_FOR_APPROVAL: null,
    IN_REVIEW: null,
  };
  isDocumentManagement: boolean;
  shouldDisplayDocsApprovalsUrl: boolean;
  approvalsMenuVisible: boolean;
  languageConfig: Config = newLanguageIdConfig([]);
  medicalDocumentAudiencesConfig: Config = newMedicalDocumentAudienceIdConfig([]);
  categoryConfig: Config = newBCategoryIdConfig([]);
  topicConfig: Config = newBTopicIdConfig([]);
  filters: MedicalDocumentsAppliedFilters = MedicalDocumentsAppliedFilters.defaults();
  availableStatuses = Object.keys(STATUSES_MAP);
  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService,
    private nomenclaturesService: NomenclaturesService,
    private medicalDocumentsApprovalService: MedicalDocumentsApprovalService
  ) {
    this.isDocumentManagement = this.router.url.includes(this.managementUrl);
    this.approvalsMenuVisible = !this.isDocumentManagement;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.authService.user.subscribe((user) => {
        this.shouldDisplayDocsApprovalsUrl = canApproveDocuments(user);
      })
    );
    this.subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event) => {
          this.isDocumentManagement = 'url' in event && event.url.includes(this.managementUrl);
          this.approvalsMenuVisible = !this.isDocumentManagement;
        })
    );

    this.subscription.add(
      this.medicalDocumentsApprovalService.appliedFilters$.subscribe((appliedFilters) => {
        this.filters = appliedFilters;
      })
    );

    this.subscription.add(
      this.medicalDocumentsApprovalService.documents$.subscribe((docs) => {
        const { totalRecords, docsCountPerStatus } = docs || {};

        if (totalRecords && docsCountPerStatus) {
          this.DOCUMENTS_COUNT = {
            TOTAL: totalRecords,
            ARCHIVED: docsCountPerStatus.archived,
            DRAFT: docsCountPerStatus.draft,
            APPROVED: docsCountPerStatus.approved,
            IN_REVIEW: docsCountPerStatus.in_review,
            SUBMITTED_FOR_APPROVAL: docsCountPerStatus.in_approval,
          };
        }
      })
    );

    const scopes = new Scopes().languages().categories().topics().medicalDocumentAudiences();

    this.nomenclaturesService.get(scopes).subscribe((resp) => {
      this.languageConfig = newLanguageIdConfig(resp.languages);
      this.medicalDocumentAudiencesConfig = newMedicalDocumentAudienceIdConfig(
        resp.medicalDocumentAudiences
      );
      this.categoryConfig = newBCategoryIdConfig(resp.categories);
      this.topicConfig = newBTopicIdConfig(resp.topics);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createNewDocument(): void {
    this.matDialog.open(DocumentCreateComponent, { disableClose: true });
  }

  resetFilters(): void {
    this.medicalDocumentsApprovalService.appliedFilters$.next(
      MedicalDocumentsAppliedFilters.defaults()
    );
  }

  updateFilters(): void {
    this.medicalDocumentsApprovalService.appliedFilters$.next({ ...this.filters });
  }

  statusToggle(event: MatCheckboxChange, status: string): void {
    this.medicalDocumentsApprovalService.appliedFilters$.next({
      ...this.filters,
      statuses: event.checked
        ? [...new Set(this.filters.statuses.concat(status))]
        : this.filters.statuses.filter((fst) => fst !== status),
    });
  }
}
