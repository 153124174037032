import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numbersOnly]',
})
export class NumberOnlyDirective {
  constructor(private _element: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this._element.nativeElement.value;

    this._element.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
    if (initialValue !== this._element.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
