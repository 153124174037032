<form (ngSubmit)="save()">
  <div class="options">
    <mat-form-field class="w-100 form-field-wrapper" [hideRequiredMarker]="true">
      <mat-label>{{ 'SUBJECT' | translate }} ({{ 'REQUIRED' | translate }})</mat-label>
      <input
        matInput
        [(ngModel)]="subject"
        [ngModelOptions]="{ standalone: true }"
        required
        autocomplete="off"
      />
    </mat-form-field>
    <mat-form-field class="w-100 form-field-wrapper" [hideRequiredMarker]="true">
      <mat-label>{{ 'Name' | translate }} ({{ 'REQUIRED' | translate }})</mat-label>
      <input matInput [(ngModel)]="templateName" [ngModelOptions]="{ standalone: true }" required />
    </mat-form-field>
    <app-search-select
      [required]="true"
      [multiple]="true"
      [config]="teamConfig"
      [(selectedValue)]="selectedTeamIds"
    ></app-search-select>
  </div>
  <editor
    [(ngModel)]="templateText"
    [ngModelOptions]="{ standalone: true }"
    [inline]="false"
    [init]="tinymceOptions"
    class="tiny-mce-popup"
  ></editor>

  <div class="medis-flex-container-even medis-pt-25">
    <button
      type="submit"
      mat-button
      class="medis-button-main"
      [disabled]="
        formValid
          | validateRequiredFields
            : templateName?.trim()
            : subject?.trim()
            : templateText?.trim()
            : selectedTeamIds.length
      "
    >
      {{ 'SAVE_UPPERCASE' | translate }}
    </button>
    <button type="reset" mat-button class="medis-button-reject" (click)="cancel()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</form>
