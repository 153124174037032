import { SimplePage } from 'app/common/types';
import { base64ToPK } from '../data-model';
import { IDataValue } from '../../../../@mi-tool/models/data-model';

export interface IQParams {
  teamId?: number;
  orderBy?: string;
  pageSize: number;
  pageIndex: number;
  filterId?: number[];
  filterName?: string[];
  filterEmail?: string[];
  filterCountry?: string[];
  filterTeam?: string[];
  filterArea?: string[];
  filterProduct?: string[];
}

export interface ICreateDepartment {
  id: string;
  name: string;
  type: string;
  email: string;
  team: string;
}

export interface IDepartmentsDropdownsData {
  id: string[];
  name: string[];
  email: string[];
  country: string[];
  team: string[];
  product: string[];
  therapeutic_area: string[];
  all_teams: Array<ITeamExtendedData>;
}

export interface ITeamExtendedData {
  id: string;
  name: string;
  countries: Array<IDataValue>;
}

export interface IDepartmentDialogData {
  action: string;
  department: Department | '';
  dropdowns: IDepartmentsDropdownsData | '';
  message: string;
}

export class Department {
  id: string;
  name: string;
  type_code: string;
  email: string;
  countries: string[];
  team: string;
  therapeutic_areas: string[];
  products: string[];

  static fromRest(json: any): Department {
    return Object.assign(new Department(), json);
  }

  static fromRestArray(json: any[]): Department[] {
    return json && json.map((v) => Department.fromRest(v));
  }

  pk(): number {
    if (this.id) {
      return base64ToPK(this.id);
    }
    return undefined;
  }
}

export type TDepartmentsResult = SimplePage<Department>;
