import { gql } from 'apollo-angular';

import { BaseModel, rProperty, parseArray } from '../data-model';
import { BNotificationOption } from '../notification-option/notification-option';
import { BNotificationFrequency } from '../notification-frequency/notification-frequency';

export class BNotificationType extends BaseModel {
  @rProperty() id: string;
  @rProperty() notificationTypeId: string;
  @rProperty() name: string;
  @rProperty() group: string;
  @rProperty() description: string;
  @rProperty() order: number;
  @rProperty() valueType: 'boolean' | 'frequency' | 'option';
  @rProperty() textGuide: string;

  notificationFrequencies: BNotificationFrequency[];
  notificationOptions: BNotificationOption[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.notificationFrequencies = parseArray<BNotificationFrequency>(
      json,
      BNotificationFrequency,
      'notificationFrequencies'
    );
    this.notificationOptions = parseArray<BNotificationOption>(
      json,
      BNotificationOption,
      'notificationOptions'
    );
  }

  static fromRest(json: any): BNotificationType {
    if (json) {
      json.notificationFrequencies = BNotificationFrequency.fromRestArray(
        json.notificationFrequencies
      );
      json.notificationOptions = BNotificationOption.fromRestArray(json.notificationOptions);
    }
    return Object.assign(new BNotificationType({}), json);
  }

  static fromRestArray(json: any[]): BNotificationType[] {
    return json && json.map((v) => BNotificationType.fromRest(v));
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment notificationTypeFragment on NotificationTypeNode {
    id
    notificationTypeId
    name
    group
    description
    order
    textGuide
    valueType
  }
`;
