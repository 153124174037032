import { Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MedisMatSidenavHelperService } from './medis-mat-sidenav.service';

@Directive({
  selector: '[medisMatSidenavHelper]',
})
export class MedisMatSidenavHelperDirective implements OnInit {
  @HostBinding('class.mat-is-locked-open') isLockedOpen: boolean;
  @Input() medisMatSidenavHelper: string;
  @Input() matIsLockedOpen: string;

  constructor(
    private _medisMatSidenavHelperService: MedisMatSidenavHelperService,
    private _matSidenav: MatSidenav
  ) {
    this.isLockedOpen = true;
  }

  ngOnInit(): void {
    this._medisMatSidenavHelperService.setSidenav(this.medisMatSidenavHelper, this._matSidenav);
    this.isLockedOpen = true;
    this._matSidenav.mode = 'side';
    this._matSidenav.toggle(true);
    /*        this.isLockedOpen = false;
        this._matSidenav.mode = 'over';
        this._matSidenav.toggle(false);*/
  }
}

@Directive({
  selector: '[medisMatSidenavToggler]',
})
export class MedisMatSidenavTogglerDirective {
  @Input() medisMatSidenavToggler: string;

  constructor(private _medisMatSidenavHelperService: MedisMatSidenavHelperService) {}

  @HostListener('click') onClick(): void {
    this._medisMatSidenavHelperService.getSidenav(this.medisMatSidenavToggler).toggle();
  }
}
