<div *ngIf="document" class="srd-faq-link">
  <app-document-available-actions
    *ngIf="adminMode"
    [document]="document"
  ></app-document-available-actions>
  <div *ngIf="document.currentVersion">
    <div *ngIf="document.currentVersion.word?.sections?.length > 0">
      <mat-checkbox
        class="select-all"
        *ngIf="!adminMode"
        [checked]="selectAll"
        (change)="checkAll($event.checked)"
        >{{ 'SELECT_ALL' | translate }}</mat-checkbox
      >
      <mat-accordion>
        <mat-expansion-panel
          *ngFor="let section of document.currentVersion.word?.sections; let i = index"
          [expanded]="i === 0"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-checkbox
                *ngIf="!adminMode"
                [checked]="section.selected"
                (change)="section.selected = $event.checked"
                (click)="$event.stopPropagation()"
              ></mat-checkbox>
              <div class="truncated-text section-title text-15-700-dark">{{ section.name }}</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="section-preview" [innerHtml]="section.text | sanitizeHtml"></div>
          <div class="aligned-right">
            <button mat-button class="medis-button-basic" (click)="openSectionPreview(section)">
              <i class="fas fa-eye"></i>
              {{ 'PREVIEW_AS_PDF' | translate }}
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div *ngIf="document.currentVersion.word?.sections?.length === 0">
      <div class="text-16-800-dark">{{ 'Preview' | translate }}</div>
      <div [innerHtml]="document.currentVersion.extractedText | sanitizeHtml"></div>
    </div>
    <app-link-visualization
      *ngIf="isDocumentWithExternalSourceUrl"
      [link]="document"
      [allowRemove]="false"
    ></app-link-visualization>
  </div>
</div>
