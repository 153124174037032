export const koreanLangData = {
  MEDICAL_INFO_SYSTEM: '의료 정보 시스템',
  ACTIVE_EVENTS: '활성 이벤트',
  SELECT_AN_EVENT_TO_BROWSE_USERS: '사용자를 찾아 볼 이벤트를 선택하십시오.',
  BACK_TO_THE_EVENT_SELECTION: '이벤트 선택으로 돌아 가기',
  IF_YOU_FORGOT_YOUR_PASSWORD: '비밀번호를 잊어 버린 경우',
  LOGIN: '로그인',
  RESET_IT_HERE: '여기로 재설정',
  CHANGE_LANGUAGE_TO: '언어를',
  TEST_SYSTEM: '테스트 시스템',
  HELP: '도움',
  THIS_IS_TEST_ENV: '실제 질문을 삽입하려면 테스트 환경입니다.',
  USER_OR_PASS_IS_INCORRECT: '사용자 이름이나 비밀번호가 잘못되었습니다',
  OPS: '작전',
  WE_DID_NOT_FIND: '우리는 당신이 찾고있는 것을 찾지 못했습니다',
  VIDEO_TUTORIALS: '비디오 자습서',
  STILL_DIDNT_FIND: '아직도 당신이 찾고있는 것을 찾지 못했습니다.',
  ASK_THE_SUPPORT_TEAM: '지원팀에 문의',
  LOGGED_IN_AS: '로 로그인',
  MY_PROFILE: '내 프로필',
  LOGOUT: '로그 아웃',
  RETRIEVING_YOUR_ACCESS_INFO: '액세스 정보 검색',
  LOGGED_IN_CAN_CLOSE: '로그인하여 이제이 탭을 닫을 수 있습니다',
  TOKEN_HAS_NOT_BEEN_SAVED: '토큰이 제대로 저장되지 않았습니다. 지원팀에 문의하십시오',
  PASSWORD_RESET: '비밀번호 초기화',
  RESET_PASSWORD: '암호를 재설정',
  INSERT_YOUR_EMAIL_TO_RESET_PASS: '비밀번호를 재설정하려면 이메일을 입력하십시오',
  CANCEL: '취소',
  CANCEL_UPPERCASE: '취소',
  RETURN_TO_LOGIN: '로그인으로 돌아 가기',
  USERNAME: '사용자 이름',
  USERNAME_IS_REQUIRED: '사용자 이름이 필요합니다',
  PASSWORD: '암호',
  PASSWORD_IS_REQUIRED: '비밀번호가 필요합니다',
  FIRST_NAME: '이름',
  LAST_NAME: '성',
  REGISTER: '레지스터',
  LOADING: '로딩',
  INSERT_NEW_PASS: '새 비밀번호를 입력하십시오',
  VERIFYING_TOKEN: '토큰 확인',
  PASSWORD_DO_NOT_MATCH: '비밀번호가 일치하지 않습니다',
  PASS_RESET_SUCCESSFUL: '비밀번호 재설정 성공',
  SAVE_NEW_PASS: '새 비밀번호 저장',
  GO_TO_INBOX: '받은 편지함으로 이동',
  SELECT_ALL: '모두 선택',
  DESELECT_ALL: '모두 선택 해제',
  DO_NOT_SHOW_AGAIN: '다시 표시하지 않습니다',
  INQUIRY: '문의',
  ATTACHMENT_WITH_THIS_INQUIRY: '>이 문의에 첨부',
  USER_DETAILS: '사용자 정보',
  CLOSE: '닫기',
  TEAMS: '팀',
  TEAM: '팀',
  SPECIALIZATION_IS: '전문화는',
  REQUIRED: '필수',
  RESULTS_PER_PAGE: '페이지 당 결과',
  RESULTS_FOUND: '찾은 결과',
  EXPORT: '수출',
  PERSONAL_SETTINGS: '개인 설정',
  TEAM_SETTINGS: '팀 설정',
  USER_SETTINGS_OF: '사용자 설정',
  SET_TEMPLATE: '템플릿 설정',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: '현재 알림으로 덮어 쓰기',
  PREVIEW: '시사',
  NOTIFICATIONS: '알림',
  ROLE: '역할',
  TEMPLATE_NAME: '템플릿 이름',
  SAVE_THIS_USER_AS_A_TEMPLATE: '이 사용자를 템플릿으로 저장',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    '사용자에게 템플릿을 추가하고 알림의 역할 및 환경 설정을 복사하십시오.',
  AUTH_AND_NOTIFY_TEMPLATE: '승인 및 알림 템플릿',
  MY_ROLES: '내 역할',
  STATUS: '상태',
  THIS_ROLE_IS_TEMP: '이 역할은 일시적이며이 사용자가 대리인이라는 사실로 인해 제공되었습니다',
  CREATED: '만들어진',
  APPROVED_REJECTED: '승인 / 거부',
  APPROVED_REJECTED_BY: '승인 / 거부',
  ARCHIVE: '아카이브',
  SAVE: '저장',
  IMMEDIATE_EFFECT: '즉각적인 효과',
  OUT_OF_OFFICE_DEPUTY: '비 과실',
  AUTO_ASSIGN_TO: '이 우수한',
  GEOGRAPHY: '지리학',
  ALIGNMENT: '조정',
  Alignments: '정렬',
  Address: '주소',
  PHONE_FAX: '전화 / 팩스',
  Email: '이메일',
  Contacts: '콘택트 렌즈',
  WORK_INFO: '업무 정보',
  LAST_NAME_IS: '성은',
  PERSONAL_INFO: '개인 정보',
  USER: '사용자',
  ID: '신분증',
  UPDATED_FROM: '에서 업데이트',
  ON: '의 위에',
  CRM: 'CRM',
  IMPORTED_FROM: '에서 수입',
  CHANGE_PASS: '비밀번호 변경',
  NOTIFICATION_IN_TEMPLATE: '템플릿의 알림',
  CONTINUE: '계속하다',
  DONT_SHOW_AGAIN: '이 메시지를 다시 표시하지 마십시오',
  USING_THE_TABS_ON_THE: '화면 상단의 탭 사용',
  PERSONAL_PROFILE_SETTINGS: '개인 프로필 설정',
  AND_YOUR: '그리고 당신의',
  YOU_CAN_SWITCH_BETWEEN: '당신은 사이를 전환 할 수 있습니다',
  WELCOME_TO_YOUR_USER_PROFILE: '사용자 프로필에 오신 것을 환영합니다',
  SAVE_THIS_USER_AS: '이 사용자를 템플릿으로 저장',
  INCLUDE_NOTIFY_SETTINGS: '알림 설정 포함',
  INCLUDE_ROLES_SETTINGS: '역할 설정 포함',
  ACTIVE_NOTIFICATIONS: '활성 알림',
  SET_THIS_USER_AS_A_: '이 사용자 권한 부여 및 알림을 새 템플릿으로 설정',
  REQUEST_AUTH: '요청 승인',
  REQUEST: '의뢰',
  FEEDBACK_SUPPORT: '피드백 및 지원',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    '그래도 원하는 것을 찾을 수 없으면 지원 팀에 문의하십시오! 또한 향후 개선에 대한 제안 사항이있는 경우 올바른 장소입니다.',
  TEMPLATE: '주형',
  SIGNATURE: '서명',
  Disclaimers: '면책',
  Greetings: '인사말',
  CUSTOMIZE: '커스텀',
  SAVE_UPPERCASE: '저장',
  DELETE: '지우다',
  RESET_TO_DEFAULT: '기본값으로 재설정',
  AFTER_SIGNATURE: '서명 후',
  BEFORE_SIGNATURE: '서명하기 전에',
  AFTER_ANSWER: '답변 후',
  BEFORE_ANSWER: '답변하기 전에',
  AFTER_GREET: '인사말 후',
  BEFORE_GREET: '인사말 전',
  Any: '어떤',
  HCP: 'HCP',
  NO_HCP: 'HCP 없음',
  Position: '위치',
  ON_LABEL: '라벨에',
  OFF_LABEL: '라벨이없는',
  YOU_ARE_TRYING_TO_CUSTOMIZE: '템플릿을 사용자 정의하려고합니다',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    '당신은 그것을 할 수있는 권한이 없습니다. 계속 진행하려면 템플릿 사본이 생성되고 거기서 변경됩니다.',
  CREATE_COPY: '사본 만들기',
  RECEIVED_ON: '받은',
  SENDER_EMAIL: '이메일 보내기',
  SUBJECT: '제목',
  UNSPAM: '스팸 방지',
  UPLOAD: '업로드',
  APPROVED: '승인',
  DRAFT: '초안',
  SELECT_STATUS: '상태 선택',
  UPLOAD_AS: '다른 이름으로 업로드',
  TITLE: '표제',
  REPOSITORY: '저장소',
  BROWSE: '검색',
  URL: 'URL',
  APPROVED_ON: '에 승인 됨',
  AUTH_HOLDER: '승인 보유자',
  AUTH_NUMBERS: '인증 번호',
  APPROVED_FOR: '승인',
  SELECT_ONE_REGION: '하나의 지역을 선택하십시오',
  OWNED_BY: '에게 소유 된',
  KEYWORDS: '키워드',
  TOPICS: '토픽',
  CATEGORIES: '카테고리',
  PRODUCTS: '제품',
  PRODUCT: '생성물',
  LANGUAGE_IS_REQUIRED: '언어가 필요합니다',
  LANGUAGE: '언어',
  TARGET_CUSTOMERS: '대상 고객',
  TYPE_IS_REQUIRED: '유형이 필요합니다',
  TYPE: '유형',
  TITLE_IS_REQUIRED: '제목이 필요합니다',
  DEFAULT_REFERENCE_IS_CREATED_:
    '기본 참조는 밴쿠버 스타일로 작성됩니다. 이것을 무시하려면 여기에 전체 참조를 입력하십시오',
  REFERENCE: '참고',
  DOI: 'DOI',
  PUBMED_ID: 'Pubmed ID',
  PAGES: '페이지',
  ISSUE: '발행물',
  VOLUME: '음량',
  JOURNAL: '일지',
  AUTHORS: '저자',
  ABSTRACT: '요약',
  REFERENCE_IS_REQUIRED: '참조가 필요합니다',
  THIS_DOC_IS_APPROVED: '이 문서는 승인되었습니다. 여기를 클릭하여',
  ORIGINAL_DOCUMENT_AND_: '원본 문서를 작성하고 의견을 삽입하십시오',
  EDIT_FILE: '파일 편집',
  PROPERTIES: '속성',
  OPEN: '열다',
  LOCALIZED_DOCUMENTS: '현지화 된 문서',
  ORIGINAL_DOCUMENTS: '원본 문서',
  INTERNAL_APPROVAL_NUMBER: '내부 승인 번호',
  CREATED_BY: '작성자',
  EXPIRATION_DATE: '만료일',
  VERSION_NUMBER: '버전 번호',
  NO_VERSION_AVAILABLE: '사용 가능한 버전이 없습니다',
  ARCHIVED_IF_NO_DRAFT: '보관 : 초안 / 승인 된 버전이없는 경우',
  APPROVED_FOR_USERS: '승인 됨 : 사용자 용.',
  DRAFT_FOR_AUTHORS_: '초안 : 저자와 편집자.',
  VERSION_LEGEND: '버전 범례',
  VERSION: '버전',
  SELECT_ONE_LANG: '하나의 언어를 선택하십시오.',
  SELECT_ONE_TYPE: '하나의 유형을 선택하십시오.',
  INTERNAL_NUMBER_REQUIRED: '내부 승인 번호가 필요합니다.',
  FILE: '파일',
  ATTACH_FILE: '파일을 첨부',
  LITERATURE: '문학',
  SLIDE_DECK: '슬라이드 데크',
  PIL: '필',
  FAQ: '자주하는 질문',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: '승인 단계 추가',
  ADD_PARALLEL_STEP: '병렬 단계 추가',
  DELETE_STEP: '단계 삭제',
  DELETE_ALL_PARALLEL_STEPS: '모든 병렬 단계 삭제',
  CHANGE_STATUS_NAME: '상태 이름 변경',
  SET_REQUIRED_ROLE: '필요한 역할 설정',
  SET_TEAM_MEMBER: '팀원 설정',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: '이 프로세스를 변경할 수있는 권한이 없습니다.',
  SAVE_ROLE: '역할 저장',
  CREATE_NEW_WORKFLOW: '새 워크 플로 만들기',
  CREATE_NEW_STEP: '새로운 단계 만들기',
  PLEASE_ENTER_THE_NUMBER_OF_:
    '동시에 승인되는 단계를 의미하는 병렬 승인 단계 수를 입력하십시오 (순차 아님). 병렬 승인 단계가없는 경우 1을 입력하십시오. 나중에 언제든지 승인 단계 수를 변경할 수 있습니다.',
  APPROVAL_MUST_BE_AT_LEAST: '승인은 최소한',
  INSERT_THE_NEW_STATUS_NAME: '새로운 상태 이름을 삽입하십시오',
  INSERT_THE_NEW_WORKFLOW_NAME: '새 워크 플로우 이름을 삽입하십시오.',
  INSERT_THE_NEW_STEP_NAME: '새 단계 이름을 삽입하십시오.',
  NEW_DOCUMENT: '새 문서',
  CREATE: '창조하다',
  DOCUMENT_MANAGEMENT: '문서 관리',
  CONFIRM: '확인',
  CONFIRM_PROCESS: '프로세스 확인',
  DEFINE_APPROVAL_STEPS_AND_TEAM: '승인 단계 및 팀 정의',
  SET_DETAILS: '세부 사항 설정',
  SELECT_TYPE: '유형 선택',
  COMPLETE_LOCALIZATION_PROCESS: '완벽한 현지화 프로세스',
  CREATE_NEW_VERSION: '새 버전 만들기',
  CREATE_NEW_DOCUMENT: '새 문서 작성',
  IS_PRIVATE: '비공개',
  SHARED_WITH_OTHER_TEAMS: '다른 팀과 공유',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    '필요한 순차적 승인 단계 수를 입력하십시오. 두 개의 승인이 동시에 있으면 한 단계로 계산됩니다. 나중에 승인 단계 수를 언제든지 변경할 수 있습니다.',
  DONE: '끝난',
  SUBMIT_FOR_APPROVAL: '승인을 위해 제출',
  YOU_HAVE_TO_CONFIRM_THE_: '업로드하기 전에 프로세스를 확인해야합니다.',
  SELECT_YOUR_DEC_TYPE: '문서 종류를 선택하십시오',
  TARGET_AUDIENCE: '대상 고객',
  PROCESS_DETAILS: '프로세스 세부 사항',
  START_DATE: '시작일',
  DOC_DETAILS: '문서 세부 사항',
  CONFIRMATION: '확인',
  APPROVAL_PROCESS: '승인 절차',
  SCHEDULED_EXP_DATE: '예정된 만기일',
  SCHEDULED_PUBLISHING_DATE: '출판 예정일',
  NO_PROCESS_SELECTED: '선택된 프로세스 없음',
  COUNTRIES: '국가',
  DOC_ID: '문서 ID',
  THERAPEUTIC_AREA: '치료 영역',
  FILTER: '필터',
  BACK: '뒤',
  ATTACH: '붙이다',
  COPY_ANSWER: '답변 복사',
  SIMILAR_INQUIRIES: '유사한 문의',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: '슬라이드 데크',
  MEDICAL_DOC: '의료 서류',
  SHOW: '보여 주다',
  LOAD_MORE: '더로드',
  NO_RESULT_FOUND: '검색 결과가 없습니다.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    '전체 문장을 검색하는 데 사용할 수 있습니다.-지정된 단어 내에서 검색 결과에서 제외하는 데 사용할 수 있습니다.',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: '의료 서류 또는 유사한 문의 사항 검색',
  SEARCH: '검색',
  Preview: '시사',
  PREVIEW_AND_SEND: '미리보기 및 보내기',
  PREVIEW_AS_PDF: 'pdf로 미리보기',
  PUBLISHED_VERSION: '게시 된 버전',
  NEW_VERSION: '새로운 버전',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    '문서 내용을 변경하여 다른 파일을 업로드하려는 경우 문서는 여전히 승인 상태입니다.',
  OUT_TO: '~로',
  NEXT: '다음',
  SELECTED_SLIDES: '선택된 슬라이드',
  GO_TO_SLIDE: '슬라이드로 이동',
  PREV: '이전',
  SELECT_SLIDE: '슬라이드 선택',
  ANSWER: '대답',
  BACK_TO_STANDARD_VIEW: '표준보기로 돌아갑니다.',
  UPLOAD_NEW_FILE: '새 파일 업로드',
  CREATE_FILE: '파일 작성',
  EDITING_WORD_DOC: 'Word 문서 편집',
  INSERT_FILE_NAME: '파일 이름 삽입',
  PROCEED_WITH_THE_LINK_: '파일의 링크로 진행',
  A_NEW_WINDOW_WILL_BE_: '빈 파일로 새 창이 열리고 내용을 작성하고 탭을 닫습니다.',
  INSERT_THE_NAME_FOR_: '작성하려는 첨부 파일의 이름을 삽입하고 파일 작성을 진행하십시오.',
  INSTRUCTIONS: '명령',
  CREATE_NEW_WORD_FILE_FOR: '에 대한 새 단어 파일 만들기',
  FROM: '에서',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    '제출 한 것과 아직 제출되지 않은 시스템에 마지막으로 삽입 한 질문을 여기서 찾을 수 있습니다. 삽입 한 문의를 재사용하여 더 유사한 문의를 작성하거나 삽입을 완료하지 않은 문의로 돌아갈 수 있습니다.',
  MY_DRAFT_INQ: '초안 문의',
  WARNING_CHANGES_NOT_SAVED: '경고 : 변경 사항이 저장되지 않았습니다',
  PAGE_THAT_WILL_BE_LOST: '계속하기로 결정하면 페이지가 손실됩니다.',
  YOU_HAVE_UNUSED: '저장하지 않은 변경 사항이 있습니다.',
  ARE_YOU_SURE_YOU_WANT_TO_: '저장하지 않고이 페이지를 떠나시겠습니까?',
  LEAVE_THIS_PAGE: '이 페이지를 남겨',
  STAY_ON: '에 머물',
  ASSIGNED_TO: '할당',
  THIS_INQ_HAS_BEEND_INSERTED_: '이 질문은 행사 기간 동안 삽입되었습니다.',
  STATUS_IN_APPROVAL: '상태 : 승인 중',
  STATUS_DRAFT: '상태 : 초안',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    '실제 문의를 작성하려면 제출 탭으로 이동하여 작성 단추를 클릭하십시오.',
  INQ_IMPORTED_FROM: '에서 수입 문의',
  SEND_NEW_ANSWER: '새로운 답변 보내기',
  RETURN_TO_INBOX: '받은 편지함으로 돌아 가기',
  SUBMITTED_FOR_APPROVAL: '승인을 위해 제출',
  ANSWER_SUBMITTED_ON: '에 제출 된 답변',
  BY: '으로',
  INQ_HAS_BEEN_CLOSED: '문의가 답변없이 마감되었습니다.',
  BCC: '숨은 참조',
  ANSWER_GIVEN_ON: '답변',
  ANSWER_SENT_ON: '답변을 보냈습니다.',
  INBOX: '받은 편지함',
  OPEN_ORIGINAL_INQ: '원본 문의 열기',
  CLOSE_ANSWER_NOT_NEEDED: '닫기 : Anser가 필요하지 않습니다',
  HISTORY: '역사',
  ADD_A_NOTE: '메모 추가',
  ADD_A_NOTE_INTO_: '조회 내역에 메모를 추가하십시오.',
  SHARE_OR_START_A_FOLLOW_UP: '후속 조치 공유 또는 시작',
  SEE_ALL_INQ_RELATED_TO: '관련 문의',
  SRD_NEEDED: '필요한 SRD',
  ANSWER_UNAVAILABLE: '사용할 수없는 답변',
  QUESTION: '질문',
  SUBMIT: '제출',
  ANSWER_UPPERCASE: '대답',
  DETAILS: '세부',
  SPLIT_INQ: '분할 문의',
  ADD_QUESTION: '질문 추가',
  ENTER_QUESTION: '질문을 입력',
  MARK_AS_ANSWERED: '답변으로 표시',
  TO_BE_ANSWERED: '답변 받기',
  FOLLOW_UP_RECEIVED: '후속 조치 접수',
  ERROR: '오류',
  SHOW_ORIGINAL_MESSAGE: '원본 메시지 표시',
  SEND: '보내다',
  DO_NO_SEND: '보내 지마',
  OK: '확인',
  MAX_LENGTH_FOR_ZIP_CODE: '우편 번호의 최대 길이는',
  CHARACTERS: '문자',
  ADDRESS_1_IS_: '주소 1은',
  LABEL_IS: '라벨은',
  NO_QUESTION_INSERTED: '질문이 삽입되지 않았습니다',
  WARNING: '경고',
  FOLLOW_UP_IS_NOT_AVAILABLE_: '이 상호 작용에 대한 후속 조치를 사용할 수 없습니다.',
  INSERT_INQ_TEXT: '문의 텍스트 삽입',
  SHOW_MORE: '자세히보기',
  OUT_OF: '에서',
  SHARE: '공유',
  HCP_PROFILE_OF: '의 HCP 프로필',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    '아래의 모든 필수 필드와 하나 이상의 이메일 주소를 입력한 다음 저장하여 GDPR 수신 알림을 미리 봅니다.',
  CONFIRMATION_SENT_BY: '보낸 알림',
  COUNTRY_IS: '국가는',
  COUNTRY: '국가',
  AT_LEAST_ONE_CONTACT_IS_: '적어도 하나의 연락처는',
  EMAIL_IS: '이메일은',
  EMAIL_LABEL_IS: '이메일 라벨은',
  PHONE_FAX_IS: '전화 / 팩스 라벨은',
  PHONE_FAX_NUMBER_IS: '전화 / 팩스 번호는',
  ADDRESS: '주소',
  DELETE_PERSONAL_DATA: '개인 정보 삭제',
  HCP_VERIFIED: 'HCP 검증',
  KEY_OPINION_LEADER: '핵심 견해',
  HOSPITAL_BASED: '병원 기반',
  HCP_RELATED_INFORMATION: 'HCP 관련 정보',
  ACCEPT: '동의하기',
  REJECT: '받지 않다',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: '이 제안을 수락 하시겠습니까?',
  SALES_REP: '영업 담당자',
  SUGGEST_A_TOPIC: '주제 제안',
  TEAM_SPECIFIC_FIELDS: '팀 특정 분야',
  ADR_RELATED: 'ADR 관련',
  NOT_ADR_RELATED: 'ADR 관련이 아님',
  PLEASE_REVIEW_THE_MESSAGE_AND_: '메시지를 검토하여 유해 약물 반응 관련 여부로 분류하십시오.',
  ADVERSE_DRUG_REACTION: '불리한 약물 반응',
  CHANGE_DETAILS: '세부 사항 변경',
  YES: '예',
  NO: '아니',
  DUE_DATE: '마감일',
  CRITICAL: '위독한',
  NORMAL: '표준',
  PRIORITY: '우선 순위',
  SELECT_DETAILS: '세부 사항 선택',
  SAVE_REVIEW: '리뷰 저장',
  SET_TO_ALL_INQ: '모든 문의로 설정',
  PRODUCT_QUALITY_COMPLAINT: '제품 품질 불만',
  PQC_PC_A_PRODUCT_:
    'PQC / PC QC (Product Quality Complaint) 또는 문제는 의약품의 결함 또는 오작동과 관련이 있거나 의약품의 품질, 성능, 안전성과 관련된 모든 문제로 정의됩니다. 냄새 및 / 또는 맛 변화, 포장 상태가 불량하거나 결함이 있거나 오염이 의심되는 경우 등',
  OFF_LABEL_USE_OF_PHARMA___:
    '승인되지 않은 적응증 또는 승인되지 않은 연령대, 복용량 또는 투여 경로에 대한 의약품의 라벨을 벗어난 사용.',
  A_RESPONSE_TO_A_MEDICINAL_:
    '의약 제품과 유해 사례 사이의 유해하고 의도하지 않은 인과 관계에있는 의약 제품에 대한 반응은 적어도 합리적인 가능성이다. 또한 특수 상황 사례 (예 : 임신 사례, 비 라벨 사용, 과다 복용)는 ADR로 표시해야합니다.',
  NORMAL_IS_7_WORKING_DAYS:
    '보통은 근무일 기준 7 일, 높음은 48 시간, 위험 (환자 관련)은 24 시간입니다.',
  CATEGORY_AND_TOPIC_ARE: '카테고리와 주제는',
  PRODUCT_IS: '제품은',
  DO_YOU_WANT_TO_ACCEPT_: '이 제안을 수락 하시겠습니까?',
  MERGE: '병합',
  Duplicate: '복제',
  Other: '다른',
  Spam: '스팸',
  CLOSE_INTERACTION: '긴밀한 상호 작용',
  RE_OPEN_THE_TARGET_: '병합 후 대상 문의를 다시여십시오',
  MARK_AS_INTERNAL_: '기존 문의에 대한 내부 메시지로 표시하십시오.',
  MARK_AS_AN_ERROR_: '기존 문의에 대한 오류 메시지로 표시하십시오.',
  THIS_MESSAGE_WILL_BE_EXISTING_: '이 메시지는 기존 문의에 대한 후속 응답으로 표시됩니다.',
  THANK_YOU: '감사합니다',
  AN_INTERNAL_MESSAGE: '내부 메시지',
  AN_ERROR_MESSAGE: '오류 메시지',
  A_FOLLOW_UP_RESPONSE: '후속 조치',
  THIS_INQ_IS: '이 문의는',
  SPLIT_INTERACTION: '스플릿 인터랙션',
  SAVE_AND_CLOSE: '저장하고 닫습니다',
  SAVE_INQ: '문의 저장',
  REVIEW_THE_TEXT_AND: '텍스트와 분류를 검토하고 확인',
  CHOOSE_THE_RELEVANT_PRODUCT: '선택한 텍스트와 관련된 제품, 카테고리 및 주제를 선택하십시오.',
  SELECT_THE_TEXT_THAT_:
    '한 제품 / 주제에 대한 질문을 나타내는 텍스트를 선택하십시오 (인사말, 서명, 면책 사항 또는 기타 관련없는 정보는 포함하지 않음)',
  REPEAT_THE_PREVIOUS_: '문의를 더 추가하려면 이전 단계를 반복하십시오.',
  READ_MORE: '더 읽어보기',
  READ_LESS: '덜 읽기',
  DO_NOT_INCLUDE_: '인사, 서명, 면책 사항 또는 기타 관련이없는 정보는 포함하지 않습니다',
  SELECT_THE_TEXT_THAT_IDENT: '하나의 제품 / 주제에 대한 질문을 식별하는 텍스트를 선택하십시오.',
  IF_YOU_HAVE_MULTIPLE_: '여러 제품 또는 주제가있는 경우 문의를 분할해야합니다.',
  THE_FOLLOWING_PROCESS_:
    '다음 프로세스는 각 제품 / 주제에 대한 관련 텍스트를 식별하는 데 도움이됩니다.',
  EXPORT_TO_PDF: 'PDF로 내보내기',
  Added: '추가',
  Deleted: '삭제',
  From: '에서',
  To: '에',
  BACK_TO_INBOX: '받은 편지함으로 돌아 가기',
  PERMISSION_DENIED: '권한 거부',
  THIS_INQ_IS_LOCKED_BY: '이 문의는',
  FORCE_UNLOCK: '잠금 해제',
  INQUIRIES: '문의',
  Download: '다운로드',
  Event: '행사',
  INSERTED_BY: '에 의해 삽입',
  LAST_EDITED_ON: '마지막으로 편집 한 날짜',
  LAST_EDITED_BY: '최종 편집자',
  CREATED_ON: '에 만든',
  TOPIC: '이야기',
  TABLE_OPTIONS: '테이블 옵션',
  DEPARTMENT: '학과',
  User: '사용자',
  ADD_THERAPEUTIC_AREA: '치료 영역 추가',
  CREATE_THERAPEUTIC_AREA: '새로운 치료 영역 만들기',
  ADD_NEW: '새로운 걸 더하다',
  SELECT_TEAM_TO_PROCEED: '진행할 팀을 선택하십시오',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: '매상',
  CALL_CENTER: '콜센터',
  ALL: '모두',
  Warning: '경고',
  clear: '명확한',
  THESE_CHANGES_WILL_BE_: '이 변경 사항은 선택된 사용자에게 적용됩니다',
  INSERT_A_FOLLOW_UP_RESP: '후속 응답 삽입',
  INSERT_A_NOTE: '메모 삽입',
  MERGED_FROM: '에서 합병',
  CHANGES: '변경',
  SHOW_LESS: '덜 보여',
  COMPLETE: '완전한',
  MISSING: '잃어버린',
  CLOSE_AS_FORWARDED: '전달 된대로 닫기',
  CLOSE_EXT_TEAM: '외부 팀에게 전달 된대로 문의를 닫습니다.',
  THE_INQ_BELONG_TO_A_:
    '이 문의는 외부 팀의 책임이있는 국가에 속합니다. 그러면 문의가이 팀에 자동으로 전달됩니다.',
  RESOLVE: '결의',
  TERRITORIES: '영토',
  EXTERNAL_COMMUNICATION: '외부 커뮤니케이션',
  FOR_INQ_COMING_DIRECTLY_:
    '이메일을 통해 HCP에서 직접 문의하는 경우, 문의가 수신되었음을 알리는 알림을 자동으로 보냅니다.',
  INTERNAL_COMMUNICATION: '내부 커뮤니케이션',
  SALESREP_RECEIVES_: 'Salesrep은 문의에 대한 답변 만받습니다.',
  SALESREP_RECEIVES_FULL_ANSWER_: 'Salesrep가 On Label 문의에 대한 전체 답변을받습니다.',
  SALESREP_RECEIVES_ONLY_NOTIF_: 'Salesrep은 문의에 대한 답변 만받습니다.',
  Name: '이름',
  Module: '구성 단위',
  Function: '함수',
  LAST_RUN: '마지막 실행',
  End: '종료',
  Every: '마다',
  Period: '기간',
  Active: '유효한',
  Resolved: '해결',
  ERROR_PREVIEW: '오류 미리보기',
  FAILURE_COUNT: '실패 횟수',
  COMPLETED_ON: '완료',
  STARTED_ON: '시작',
  TASK_NAME: '작업 이름',
  PRODUCT_SPECIFIC: '제품 사양',
  THERAPEUTIC_AREAS: '치료 분야',
  PRODUCT_GROUPS: '제품 그룹',
  LICENSING_PARTNER: '라이센스 파트너',
  PRODUCT_CODE: '제품 코드',
  EXTERNAL_ID: '외부 ID',
  OBJECT_TYPE: '객체 유형',
  MIGRATION_NAME: '마이그레이션 이름',
  MIGRATION_ERRORS: '마이그레이션 오류',
  ADD_SALES_REP: '영업 담당자 추가',
  ASSIGNED_TO_MSL: 'MSL에 할당',
  NOT_ASSIGNED: '미 할당',
  ASSIGNED_TO_OTHER_MSL: '다른 MSL에 할당',
  ASSIGNED_TO_THIS_MSL: '이 MSL에 할당',
  SHOW_ONLY: '만 표시',
  SALES_REP_LIST: '영업 담당자 목록',
  ACTIVE_TASK: '작업 활성화',
  CHOOSE_USER_AND_SET_THE_SHIFT_: '사용자를 선택하고 이벤트의 이동을 설정하십시오',
  MANAGE_SHIFTS: '쉬프먼트 관리',
  CREATE_NEW_USER: '새로운 사용자 생성',
  THIS_TEMPLATE_WILL_BE_AUTO_: '이 템플릿은 팀 구성원에게 자동으로 추가됩니다.',
  TEMPLATE_FOR: '에 대한 템플릿',
  Day: '일',
  Week: '주',
  Month: '달',
  Today: '오늘',
  Archive: '보관',
  PARTICIPANTS: '참가자',
  EVENT_MANAGEMENT: '이벤트 관리',
  ADD_TASK: '작업 추가',
  EDIT_TASK: '작업 편집',
  IS_ACTIVE: '활성',
  Months: '개월',
  Days: '일',
  Hours: '시간',
  Minutes: '의사록',
  HCP_ADMINISTRATION: 'HCP 관리',
  AUTH_REQ_ADMINISTRATION: '인증 요청 관리',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_: '선택한 치료 영역에서 제품이 강조 표시됩니다.',
  PRODUCT_GROUPS_DEFINE_: '제품 그룹은이 제품 문의에 대한 답변을 담당하는 팀을 정의합니다.',
  USER_ADMINISTRATION: '사용자 관리',
  IS_PRODUCT_SPECIFIC: '제품 별',
  USER_TEMPLATES_ADMIN: '사용자 템플릿 관리',
  SET_DEFAULT_VALUES: '기본 값 설정',
  SET_DEFAULT_VALUES_FOR_: '이벤트 기본값 설정',
  INQUIRY_DEFAULT_VAL: '문의 기본값',
  CREATE_NEW_EVENT: '새로운 이벤트 만들기',
  EDIT_EVENT: '이벤트 수정',
  SET_DETAILS_TO_CREATE_NEW_EVENT: '새 일정을 만들 세부 정보 설정',
  ADD_DOC_LINKED_TO_: '이벤트에 연결된 문서 추가',
  INC_CHANNEL: 'Inc. 채널',
  CHANGE_DETAILS_OF_THE_EVENT: '이벤트 세부 사항 변경',
  DESCRIPTION: '기술',
  CREATE_APPROVAL_PROCESS: '승인 프로세스 선택',
  CREATE_NEW: '새로 만들기',
  CUSTOMIZE_PROCESS: '프로세스 사용자 정의',
  SELECTED_PROCESS: '선택된 프로세스',
  CLONE: '클론',
  PREFERENCES_TITLE: '환경 설정',
  NOTIFICATIONS_TITLE: '알림',
  TEMPLATES: '템플릿',
  LETTER_TEMPLATES: '편지 템플릿',
  USER_TEMPLATES: '사용자 템플릿',
  TEAM_MEMBERS: '팀 멤버',
  NEW_PASSWORD: '새 비밀번호',
  REPEAT_NEW_PASSWORD: '새로운 비밀번호를 다시 입력',
  AT_LEAST_1_LOWERCASE_CHARACTER: '소문자 1 자 이상',
  AT_LEAST_1_UPPERCASE_CHARACTER: '대문자 1 자 이상',
  AT_LEAST_1_DIGIT: '1 자리 이상',
  AT_LEAST_1_SPECIAL_CHARACTER: '최소 1 개의 특수 문자',
  MINIMUM_8_CHARACTERS: '최소 8 자',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: '이메일이나 이름을 포함해서는 안됩니다',
  SHOULD_MATCH_PASSWORD: '비밀번호와 일치해야합니다',
  USER_MANUAL: '사용자 매뉴얼',
  SUPPORT: '지원하다',
  BACK_TO_LOGIN: '로그인으로 돌아 가기',
  PASSWORD_CONFIRMATION: '비밀번호 확인',
  HAVE_AN_ACCOUNT: '이미 계정이 있습니까?',
  Submit: '제출',
  Gender: '성별',
  PROFESSION: '직업',
  MEDICAL_INQ_ROLES: '의료 문의 처리 역할',
  MEDICAL_INFO_MANAGER: '의료 정보 관리자',
  MEDICAL_SCIENCE_LIAISON: '의료 과학 연락',
  NOT_PROCESSING_INQUIRIES: '미처리 문의',
  ACTIVE_HEALTH_CARE_SPEC: '적극적인 건강 관리 전문가',
  NOT_ACTIVE_HEALTH_CARE_SPEC: '비 활동 건강 관리 전문가',
  PASS_IS_REQUIRED: '비밀번호가 필요합니다!',
  EMAIL_IS_REQUIRED: '이메일이 필요합니다!',
  VALID_EMAIL: '유효한 이메일 주소를 입력하세요!',
  FIELD_IS_REQUIRED: '이 필드는 필수입니다!',
  ONLY_LETTERS: '문자 만 입력하십시오!',
  ARE_YOU_daiichi_EMPLOYEE: 'Daiichi Sankyo Employee입니까?',
  here: '여기',
  CREATE_AN_ACCOUNT: '계정 만들기',
  FORGOT: '잊었습니까?',
  Medical: '의료',
  Medical_not_resp: '의료-MIR에 응답 할 책임이 없음',
  Commercial: '상업용',
  Market_access: '시장 접근',
  Pharmacovigilance: '약물 감시',
  PR: 'PR',
  Please_specify: '명시 해주세요',
  Medical_information_manager: '의료 정보 관리자',
  Medical_advisor: '의료 고문',
  Are_Medical_inquiries: '의학적 질문에 답변 할 책임이 있습니까?',
  Are_Health_care_professional: '당신은 연습 건강 관리 전문가입니까?',
  Health_care_Industry: '건강 관리 산업',
  Health_Authority: '보건 당국',
  Journalist: '기자',
  Lawyer: '변호사',
  Patient: '환자',
  Payor: '지불 자',
  Scientist: '과학자',
  Angiologist: '혈관 전문의',
  Cardiologist: '심장병 전문의',
  Cardiovascular_Surgeon: '심혈관 외과 의사',
  Dentist: '치과 의사',
  Gastroenterologist: '위장병 전문의',
  General_Practitioner: '일반 개업의',
  Geriatrician: '노인병 학자',
  Gynecologist: '부인과 의사',
  Haematologist: '혈액 학자',
  Internist: '내과 의사',
  Medical_Student: '의대생',
  Nephrologist: '신장 학자',
  Neurologist: '신경과 전문의',
  Neurosurgeon: '신경 외과 의사',
  Nurse: '간호사',
  Oncologist: '종양 학자',
  Oncology_Nurse: '종양학 간호사',
  Other_HCP: '다른 HCP',
  Other_Surgeon: '다른 외과 의사',
  Pediatrician: '소아과 의사',
  Pharmacist: '제약사',
  Pharmacologist: '약리학 자',
  Pharmacy_Technician: '약국 기술자',
  Pneumologist: '폐과 전문의',
  Radiologist: '방사선 학자',
  Rheumatologist: '류마티스 전문의',
  USER_PASS_INCORRECT: '사용자 이름 또는 암호가 올바르지 않습니다',
  SUBMITTED_REQUEST: '귀하의 요청이 성공적으로 제출되었습니다.',
  Reset: '초기화',
  Reset_pass: '암호를 재설정',
  New_pass: '새 비밀번호',
  RESET_PASS_EMAIL: '비밀번호 재설정 링크가 이메일로 전송되었습니다',
  NEW_PASS_SUCCESS: '비밀번호가 성공적으로 재설정되었습니다',
  SOMETHING_WENT_WRONG: '문제가 발생했습니다. 지원팀에 문의하십시오.',
  EVENTS: '행사',
  Login_here: '여기에 로그인',
  REGISTRATION: '기재',
  Create_an_account: '계정 만들기',
  NO_RECORDS: '기록 없음',
  INTERACTION: '상호 작용',
  SPECIALIZATION: '전문화',
  SPECIALIZATION_TYPE: '전문화 유형',
  SAVED: '저장되었습니다',
  SAVING: '절약',
  TYPING: '타자',
  CREATE_NEW_INTERACTION: '새로운 상호 작용 만들기',
  FILTER_BY: '필터링',
  SEND_EMAIL: '이메일을 보내',
  SAVE_WITHOUT_EMAIL: '이메일없이 저장',
  SUBMIT_TO_INBOX: '받은 편지함에 제출',
  Create_my_acc: '내 계정 만들기',
  NEW_QUESTION: '새로운 질문',
  LABEL: '상표',
  SELECT_A_LABEL: '라벨을 선택하십시오',
  CITY: '시티',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: '새로운 카테고리 제안 또는 기존 카테고리 선택',
  SUGGEST_CATEGORY_OR_TOPIC: '새로운 카테고리 또는 주제 제안',
  URGENT: '긴급한',
  EXISTING_CATEGORY: '기존 카테고리',
  NEW_CATEGORY_NAME: '새로운 카테고리 이름',
  NEW_TOPIC_NAME: '새로운 주제 이름',
  CATEGORY: '범주',
  MSLA: 'MSLA',
  OUT_CHANNEL: '발신 채널',
  LOGIN_TO_CONTINUE: 'Madjenta에 계속 로그인',
  Email_address: '이메일 주소',
  FORGOT_PASS: '비밀번호를 잊으 셨나요?',
  Remember_me: '날 기억해',
  YOU_WILL_BE_NOTIFIED: '등록을위한 추가 지침이 담긴 확인 이메일이 발송되었습니다.',
  SUCCESS_EMAIL_CONFIRMATION: '귀하의 이메일이 성공적으로 확인되었습니다.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: '요청이 검토되면 알림을받습니다.',
  FAILED_EMAIL_CONFIRMATION: '귀하의 이메일을 확인할 수 없습니다.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    '재 시도하십시오. 문제가 지속되면 문제에 대한 설명을 작성하여 지원하십시오.',
  RESET_FILTERS: '필터 재설정',
  NEW_PASS_RESET_SUCCESS: '비밀번호 재설정이 완료되었습니다!',
  Cardiovascular: '심혈관',
  Oncology: '종양학',
  Due_On: '마감일',
  Follow_Up_Sent_On: '보낸 후속 조치',
  Follow_Up_Received_On: '후속 조치 수신',
  Closed_On: '휴무일',
  Reopened_On: '재개',
  Inquiry_ID: '문의 ID',
  Search: '검색',
  Assigned_to_Now: '(지금)에 할당',
  Edited_By: '편집자',
  Assigned_to_Any_Time: '(언제든지)에 할당',
  Closed: '닫은',
  Foreign: '외국',
  Complex_search_Sentence_: '복잡한 검색 : 문장 : "검색 문장", 아님 : -word',
  Search_inquiries: '검색 문의',
  SEARCH_REPOSITORY: '검색 저장소',
  MIR_INBOX: '미르 인 박스',
  Filters: '필터',
  Active_Filters: '활성 필터',
  CONTACT_SUPPORT: '연락처 지원',
  MedicalInformationRequests: '문의 (pdf)',
  UserProfileNavigation: '사용자 프로필 (pdf)',
  UserManualChanges: '사용자 매뉴얼 변경 (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: '문의 할당',
  MedIS_Documentation_Approval_Process: '문서 승인 프로세스',
  MedIS_Documentation_Overview: '설명서 개요',
  MedIS_Inquiry_Documentation: '문의 문서',
  MedIS_MIR_Inbox_Filters: 'MIR받은 편지함 필터',
  MedIS_Merge_MIR: 'MIR 병합',
  MedIS_New_Documentation: '새로운 문서',
  MedIS_New_Inquiry: '새로운 문의',
  MedIS_Out_Of_Office: '사무실 중',
  MedIS_Prepare_Custom_Answer: '맞춤 답변 준비',
  MedIS_Split_MIR_Part_I: '미르 1 부 분할',
  MedIS_Split_MIR_Part_II: '스플릿 미르 2 부',
  I_AGREE_TO: '나는 동의한다',
  TermsAndConditionsLink: '이용 약관',
  Title: '표제',
  INTERACTION_TITLE: '상호 작용 제목',
  Move: '움직임',
  Left: '왼쪽',
  Right: '권리',
  Requestor: '요청자',
  Assigned_To: '할당',
  Status: '상태',
  Note: '노트',
  Confirm: '확인',
  rejectConfirm: '요청을 거부 하시겠습니까?',
  approveConfirm: '요청을 승인 하시겠습니까?',
  newUser: '새로운 사용자',
  editUser: '사용자 편집',
  deleteUser: '사용자 삭제',
  newProduct: '새로운 제품',
  editProduct: '제품 편집',
  deleteProduct: '제품 삭제',
  newCategory: '새 분류',
  editCategory: '카테고리 편집',
  deleteCategory: '카테고리 삭제',
  newTopic: '새로운 주제',
  editTopic: '주제 편집',
  deleteTopic: '주제 삭제',
  userRequest: '사용자 요청',
  productRequest: '제품 요청',
  categoryRequest: '카테고리 요청',
  categoryTopicRequest: '카테고리 / 주제 요청',
  topicRequest: '주제 요청',
  Suggestion: '암시',
  tradeName: '상표명',
  activeSubstance: '활성 물질',
  productName: '상품명',
  productSpecific: '특정 제품입니까?',
  EMAIL_IN_USE: '이 이메일에 기존 계정이 있습니다',
  fromDate: '날짜부터',
  toDate: '현재까지',
  applyFilter: '필터 적용',
  requestReceivedFrom: '요청이 접수되었습니다',
  on: '의 위에',
  at: '...에서',
  to: '에',
  add: '더하다',
  from: '...에서',
  approve: '승인',
  reject: '받지 않다',
  withTheFollowingRole: '다음과 같은 역할',
  forTheFollowingTeam: '다음 팀을 위해',
  request: '의뢰',
  submittedBy: '에 의해 제출 된',
  INVALID_OR_EXPIRED_RESET_REQUEST: '비밀번호 재설정 요청이 유효하지 않거나 만료되었습니다',
  PASS_IS_RECENT: '최근에 사용한 비밀번호',
  Assigned_to_me: '나에게 할당',
  My_drafts: '내 초안',
  REPORTS: '보고서',
  Pending_approval: '승인 대기 중',
  ADMINISTRATION: '관리',
  Show_all: '모두 표시',
  In_progress: '진행중',
  Table_Settings_Columns: '테이블 설정-열',
  Change_column_visibility: '열 가시성 변경',
  Reassign_to: '재 할당',
  set_priority: '우선 순위 설정',
  set_status: '상태 설정',
  Spam_list: '스팸 목록',
  Institution: '제도',
  DUE_ON: '마감일',
  Profile_settings: '프로필 설정',
  Preferences: '환경 설정',
  Role_settings: '역할 설정',
  Notification_settings: '알림 설정',
  User_profile: '유저 프로필',
  Select_person: '사람을 선택하십시오',
  FROM_DATE: '날짜부터',
  TO_DATE: '현재까지',
  JOB_DESC: '업무 설명서',
  INQ_Management: '문의 관리',
  DOCS_Management: '문서 관리',
  USER_Management: '사용자 관리',
  Define_Frequency: '빈도 정의',
  All_teams_all_inq: '모든 팀에 대한 모든 문의',
  My_teams_all_inq: '팀에 대한 모든 문의',
  Search_people_here: '여기에서 사람 검색',
  Search_team_here: '여기에서 팀 검색',
  people: '사람들',
  teams: '팀',
  empty: '(빈)',
  can_not_be_assigned: '이 사용자는 팀이 없으며 할당 할 수 없습니다',
  select_a_team: '팀을 선택하십시오',
  confirm_team_change: '팀 변경 확인',
  change_the_team_: '팀을 다음으로 변경 하시겠습니까?',
  can_not_see_it_anymore: '당신은 현재에서 그것을 볼 수 없습니다',
  filtered_view: '필터링 된보기.',
  PHONE: '전화',
  FAX: '팩스',
  LETTER: '편지',
  F2F_SITE_VISIT: 'F2F (사이트 방문)',
  F2F_CONGRESS: 'F2F (의회)',
  F2F_ADBOARD: 'F2F (애드 보드)',
  F2F_OTHER: 'F2F (기타)',
  WEBINAR_SITE_VISIT: '가상 (사이트 방문)',
  WEBINAR_CONGRESS: '가상 (의회)',
  WEBINAR_ADBOARD: '가상 (애드 보드)',
  WEBINAR_OTHER: '가상 (기타)',
  CHAT: '잡담',
  COURIER: '택배원',
  REP_DELIVERY: '담당자 배달',
  SYSTEM: '체계',
  WEB_FORM: '웹 양식',
  Signature: '서명',
  Disclaimer: '부인 성명',
  STATE: '상태',
  ADDRESS_1: '주소 1',
  ADDRESS_2: '주소 2',
  ZIP_CODE: '우편 번호',
  HIGH: '높은',
  ADVANCED: '많은',
  CLICK_BELOW_TO_POPULATE_ANSWER: '아래의 텍스트와 필드를 클릭하여 답변을 채우고 편집하십시오.',
  DOCUMENT_VIEW: '문서보기',
  FORM_VIEW: '양식보기',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: '귀하는 답변을 보낼 권리가 없습니다.',
  ANSWER_TEXT: '답변 텍스트',
  CONTACT_INFORMATION: '연락 정보',
  COMPANY: '회사',
  SRD_NEEDED_TOOLTIP: '이 문의에 대한 답변에는 새로운 SRD 문서가 필요합니다.',
  ENABLE_ALL: '모두 활성화',
  DISABLE_ALL: '모두 비활성화',
  BEFORE: '전에',
  AFTER: '후',
  EDIT: '편집하다',
  MY_TEMPLATES: '내 템플릿',
  TEAM_TEMPLATES: '팀 템플릿',
  TEAM_TEMPLATES_HINT: '전체 팀을 위해 의료 정보 관리자가 만든 템플릿',
  SOURCE: '출처',
  TEMPLATE_NO_MATCH_INTERACTION:
    '이 템플릿은 상호 작용 세부 정보와 일치하지 않으며 적용 할 수 없습니다.',
  EMAIL_SUBJECT: '메일 제목',
  INQUIRY_SUBMIT_HINT: '문의에 대한 답변을 위해 모든 필수 필드를 작성해주십시오.',
  SCHEDULED_PUBLISH_TO_EXP_DATE: '만료 날짜까지 게시 예정',
  COPY_ATTACHMENT_TO_ANSWER: '답변에 첨부 파일 복사',
  COPY_TEXT_TO_ANSWER: '답변에 텍스트 복사',
  SELECT_INQUIRY: '문의 선택',
  EDIT_INQ: '질문 수정',
  SPLIT_ANOTHER: '다른 분할',
  DELETE_INQ: '문의 삭제',
  SELECT_TEXT_FOR_SPLIT: '동일한 상호 작용에 새 질문 추가',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: '별도의 상호 작용 / 사례 생성',
  SPLIT_ORIGINAL_TEXT: '원본 텍스트 (분할 할 텍스트 선택)',
  SPLIT_TEXT_TO_REMAIN_: '초기 문의에 남을 텍스트',
  SPLIT_TEXT_TO_SPLIT_: '분할 할 텍스트',
  SPLIT_SELECTED_SECTIONS: '분할 할 선택된 섹션',
  SPLIT_NO_MODIFICATIONS_HINT: '원본 텍스트에서 섹션을 선택하면 텍스트가 자동으로 채워집니다.',
  SPLIT_MODIFICATIONS_HINT:
    '텍스트는 수동으로 수정되었으며 원본 텍스트의 추가 선택 사항은 적용되지 않습니다.\n 수정 사항을 취소하려면 여기를 클릭하십시오.',
  TEMPLATE_NO_MODIFICATIONS_HINT: '문의 내용이 변경되면 텍스트가 자동으로 업데이트됩니다.',
  SELECT_DIFFERENT_TEMPLATE: '다른 템플릿 선택',
  APPROVE: '승인',
  LAST_EDIT_ON: '마지막 편집 날짜',
  LAST_EDIT_BY: '마지막 편집자',
  INTERNAL_COMM: '내부 커뮤니케이션',
  INACTIVE: '비활성',
  DEACTIVATE: '비활성화',
  ARCHIVED: '보관 됨',
  REPUBLISH: '다시 게시',
  INTERACTION_TEMPLATE_HINT:
    '서명, 인사말 및 면책 조항은 이메일, 편지 및 팩스와 같은 서면 커뮤니케이션의 일부입니다.\n 이를 사용하려면 발신 채널이 그 중 하나로 설정되어 있는지 확인하십시오.',
  CONFIRM_AUTH_REQUEST: '인증 요청 확인',
  VIEW_AUTH_REQUEST: '인증 요청보기',
  QUESTION_TITLE: '질문 제목 : ',
  NO_TITLE_INQUIRY: '-제목-문의 없음-',
  EXISTING_CATEGORIES: '기존 카테고리',
  EXISTING_TOPICS: '기존 주제',
  DO_NOT_ASSIGN_PRODUCT: '제품을 할당하지 마십시오',
  QUESTION_TEXT: '질문 텍스트',
  DATE: '데이트',
  REJECTED: '거부 됨',
  FILTER_AUTH_REQUESTS_BY: '인증 요청 필터링 기준',
  ALL_REQUESTS_ALL_TEAMS: '모든 팀에 대한 모든 요청',
  ALL_TEAMS: '모든 팀',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST: '카테고리 / 주제 요청을 편집 할 권한이 없습니다.',
  SEND_FOLLOW_UP: '후속 조치 보내기',
  RECEIPT_NOTIFICATION_CUSTOMER: '고객에게 영수증 알림',
  GDPR_DISCLAIMER: 'GDPR 면책 조항',
  ACTIONS: '행위',
  SUBMISSION_CONDITIONS: '제출 조건',
  TYPE_OF_NOTIFICATION: '알림 유형',
  DIRECT_SUBMISSION: '직접 제출',
  DIRECT_SUBMISSION_TOOLTIP:
    '이메일을 통해 고객이 직접 제출한 문의 사항에 대해 알림을 보내 고객에게 개인 데이터 처리에 대해 알려줍니다.',
  COMPANY_EMPLOYEE_SUBMISSION: '회사 직원을 통해 제출',
  ENABLE_NOTIFICATION: '알림 활성화',
  NOTIFICATION_SENDER: '발신자에게 알림',
  SALES_REP_DELIVERED_RESPONSE: '전달된 응답에 대한 영업 담당자',
  NOTIFICATION_DELIVERED_RESPONSE: '전달된 응답에 대한 알림',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    '회사 담당자(영업 담당자, MSL 등)를 통해 제출된 문의의 경우 고객이 문의에 대한 답변을 받았을 때 발신인에게 알립니다.',
  FULL_ANSWER: '전체 답변',
  NOTIFICATION: '공고',
  NAME: '이름',
  COLUMN_INIT_QUESTION_TEXT: '원래 질문 텍스트',
  NOTIFICATION_ENABLED: '알림 활성화됨',
  DEFAULT_TEMPLATE: '기본 템플릿',
  SET_AS_DEFAULT: '기본값으로 설정',
  DEFAULT_TEMPLATE_TOOLTIP: '기본 템플릿이 하나 이상 있어야 하므로 토글이 비활성화됩니다!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: '기본 템플릿은 삭제할 수 없습니다!',
  BATCH_UPDATE_ONLY: '최상위 체크박스를 통해서만 일괄 업데이트가 가능합니다.',
  ACCESS_DENIED: '접근 불가!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: '이 페이지에 액세스할 수 있는 권한이 없습니다.',
  USER_ROLES: '사용자 역할',
  MANAGE_USER_ROLES: '사용자 역할 관리',
  ARCHIVE_ROLE: '아카이브 역할',
  ROLE_ARCHIVE_CONFIRMATION: '이 역할을 보관하시겠습니까?',
  ROLES_FOR: '역할',
  AUDIT_TRAIL: '추적 감사',
  ACTION_BY_USER: '사용자의 작업',
  ACTION: '액션',
  ORIGINAL_VALUE: '원래 값',
  CHANGE: '변경',
  IP: 'IP',
  GEOLOCATION: '지리적 위치',
  NEW_VALUE: '새 값',
  FILTER_BY_ACTION: '동작으로 필터링',
  SELECT_DATE: '날짜 선택',
  TEAM_SAVE: '구하다',
  TEAM_DELETE: '삭제',
  TEAM_CREATE: '만들다',
  TEAM_CLONE: '복사',
  TEAM_NAME: '이름',
  TEAM_CODE: '암호',
  TEAM_ACTION_DELETE_CONFIRM: '정말 삭제하고 싶습니까?',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: '조심해요!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: '삭제되면 복원 할 수 없습니다!',
  TEAM_FULL_ANSWER_ON_LABEL: '레이블에 대한 전체 답변',
  TEAM_RESTRICTED_TEAM: '제한된',
  TEAM_SEGREGATED_DATA: '분리 된 데이터',
  TEAM_PRIVATE_TEAM: '개인 팀',
  TEAM_AUTO_ASSIGN: '자동 할당',
  TEAM_MAILBOX_IN: '인바운드 우편함',
  TEAM_MAILBOX_OUT: '아웃 바운드 우편함',
  TEAM_DEFAULT_LANGUAGE: '기본 설정 언어',
  TEAM_COUNTRIES: '지정된 국가',
  TEAM_PRODUCT_GROUPS: '제품 그룹을 할당합니다',
  TEAM_PARENT: '학부모 팀',
  TEAM_OVERRIDE_CLUSTER_ROOT: '클러스터 루트 팀을 무시합니다',
  TEAM_AFFILIATIONS: '제휴 팀',
  TEAM_TIMEZONE: '시간대',
  UPDATE: '업데이트',
  AUTO_ASSIGN_BY: '자동 할당',
  AUTO_ASSIGNMENTS: '자동 할당',
  NO_ONE: '아무도',
  NOT_APPLICABLE: '적용 할 수 없습니다',
  OVERRIDE_NOTIFICATIONS: '알림을 무시합니다',
  OVERRIDE_NOTIFICATIONS_HINT:
    'Oredifications가있는 사용자 템플릿이 사용자에게 설정되면 기존 알림 설정을 비활성화하고 사용자 템플릿에 나열된 알림 설정 만 활성화합니다.',
  CREATED_MANUALLY: '수동으로 생성되었습니다',
  DOCUMENT_TITLE_FOR_WORD:
    'Word Documents의 제목은 일반적으로 문서 자체의 제목 형식 섹션에서 가져옵니다. 문서에 제목이 포함되어 있지 않으면 여기에 지정할 수 있습니다. 여기에 제목을 지정하지 않고 Word 문서에 제목 형식 섹션이 포함되어 있지 않으면 문서 ID가 제목에 사용됩니다.',
  ORDER_BY: '주문',
  FORMAT: '체재',
  SELECT_QUESTION: '질문을 선택하십시오',
  SELECT_QUESTION_FOR_NOTE: '메모를 추가하려는 질문을 선택하십시오',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: '전체 문의에 대한 참고 사항을 추가하십시오',
  REQUESTED_DATE: '요청 날짜',
  UNASSIGNED: '할당되지 않았습니다',
  BLANK: '공백',
  ANSWER_SAVED_WITHOUT_EMAIL: '이메일없이 저장되었습니다',
  INQ_RELATED_TO: '관련 문의',
  QUESTION_TEXT_BEFORE_SPLIT: '분할 전에 질문 텍스트',
  SPLIT_MERGE: '분할 및 병합',
  CLARIFICATION_QUESTION: '설명 질문',
  ACTIVE_ON: '활성',
  DISMISS: '해고하다',
  GO_TO_ENQUIRY: '문의하십시오',
  NO_TEAMS: '팀없이',
  NO_TEAMS_TIP:
    '어떤 팀에도 할당되지 않은 사용자가 포함되어 있습니다. 그 중 일부가 팀 출신인지 확인하고 적절한 역할을 부여 할 수 있습니다.',
  TASKS: '작업',
  Therapeutic_Areas: '치료 영역',
  Product_Groups: '제품 그룹',
  Licensing_Partner: '라이센스 파트너',
  Product_Code: '제품 코드',
  SELECT_EXISTING_ONE: '기존을 선택하십시오',
  OR: '또는',
  MANAGE_FORM: '양식 관리',
  EVENT_FORM_ADDRESS: '이벤트 양식 주소',
  EVENT_FORM_PREVIEW_WARING:
    '의회 양식을 개설하거나 주소를 복사하기 전에 변경 사항을 저장했는지 확인하십시오.',
  SEARCH_SAVED_SEARCHES: '검색 저장 검색',
  GO: '가다',
  ASSIGN: '양수인',
  CERTIFICATION: '인증',
  SORT: '종류',
  ADD_MAILBOX: '사서함을 추가하십시오',
  UNSET_INCOMING_MAILBOX: '수신 사서함이 없습니다',
  SET_INCOMING_MAILBOX: '들어오는 사서함을 설정하십시오',
  UNSET_OUTGOING_MAILBOX: '나가는 사서함이 없습니다',
  SET_OUTGOING_MAILBOX: '나가는 사서함을 설정합니다',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: '현재 사서함이있는 모든 팀',
  ALL_TEAMS_WITH_MAILBOX: '사서함이있는 모든 팀',
  MAILBOX: '사서함',
  EMAIL_POLLING: '이메일 폴링',
  ADVANCED_SEARCH: '고급 검색',
  SELECT_FIELDS: '필드를 선택하십시오',
  SEARCH_TERM: '검색어',
  COLUMN_INTERACTION_ID: '상호 작용 ID',
  COLUMN_QUESTION_ID: '질문 ID',
  COLUMN_CREATED_ON: '에 만든',
  COLUMN_LAST_EDITED_DATE: '마지막 편집',
  COLUMN_ASSIGNED_TO: '할당',
  COLUMN_DUE_DATE: '마감일',
  COLUMN_FOLLOW_SENT_DATE: '후속 발송 날짜',
  COLUMN_FOLLOW_RECEIVED_DATE: '후속 접수 날짜',
  COLUMN_ANSWER_DATE: '답변 날짜',
  COLUMN_CLOSED_ON_DATE: '날짜가 마감되었습니다',
  COLUMN_REOPENED_DATE: '다시 열린 날짜',
  COLUMN_INSERTED_BY: '삽입',
  COLUMN_LAST_EDITED_BY: '마지막 편집',
  COLUMN_ANSWERED_BY: '답변',
  COLUMN_APPROVED_BY: '승인',
  COLUMN_INT_COMM_USER: '내부 커뮤니케이션 사용자',
  COLUMN_INT_COMM_DATE: '내부 커뮤니케이션 날짜',
  COLUMN_MY_TEAM: '내 팀',
  COLUMN_PQC_RELATED: 'PQC 관련',
  COLUMN_INCOMING_CHANNEL: '들어오는 채널',
  COLUMN_OUTGOING_CHANNEL: '나가는 채널',
  COLUMN_OFF_LABEL: '라벨이없는',
  COLUMN_HCP_SPEC: 'HCP + 전문화',
  COLUMN_NON_HCP_SPEC: '비 HCP + 직업',
  COLUMN_KEE: '키',
  COLUMN_HOSPITAL_BASED: '병원 기반',
  COLUMN_CITY: '도시',
  COLUMN_INSTITUTION: '기관',
  COLUMN_TERRITORY: '영토',
  CASE_ID: '케이스 ID',
  OPTION_ADD: '추가하다',
  OPTION_AND: '그리고',
  OPTION_OR: '또는',
  OPTION_NOT: '아니다',
  OPTION_YES: '예',
  OPTION_NO: '아니',
  QUERY: '질문',
  EXPORT_LABEL: '내보내기 열을 정의합니다',
  SAVE_SEARCH: '검색 저장',
  SHARE_SEARCH: '검색을 공유하십시오',
  SEARCH_BTN: '검색',
  BACK_ACTION: '뒤',
  SAVE_CHANGES: '변경 사항을 저장하다',
  SAVED_SEARCH: '저장된 검색',
  NEW: '새로운',
  SAVE_AS: '이름으로 저장하십시오',
  ENTER_RECIPIENTS: '수신자를 입력하십시오',
  CLEAR_SAVED_SEARCH: '저장된 검색을 명확하게합니다',
  NO_SAVED_SEARCH: '저장된 검색이 없습니다',
  INT_COMM_TEXT: '내부 커뮤니케이션 텍스트',
  FOLLOW_UP_TEXT: '후속 텍스트',
  CONFIRM_DELETION: '삭제를 확인하십시오',
  ARE_SURE: '저장된 검색을 삭제하고 싶습니까?',
  PERMANENTLY_DELETED: '검색은 영구적으로 <b> 삭제됩니다 </b>.',
  NOTHING_SAVE: '저장할 것이 없습니다.',
  NOTHING_SEARCH: '검색 할 것이 없습니다.',
  NOTHING_EXPORT: '검색에는 내보내기 기록이 없습니다.',
  SHARE_SUCCESS: '검색이 성공적으로 공유되었습니다',
  SHARE_FAILURE: '검색은 공유 할 수 없습니다',
  DELETED_SUCCESS: '저장된 검색을 성공적으로 삭제했습니다',
  ALL_DELETED_SUCCESS: '저장된 모든 검색을 성공적으로 삭제했습니다',
  VIEW_SAVED_SEARCH: '저장된 검색을 봅니다',
  EDIT_SAVED_SEARCH: '저장된 검색을 편집합니다',
  EXPORT_SAVED_SEARCH: '수출 저장 검색',
  EXPORT_SEARCH: '수출 검색',
  DELETE_SAVED_SEARCH: '저장된 검색 삭제',
  SELECT_EXPORT_COLUMNS: '내보내기 열을 선택하십시오',
  SAVED_SEARCH_ALREADY_EXIST: '이미 그 이름으로 검색하십시오.',
  CHARACTER_LIMIT: '최소 3 자. 최대 35 자.',
  CHARACTER_LEFT: '캐릭터가 남아 있습니다',
  ACTIVATE: '활성화',
  INACTIVE_USER: '이 사용자는 더 이상 활성화되지 않습니다. 시스템 관리자에게 문의하십시오.',
  FOLLOW_UP: '후속 조치',
  USER_NOT_FOUND: '사용자를 찾을 수 없습니다',
  DEPARTMENTS: '부서',
  EMAIL: '이메일',
  ADD_NEW_DEPARTMENT: '새 부서를 추가하십시오',
  DEPARTMENT_COUNTRY: '국가',
  DEPARTMENT_COMPANY: '회사',
  DEPARTMENT_TEAM: '팀',
  DEPARTMENT_TA: '치료 영역',
  DEPARTMENT_PRODUCT: '제품',
  DEPARTMENT_ACTIONS: '행위',
  DEPARTMENT_CONFIRMATION_1: '삭제를 확인 하시겠습니까?',
  DEPARTMENT_CONFIRMATION_2: '이 조치는 돌이킬 수 없습니다.',
  DEPARTMENT_NAME: '부서 이름',
  TYPE_DEPARTMENT: '유형',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: '국가 - 먼저 팀을 선택하십시오',
  DEP_NAME_CHARACTER_LIMIT: '최대 200 자.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: '사용자 요청',
      CategoryTopicRequest: '범주/주제 요청',
      ProductRequest: '제품 요청',
      UserUpdateRequest: '사용자 업데이트 요청',
    },
    TYPE: {
      NewUser: '새로운 사용자',
      NewCategoryTopic: '새로운 카테고리/주제',
      NewUserUpdate: '사용자 업데이트',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: '국가 별 전문화 목록을 정의하십시오',
      IS_COUNTRY_SPECIFIC: '국가에 따라 다르나요?',
      TYPE: '문의자 유형',
      SPECIALIZATION_EXISTS: '"Enquirer 유형/전문 분야"에 대한 구성이 이미 있습니다.',
    },
    AUTHORIZATION_REQUESTS: { GENDER: '성별', COUNTRY: '국가', TITLE: '제목' },
  },
  hcp: 'HCP',
  emp: '직원',
  cons: '비 HCP',
  CONDITION: '상태',
  OOO_DEPUTY: 'OOO 차장',
  NO_DEPUTY: '차장 없음',
  UNSPECIFIED_END_DATE: '지정되지 않은 종료 날짜',
  DELEGATORS: '대표단',
  NO_DELEGATORS: '대표단 없음',
  PREVIEW_DELEGATORS: '미리보기 대표단',
  REMOVE_DEPUTY: '차장을 제거하십시오',
  USERS_WHO_SET_YOU_AS_DEPUTY: '당신을 대리로 설정 한 사용자',
  CHANGED_USER: '변경된 사용자',
  DEFAULT: '기본',
  SPECIALIZATIONS: '전문화',
  ENQUIRERS_LIST: 'Enquirers 목록',
  EDIT_THE_RECORD: '레코드를 편집하십시오',
  DEACTIVATE_THE_RECORD: '기록을 비활성화하십시오',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: '사무실 중',
      HINT: '문의 처리 역할을 가진 활성 사용자 만 사무실 밖에서 선택할 수 있습니다.',
      MODAL_TITLE: '사무실 설정을 확인하십시오',
      MODAL_MSG_1:
        '귀하는 즉각적인 효과로 사무실 옵션을 설정했으며, 모든 문의는 즉시 차장에게 할당됩니다.',
      MODAL_MSG_2:
        '귀하는 사무실 옵션을 설정했으며, 모든 문의는 사무실 밖에서 부국장에 할당됩니다.',
    },
  },
  GENDER_MALE: '씨.',
  GENDER_FEMALE: '부인.',
  TITLE_DR: '닥터',
  TITLE_PHD: '박사 학위',
  TITLE_PROF: '교수',
  TITLE_PROF_DR: '교수',
  HOME: '집',
  HOSPITAL: '병원',
  OFFICE: '사무실',
  WORK: '일하다',
  INSERT_IMAGE: '이미지 삽입',
  USER_ADMIN: '사용자 관리자',
  MAILBOXES: '사서함',
  AUTHORIZATION_REQUESTS: '승인 요청',
  METADATA: '메타 데이터',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: '과제',
      FORWARD: '앞으로',
      FOLLOW_UP: '후속 조치',
      DUE_DATE_SETTINGS: '마감일 설정',
      CLOSE: '닫다',
      DELIVERY_ERROR: '배송 오류',
      SCHEDULER: '스케줄러',
      USER_REPORTS: '사용자 보고서',
      DOCUMENT_APPROVAL: '문서 승인',
      AUTHORIZATION_REQUEST: '승인 요청',
      NEW_INQUIRY: '새로운 문의',
      INQUIRY_APPROVAL: '문의 승인',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: '역할 요청이 승인/거부되었습니다',
      AUTHORIZATION_REQUEST_3: '역할은 내 승인을 기다리는 것입니다',
      DOCUMENT_APPROVAL_1: '문서가 귀하의 승인을 기다리고 있습니다',
      DOCUMENT_APPROVAL_2: '문서가 승인/거부되었습니다',
      DOCUMENT_APPROVAL_3: '문서가 보관되었습니다',
      DOCUMENT_APPROVAL_4: '문서는 변경 사항으로 승인되었습니다',
      DOCUMENT_APPROVAL_5: '문서가 보관됩니다',
      DOCUMENT_APPROVAL_6: '승인 된 문서 목록',
      DUE_DATE_2: '과거에 반복하십시오',
      ERROR_1: '배송 알림 실패 (예 : 잘못된 이메일)',
      FOLLOW_UP_1: '후속 조치 또는 알림이 고객에게 전송되었습니다',
      FOLLOW_UP_4: '후속 조치가 접수되었습니다',
      IMPORT_DATA_1: '가져 오기 동안 새로운 객체가 생성되었습니다',
      IMPORT_DATA_4: '가져 오기 동안 새로운 사용자가 생성되었습니다',
      IMPORT_DATA_5:
        '사용자가 이미 존재하는 사람과 일치했지만 이전 가져 오기에서 나오지 않았습니다.',
      IMPORT_DATA_6: '외부 소스에서 데이터를 가져 오는 동안 오류가 발생했습니다.',
      NEW_DOCUMENT_1: '우리 나라에서는 새로운 승인 된 문서가 제공됩니다',
      NEW_DOCUMENT_2: '지역 승인을 위해 새로운 문서가 제공됩니다',
      NEW_DOCUMENT_3: '유럽 / 글로벌 승인을 위해 새로운 로컬 문서를 사용할 수 있습니다.',
      NEW_DOCUMENT_4: '새로운 글로벌 문서를 사용할 수 있습니다',
      NEW_DOCUMENT_5: '새로운 유럽 문서를 사용할 수 있습니다',
      NEW_DOCUMENT_6: '글로벌 승인을 위해 새로운 유럽 문서가 제공됩니다',
      SCHEDULER_TASK_1: '작업 실행 중에 오류가 발생했습니다',
      USER_REPORTS_1: '활성 사용자 목록',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3: '사용자 역할 요청을 승인/거부해야 할 때 알림을받습니다.',
        AUTHORIZATION_REQUEST_2:
          '새 사용자가 생성되었거나 팀의 사용자가 역할을 요청했을 때 알림을받습니다.',
        AUTHORIZATION_REQUEST_4: '새로운 주제 및/또는 카테고리가 제안되었을 때 알림을받습니다.',
        FOLLOW_UP_4: '내가 할당된 문의에 대해 문의자로부터 후속 답변을 받았다는 알림을 받습니다.',
        NEW_INQUIRY_1:
          '귀하의 국가에서 수신된 새로운 문의, 문의자/사용자가 추가한 문의 또는 다른 팀에서 전달된 문의에 대한 알림을 받습니다.',
        ANSWER_2: '내가 협력자로 일했지만 문의에 직접 할당되지 않은 문의에 대한 답변을 받습니다.',
        DUE_DATE_1:
          '이 옵션을 선택하면 아래 모든 알림이 활성화됩니다. 알림 수를 줄이려면 필요하지 않은 알림의 선택을 해제하세요.',
        INQUIRY_APPROVAL_5:
          '제안된 답변이 거부되었거나, 상호 작용 상태가 승인을 위해 제출에서 진행 중으로 업데이트되었습니다. 이는 상호 작용을 통한 분할, 새로운 문의로 병합하여 분할이 발생했거나, 후속 문의 승인이 필요한 측면에서 팀 설정이 변경되었기 때문입니다.',
        INQUIRY_APPROVAL_4: '귀하가 제안한 답변이 승인되었습니다.',
        METADATA_MANAGEMENT_2: '생성 이유와 관계없이 새로 생성된 상품에 대한 알림을 받습니다.',
        METADATA_MANAGEMENT_3: '생성 이유와 관계없이 새로 생성된 카테고리에 대한 알림을 받습니다.',
        METADATA_MANAGEMENT_4: '생성 이유와 관계없이 새로 생성된 주제에 대한 알림을 받습니다.',
        FORWARD_1:
          '상호 작용 데이터는 부서에 전달되지 않은 경우 편집자 및 양수인 사용자 및/또는 양수인에게 통지합니다.',
      },
      FREQUENCIES: {
        INSTANTLY: '곧',
        DAILY: '일일',
        WEEKLY: '주간',
        QUARTERLY: '계간지',
        MONTHLY: '월간 간행물',
        SECOND_DAY: '매일 매일',
        THIRD_DAY: '셋째 날마다',
        ONE_WEEK: '일주일',
        ONE_MONTH: '한달',
        TWO_MONTHS: '이 개월',
        THREE_MONTHS: '삼 개월',
        SIX_MONTHS: '6 개월',
        NINE_MONTHS: '9 개월',
        EVERY_TWO_WEEKS: '모든 이주',
        EVERY_THREE_WEEKS: '3 주마다',
        EVERY_FOUR_WEEKS: '4 주마다',
      },
      OPTIONS: {
        ALMOST_DUE: '거의 마감',
        DUE_NOW: '지금 마감',
        DUE_SOON: '곧',
        EARLY_ON: '일찍',
        TEXT_GUIDES: {
          DUE_NOW: '문의 기한이 되면 알림을 받으세요',
          EARLY_ON:
            '마감일이 임박한 문의에 대해 조기에 알림을 받으세요: 정상 4일 - 높음 2일 - 중요 마감일 24시간 전',
          DUE_SOON:
            '곧 마감되는 문의에 대한 알림을 받으세요: 일반 48시간 - 높음 12시간 - 중요 6시간 전 마감일',
          ALMOST_DUE:
            '마감이 임박한 문의에 대한 알림을 받으세요: 일반 24시간 - 높음 6시간 - 마감일 3시간 전 중요',
        },
      },
      AUTHORIZATION_REQUEST_4: '우리 팀에서 카테고리/ 주제가 요청되었습니다',
      AUTHORIZATION_REQUEST_2: '사용자 요청',
      IMPORT_DATA_2: '가져오기 중에 새로운 문의자가 생성되었습니다.',
      IMPORT_DATA_3:
        '문의자는 이미 존재하는 문의자와 일치했지만 이전 수입처에서 온 것이 아니었습니다.',
      INTERNAL_1: '내 할당된 문의에 메모가 추가되었습니다.',
      ANSWER_1: '내가 답변한 상호작용에서 답변 사본을 받습니다.',
      ANSWER_6: '내가 승인한 상호작용에서 답변 사본을 받습니다.',
      INQUIRY_APPROVAL_5: '제안된 답변이 거부되었습니다.',
      INQUIRY_APPROVAL_4: '제안하신 답변이 승인되었습니다.',
      METADATA_MANAGEMENT_1: 'CRM 가져오기 중의 지리 및 정렬',
      METADATA_MANAGEMENT_2: '새로운 제품이 생성되었습니다.',
      METADATA_MANAGEMENT_3: '새로운 카테고리가 생성되었습니다',
      METADATA_MANAGEMENT_4: '새로운 주제가 생성되었습니다',
      ANSWER_2: '내가 참여한 상호 작용에 대한 답변이있었습니다',
      ASSIGNMENT_1: '상호 작용이 나에게 할당되었습니다',
      ASSIGNMENT_5: '상호 작용은 내 차장에게 할당되었습니다',
      CLOSE_1: '상호 작용이 폐쇄되었습니다',
      DUE_DATE_1: '내가 할당 한 상호 작용은 기한에 가깝습니다.',
      FOLLOW_UP_7: '상호 작용은 후속 조치로 손실되었습니다',
      FORWARD_1: '상호 작용 전달',
      INQUIRY_APPROVAL_1: '상호 작용이 귀하의 승인을 기다리고 있습니다',
      NEW_INQUIRY_1: '우리 나라에서 새로운 상호 작용이 접수되었습니다',
      SPAM_1: '스팸에서받은 새로운 상호 작용',
    },
  },
  NEW_TEAM: '새로운 팀',
  SmPc: 'SMPC',
  USERS: '사용자',
  PAGINATOR_NEXT_PAGE_LABEL: '다음 페이지',
  PAGINATOR_PREV_PAGE_LABEL: '이전 페이지',
  PAGINATOR_FIRST_PAGE_LABEL: '첫 페이지',
  PAGINATOR_LAST_PAGE_LABEL: '마지막 페이지',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: '페이지 당 항목',
  Date: '날짜',
  Action: '동작',
  'Action by user': '사용자에 의한 조치',
  'Changed user': '변경된 사용자',
  'Original value': '원래 가치',
  'New value': '새로운 가치',
  REGION: '지역',
  TEAM_REGION_LABEL: '지역 - 먼저 회사를 선택하십시오',
  TEAM_THERAPEUTIC_AREA_LABEL: '치료 영역 - 먼저 회사를 선택하십시오',
  TEAM_PRODUCT_LABEL: '제품 - 치료 영역을 먼저 선택하십시오',
  TEAM_COUNTRIES_LABEL: '국가 - 먼저 지역을 선택하십시오',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: '각 국가의 제품을 정의하십시오',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: '하나 이상의 제품을 선택하십시오',
  VIEW_CHANGES: '변경 사항을 봅니다',
  Sales_Representative: '영업 담당자',
  Other_Commercial: '다른 광고',
  REGIONS: '지역',
  MAIN_CLIENT: '주요 클라이언트',
  LICENSE_PARTNER: '라이센스 파트너',
  TEAM_SET_UP: '팀 설정',
  LIST_TEAMS: '목록 팀',
  COMPANIES: '회사',
  DOMAINS: '도메인',
  TOKEN_REFRESH_NOTICE: 'ENQMED 계정 등록에 대한 새 확인 이메일을 요청했습니다.',
  TOKEN_REFRESH_SUCCESS: '새로운 확인 링크가 귀하의 이메일로 전송되었습니다.',
  TOKEN_REFRESH_ERROR: '오류가 발생했습니다. 추가 지원이 필요한 경우 지원에 문의하십시오.',
  PLEASE_ENTER_MAX: '최대를 입력하십시오',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    '아래 변경 사항을 검토하고 회사가 변경되어야하는지 확인하십시오.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE: '변경 사항이 팀에 영향을 미치기 때문에주의를 기울이십시오',
  THERE_ARE_NO_CHANGES: '변화가 없습니다.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '우리가 이미 동일한 값을 가진 회사를 가지고 있기 때문에 대화의 뒤에있는 기존 회사를 확인하십시오.',
  SIMPLE_TEXT:
    '문자 (A-Z, A-Z), 숫자 (0-9) 및 (대시, 기간, 공간 및 결장)와 같은 특수 기호 만 입력하십시오.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: '지역을 위해 하나 이상의 국가를 선택하십시오',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    '치료 영역에 대한 최소 하나의 제품을 선택하십시오',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    '하나 이상의 치료 영역에 속하는 제품을 선택했습니다. 필요한 모든 치료 영역을 선택하십시오.',
  HTML_CHANGES: 'HTML 변경',
  SIDE_BY_SIDE_VIEW: '나란히보기',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '이미 동일한 값을 가진 도메인을 가지고 있으므로 대화 상자 뒤에있는 기존 도메인을 확인하십시오.',
  OUT_CHANNEL_ERROR_MSG: '나가는 채널 편지 Enquirer의 우편 주소',
  OUT_CHANNEL_ERROR_MSG_LONG:
    '나가는 채널은 편지로 선택되었습니다. Enquirer 지역에 Enquirer의 우편 주소를 입력하십시오.',
  LABEL_FOR: '라벨',
  male: '남성',
  female: '여성',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    '이 역할을 보관할 권리가 없습니다. 팀의 경우 <b> mims </b> 만 팀원의 역할을 보관할 수 있습니다.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION: '요청 승인/거부에 따라 사용자에게 이메일 보내기',
  APPROVE_REQUEST_QUESTION: '요청을 승인 하시겠습니까?',
  NEW_USER_REQUEST: '새로운 사용자 요청',
  OPEN_REQUESTS: '열린 요청',
  APPROVED_REQUESTS: '승인 된 요청',
  REJECTED_REQUESTS: '거부 된 요청',
  USER_REQUESTS: '사용자 요청',
  REQUEST_FOR: '요청',
  CATEGORY_TOPIC_REQUESTS: '카테고리/주제 요청',
  WITH_THE_FOLLOWING_PAIRS: '다음 쌍으로 :',
  MY_TEAM_OPEN_REQUESTS: '열린 요청',
  MY_TEAM_APPROVED_REQUESTS: '승인 된 요청',
  MY_TEAM_REJECTED_REQUESTS: '거부 된 요청',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'EnqMed 내의 문서 승인',
  DOCUMENTS_APPROVAL: '문서 승인',
  DOCUMENTS_APPROVAL_TITLE: '선택한 팀의 문서 승인 프로세스 설정',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    '나가는 채널은 팩스로 선택되었습니다. Enquirer 지역에 Enquirer의 팩스 번호를 입력하십시오',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    '발신 채널이 전화로 선택되었습니다. Enquirer 지역에 Enquirer의 전화 번호를 입력하십시오',
  OUT_CHANNEL_FAX_ERROR_MSG: '나가는 채널 팩스 Enquirer의 가치',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: '나가는 채널 전화 Enquirer의 가치',
  COPYRIGHT_PART_ONE: '저작권',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. 판권 소유.',
  REVIEWED_DATE: '검토 날짜',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    '나가는 채널이 이메일로 선택되었습니다. Enquirer 지역에 Enquirer의 이메일을 입력하십시오',
  OUT_CHANNEL_EMAIL_ERROR_MSG: '나가는 채널 이메일 Enquirer의 가치',
  ENTER_USER_COMMENT_OPTIONAL: '사용자에게 댓글을 입력하십시오 (선택 사항)',
  SELECT_ANOTHER_CATEGORY: '다른 카테고리를 선택하십시오',
  SELECT_ANOTHER_PRODUCT: '다른 제품을 선택하십시오',
  SUGGESTED_TOPIC: '제안 된 주제',
  EDIT_USER_REQUEST: '사용자 요청 편집',
  NEW_TOPIC_REQUEST: '새로운 주제가 제안되었습니다',
  EDIT_TOPIC_REQUEST: '제안 된 주제를 편집합니다',
  NEW_CATEGORY_REQUEST: '새로운 카테고리가 제안되었습니다',
  EDIT_CATEGORY_REQUEST: '제안 된 카테고리 편집',
  REVIEW_COMMENT: '의견을 검토하십시오',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: '문서 승인 워크 플로우',
    CREATE_NEW: '새 워크 플로를 만듭니다',
    CUSTOMIZE: '워크 플로를 사용자 정의합니다',
    NAME: '워크 플로 이름',
    ERROR_MSG_DUPLICATE_NAME: '이 이름을 가진 워크 플로가 이미 존재합니다.',
    SAVE: '워크 플로를 저장하십시오',
    DELETE: '워크 플로 삭제',
    STEP: '단계',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: '문서 저자 역할을 가진 모든 사용자.',
    APPROVAL_DEADLINE: '승인 마감일',
    SELECT_APPROVAL_TYPE: '승인 유형을 선택하십시오',
    SELECT_APPROVAL_TYPE_FIRST: '먼저 승인 유형을 선택하십시오',
    SELECT_REVIEWERS: '리뷰어를 선택하십시오',
    ACTION_ON_APPROVE_WITH_CHANGES: '변경으로 승인에 대한 조치',
    ACTION_ON_REJECT: '거부에 대한 조치',
    FILL_ALL_REQUIRED_FIELDS: '새로운 단계를 추가하려면 필요한 모든 필드를 채우십시오.',
    ALL_APPROVAL_TYPES_USED:
      '현재 단계에서 사용할 수있는 모든 유형을 사용하므로 더 많은 승인 하위 단계를 추가 할 수 없습니다.',
    CONFIRMATION_ROW_1: '팀의 문서 승인 워크 플로우를 삭제하려고합니다 {{ NAME }}.',
    CONFIRMATION_ROW_2: '승인 워크 플로를 정말로 삭제하고 싶습니까?',
    CONFIRMATION_TITLE: '문서 승인 워크 플로우 삭제',
    TEAM_VIEW_SELECT_EXISTING: '아래 목록에서 기존 문서 승인 워크 플로를 선택하거나',
    DOC_VIEW_SELECT_EXISTING: '기존 문서 승인 워크 플로를 선택하십시오',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      '단일 승인 단계 만 최종으로 표시 될 수 있습니다.',
    APPROVAL_TYPE_TOOLTIP:
      '이 팀의 문서 승인 역할을 가진 사용자는 없습니다. 먼저 승인 역할을 추가하십시오.',
    INFO_DIALOG_TITLE: '이 팀에 대한 문서 승인 워크 플로우는 없습니다.',
    INFO_DIALOG_MSG: '팀을위한 문서 승인 워크 플로를 만들려면 현지 MIM에 문의하십시오.',
    STEP_LIMIT_REACHED: '승인 단계 한도인 {{ MAX_STEPS }}개에 도달했습니다.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: '승인이 필요하지 않은 문서 유형',
  PLEASE_SELECT_TYPE: '유형을 선택하십시오',
  ABORT_FILE_UPLOAD: '파일 업로드 중단',
  USERS_REPORT: '사용자 보고서',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG: '이 창을 닫으면 이미 채워진 정보가 느슨해집니다.',
  DO_YOU_WANT_TO_PROCEED: '진행 하시겠습니까?',
  USERS_REPORT_DESCRIPTION:
    'Daiichi Sankyo Europe 종양학 및 심혈관의 MIM이 MIM 역할을하는 모든 추가 팀의 사용자 목록.',
  TEMPLATE_CONFIRM_MSG: '템플릿이 삭제되면 복원 할 수 없습니다!',
  EVENT_CONFIRM_MSG: '이벤트가 보관되면 복원 할 수 없습니다!',
  CREATE_DRAFT: '드래프트를 만듭니다',
  CREATE_USER: '사용자를 만듭니다',
  ADD_ROLE: '역할 추가',
  DOMAIN_ADDRESS_INVALID_FOR: '도메인 주소는 유효하지 않습니다',
  AVAILABLE_ADDRESSES: '사용 가능한 주소',
  IN_TEAM: '팀에서',
  TO_THE_SELECTED_USER: '선택한 사용자에게',
  USER_INFORMATION: '사용자 정보',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    '도메인 주소가 팀에 해당하지 않으므로 사용중인 이메일 주소를 업데이트해야합니다. 프로필이 연결되어 있지 않으므로 시스템 관리자에게 문의하거나 지원 팀에 문의하십시오.',
  MESSAGE: '메시지',
  IN_APPROVAL: '승인 중',
  IN_REVIEW: '검토에서',
  DOCUMENT_APPROVAL: '문서 승인',
  DOCUMENT_TITLE: '문서 제목',
  SEARCH_DOCUMENTS: '검색 문서',
  APPROVAL_INBOX: '승인받은 편지함',
  FILTER_DOCUMENTS_BY: '서류를 필터링하십시오',
  submit_to_approval: '승인에 제출하십시오',
  create_document_metadata: '문서 메타 데이터를 만듭니다',
  archive: '보관소',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    '나는 지역 요구 사항에 따라 모든 의료 제품의 모든 부작용 약물 반응 (ADR)을보고하는 것이 나의 의무임을 인정합니다. ENQMED는 부작용 약물 반응에 대한 보고서를 포착하기위한 것이 아닙니다. ADR은 국소 요구 사항에 따라 직접 약물 감시에보고해야합니다.',
  VERSION_DIALOG: {
    TITLE: '새 버전 사용 가능',
    MESSAGE:
      '사용 가능한 소프트웨어의 새 버전이 있습니다!\n 개선된 기능을 활용하고 이전 버전 사용으로 인한 문제를 피하려면 새 버전을 받아야 합니다.',
    SAVE_BUTTON: '새 버전 받기',
  },
  ACTIVE_INACTIVE: '활성/비활성',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '동일한 값을 가진 범주가 이미 있으므로 대화 상자 뒤의 테이블에서 기존 범주를 확인하십시오.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: '오류! 배달 실패',
  VIA: '~을 통해',
  INSERTED_MANUALLY: '수동으로 삽입됨',
  AS_A: '로서',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '동일한 값을 가진 제품이 이미 있으므로 대화 상자 뒤의 표에서 기존 제품을 확인하십시오.',
  NOT_PRODUCT_SPECIFIC: '특정 제품이 아님',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '동일한 값을 가진 항목이 이미 있으므로 대화 상자 뒤의 테이블에서 기존 항목을 확인하세요.',
  PLEASE_ENTER_MAX_CHARACTERS: '최대 {{ MAX_CHARS }}자를 입력하세요.',
  THANK_YOU_MESSAGE: '감사 메시지',
  AS: '~처럼',
  THERAPEUTIC_AREA_PARENT: '상위 영역',
  MAIN_THERAPEUTIC_AREA: '주요 치료분야',
  CHOOSE_AN_IMAGE: '이미지를 선택하세요',
  ALLOWED_FORMATS: '{{ FORMATS }} 형식만 허용됩니다.',
  REMOVE_IMAGE: '이미지 삭제',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '동일한 값을 가진 영역이 이미 있으므로 대화 상자 뒤의 테이블에서 기존 치료 영역을 확인하십시오.',
  PLAIN_TEXT_VIEW: '일반 텍스트 보기',
  RICH_TEXT_VIEW: '리치 텍스트 보기',
  EDIT_ITEM: '{{ ITEM }} 수정',
  CREATE_NEW_ITEM: '새 {{ ITEM }} 만들기',
  ADD_NEW_ITEM: '새 {{ ITEM }} 추가',
  ADD_ITEM: '{{ ITEM }} 추가',
  AND_HENCE_STATUS_CHANGED_FROM: '따라서 상태가 다음과 같이 변경되었습니다.',
  FOLLOW_UP_WITH_ENQUIRER: '문의자와의 후속 조치',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: '변경 사항을 저장하려면 다음을 삽입해야 합니다.',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION: '비공개 대화의 경우 후속 조치가 불가능합니다.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    '검토 상태의 상호 작용. 이 메뉴를 활성화하려면 리뷰를 저장하세요.',
  QUESTION_TEXT_MIN_SIX_CHARS: '질문 텍스트(최소 6자)',
  PLEASE_PROVIDE_AN_ANSWER_: '답변을 제공해 주세요(최소 6자 또는 첨부파일).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE: "사용자는 '비밀번호 찾기'에서 비밀번호를 복구해야 합니다.",
  ENABLE_USER_LOGIN_MESSAGE: '그러면 사용자 로그인이 활성화됩니다.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    '이렇게 하면 사용자 로그인 및 알림이 비활성화됩니다.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE: '사용자 프로필에서 알림 기본 설정을 설정하세요!',
  SET_PASSWORD: '비밀번호를 설정해주세요',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION: '계정을 다시 활성화한 후 비밀번호가 삭제되었습니다.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: '전문화 비활성화를 확인하세요!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    '그러면 활성화된 전문화 목록에서 해당 전문화가 제거됩니다.',
  GO_TO_ROLES: '역할로 이동',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    '사용자 연락처 정보 변경으로 인해 로그인 이메일이 {{ OLD_USERNAME }}에서 {{ NEW_USERNAME }}(으)로 변경되었습니다.',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: '역할과 알림을 할당해 주세요.',
  DO_YOU_WANT_TO_CONTINUE: '계속하시겠습니까?',
  COPY_ANSWER_TO_NEW_INTERACTION: '새 상호작용에 대한 답변 복사',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    '새로운 문의사항이 들어왔습니다. 모든 문의 사항을 특성화하고 답변하십시오.',
  LEAVE_WITHOUT_SAVING: '저장하지 않고 나가기',
  PENDING_CHANGES_MESSAGE: '보류 중인 변경사항은 {{ CHANGES }}입니까?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    '새 상호 작용에서 선택한 텍스트를 분할하시겠습니까?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    '그러면 상호 작용에서 선택한 텍스트가 제거되고 새 텍스트가 생성됩니다.',
  DOWNLOAD_ERROR: '다운로드 오류!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    '1000개가 넘는 문의를 다운로드하려고 합니다. 지원되는 최대 숫자는 1000입니다. 선택 범위를 좁혀주세요.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION: '질문 특성을 변경하려고 합니다!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    '질문 특성화를 변경하면 이전에 제공된 답변이 변경되거나 새 답변을 보낼 수 없습니다!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE: '새 답변을 보내기 위해 닫힌 상호작용을 다시 열려고 합니다.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE: '이 후속 조치를 질문으로 전환하시겠습니까?',
  ARE_YOU_SURE: '확실합니까?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: '{{ USER_INFO }} 비활성화 확인',
  USER_REACTIVATION_CONFIRMATION_QUESTION: '{{ USER_INFO }} 재활성화 확인',
  USER_REACTIVATED_MESSAGE: '사용자 {{ USER_INFO }}이(가) 성공적으로 재활성화되었습니다!',
  DELETE_NOT_POSSIBLE: '삭제 불가!',
  SEARCH_NO_LONGER_EXIST: '이 검색은 보낸 사람이 삭제했기 때문에 더 이상 존재하지 않습니다.',
  LOGIN_CREDENTIALS_CHANGED: '로그인 자격 증명이 변경되었습니다!',
  EDIT_TEXT: '텍스트 편집',
  FOLLOW_UP_ATTEMPTS: '후속 시도',
  FIRST_FOLLOW_UP_INFO_TEXT: '개별 상호작용 내에서 첫 번째 후속 조치가 전송됨',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    '초기 요청 후 아래 중 하나라도 비활성화되면 프로세스는 다음 단계로 전환됩니다.',
  SECOND_ATTEMPT: '두 번째 시도(첫 번째 알림)',
  THIRD_ATTEMPT: '세 번째 시도(첫 번째 알림)',
  FINAL_ATTEMPT: '후속 조치 실패(닫기)',
  REMINDER_FREQUENCY: '알림 빈도',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE: '문의자에게 연락하는 데 사용되는 언어로 자동 번역 적용',
  ON_OFF_SWITCH: '켜기/끄기',
  DAY: '낮',
  DAYS: '날',
  MONTH: '월',
  MONTHS: '개월',
  RESET_TEMPLATES_TO_DEFAULTS: '템플릿을 기본값으로 재설정',
  SAVE_FREQUENCY: '빈도 저장',
  SELECT_FREQUENCY: '주파수 선택',
  TEMPLATE_MODIFICATIONS_HINT:
    '텍스트는 수동으로 수정되었으며 더 이상 자동으로 변경되지 않습니다. 수정 사항을 취소하고 자동 업데이트를 다시 활성화하려면 여기를 클릭하세요.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: '이메일 주소가 잘못되었습니다!',
  AUDIT_TRAIL_FOR: '{{ ITEM }}에 대한 감사 추적',
  AUDIT_TRAIL_FOR_ALL: '모든 {{ ITEM }}에 대한 감사 추적',
  HISTORY_CHANGES_FOR: '에 대한 기록 변경사항',
  HOLIDAY_GDPR_DISCLAIMER: '휴일 GDPR 고지 사항',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: '휴일 GDPR 면책조항 관리',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS: '다음 팀의 휴일 GDPR 템플릿이 누락되었습니다.',
  BULK_CREATE: '대량 생성',
  LAST_SUCCESS: '마지막 성공',
  LAST_FAILURE: '마지막 실패',
  FAILURE: '실패',
  TASK: '일',
  Weeks: '주',
  REPEAT_EVERY: '매회 반복',
  DEACTIVATE_ITEM: '{{ ITEM }} 비활성화',
  RESET_FAILURE_COUNT: '실패 횟수 재설정',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '동일한 값을 가진 작업이 이미 있으므로 대화 상자 뒤의 테이블에서 기존 작업을 확인하십시오.',
  ACTIVATE_ITEM: '{{ ITEM }} 활성화',
  PLEASE_ENTER_MIN_NUMBER: '{{ MIN_NUMBER }} 보다 큰 숫자를 입력하세요.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      '병합을 위해 선택한 대상 상호작용이 이미 종료되었습니다. 이 문의는 새로운 상호 작용이므로 병합할 필요가 없습니다.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      '기존 문의에 새로운 질문이 추가되며, 모든 질문에 답변해야 합니다. 병합할 질문을 선택하세요.',
    VALIDATION_MSG: '병합을 진행하려면 병합된 상호작용에서 질문을 하나 이상 선택하세요.',
  },
  MERGED_INTERACTION: '병합된 상호작용',
  TARGET_INTERACTION: '표적 상호작용',
  MERGE_SUGGESTION: '제안 병합',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: '아래의 병합 제안을 검토하세요.',
  INQUIRERS_COMPARISON_TITLE:
    '병합된 상호작용과 대상 상호작용에 대한 문의자가 다른 점에 유의하세요.',
  INQUIRERS_COMPARISON_QUESTION:
    '이러한 차이에도 불구하고 계속 진행하시겠습니까? 확인하시기 바랍니다.',
  TERRITORY: '지역',
  DOMAIN: '도메인',
  IN: '~에',
  TEAM_GROUP_FILTER: {
    TITLE: '추가 팀을 볼 수 있는 권한 정의',
    SAVE_BTN_TOOLTIP: '팀 필수 항목을 모두 입력하신 후 팀을 먼저 저장해 주세요.',
  },
  ADR_REF_ID: 'ADR 참조 ID',
  PQC_REF_ID: 'PQC 참조 ID',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS: '이 첨부 파일은 여러 상호작용에 존재합니다.',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    '모든 상호 작용에서 첨부 파일을 삭제하려면 확인란 옵션을 선택하십시오.',
  CONFIRM_DELETE_ATTACHMENT: '이 첨부파일을 삭제할지 확인해 주세요.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: '위에 언급된 모든 상호작용에서 삭제',
  ATTACHMENT_DELETED: '첨부파일이 삭제되었습니다.',
  ROLES: '역할',
  SET_TO_ALL_INQ_TOOLTIP: '모든 질문에 대해 동일한 ADR, PQC 및 오프 라벨 특성을 설정합니다.',
  TASKS_LIST: '작업 목록',
  TASK_LOGS: '작업 로그',
  SELECTED: '선택된',
  LICENSE_COUNTER: '라이센스 카운터',
  LICENSES_LIMIT: '라이센스 번호 제한',
  USERS_TO_EXCLUDE: '제외할 사용자',
  ENTER_MAX_NUMBER_OF_LICENSES: '계약된 라이센스 비용(사용자 역할).',
  SELECT_USER_ROLES_TO_COUNT: '라이선스 비용 계산에 적용 가능한 사용자 역할을 선택하세요.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: '라이센스 비용 계산에서 사용자를 제외합니다.',
  RECEIPT_AND_SALES_NOTIFICATIONS: '영수증 및 판매 알림',
  REFRESH_MIGRATION_ERRORS: '마이그레이션 오류 목록 새로 고침',
  MARKED_AS_RESOLVED_BY: '해결된 것으로 표시됨:',
  MARKED_AS_RESOLVED_ON: '해결된 날짜로 표시됨',
  MARK_AS_RESOLVED: '해결됨으로 표시',
  ERROR_ON: '오류 발생',
  TEXT_WITH_SPECIAL_CHARS:
    '문자(a-z, A-Z), 숫자(0-9)와 대시(-), 마침표(.), 공백, 콜론(:), 쉼표(,), 괄호(()), 앞으로 등의 특수 기호만 입력하세요. 슬래시(/) 및 더하기(+)입니다.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    '승인 상태로 제출된 상호작용입니다. 이 메뉴를 활성화하려면 승인/거부하세요.',
  REFRESH_TASK_LOGS: '작업 로그 목록 새로 고침',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE: '변경을 진행하려면 확인해주세요!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: '위임된 역할에는 수동 보관이 허용되지 않습니다.',
  RUN_COUNTER: '카운터 실행',
  EXPORT_LICENSES: '수출 라이센스',
  LICENSE_COUNT_MESSAGE: '사용된 라이센스 수는 다음과 같습니다.',
  QUESTIONS: '질문',
  CLARIFICATION: '설명',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG: '병합을 진행하려면 대상 상호작용에서 질문을 하나 이상 선택하세요.',
    DESCRIPTION: '설명 질문은 대상 상호 작용에서 선택한 질문 아래에 추가됩니다.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION: '상호 작용을 기존 상호 작용의 중복으로 표시하려면 이 옵션을 선택합니다.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    '설명 질문으로 이전 병합이 감지되었습니다. 대상 상호 작용에서 이전에 선택한 답변 필드 외에 선택한 모든 답변 필드가 답변을 위해 다시 열립니다.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    '다른 질문에 대한 답변을 제공하기 위해 상호작용이 다시 시작되었습니다. 이 질문에 대한 답변은 이미 이전에 제공되었습니다.',
  TIP: '팁',
  MERGE_TIP_TEXT:
    '대신 상호작용 {{ MERGED_INTO_ID }}을(를) 다시 열려면 아니요를 누르고 대상 상호작용 {{ MERGED_INTO_ID }}을 나타내는 새로운 병합 프로세스를 시작하세요.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    '대상 상호 작용을 열지 않고 병합 작업을 선택하고 있습니다.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    '잘못된 타겟 상호작용 {{ TARGET_INTERACTION_ID }}에 대한 병합 작업을 선택하고 있습니다.<br>상호작용 {{ TARGET_INTERACTION_ID }}이(가) {{ STATUS }}{{ LAST_MERGE_INFO }} 상태로 종료되었습니다.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    '이로 인해 상호작용이 폐쇄되고 답변을 제공할 수 없게 됩니다.',
  INTO_INTERACTION: '상호 작용으로',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: '병합할 상호작용을 선택하세요.',
  PLEASE_SELECT_MERGE_REASON: '병합 이유를 선택하세요.',
  QUESTION_CANNOT_BE_DELETED:
    '답변되지 않은 설명 질문과 연결되어 있으므로 이 질문을 삭제할 수 없습니다.',
  MERGE_REASON: '병합 이유',
  WITH_REOPEN: '재개장과 함께',
  MERGED_INTO_QUESTION_IDS: '질문 ID에 병합됨',
  MERGED_INTO_QUESTIONS: '질문에 병합됨',
  MERGED_PREVIOUSLY_FROM: '이전에 병합됨',
  FROM_INTERACTION_ID: '상호작용 ID에서',
  IN_INTERACTION_ID: '상호작용 ID에서',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: '병합 제안을 취소하려고 합니다.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: '병합 제안을 다시 표시하지 않습니까?',
  PRODUCT_RESOLVED_VIA_AI: '제품(AI를 통해 해결)',
  REQUIRED_FIELDS_HINT: '필수 입력란을 모두 작성해 주세요.',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: '하나 이상의 연락처가 필요합니다!',
  DISCLAIMER_INFO: '면책조항 정보',
  QUESTION_ID: '질문 ID',
  QUESTION_IDS: '질문 ID',
  SHOW_TEAMS: '팀 표시',
  LAST_POLLING: '마지막 폴링',
  HOST: '주인',
  PROTOCOL: '규약',
  FROM_EMAIL: '이메일에서',
  REASON_FOR_REMOVAL: '제거 이유',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: '닫힌 상호 작용은 편집할 수 없습니다.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    '상호 작용에는 두 개 이상의 질문이 있습니다. 공개 상호작용에서만 편집이 가능합니다.',
  BULK_EDIT: '일괄 수정',
  HOW_TO_ENABLE_BULK_EDIT:
    '일괄 업데이트를 수행하려면 동일한 언어로 된 템플릿을 두 개 이상 선택해야 합니다.',
  REASON_FOR_UPDATE: '업데이트 이유',
  FOLLOW_UP_RESEND: '후속 조치 재전송',
  SENT_FOLLOW_UP_REMINDER: '후속 알림을 보냈습니다',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    '상호 작용을 종료하고 후속 알림을 보내지 않음',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: '최소 15자 이상의 텍스트를 입력하세요.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    '후속 조치가 비활성화되었습니다! 문의자를 먼저 입력해주세요.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    '후속 요청이 비활성화되었습니다! 유효한 문의자의 이메일을 먼저 입력해주세요.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    '선택한 빈도 유형의 숫자는 1에서 {{ MAX_ALLOWED_FREQUENCY }} 사이여야 합니다.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: '주파수 유형을 선택하세요.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    '최초 후속 요청 날짜 및 시간부터 계산이 시작됩니다. 일을 주로 변환하려면 7을 곱하세요. 예: 2주 = 14일, 며칠에서 몇 달까지의 경우 30을 곱합니다. 1개월 = 30일.',
  VALID_PHONE_FAX: '유효한 전화/팩스 번호를 입력하세요!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    '번역된 템플릿을 보고 관리하려면 언어를 선택하세요.',
  RETRANSLATE: '재번역',
  FOLLOW_UP_REMINDERS_INFO_TEXT: '아래 템플릿은 영어로만 제공되며 번역은 아래에서 관리됩니다.',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    '위의 모든 템플릿은 기본값으로 재설정되고(수동 수정은 제거되고 빈도는 14일로 재설정되며 번역 확인란 선택이 취소됨) 모든 언어로 다시 번역되며 is_manually_modified가 지워집니다.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    '템플릿 텍스트를 편집하고 수동으로 수정되지 않은 모든 번역을 다시 번역하세요.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    '수동으로 수정한 내용을 포함하여 원본 템플릿 텍스트를 다시 번역합니다.',
  FOLLOW_UP_REMINDER_TEMPLATES: '후속 알림 템플릿',
  MANUALLY_MODIFIED: '수동으로 수정됨',
  SYNC: '동조',
  DIFFERENT: '다른',
  DEFAULT_CONFIRMATION_QUESTION: '계속 진행하시려면 확인해 주세요.',
  SEND_WELCOME_MAIL_: '비밀번호 재설정이 포함된 환영 이메일 보내기',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    '문의자가 새로운 후속 정보를 받았음을 확인하며 답변 제공을 위한 상호 작용을 다시 열고 싶습니다.',
  ONLY_LETTERS_SPACE_AND_DASH: '문자, 공백, - 기호만 입력하세요!',
  INFO: '정보',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    '문의자 국가가 변경되면 상호작용 할당이 팀 {{ CURRENT_TEAM }}에서 팀 {{ FUTURE_TEAM }}으로 변경됩니다.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    '문의자 국가를 변경하면 상호작용 할당이 팀 {{CURRENT_TEAM}}에서 팀 {{FUTURE_TEAM}}으로 변경됩니다. 사용자 {{ASSIGNED_TO}}는 {{FUTURE_TEAM}} 팀에 대한 권한이 없으며 해당 사용자의 개인 할당이 제거됩니다.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    '이 변경 사항은 다음과 같은 추가 상호 작용에서 팀 할당에 영향을 미치며, 이 경우 개인 할당은 제거됩니다: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    '문의자 국가가 변경되면 다음 추가 상호 작용에서 팀 할당이 변경되며, 이 경우 개인 할당은 제거됩니다. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    '상호작용 ID: {{ CURRENT_TEAM }}에서 {{ FUTURE_TEAM }}(으)로 {{ INTERACTION_ID }}, 사용자 {{ ASSIGNED_TO }}의 개인 할당이 제거되었습니다.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '사용자 {{ EDITING_USER }}이(가) 상호작용 ID: {{ UPDATED_INTERACTION_ID }}에서 문의자 {{ ENQUIRER }}의 국가를 변경했으며, 이로 인해 현재 상호작용의 할당이 팀 {{ OLD_TEAM }}에서 팀 {{ NEW_TEAM }}으로 변경되었습니다.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '사용자 {{ EDITING_USER }}이(가) HCP 관리에서 문의자 {{ ENQUIRER }}의 국가를 변경했으며, 이로 인해 현재 상호작용의 할당이 팀 {{ OLD_TEAM }}에서 팀 {{ NEW_TEAM }}으로 변경되었습니다.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '사용자 {{ INTERACTION_ASSIGN_TO }}에게는 {{ NEW_TEAM }} 팀에 대한 권한이 없으며 해당 개인 할당은 제거되었습니다.',
  PERSONAL_ASSIGNMENT: '개인 과제',
  DUE_TO_FU_REQUEST: '후속 요청으로 인해',
  DUE_TO_FU_RESPONSE: '후속 대응으로 인해',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE: '관리자 페이지에서 후속 알림 빈도가 업데이트됨에 따라',
  UPDATED_FROM_INBOX: '받은 편지함에서 업데이트됨',
  ARCHIVED_ROLE: '보관된 역할',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    '사용자 역할을 보관하면 다음과 같은 추가 상호 작용에서 할당이 변경되며, 이때 개인 할당은 제거됩니다. {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    '사용자 {{ EDITING_USER }}가 {{ INTERACTION_ASSIGN_TO }} 사용자를 편집하여 팀 {{ TEAM }}의 {{ USER_ROLE }} 역할을 보관했습니다. 사용자 {{ INTERACTION_ASSIGN_TO }}는 {{ TEAM }} 팀에 대한 권한이 없으며 해당 사용자의 개인 할당이 제거되었습니다.',
  ASSIGNMENT: '과제',
  PERIOD_TO_PERIOD_OVERLAP: '기간과 종료 기간이 겹칩니다.',
  PERIOD_TO_PERIOD_MISMATCH:
    '기간 및 종료 기간은 서로 다른 기간을 갖습니다. {{ PERIOD }} {{ INTERVAL }} 및 {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: '후속 응답에서 질문을 생성했습니다.',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    '이 옵션을 사용하면 후속 질문에서 새로운 질문을 만들 수 있습니다.',
  ADD_AS_A_NEW_QUESTION: '새로운 질문으로 추가',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    '이 사용자의 마지막 승인된 역할을 보관하려 하고 있으며, 이를 수행하면 사용자가 비활성화되고 EnqMed에 더 이상 액세스할 수 없게 됩니다.',
  ATTEMPTED_FOLLOW_UP: '후속 조치를 시도했다',
  FAILED_FU_ATTEMPT: '후속 시도 실패',
  UNABLE_TO_REACH_ENQUIRER: '문의자에게 연락할 수 없습니다.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    '문의자에게 연락을 시도했지만 성공하지 못한 경우(예: 전화로 연락이 안 됨, F2F 또는 다른 약속에 실패한 경우) 이 옵션을 선택합니다.\n시도는 상호작용 기록에 기록됩니다.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS: '귀하에게 권한이 있는 페이지로 리디렉션됩니다.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE: '문의자 변경으로 인해 후속 조치 프로세스가 중단됨',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    '문의자를 변경하려고 하는데, 변경하면 후속 조치 프로세스가 중단됩니다.',
  INQUIRER_DETAILS: '문의자 세부 정보',
  INQUIRER_TYPE: '문의자 유형',
  DELETE_INQUIRER_PII: '문의자 개인정보 삭제',
  MARKED_INQUIRER_PII_DELETE: '삭제를 위해 문의자 개인 정보를 표시했습니다.',
  UNMARKED_INQUIRER_PII_DELETE: '표시되지 않은 문의자 개인 정보 삭제',
  NEW_ENQUIRER: '새로운 문의자',
  NEW_ENQUIRER_TOOLTIP:
    '이 옵션을 사용하여 완전히 새로운 문의자를 만듭니다. 대신 기존 문의자 데이터를 편집해야 하는 경우 이 옵션을 사용하지 마십시오.',
  CONFIRM_SET_NEW_ENQUIRER:
    '기존 문의자를 새 문의자로 바꿀 것인지 확인해 주세요.<br>입력한 모든 정보가 손실됩니다.<br>기존 문의자의 데이터를 편집하려면 양식에서 직접 데이터를 수정하고 저장을 누르세요.',
  INQUIRER: '인콰이어러',
  KEEP_INQUIRER_DETAILS_OPEN: '문의자 세부 정보를 열어두세요',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    '저장하지 않은 변경 사항이 있는데, 문의자 세부 정보를 닫으면 해당 변경 사항이 손실됩니다.',
  UPDATED_INQUIRER_DETAILS: '업데이트된 문의자 세부 정보',
  CREATED_INQUIRER: '인콰이어러를 만들었습니다',
  SUGGESTED_INQ: '제안된 문의자',
  SELECT_INQUIRER: '인콰이어러를 선택하세요',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'ADR 의무 동의 및 문의자를 선택해야 상호작용을 제출할 수 있습니다.',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    '잘못된 이메일 주소로 인해 문의자에게 답변을 전송하지 못했습니다.',
  MSG_NOTIFICATION_WILL_BE_SEND_1: '감사합니다! 문의가 제출되거나 답변되면 알림이 전송됩니다.',
  MSG_NOTIFICATION_WILL_BE_SEND_2: '감사합니다! 문의자가 저장되면 알림이 전송됩니다.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ: '다음 GDPR 알림은 이메일을 통해 문의자에게 전송됩니다.',
  NOTIFICATION_SENT_TO_THE_INQ: '다음 GDPR 알림이 이메일을 통해 문의자에게 전송되었습니다.',
  SEND_A_NOTIFICATION_TO_THE_INQ: '다음 GDPR 알림을 문의자에게 이메일로 보내세요.',
  INQ_HAS_NO_EMAIL: 'enquirer에게는 이메일이 없습니다.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    '문의자에게 문의사항이 접수되었다는 알림이 전송되지 않았습니다.',
  NOTIFICATION_SENT_TO_THE_INQUIRER: '문의가 접수되었음을 문의자에게 알리는 알림입니다.',
  THIS_IS_JUST_A_THANK_YOU: '이는 기존 문의에 대해 문의한 사람으로부터의 감사 메시지입니다.',
  SAVE_INQUIRER: '인콰이어러 저장',
  INQ_DEFAULT_VAL: 'Enquirer 기본 값',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    '회사 담당자(영업 담당자, MSL 또는 기타)를 통해 문의 사항을 제출한 경우, 문의자에게 개인 정보 처리에 관해 알려주시기 바랍니다.',
  SELECT_PREFERRED_CONTACT_ERROR: '문의자에 대한 기본 연락처를 선택해야 합니다.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: '문의자가 후속 조치를 시작했습니다.',
  NO_INQUIRER: '문의하지 않음',
  'Changed inquirer': '문의자가 변경되었습니다',
  QUESTION_REQUIRE_ANSWER_MESSAGE: '새로운 질문에 대한 답변을 통해서만 문의를 마감할 수 있습니다.',
  IMAGE: '영상',
  THERAPEUTIC_AREA_STATUS_MESSAGE: '치료 영역은 {{ STATUS }}입니다.',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', 연관된 제품이 없습니다. 치료 영역을 활성화하려면 최소한 하나의 제품을 선택하십시오.',
  EXPAND_ALL_THERAPEUTIC_AREAS: '모든 치료 영역 확장',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: '모든 치료 영역 축소',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    '치료 영역(TA)을 기본 TA로 설정하려면 부모 TA를 선택 해제하고 저장합니다.',
  EXTRA_STEP_REQUIRED_TO_LOGIN: '로그인에 추가 단계가 필요합니다',
  EXTRA_STEP_DESCRIPTION:
    '로그인 확인이 귀하의 이메일로 전송되었습니다. 시스템을 계속 사용하려면 해당 단계를 따르세요.',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    '최소 15자 이상의 텍스트나 첨부파일을 입력하세요.',
  REASON_FOR_REJECTION: '거부 이유',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: '메모가 기록에 올바르게 삽입되었습니다.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    '{{ ACTION_TYPE }}에 따라 선택된 {{ METADATA_TYPE }}에 따라 다음과 같은 추가 관련 변경 사항이 적용됩니다.',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    '해당 제품을 제거하면 다음과 같은 추가 관련 변경 사항이 적용됩니다.',
  TEAM_NOTIFY_INQUIRER: '문의자에게 알림',
  TEAM_NOTIFY_INQUIRER_TOOLTIP:
    '이 옵션을 활성화하면 아직 알림을 받지 못한 이 팀에서 진행 중인 공개 상호작용에 대한 문의자에게 GDPR 알림이 자동으로 전송됩니다.',
  TEAM_NOTIFY_SALES: '판매 알림',
  TEAM_NOTIFY_SALES_TOOLTIP:
    '이 옵션을 활성화하면 이 팀에서 영업 역할을 가진 모든 사용자에게 답변 관련 알림이 활성화됩니다.\n\n이 옵션을 비활성화하면 이 팀에서 영업 역할을 가진 모든 사용자에게 답변 관련 알림이 비활성화됩니다. 단, 영업 알림 설정이 활성화된 다른 팀에서 영업 역할을 가진 사용자는 예외입니다.',
  RECEIVES_THE_FOLLOWING_NOTIFICATION: '다음 알림을 받습니다',
  EMAILS: '이메일',
  PHONES: '전화',
  USERS_DEACTIVATED_SUCCESSFULLY: '사용자가 성공적으로 비활성화되었습니다.',
  CONFIRM_BULK_DEACTIVATION_OF_USERS: '사용자 대량 비활성화 확인',
  DEACTIVATE_SELECTED_USERS: '선택한 사용자 비활성화',
  SELECT_USERS_TO_DEACTIVATE: '비활성화할 사용자를 선택하세요',
  ARCHIVE_FOLDER: '보관 폴더',
  EXTRA_PARAMS: "추가 매개변수(k1=v1&k2=v2, '폴더' 및 '아카이브'는 예약되어 사용할 수 없음)",
  POLL_FOLDER: '투표 폴더',
  POLL_FOLDERS_TEXT:
    '폴 폴더는 프로토콜 imap 또는 gmail에 대해서만 설정할 수 있습니다. 다른 모든 프로토콜의 경우 기본값은 받은 편지함입니다.',
  POLL_FOLDERS_TOOLTIP_1:
    '폴더 이름을 입력하고 Enter 키를 누르거나 쉼표를 사용하여 사서함에서 폴링하도록 추가합니다(폴더를 추가하지 않으면 기본적으로 받은 편지함이 폴링됩니다).',
  POLL_FOLDERS_TOOLTIP_2: '여기에 나열된 폴더 이름은 사서함에서 폴링됩니다.',
  PERMISSION: '허가',
  ROLE_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '대화 상자 뒤에 있는 표에서 기존 역할을 확인하세요. 같은 이름의 역할이 이미 있습니다.',
  CURR_MAILBOX: '현재 사서함',
  ENQUIRY_ANSWER_APPROVAL: '문의 답변 승인',
  REASON_FOR_INCLUSION: '포함 이유',
  FOLDERS_TO_POLL_FROM_MAILBOX: '사서함에서 폴링할 폴더',
  VERIFY_SETUP: '설정 확인',
  HTML_PREVIEW: 'HTML 미리보기',
  IN_REPLY_TO_ID: 'id에 대한 답변',
  INCOMING: '들어오는',
  OUTGOING: '나가는',
  VIEW: '보다',
  ROLE_IN_USE_USERS:
    '현재 역할이 사용자에게 할당되어 있으며 이를 보관하면 액세스 권한이 제한될 수 있습니다.',
  ROLE_IN_USE_USER_TEMPLATES:
    '이 역할은 현재 사용자 템플릿에서 사용 중이며 이를 보관하면 해당 템플릿에서 제거됩니다.',
  ROLE_IN_USE_LICENSE_COUNTER:
    '해당 역할은 현재 라이센스 카운터에서 사용 중이며, 보관하면 해당 역할이 제거됩니다.',
  REASON_FOR_REPLACEMENT: '교체 이유',
  QUESTION_TEXT_AFTER_SPLIT: '분할 후 질문 텍스트',
  TEAM_ENQUIRY_ANSWER_APPROVAL_TOOLTIP:
    '문의 답변 승인 워크플로는 팀에서 비활성화됩니다. "승인을 위해 제출됨" 상태의 상호작용은 영향을 받고 "진행 중" 상태로 되돌아가서 승인 없이 정답을 준비하고 응답할 수 있습니다.',
  REVERT_TO_IN_PROGRESS: '진행 중으로 되돌리기',
  SPLIT_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    '질문 분할이 수행되었으며, 상호작용은 {{ SUBMITTED_FOR_APPROVAL }} 상태입니다. 이 작업은 상태를 {{ IN_PROGRESS }}로 되돌려 새로 생성된 질문에 대한 답변 준비를 허용합니다.',
  MERGE_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    '대상 상호작용이 {{ MERGE_AS_ADDITIONAL_QUESTION }} 상태인 동안 현재 상호작용에 {{ SUBMITTED_FOR_APPROVAL }} 작업이 수행되었습니다. 이 작업은 상태를 {{ IN_PROGRESS }}로 되돌려 새로 만든 질문에 대한 답변 준비를 허용합니다.',
  ENQUIRY_ANSWER_APPROVAL_TITLE: '선택한 팀에 대한 문의 답변 승인 설정',
  APPROVAL_ASSIGN_TO: 'Enq. 승인 할당',
  APPROVAL_ASSIGN_BY: 'Enq. 승인 할당',
  APPROVAL_ASSIGNMENTS: 'Enq. 승인 과제',
  APPROVAL_ASSIGNMENTS_TO: 'Enq. 승인 과제',
  APPROVAL_ASSIGNMENTS_BY: 'Enq. 승인 과제',
  My_pending_approvals: '내 보류 중인 승인',
  RULE: '규칙',
  RULES: '규칙',
  FIELD: '필드',
  CRITERIA: '기준',
  NUMBER: '숫자',
  MIN: '최소',
  MAX: '맥스',
  ADD_GROUP: '그룹 추가',
  DELETE_GROUP: '그룹 삭제',
  VALID_FROM_TO: '유효 기간: ~부터/까지',
  NEW_INQ: '새로운 질문',
  NOT_APPROVING_INQUIRIES: '문의를 승인하지 않습니다',
  DOCUMENT_EXTERNAL_URL: '외부 URL을 문서화하십시오',
  QUESTION_CHARACTERISATION: '질문 특성화',
  MAIL_IMPORT_DOMAIN_SETTINGS_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '이미 동일한 값으로 도메인 Regex를 추적 했으므로 대화 상자 뒤에있는 기존 도메인 Regex를 확인하십시오.',
  TEAMS_DOMAINS: '팀 도메인',
  MAIL_IMPORT_DOMAIN_SETTINGS: '메일 가져 오기 도메인 설정',
  MAIL_IMPORT_DOMAINS_SETTINGS: '메일 가져 오기 도메인 설정',
  THE_ACTION_IS_IRREVERSIBLE: '행동은 돌이킬 수 없습니다!',
  USER_DOMAINS: '사용자 도메인',
  SPAM_DOMAINS: '스팸 도메인',
  DOMAIN_REGEX: '도메인 Regex',
  REGEX_PYTHON_INFORMATION: 'Python Supported Regex에 대한 자세한 내용은 클릭하십시오.',
  USER_DOMAINS_TOOLTIP:
    '어떤 도메인 (특정 도메인 또는 키워드로 제출 된 이메일)이 Enquirer 필드를 비워 두는 것을 정의합니다.',
  SPAM_DOMAINS_TOOLTIP:
    '도메인 (특정 도메인 또는 키워드로 제출 된 이메일)이 시스템에 들어가는 상호 작용으로 변환되지 않고 스팸으로 표시되는 메시지를 정의합니다.',
  EMAIL_SUBJECT_TEMPLATE_HINT_1:
    '전자 메일 주제는 전자 메일 주제가 수동으로 편집되지 않고 상호 작용이 수동으로 생성 된 경우 Team {{ TEAM }}에 할당 된 모든 상호 작용에서 볼 수 있습니다.',
  EMAIL_SUBJECT_TEMPLATE_HINT_2:
    '이메일을 통해 ENQMED에 제출 된 상호 작용에서 이메일 주제는 이메일 헤더에서 가져옵니다.',
  CC: 'CC',
  SET_INTERACTION_AS_BEING_PART_OF_AN_EVENT: '상호 작용을 이벤트의 일부로 설정하십시오',
  UPDATED_EVENT: '업데이트 된 이벤트',
  REMOVED_INTERACTION_AS_BEING_PART_OF_AN_EVENT: '이벤트의 일부로 상호 작용을 제거했습니다',
  FORWARD: '앞으로',
  VALID_FROM: '유효합니다',
  VALID_TO: '유효합니다',
  WATCHERS: '감시자',
  WATCH_OPTIONS: '시계 옵션',
  START_WATCHING: '시청을 시작하십시오',
  STOP_WATCHING: '시청을 그만 두십시오',
  UPDATE_WATCHERS: '상호 작용에서 시청자를 업데이트했습니다',
  ACTIVATE_PERMISSION_EDIT: '권한 편집을 활성화합니다',
  DEACTIVATE_PERMISSION_EDIT: '권한 편집을 비활성화합니다',
  SHARE_FROM_HERE_YOU_CAN_:
    '공유 : 여기에서 상호 작용 데이터를 내부/외부 사용자 또는 부서로 전달할 수 있습니다.',
  QUESTIONS_TO_BE_SENT: '전송 될 질문',
  SHOW_QUESTION: '질문 표시',
  HIDE_QUESTION: '질문을 숨 깁니다',
  SHOW_QUESTION_TEXT: '질문 텍스트를 표시하십시오',
  HIDE_QUESTION_TEXT: '질문 텍스트를 숨기십시오',
  SEARCH_RECIPIENTS: '수신자',
  SEARCH_PARTICIPANTS: '검색 참가자',
  SEARCH_USER: '검색 사용자',
  SEARCH_RECIPIENTS_TO_SHARE_THE_ENQUIRY: '수신자가 문의를 공유 할 수 있습니다.',
  SEARCH_USERS_WHICH_WILL_PARTICIPATE_TO_THE_EVENT: '이벤트에 참여할 검색 사용자.',
  SEARCH_USER_WHICH_WILL_APPROVE_THE_STEP: '단계를 승인하는 검색 사용자.',
  RULE_ENQ_ANSWER_APPROVAL_MSG: '문의 답변 승인은 이미 팀에서 활성화되어 있습니다.',
  PERMISSION_EDIT_TOOLTIP: '권한을 편집하려면 클릭 {{name}}',
  ADDITIONAL_REF_ID: '{{ TYPE }} 참조 ID',
  ADDITIONAL_REF_IDS_TITLE: '{{ TYPE }} 참조 ID',
  ADD_ADDITIONAL_REF_IDS_TOOLTIP: '추가 {{ TYPE }} 참조 ID 편집 / 추가 / 삭제',
  ADD_ADDITIONAL_REF_IDS: '{{ TYPE }} 참조 ID를 추가하십시오',
  FWD_TO_DEPT_FAILED: '부서로의 전진이 실패했습니다.',
  RESEND: '재판소',
  GDPR_NOTIF_FAILED: '영수증 알림/GDPR이 실패했습니다.',
  INTERACTIONS: '상호 작용',
  NEW_INTERACTION: '새로운 상호 작용',
  INTERACTIONS_INBOX: '상호 작용받은 편지함',
  REFERENCE_SOURCE: '참조 소스',
  REFERENCE_ID: '참조 ID',
  REFERENCE_TYPE: '참조 유형',
  SAVE_REFERENCE_ID: '참조 ID를 저장하십시오',
  My_open_inquiries: '내 열린 상호 작용',
  My_closed_inquiries: '내 닫힌 상호 작용',
  Filter_inquiries_by: '필터 상호 작용',
  INBOX_NO_FILTERS_HINT:
    '필터가 선택되지 않았습니다. 원하는 상호 작용을 찾을 수 없으면 올바른받은 편지함 섹션에 있는지 확인하십시오.',
  My_teams_open_inq: '우리 팀의 열린 상호 작용',
  My_teams_closed_inq: '우리 팀의 폐쇄 상호 작용',
  All_teams_open_inq: '모든 팀은 상호 작용을 열었습니다',
  All_teams_closed_inq: '모든 팀은 상호 작용을 마감했습니다',
  INTERACTION_REFERENCES: '상호 작용 참조',
  INTERACTION_REFERENCE_ID: '상호 작용 참조 ID',
  INSERT_NEW_REF_SOURCE: '새 참조 소스를 삽입하십시오',
  BACK_TO_AVAILABLE_SOURCE: '사용 가능한 소스로 돌아갑니다',
  CLICK_TO_ENABLE_REF_ID_EDIT: '참조 ID의 편집을 활성화하려면 클릭하십시오',
  DELETE_REF_ID: '참조 ID를 삭제합니다',
  DELETE_REF_ID_CONFIRMATION_QUESTION: '상호 작용 참조 ID를 삭제 하시겠습니까?',
  DUPLICATED_REF_ID_SOURCE_VALUE_WARNING:
    '동일한 값이 이미 존재하는 참조 소스로 기존 참조 소스를 확인하십시오.',
};
