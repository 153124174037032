<app-loading-indicator [empty]="visibleHistory.length === 0"></app-loading-indicator>
<div *ngFor="let history of visibleHistory" class="history-row {{ history.containerClass }}">
  <div *ngIf="!historySkipHistoryInfoRow.includes(history.category)">
    <ng-template #previewInquirerTemplate>
      @if (history.historyDescription?.inquirer?.id) {
      <app-permission-edit [permissionName]="'View Enquirer'" class="d-inline-block">
        <span
          class="medis-clickable text-14-800-dark"
          (click)="previewInquirer(history.historyDescription.inquirer.pk())"
        >
          {{ history.historyDescription.inquirer | displayInfo }}
        </span>
      </app-permission-edit>
      } @else {
      {{ 'INQUIRER' | translate }}
      }
    </ng-template>
    @if(history.category === 'fu_response' && history.createdBy?.username === 'system' &&
    !history.mergeOrDuplicateParam) {
    <ng-container *ngTemplateOutlet="previewInquirerTemplate"></ng-container>
    } @else if (history.createdBy?.id){
    <app-permission-edit [permissionName]="'View Enquiry Edited By'" class="d-inline-block">
      <span class="medis-clickable text-14-800-dark" (click)="previewUser(history.createdBy.pk())">
        {{ history.createdBy | displayInfo }}
      </span>
    </app-permission-edit>
    }
    <span *ngIf="!history.mergeOrDuplicateParam">
      @if (history.category === 'fu_response' && history.createdBy?.username != 'system') {
      {{ 'INSERTED_MANUALLY' | translate }}
      {{ historyLabel(history) | lowercase }}
      {{ 'FROM' | translate }}
      <ng-container *ngTemplateOutlet="previewInquirerTemplate"></ng-container>
      {{ 'VIA' | translate }} {{ history.channel | channelTranslationKey | translate }}
      } @else {
      {{
        history.category === 'create' && !history.createdBy?.id
          ? (historyLabel(history) | translate | titlecase)
          : (historyLabel(history) | translate)
      }}
      <span *ngIf="history.historyDescription?.length">- {{ history.historyDescription }}</span>
      <span *ngIf="history.shouldDisplayChannel">
        {{ 'VIA' | translate }} {{ history.channel | channelTranslationKey | translate }}
      </span>
      }
    </span>
    <ng-container *ngIf="history.mergeOrDuplicateParam">
      <a [href]="resolveInteractionUrl(history.mergeOrDuplicateParam)">
        {{ getMessageLabel(history) | translate | lowercase }} {{ history.mergeOrDuplicateParam }}
      </a>
      <!-- The following lines below will be shown only for interaction merge. -->
      <span *ngFor="let translationKey of resolveMergeReasonTranslationKeys(history)">
        {{ translationKey | translate | lowercase }}
      </span>
    </ng-container>
    <span *ngIf="history.historyDescription?.withReopen">
      {{ 'WITH_REOPEN' | translate }}
    </span>
    <!-- Show the status change. -->
    <span *ngIf="history.historyDescription?.status?.length > 1">
      {{ 'AND_HENCE_STATUS_CHANGED_FROM' | translate }}
      <b>{{ history.historyDescription.status[0] }}</b>
      {{ 'to' | translate }}
      <b>{{ history.historyDescription.status[1] }}</b>
    </span>

    <span *ngIf="history.category === 'add_attachment' && history.text">
      {{ 'IN' | translate }}
      {{ fieldNameMap?.[history.text] || history.text | translate | lowercase}}
    </span>

    {{ 'on' | translate }} {{ history.createdTs | dateChecker }}
  </div>
  <div
    *ngIf="jsonDetailChangeHistoryIds.includes(history.id)"
    class="truncated-text medis-frame-4-azure medis-pa-5"
  >
    <div *ngFor="let data of detailChanges[history.id]">
      <div *ngIf="splitTextRawFields.includes(data.rawField)" class="d-flex flex-column py-2">
        @if (data.rawField !== 'text_to_split') {
        <b>
          {{ data.field | translate }}
          <span *ngIf="loggedInUserIsSysAdmin && data.to && data.to !== 'BLANK'">
            ({{ 'QUESTION_ID' | translate }}: {{ data.to }}) </span
          >:
        </b>
        <div class="disable-truncated-text" [innerHtml]="data.from | sanitizeHtml"></div>
        } @else { @for (text of data.from; track text; let i = $index) {
        <b>
          {{ data.field | translate }}
          @if(loggedInUserIsSysAdmin && data.to?.[i]){
          <span>({{ 'QUESTION_ID' | translate }}: {{ data.to?.[i] }})</span>
          }:
        </b>
        <div class="disable-truncated-text" [innerHtml]="text | sanitizeHtml"></div>
        } }
      </div>
      <span
        *ngIf="
          !freeTextRawFields.includes(data.rawField) &&
          !historySkipRawFields.includes(data.rawField) &&
          !jsonSemiDetailChangeCategories.includes(history.category)
        "
      >
        <b>{{ data.field | translate }}</b
        >: {{ 'From' | translate }} <b>{{ data.from | translate }}</b> {{ 'To' | translate }}
        <b>{{ data.to | translate }}</b>
      </span>

      <span *ngIf="jsonSemiDetailChangeCategories.includes(history.category)">
        @if (history.category !== 'update_watchers') {
        <b>{{ data.field | translate }}</b
        >: @if (data.rawField !== 'Preview message') {
        {{ data.from | translate }}
        } @else {
        <i
          class="fas fa-eye medis-icon ms-0"
          (click)="emailMessagePreview(history.id, data.from)"
          matTooltip="{{ 'VIEW' | translate }} {{ 'MESSAGE' | translate | lowercase }}"
        ></i>
        } } @else {
        <span class="disable-truncated-text">
          <b>{{ data.field | translate }}</b
          >:
          <span *ngFor="let user of data.from; let i = index; last as isLast">
            <b
              ><span class="medis-clickable" (click)="previewUser(data.to[i])">{{ user }}</span></b
            ><span *ngIf="!isLast">, </span>
          </span>
        </span>
        }
      </span>

      <span *ngIf="referencesRawFields.includes(data.rawField)">
        @if ((data.rawField === 'reference_question_id' && loggedInUserIsSysAdmin) || data.rawField
        !== 'reference_question_id') {
        <b>{{ data.field | translate }}</b
        >: @if (data.from === data.to) {
        <b>{{ data.from }}</b>
        } @else {
        {{ 'From' | translate }} <b>{{ data.from | translate }}</b> {{ 'To' | translate }}
        <b>{{ data.to | translate }}</b>
        } }
      </span>

      <span *ngIf="data.rawField === 'due_date'">
        <b>{{ data.field | translate }}</b
        >:
        {{ 'From' | translate }}
        <b> {{ data.from === 'BLANK' ? (data.from | translate) : (data.from | dateChecker) }}</b>
        {{ 'To' | translate }}
        <b> {{ (data.to.includes(',') ? data.to.split(',')[0] : data.to) | dateChecker }}</b>
        <ng-container *ngIf="data.to.includes(',')">
          - {{ data.to.split(',')[1] | translate }}
        </ng-container>
      </span>

      <span *ngIf="data.rawField === 'notification'">
        {{ data.from | translate }}
        @if (data.to && data.to != 'BLANK') { @if (isObject(data.to)) {
        <span
          >to department <b>{{ data.to['department_name'] }}</b> at
          <b>{{ data.to['department_email'] }} </b></span
        >
        <i
          class="fas fa-eye medis-icon ms-0"
          (click)="emailMessagePreview(history.id, data.to['message_id'])"
          matTooltip="{{ 'VIEW' | translate }} {{ 'MESSAGE' | translate | lowercase }}"
        ></i>
        } @else { <span>: {{ data.to | translate }}</span
        >} }
      </span>

      <span
        *ngIf="
          (loggedInUserIsSysAdmin && ['question_id', 'question_ids'].includes(data.rawField)) ||
          discRawFields.includes(data.rawField)
        "
        class="disable-truncated-text"
      >
        <b>{{ data.field | translate }}</b
        >: {{ data.from }}<span *ngIf="data.to?.trim() !== 'BLANK'">, {{ data.to }}</span>
      </span>

      <span *ngIf="data.rawField === 'reason_for_update'" class="disable-truncated-text">
        <b>{{ data.field | translate }}</b
        >: {{ data.from }}
        <span *ngIf="data.to.trim() !== 'BLANK'">{{ data.to | dateChecker }}</span>
      </span>
      <span *ngIf="data.rawField === 'reason_for_update_accessory'">
        {{ data.from }}
      </span>

      <span
        *ngIf="freeTextRawFields.includes(data.rawField) && data.rawField !== 'gdpr_notification'"
      >
        <b>{{ data.field | translate }}</b
        >:
        <div class="medis-flex-container-between">
          {{ 'From' | translate }}
          <mat-divider></mat-divider>
        </div>
        <div class="text-max-height" [innerHtml]="data.from | sanitizeHtml"></div>
        <div class="medis-flex-container-between">
          {{ 'To' | translate }}
          <mat-divider></mat-divider>
        </div>
        <div class="text-max-height" [innerHtml]="data.to | sanitizeHtml"></div>
      </span>
      <span *ngIf="data.rawField === 'inquirer'">
        <b>{{ data.field | translate }}</b
        >: {{ 'From' | translate }}
        <b *ngIf="!data.from.id" class="text-14-800-dark">{{ 'BLANK' | translate }}</b>
        <b
          *ngIf="data.from.id"
          class="medis-clickable text-14-800-dark"
          (click)="previewInquirer(data.from.pk())"
          >{{ data.from | displayInfo }}</b
        >
        {{ 'To' | translate }}
        <b class="medis-clickable text-14-800-dark" (click)="previewInquirer(data.to.pk())">{{
          data.to | displayInfo
        }}</b>
      </span>
      <span *ngIf="data.rawField === 'inquirer_details'">
        <mat-divider></mat-divider>
        <div>
          <span [innerHtml]="data.from | sanitizeHtml"></span>
          <b class="medis-clickable text-14-800-dark" (click)="previewInquirer(data.to.pk())">{{
            data.to | displayInfo
          }}</b>
        </div>
      </span>
      <span>
        <div
          *ngIf="
            data.rawField === 'team_update_details' ||
            data.rawField === 'assignment_removal_on_team_update_details'
          "
        >
          <span>
            <b>{{ 'COUNTRY' | translate }}</b
            >: {{ 'From' | translate }}
            <b>{{ data.from.split(',')[0] }}</b>
            {{ 'To' | translate }}
            <b>{{ data.from.split(',')[1] }}</b>
          </span>
        </div>
        <div
          *ngIf="
            data.rawField === 'assignment_removal_on_team_update_details' ||
            data.rawField === 'assignment_removal_on_role_archive_details'
          "
        >
          <span>
            <b>{{ 'PERSONAL_ASSIGNMENT' | translate }}</b
            >:
            {{ 'From' | translate }}
            <b>{{ data.from.split(',')[2] }}</b>
            {{ 'To' | translate }}
            <b>{{ data.from.split(',')[3] }}</b>
          </span>
        </div>
        <div *ngIf="data.rawField === 'assignment_removal_on_role_archive_details'">
          <span>
            <b>{{ 'ARCHIVED_ROLE' | translate }}</b
            >:
            <b>{{ data.from.split(',')[0] }}</b>
            {{ 'IN' | translate }} {{ 'TEAM' | translate | lowercase }}
            <b>{{ data.from.split(',')[1] }}</b>
          </span>
        </div>
      </span>
      <span *ngIf="data.rawField === 'gdpr_notification'">
        <b>{{ data.field | translate }}</b>
        <mat-divider></mat-divider>
        <div
          class="text-max-height mt-2 white-space-normal"
          [innerHtml]="data.from | sanitizeHtml"
        ></div>
      </span>
      <span *ngIf="['merge_as_inquiry', 'merge_as_new_inquiry'].includes(data.rawField)">
        <ng-container *ngFor="let fromObj of getFromData(data)">
          <ng-template #templateMergedPreviouslyFrom>
            <ng-container *ngIf="fromObj.mergedFromId">
              ({{ 'MERGED_PREVIOUSLY_FROM' | translate }}
              <a [href]="resolveInteractionUrl(fromObj.mergedFromId)" class="clickable">{{
                fromObj.mergedFromId
              }}</a
              >)
            </ng-container>
          </ng-template>
          <ng-template #templateFromInteractionId>
            {{ 'FROM_INTERACTION_ID' | translate }}
            {{
              history.category === 'closed_as_merge'
                ? history.interactionId
                : history.mergeOrDuplicateParam
            }}
          </ng-template>
          <ng-template #templateInInteractionId>
            {{ 'IN_INTERACTION_ID' | translate }}
            {{
              history.category === 'closed_as_merge'
                ? history.mergeOrDuplicateParam
                : history.interactionId
            }}
          </ng-template>
          <ng-container *ngIf="loggedInUserIsSysAdmin">
            {{ 'COLUMN_QUESTION_ID' | translate }} {{ fromObj.id }}
            <ng-container *ngTemplateOutlet="templateFromInteractionId"></ng-container>
            <ng-container *ngTemplateOutlet="templateMergedPreviouslyFrom"></ng-container>
            {{ 'VIA' | translate }} {{ fromObj.channel | translate | lowercase }}
            <span *ngIf="data.rawField === 'merge_as_inquiry'">
              {{ 'MERGED_INTO_QUESTION_IDS' | translate }} {{ data.to }}
              <ng-container *ngTemplateOutlet="templateInInteractionId"></ng-container>
            </span>
          </ng-container>
          <ng-container *ngIf="!loggedInUserIsSysAdmin">
            {{ 'QUESTION' | translate }}
            <ng-container *ngTemplateOutlet="templateFromInteractionId"></ng-container>
            <ng-container *ngTemplateOutlet="templateMergedPreviouslyFrom"></ng-container>
            {{ 'VIA' | translate }} {{ fromObj.channel | translate | lowercase }}
            <span *ngIf="data.rawField === 'merge_as_inquiry'">
              {{ 'MERGED_INTO_QUESTIONS' | translate }}
              <ng-container *ngTemplateOutlet="templateInInteractionId"></ng-container>
            </span>
          </ng-container>
          :
          <div class="text-max-height" [innerHtml]="fromObj.text | sanitizeHtml"></div>
        </ng-container>
      </span>
    </div>
  </div>
  <div
    *ngIf="history.category === 'create' && history.channel === 'email'"
    class="medis-frame-4-azure medis-pa-5 medis-flex-grow"
  >
    {{ 'From' | translate }} <b>{{ interaction.mailFrom }}</b> {{ 'To' | translate }}
    <b>{{ interaction.mailTo }}</b>
  </div>
  <div
    *ngIf="history.category === 'personal_assignment'"
    class="medis-frame-4-azure medis-pa-5 medis-flex-grow"
  >
    {{ 'From' | translate }}
    <span *ngIf="!history.reassignedFrom" class="text-14-800-dark">
      {{ 'UNASSIGNED' | translate }}
    </span>
    <span
      *ngIf="history.reassignedFrom"
      class="medis-clickable text-14-800-dark"
      (click)="previewUser(history.reassignedFrom.pk())"
    >
      {{ history.reassignedFrom | fullName }}
    </span>
    {{ 'To' | translate }}
    <span *ngIf="!history.reassignedTo" class="text-14-800-dark">
      {{ 'UNASSIGNED' | translate }}</span
    >
    <span
      *ngIf="history.reassignedTo"
      class="medis-clickable text-14-800-dark"
      (click)="previewUser(history.reassignedTo.pk())"
    >
      {{ history.reassignedTo | fullName }}
    </span>
    <span *ngIf="history.text"> - {{ history.text }}</span>
  </div>
  <div
    *ngIf="
      !jsonDetailChangeHistoryIds.includes(history.id) &&
      !skipCategoriesForTextContainer.includes(history.category) &&
      history.text
    "
    class="medis-flex-container-even"
  >
    <div class="medis-frame-4-azure medis-pa-5 medis-flex-grow">
      @if (categoriesWithTranslatableText.includes(history.category)) {
      <div class="text-max-height">{{ history.text | translate }}</div>
      } @else {
      <div class="text-max-height" [innerHtml]="history.text | sanitizeHtml"></div>
      }
    </div>
    <i
      *ngIf="
        (history.category === 'other' || history.category === 'reject_answer_note') &&
        !interaction.isClosedOrInReview() &&
        !disabled
      "
      class="far fa-copy medis-icon"
      matTooltip="{{ 'COPY_TEXT_TO_ANSWER' | translate }}"
      (click)="copyTextToAnswer(history)"
    ></i>
  </div>
  <div *ngFor="let link of history.linkedFiles">
    <app-link-visualization
      [link]="link"
      [allowRemove]="false"
      [canOpenFile]="loggedInUserIsSysAdmin || !link.isDeleted"
    ></app-link-visualization>
    <i
      *ngIf="
        !link.isDeleted &&
        history.category === 'other' &&
        !interaction.isClosedOrInReview() &&
        !disabled
      "
      class="far fa-copy medis-icon"
      matTooltip="{{ 'COPY_ATTACHMENT_TO_ANSWER' | translate }}"
      (click)="copyToAnswer(link)"
    ></i>
  </div>
</div>
