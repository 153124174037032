<h2 mat-dialog-title>
  <span *ngIf="view == 'email'">{{ 'SEARCH_RECIPIENTS' | translate | uppercase }}</span>
  <span *ngIf="view == 'event'">{{ 'SEARCH_PARTICIPANTS' | translate | uppercase }}</span>
  <span *ngIf="view == 'create-document'">{{ 'SEARCH_USER' | translate | uppercase }}</span>
  <i class="far fa-times-circle medis-icon-large" (click)="cancel()"></i>
</h2>
<mat-dialog-content>
  <div class="row g-0">
    <div class="col-auto side-nav">
      <!-- SIDE NAV-->
      <div *ngIf="view == 'email'">{{ 'SEARCH_RECIPIENTS_TO_SHARE_THE_ENQUIRY' | translate }}</div>
      <div *ngIf="view == 'event'">
        {{ 'SEARCH_USERS_WHICH_WILL_PARTICIPATE_TO_THE_EVENT' | translate }}
      </div>
      <div *ngIf="view == 'create-document'">
        {{ 'SEARCH_USER_WHICH_WILL_APPROVE_THE_STEP' | translate }}
      </div>
      <div class="filters-container">
        <div class="description">Filter results by</div>
        <div *ngIf="isOptionAvailable('user')">
          <mat-checkbox [(ngModel)]="searchTypes['user']" (ngModelChange)="refreshSearch()">
            Employee
          </mat-checkbox>
          <div *ngIf="searchTypes['user']" class="role-container">
            <mat-form-field class="form-field-wrapper">
              <mat-select
                placeholder="Role"
                [(ngModel)]="searchFilters['role']"
                (ngModelChange)="refreshSearch()"
              >
                <ng-container *ngIf="allowedRoles">
                  <mat-option *ngFor="let role of allowedRoles" [value]="role.pk()">
                    {{ role.description }}
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="!allowedRoles">
                  <mat-option *ngFor="let role of roles" [value]="role.pk()">
                    {{ role.description }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="isOptionAvailable('callcenter')">
          <mat-checkbox [(ngModel)]="searchTypes['callcenter']" (ngModelChange)="refreshSearch()">
            Callcenter
          </mat-checkbox>
        </div>
        <div *ngIf="isOptionAvailable('department')">
          <mat-checkbox [(ngModel)]="searchTypes['department']" (ngModelChange)="refreshSearch()">
            Department
          </mat-checkbox>
        </div>
        <div class="description">Refine by</div>
        <div>
          <mat-form-field class="form-field-wrapper">
            <mat-select
              placeholder="Team"
              [(ngModel)]="searchFilters['team']"
              (ngModelChange)="refreshSearch()"
            >
              <ng-container *ngIf="allowedTeams">
                <mat-option *ngFor="let team of allowedTeams" [value]="team.pk()">
                  {{ team.name }}
                </mat-option>
              </ng-container>
              <ng-container *ngIf="!allowedTeams">
                <mat-option *ngFor="let team of teams" [value]="team.pk()">
                  {{ team.name }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="form-field-wrapper">
            <mat-select
              placeholder="Country"
              [(ngModel)]="searchFilters['country']"
              (ngModelChange)="refreshSearch()"
            >
              <mat-option *ngFor="let country of countries" [value]="country.pk()">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="centered">
          <button mat-button class="medis-button-reject" (click)="resetFilters()">
            RESET FILTERS
          </button>
        </div>
      </div>
    </div>
    <div class="col">
      <!-- RESULTS -->
      <div class="row">
        <div class="col">
          <mat-form-field class="mat-full-width">
            <input
              matInput
              placeholder="Search"
              [(ngModel)]="textSearch"
              type="search"
              [formControl]="term"
              [matChipInputFor]="chipList"
            />
            <mat-chip-grid #chipList>
              <app-entity-chip
                *ngFor="let entity of selectedEntities"
                [entity]="entity"
                (removed)="unselect(entity)"
              ></app-entity-chip>
            </mat-chip-grid>
          </mat-form-field>
        </div>
      </div>

      <mat-table #table [dataSource]="dataSource" class="medis-table simple-header">
        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="search-title truncated-text {{ getClassForRow(element) }}">
              {{ element.value }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>{{ element.email }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="team">
          <mat-header-cell *matHeaderCellDef> Team </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>{{ element.team }}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ selected: isAlreadySelected(row) }"
          class="cursor-pointer"
          (click)="loadDataRow(row)"
        ></mat-row>
      </mat-table>
      <button
        *ngIf="!hideLoadMoreButton"
        mat-button
        class="medis-button-basic load-more-button"
        (click)="loadMore()"
      >
        {{ 'LOAD_MORE' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button class="medis-button-main" *ngIf="allowedMulti" (click)="saveMulti()">
    Add Recipients
  </button>
  <button mat-button class="medis-button-reject" (click)="cancel()">Cancel</button>
</mat-dialog-actions>
