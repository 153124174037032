import { gql } from 'apollo-angular';

import { MAlignment, MAuthRequest, MTeam, MUser, MUserNotification } from './user';
import * as MLanguage from '../language/language';
import * as MEmail from '../email/email';
import * as MPhone from '../phone/phone';
import * as MAddress from '../address/address';
import * as MCountry from '../country/country';
import * as MRole from '../role/role';
import * as MGeography from '../geography/geography';
import * as MAffiliation from './affiliation.namespace';

export const mutateUser = gql`
  mutation updateUser($params: UpdateUserInput!) {
    updateUser(input: $params) {
      user {
        ...userFragment
        language {
          ...languageFragment
        }
        emails {
          ...emailConnectionFragment
        }
        phones {
          ...phoneConnectionFragment
        }
        addresses {
          edges {
            node {
              ...addressFragment
              country {
                ...countryFragment
              }
            }
          }
        }
      }
    }
  }
  ${MUser.fragment}
  ${MEmail.fragmentConnection}
  ${MPhone.fragmentConnection}
  ${MAddress.fragment}
  ${MCountry.fragment}
  ${MLanguage.fragment}
`;

export const basicSingleUserQuery = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      ...userFragment
      language {
        ...languageFragment
      }
      mainPhone {
        ...phoneFragment
      }
      mainAddress {
        ...addressFragment
        country {
          ...countryFragment
        }
      }
      affiliations {
        edges {
          node {
            id
            role {
              ...roleFragment
            }
            team {
              ...teamFragment
            }
          }
        }
      }
    }
  }
  ${MUser.fragment}
  ${MLanguage.fragment}
  ${MAddress.fragment}
  ${MPhone.fragment}
  ${MCountry.fragment}
  ${MRole.fragment}
  ${MTeam.fragment}
`;

export const singleUserQuery = gql`
  query singleUser($id: ID!) {
    user(id: $id) {
      ...userFragment
      language {
        ...languageFragment
      }
      emails {
        ...emailConnectionFragment
      }
      phones {
        ...phoneConnectionFragment
      }
      addresses {
        edges {
          node {
            ...addressFragment
            country {
              ...countryFragment
            }
          }
        }
      }
      alignments {
        edges {
          node {
            ...alignmentFragment
            geographies {
              edges {
                node {
                  ...geographyFragment
                }
              }
            }
          }
        }
      }
      authRequests {
        edges {
          node {
            ...authRequestFragment
          }
        }
      }
      affiliations {
        edges {
          node {
            ...affiliationFragment
          }
        }
      }
      autoAssignTo {
        id
        firstName
        lastName
      }
      autoAssignedBy {
        edges {
          node {
            id
            firstName
            lastName
            emails {
              ...emailConnectionFragment
            }
          }
        }
      }
      deputy {
        id
        firstName
        lastName
      }
      activeNotifications {
        edges {
          node {
            ...userNotificationFragment
          }
        }
      }
    }
  }
  ${MUser.fragment}
  ${MEmail.fragmentConnection}
  ${MPhone.fragmentConnection}
  ${MAddress.fragment}
  ${MCountry.fragment}
  ${MRole.fragment}
  ${MAffiliation.fragmentConnection}
  ${MAuthRequest.fragment}
  ${MTeam.fragment}
  ${MLanguage.fragment}
  ${MUserNotification.fragment}
  ${MAlignment.fragment}
  ${MGeography.fragment}
`;

export const mutateUserNotifications = gql`
  mutation updateUserNotifications($params: UpdateUserNotificationsInput!) {
    updateUserNotifications(input: $params) {
      user {
        id
        activeNotifications {
          edges {
            node {
              ...userNotificationFragment
            }
          }
        }
      }
    }
  }
  ${MUserNotification.fragment}
`;

export const updateMultipleUsers = gql`
  mutation updateMultipleUsers($params: UpdateMultipleUsersInput!) {
    updateMultipleUsers(input: $params) {
      success
    }
  }
`;
