import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  basicSingleUserQuery,
  mutateUser,
  mutateUserNotifications,
  singleUserQuery,
  updateMultipleUsers,
} from './queries';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { parseAttr, pkToBase64 } from '../data-model';
import { createGuid } from 'app/common/uuid-generator';
import { BUser } from './user';

@Injectable()
export class UserEditService {
  response: Subject<BUser> = new Subject<BUser>();
  responseMutateMultipleUsers: Subject<boolean> = new Subject<boolean>();

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {}

  editUser(toMutate: Object, completionListener = (changedUser: BUser) => {}) {
    this.mutateWithQuery(toMutate, mutateUser).subscribe({
      next: (response) => {
        if (response) {
          this.messageService.info('User updated correctly.');
          let userResponseData = response.data['updateUser'];
          let changedUser = parseAttr<BUser>(userResponseData, BUser, 'user');
          this.response.next(changedUser);
          completionListener(changedUser);
        } else {
          this.response.next(undefined);
          this.messageService.error('User Edit: empty response');
        }
      },
      error: (error) => {
        if (
          error.message &&
          error.message.includes('Duplicate') &&
          error.message.includes("for key 'username'")
        ) {
          this.messageService.error(
            'The specified e-mail is already used as login credential from another user'
          );
        } else {
          this.messageService.error('(User Edit) ' + error);
        }
      },
    });
  }

  updateMultipleUsers(toMutate: Object) {
    this.mutateWithQuery(toMutate, updateMultipleUsers).subscribe({
      next: (response) => {
        if (response && response.data) {
          this.messageService.info('Users updated correctly.');
          this.responseMutateMultipleUsers.next(true);
        } else {
          this.responseMutateMultipleUsers.next(false);
          this.messageService.error('Users Edit: empty response');
        }
      },
      error: (error) => {
        this.responseMutateMultipleUsers.next(false);
        this.messageService.error('(Users Edit) ' + error);
      },
    });
  }

  private mutateWithQuery(toMutate: Object, query: any) {
    const mutationData = Object.assign({}, toMutate);
    mutationData['clientMutationId'] = createGuid();
    return this.apollo.mutate({
      mutation: query,
      variables: { params: mutationData },
    });
  }
}

@Injectable()
export class UserEditNotificationsService {
  user: Subject<Object> = new Subject<Object>();
  response: Subject<BUser> = new Subject<BUser>();

  constructor(apollo: Apollo, private messageService: MessageHandlerService) {
    this.user.subscribe((toMutate) => {
      var mutationData = {};
      mutationData = Object.assign({}, toMutate);
      mutationData['clientMutationId'] = createGuid();
      let mutation = apollo.mutate({
        mutation: mutateUserNotifications,
        variables: { params: mutationData },
      });
      mutation.subscribe({
        next: (response) => {
          if (response) {
            this.messageService.info('User notifications updated correctly.');
            let userResponseData = response.data['updateUserNotifications'];
            let changedUser = parseAttr<BUser>(userResponseData, BUser, 'user');
            this.response.next(changedUser);
          } else {
            this.response.next(undefined);
            this.messageService.error('User Notifications New Edit: empty response');
          }
        },
        error: (error) => {
          this.messageService.error('(User Notifications New Edit) ' + error);
        },
      });
    });
  }
}

@Injectable({ providedIn: 'root' })
export class SingleUserService {
  readonly user = new Subject<BUser>();
  readonly basicUser = new Subject<BUser>();

  constructor(private apollo: Apollo) {}

  performQueryWithID(id: number) {
    let pk = pkToBase64('UserNode', id);
    this.performQuery(pk);
  }

  performQuery(pk: string) {
    this.apollo
      .query({
        query: singleUserQuery,
        variables: { id: pk },
        fetchPolicy: 'network-only',
      })
      .subscribe((response) => {
        this.user.next(new BUser(response.data['user']));
      });
  }

  performBasicQuery(id: number): void {
    this.apollo
      .query({
        query: basicSingleUserQuery,
        variables: { id: pkToBase64('UserNode', id), 'X-NO-LOADING': true },
      })
      .subscribe((res) => {
        if (res.data) {
          const user = parseAttr<BUser>(res.data, BUser, 'user');
          this.basicUser.next(user);
        } else {
          console.log('Response with no data!');
        }
      });
  }
}
