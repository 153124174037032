<mat-card appearance="outlined" class="more-info-container {{ position }}">
  <div class="card">
    <user-inquirer-card
      *ngIf="inquirer || user || department"
      [user]="user"
      [inquirer]="inquirer"
      [department]="department"
      [minimizedVisualization]="true"
    ></user-inquirer-card>
    <div *ngIf="isLoading">
      <h5><i class="fa fa-spinner fa-spin"></i> {{ 'LOADING' | translate }}...</h5>
    </div>
  </div>
  <div *ngIf="entityIdentifier && user" class="entity-identifier-container user">
    {{ 'User' | translate }}
  </div>
  <div *ngIf="entityIdentifier && inquirer" class="entity-identifier-container inquirer">
    {{ 'INQUIRER' | translate }}
  </div>
  <div *ngIf="entityIdentifier && department" class="entity-identifier-container department">
    {{ 'DEPARTMENT' | translate }}
  </div>
</mat-card>
