import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { parseAttr } from '../data-model';
import { mutateHistoryQuery } from './queries';
import { createGuid } from 'app/common/uuid-generator';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { BHistory } from 'app/modules/data-model/interaction/interaction';

@Injectable({ providedIn: 'root' })
export class HistoryEditService {
  response: Subject<BHistory> = new Subject<BHistory>();
  // to customize success message after response
  successMessage: string;

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {}

  saveHistoryWithData(toMutate: any, background?: boolean) {
    if (background == undefined) {
      background = false;
    }
    const mutationData = Object.assign({}, toMutate);
    mutationData['clientMutationId'] = createGuid();
    this.apollo
      .mutate({
        mutation: mutateHistoryQuery,
        variables: { params: mutationData, 'X-NO-LOADING': background },
      })
      .subscribe({
        next: (response) => {
          if (response) {
            let historyResponseData = response.data['updateHistory'];
            let field = 'history';
            if (!historyResponseData['history']) {
              // update of a new interaction object
              field = 'newHistory';
            }
            let changedHistoryObject = parseAttr<BHistory>(historyResponseData, BHistory, field);
            this.response.next(changedHistoryObject);
            if (!background) {
              if (this.successMessage) {
                this.messageService.info(this.successMessage);
                this.successMessage = undefined;
              } else {
                this.messageService.info('Message saved.');
              }
            }
          } else {
            this.response.next(undefined);
            this.messageService.error('History Edit: empty response');
          }
        },
        error: (error) => {
          this.messageService.error('(History Edit) ' + error);
        },
      });
  }
}
