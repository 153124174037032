<h2 mat-dialog-title>
  {{
    (rule.id ? 'EDIT_ITEM' : 'CREATE_NEW_ITEM')
      | translate: { ITEM: 'RULE' | translate | lowercase }
  }}
  <i class="far fa-times-circle medis-icon-large" mat-dialog-close></i>
</h2>

<mat-dialog-content>
  <div class="medis-flex-container-start gap-3 mb-3">
    <mat-form-field (click)="$event.stopPropagation()" class="form-field-wrapper fs-10 m-0">
      <mat-label>{{ 'NAME' | translate }}</mat-label>
      <input matInput type="text" [(ngModel)]="rule.name" (ngModelChange)="validateRule()" />
    </mat-form-field>

    <app-search-select
      [disabled]="!!preselectTypeId"
      [config]="typeConfig"
      [hideResetIcon]="true"
      [required]="true"
      [(selectedValue)]="rule.typeId"
      (selectedValueChange)="validateRule()"
      [style]="'fs-10 m-0'"
    ></app-search-select>

    <app-date-time-picker
      [timeZone]="'UTC'"
      [minDate]="'today'"
      [enableReset]="true"
      [value]="rule.validFrom && rule.validTo && { from: rule.validFrom, to: rule.validTo }"
      [label]="'VALID_FROM_TO'"
      [style]="'fs-10 m-0'"
      (valueChange)="onDateRangeChange($event)"
    ></app-date-time-picker>

    <mat-slide-toggle [(ngModel)]="rule.isActive" (ngModelChange)="validateRule()">
      {{ (rule.isActive ? 'Active' : 'INACTIVE') | translate }}
    </mat-slide-toggle>
  </div>

  @for (group of rule.groups; track group; let groupIndex = $index, isLastGroup = $last) {
  <div class="group medis-frame-4-azure p-4" [ngClass]="isLastGroup ? 'mb-5' : 'mb-3'">
    @for (cond of group.conds; track cond; let condIndex = $index, isLastCond = $last) {
    <div class="cond" [class.pb-3]="!isLastCond">
      <app-search-select
        [style]="'m-0'"
        [config]="fieldConfig"
        [hideResetIcon]="true"
        [(selectedValue)]="cond.field"
        (selectedValueChange)="onFieldChange(cond)"
      ></app-search-select>

      <app-search-select
        [style]="'m-0'"
        [config]="fieldToCriteriaConfig[cond.field]"
        [hideResetIcon]="true"
        [(selectedValue)]="cond.criteria"
        (selectedValueChange)="validateRule()"
      ></app-search-select>

      @switch (fieldToType[cond.field]) { @case (fType.SC) {
      <app-search-select
        [multiple]="false"
        [required]="true"
        [config]="fieldToDropdownConfig[cond.field]"
        [selectedValue]="cond.values[0]"
        (selectedValueChange)="cond.values = [$event]; validateRule()"
        [style]="'m-0'"
      ></app-search-select>
      } @case (fType.MC) {
      <app-search-select
        [multiple]="true"
        [required]="true"
        [config]="fieldToDropdownConfig[cond.field]"
        [(selectedValue)]="cond.values"
        (selectedValueChange)="validateRule()"
        [style]="'m-0'"
      ></app-search-select>
      } @case (fType.DATE) {
      <app-date-time-picker
        [timeZone]="'UTC'"
        [enableReset]="true"
        [required]="true"
        [value]="cond.values[0] && { from: cond.values[0]['from'], to: cond.values[0]['to'] }"
        [label]="'Date'"
        [style]="'fs-10 m-0'"
        (valueChange)="cond.values[0] = $event; validateRule()"
      ></app-date-time-picker>
      } @case (fType.TEXT) {
      <mat-form-field class="form-field-wrapper">
        <input matInput [(ngModel)]="cond.values[0]" (ngModelChange)="validateRule()" />
      </mat-form-field>
      } @default {
      <div [class.number-fields]="fieldToType[cond.field] == fType.NUMBER_RANGE">
        <mat-form-field
          class="form-field-wrapper"
          [ngClass]="{ 'w-100': fieldToType[cond.field] == fType.NUMBER }"
        >
          <mat-label>
            {{ (fieldToType[cond.field] == fType.NUMBER ? 'NUMBER' : 'MIN') | translate }}
          </mat-label>
          <input
            matInput
            [(ngModel)]="cond.values[0]"
            type="number"
            numbersOnly
            (ngModelChange)="validateRule()"
          />
        </mat-form-field>
        @if (fieldToType[cond.field] == fType.NUMBER_RANGE) {
        <mat-form-field class="form-field-wrapper">
          <mat-label>{{ 'MAX' | translate }}</mat-label>
          <input
            matInput
            [(ngModel)]="cond.values[1]"
            type="number"
            numbersOnly
            (ngModelChange)="validateRule()"
          />
        </mat-form-field>
        }
      </div>
      } } @if (isLastCond) {
      <button
        mat-button
        class="medis-button-main compact"
        (click)="addCond(groupIndex)"
        matTooltip="{{ 'add' | translate | titlecase }} {{ 'CONDITION' | translate | lowercase }}"
      >
        <i class="fa fa-plus pe-1"></i>
        {{ 'add' | translate | titlecase }}
      </button>
      } @else {
      <app-multi-toggle
        [choices]="operatorOptions"
        [(value)]="cond.operator"
        (valueChange)="validateRule()"
      ></app-multi-toggle>
      }

      <i
        [class.disabled]="rule.groups.length === 1 && group.conds.length === 1"
        class="fa fa-trash medis-icon-reject m-0"
        (click)="
          !(rule.groups.length === 1 && group.conds.length === 1) &&
            removeCond(groupIndex, condIndex)
        "
        matTooltip="{{ 'DELETE' | translate | titlecase }} {{
          'CONDITION' | translate | lowercase
        }}"
      ></i>
    </div>
    }

    <i
      [class.disabled]="rule.groups.length === 1"
      matTooltip="{{ 'DELETE_GROUP' | translate }}"
      (click)="rule.groups.length != 1 && removeGroup(groupIndex)"
      class="fa fa-trash-alt medis-icon-reject m-0 delete-group-icon"
    ></i>

    @if (isLastGroup) {
    <button
      mat-button
      class="medis-button-main compact add-group-btn"
      (click)="addGroup()"
      matTooltip="{{ 'ADD_GROUP' | translate }}"
    >
      <i class="fa fa-plus pe-1"></i>
      {{ 'add' | translate | titlecase }}
    </button>
    }@else {
    <app-multi-toggle
      class="group-operator"
      [choices]="operatorOptions"
      [(value)]="group.operator"
      [style]="'bg-white'"
      (valueChange)="validateRule()"
    ></app-multi-toggle>
    }
  </div>
  }
  <mat-error *ngIf="noChanges">{{ 'THERE_ARE_NO_CHANGES' | translate }}</mat-error>
  <mat-error *ngIf="selectedTeamsWithApproval">
    {{ 'RULE_ENQ_ANSWER_APPROVAL_MSG' | translate }}: {{ selectedTeamsWithApproval }}
  </mat-error>
</mat-dialog-content>

<mat-dialog-actions class="responsive-actions">
  <button
    mat-button
    class="medis-button-main"
    [disabled]="isRuleInvalid || noChanges"
    (click)="rule.id ? edit() : create()"
  >
    {{ 'SAVE' | translate }}
  </button>
  <button mat-button mat-dialog-close class="medis-button-reject">
    {{ 'CANCEL' | translate }}
  </button>
</mat-dialog-actions>
