import { PermissionHandler } from 'app/auth/auth.service';
import { filter, group, MedisNavigationItem, newItem, urlItem } from '../types';
import {
  PERM_APPROVE_ENQUIRIES,
  PERM_VIEW_ENQUIRY_DRAFTS,
} from 'app/common/common/constants/permissions';

export function inquiriesNavigation(permissions: PermissionHandler): MedisNavigationItem[] {
  const navigation: MedisNavigationItem[] = [
    group('Filter_inquiries_by', 'Filter_inquiries_by', 'fa-filter', [
      urlItem('Assigned_to_me', 'Assigned_to_me', 'fa-user-circle', '/inq/assigned-to-me'),
      urlItem(
        'My_pending_approvals',
        'My_pending_approvals',
        'fa-envelope-open-text',
        '/inq/my-pending-approvals',
        [PERM_APPROVE_ENQUIRIES]
      ),
      urlItem('My_drafts', 'My_drafts', 'fa-pencil-ruler', '/drafts', [PERM_VIEW_ENQUIRY_DRAFTS]),
      urlItem('My_open_inquiries', 'My_open_inquiries', 'fa-reply', '/inq/my-open-inquiries'),
      urlItem('My_closed_inquiries', 'My_closed_inquiries', 'fa-reply', '/inq/my-closed-inquiries'),
    ]),
    group('My_team', '', '', [
      newItem('My_teams_all_inq', 'My_teams_all_inq', '', '/inq/all-inq-for-my-team', 'my_team'),
      urlItem(
        'My_teams_open_inq',
        'My_teams_open_inq',
        'fa-user-friends',
        '/inq/my-teams-open-inq'
      ),
      urlItem('My_teams_closed_inq', 'My_teams_closed_inq', 'fa-reply', '/inq/my-teams-closed-inq'),
      newItem('Pending_approval', 'Pending_approval', 'fa-hourglass', '/inq/pending-approval'),
      urlItem('Spam_list', 'Spam_list', 'fa-ban', '/spam/list'),
    ]),
    group('All_teams', '', '', [
      newItem(
        'All_teams_all_inq',
        'All_teams_all_inq',
        '',
        '/inq/all-inq-for-all-team',
        'all_teams'
      ),
      urlItem(
        'All_teams_open_inq',
        'All_teams_open_inq',
        'fa-globe-americas',
        '/inq/all-teams-open-inq'
      ),
      urlItem(
        'All_teams_closed_inq',
        'All_teams_closed_inq',
        'fa-reply',
        '/inq/all-teams-closed-inq'
      ),
    ]),
  ];
  if (!permissions.hasTeamWithEnquiryAnswerApproval) {
    const myInquiries = navigation.find((g) => g.id === 'Filter_inquiries_by');
    const myTeam = navigation.find((g) => g.id === 'My_team');
    myInquiries.children = myInquiries.children.filter((c) => c.id !== 'My_pending_approvals');
    myTeam.children = myTeam.children.filter((c) => c.id !== 'Pending_approval');
  }
  return filter(navigation, permissions);
}
