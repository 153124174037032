export const MED_DOCS = [
  { key: 'faq', name: 'FAQ' },
  { key: 'lit', name: 'LITERATURE' },
  { key: 'pil', name: 'PIL' },
  { key: 'slide', name: 'SLIDE_DECK' },
  { key: 'smpc,binary', name: 'SmPc' },
  { key: 'srd', name: 'SRD' },
];

export const MED_DOCS_AND_SIMILAR = MED_DOCS.concat({ key: 'similar', name: 'SIMILAR_INQUIRIES' });
