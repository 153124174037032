import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl } from 'app/common/url-resolver.service';
import { BAuditTrail, TAuditTrailDetailData } from './audit-trail';
import { isNil, omitBy, uniq, without } from 'lodash';
import { Ajax } from 'app/common/ajax';
import { AuditTrailComponent } from 'app/common/common/audit-trail/audit-trail.component';
import {
  AuditDataType,
  AuditDataValue,
  NonUserAuditData,
  NonUserSingleAuditData,
  UserAuditData,
} from 'app/common/common/audit-trail/audit-trail-data';
import { MatDialog } from '@angular/material/dialog';
import { BTeam, BUser } from '../data-model.module';

@Injectable({ providedIn: 'root' })
export class AuditTrailService {
  private readonly URL: string = apiUrl('audit');

  constructor(private http: HttpClient, private modalService: MatDialog) {}

  get(options: { params: Record<string, any>; responseType?: 'json' }): Observable<BAuditTrail> {
    return this.http.get<BAuditTrail>(this.URL, {
      ...Ajax.X_NO_LOADING_OPTIONS,
      ...options,
      params: omitBy<Record<string, any>>(options.params, isNil),
    });
  }

  getDetailsFor(recordId: string): Observable<TAuditTrailDetailData> {
    return this.http.get<TAuditTrailDetailData>(apiUrl('audit', 'details', recordId));
  }

  openUserAuditTrailDialog(allUsers: BUser[], role?: string, team?: BTeam): void {
    this.openAuditTrailDialog(new UserAuditData(allUsers, role, team));
  }

  openNonUserAuditTrailDialog(type: AuditDataType, val: AuditDataValue, event?: MouseEvent): void {
    this.openAuditTrailDialog(new NonUserAuditData(type, val), event);
  }

  openNonUserSingleAuditTrailDialog(type: AuditDataType, event?: MouseEvent): void {
    this.openAuditTrailDialog(new NonUserSingleAuditData(type), event);
  }

  private openAuditTrailDialog(
    auditTrailData: UserAuditData | NonUserAuditData | NonUserSingleAuditData,
    event: MouseEvent = undefined
  ): void {
    event?.stopPropagation();
    this.modalService.open(AuditTrailComponent, {
      maxWidth: '95vw',
      data: auditTrailData,
    });
  }

  modifyDisplayedColumnsIfSysAdmin(userIsSysAdmin: boolean, displayedColumns: string[]): string[] {
    /**
     * Updates the displayed columns based on the user's role.
     * If the user is a system administrator, the "auditTrail" column is added to the displayed columns.
     * If the user is not a system administrator, the "auditTrail" column is removed from the displayed columns.
     * @param isSysAdmin - A boolean indicating whether the user is a system administrator.
     * @param displayedColumns - An array of strings representing the columns to be displayed.
     * @returns An updated array of strings representing the modified displayed columns.
     */
    return userIsSysAdmin
      ? uniq([...displayedColumns, 'auditTrail'])
      : without(displayedColumns, 'auditTrail');
  }
}
