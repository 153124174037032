export const englishLangData = {
  MEDICAL_INFO_SYSTEM: 'Medical Information System',
  ACTIVE_EVENTS: 'Active Events',
  EVENTS: 'Events',
  SET_INTERACTION_AS_BEING_PART_OF_AN_EVENT: 'set interaction as being part of an event',
  UPDATED_EVENT: 'updated event',
  REMOVED_INTERACTION_AS_BEING_PART_OF_AN_EVENT: 'removed interaction as being part of an event',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Select an event to browse users.',
  BACK_TO_THE_EVENT_SELECTION: 'Back to the event selection',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'If you forgot your password ',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    "Please note that the user have to recover the password from 'Forgot Password'.",
  LOGIN: 'Login',
  Login_here: 'Login here',
  ENABLE_USER_LOGIN_MESSAGE: 'This will enable user login.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE: 'This will disable user login and notifications.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'Please set your notification preferences from your user profile!',
  RESET_IT_HERE: 'reset it here',
  CHANGE_LANGUAGE_TO: 'Change Language To',
  TEST_SYSTEM: 'TEST SYSTEM',
  HELP: 'help',
  THIS_IS_TEST_ENV: 'This is a test environment, if you want insert real enquiry go to',
  USER_OR_PASS_IS_INCORRECT: 'Username or password is incorrect',
  OPS: 'Ops',
  WE_DID_NOT_FIND: 'We did not find what you were looking for',
  VIDEO_TUTORIALS: 'Video Tutorials',
  STILL_DIDNT_FIND: "Still didn't find what you are looking for",
  ASK_THE_SUPPORT_TEAM: 'Ask the support team',
  LOGGED_IN_AS: 'Logged in as',
  MY_PROFILE: 'My Profile',
  LOGOUT: 'Logout',
  RETRIEVING_YOUR_ACCESS_INFO: 'Retrieving your access information',
  LOGGED_IN_CAN_CLOSE: 'Logged in, you can now close this tab',
  TOKEN_HAS_NOT_BEEN_SAVED: 'Token has not been saved properly, please contact the support',
  PASSWORD_RESET: 'Password Reset',
  RESET_PASSWORD: 'Reset Password',
  INSERT_YOUR_EMAIL_TO_RESET_PASS: 'Please insert your email to reset your password',
  SET_PASSWORD: 'Please set your password',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'After reactivation of your account, your password had been erased.',
  CANCEL: 'Cancel',
  CANCEL_UPPERCASE: 'CANCEL',
  RETURN_TO_LOGIN: 'Return to Login',
  USERNAME: 'Username',
  USERNAME_IS_REQUIRED: 'Username is required',
  PASSWORD: 'Password',
  PASSWORD_IS_REQUIRED: 'Password is required',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  REGISTER: 'Register',
  REGISTRATION: 'Registration',
  Create_an_account: 'Create an account',
  LOADING: 'Loading',
  NO_RECORDS: 'No Records',
  INSERT_NEW_PASS: 'Please insert your new password',
  VERIFYING_TOKEN: 'Verifying Token',
  PASSWORD_DO_NOT_MATCH: 'Passwords do not match',
  PASS_RESET_SUCCESSFUL: 'Password Reset Successful',
  SAVE_NEW_PASS: 'Save New Password',
  GO_TO_INBOX: 'Go To Inbox',
  SELECT_ALL: 'Select All',
  DESELECT_ALL: 'Deselect All',
  DO_NOT_SHOW_AGAIN: ' Do not show again',
  INQUIRY: 'Enquiry',
  INTERACTION: 'Interaction',
  INTERACTIONS: 'Interactions',
  ATTACHMENT_WITH_THIS_INQUIRY: '>Attachments with this Enquiry',
  USER_DETAILS: 'User Details',
  INQUIRER_DETAILS: 'Enquirer Details',
  CLOSE: 'Close',
  TEAMS: 'Teams',
  TEAM: 'Team',

  TEAM_SAVE: 'Save',
  TEAM_DELETE: 'Delete',
  TEAM_CREATE: 'Create',
  TEAM_CLONE: 'Copy',

  TEAM_NAME: 'Name',
  TEAM_CODE: 'Code',

  TEAM_ACTION_DELETE_CONFIRM: 'Do you really want to delete ',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'Be Careful!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: "Once deleted, it can't be restored!",

  TEAM_FULL_ANSWER_ON_LABEL: 'Full answer on label',
  TEAM_RESTRICTED_TEAM: 'Restricted',
  TEAM_NOTIFY_INQUIRER: 'Notify enquirer',
  TEAM_NOTIFY_INQUIRER_TOOLTIP:
    'Enabling this option will send automatically GDPR notifications to enquirers in open interactions in this team which had not been notified yet.',
  TEAM_NOTIFY_SALES: 'Notify sales',
  TEAM_NOTIFY_SALES_TOOLTIP:
    'Enabling this option will activate the answer involved notification for all users with the sales role in this team.\n\nDisabling this option will deactivate the answer involved notification for all users with the sales role in this team, unless they have the sales role in another team with the notify sales setting enabled.',
  TEAM_ENQUIRY_ANSWER_APPROVAL_TOOLTIP:
    'Enquiry answer approval workflow will be disabled for the team. The interaction/s in "Submitted for Approval" status will be affected and reverted back to status "In Progress" to allow correct answer preparation and response without approval.',
  TEAM_SEGREGATED_DATA: 'Segregated Data',
  TEAM_PRIVATE_TEAM: 'Private Team',
  TEAM_AUTO_ASSIGN: 'Auto Assign',

  TEAM_MAILBOX_IN: 'Inbound Mailbox',
  TEAM_MAILBOX_OUT: 'Outbound Mailbox',
  TEAM_DEFAULT_LANGUAGE: 'Default Language',
  TEAM_COUNTRIES: 'Assigned Countries',
  TEAM_PRODUCT_GROUPS: 'Assign Product Groups',
  TEAM_PARENT: 'Parent Team',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Override Cluster Root Team',
  TEAM_AFFILIATIONS: 'Affiliated Teams',
  TEAM_TIMEZONE: 'Timezone',

  SOURCE: 'Source',
  TEMPLATE_NO_MATCH_INTERACTION:
    'This template does not match the interaction details and is not applicable',
  SPECIALIZATION_IS: 'Specialization is',
  SPECIALIZATION: 'Specialization',
  SPECIALIZATION_TYPE: 'Specialization Type',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'Confirm specialization deactivation!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'This will remove it from the list with active specializations.',
  INQUIRER_TYPE: 'Enquirer Type',
  REQUIRED: 'required',
  RESULTS_PER_PAGE: 'results per page',
  RESULTS_FOUND: 'Results Found',
  EXPORT: 'Export',
  PERSONAL_SETTINGS: 'Personal Settings',
  TEAM_SETTINGS: 'Team Settings',
  USER_SETTINGS_OF: 'USER SETTINGS OF',
  SET_TEMPLATE: 'Set Template',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'Notifications will be overwritten with the current ones',
  PREVIEW: 'Preview',
  NOTIFICATIONS: 'NOTIFICATIONS',
  ROLE: 'Role',
  PERMISSION: 'Permission',
  ARCHIVED_ROLE: 'Archived role',
  TEMPLATE_NAME: 'Template Name',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Save this User as a Template',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Add a template to your user and copy role and preferences for notifications',
  AUTH_AND_NOTIFY_TEMPLATE: 'Authorization and Notifications Template',
  ROLES: 'Roles',
  MY_ROLES: 'My roles',
  GO_TO_ROLES: 'Go to Roles',
  STATUS: 'STATUS',
  THIS_ROLE_IS_TEMP:
    'This role is temporary, it has been given due to the fact that this user is a deputy',
  CREATED: 'CREATED',
  APPROVED_REJECTED: 'APPROVED / REJECTED',
  APPROVED_REJECTED_BY: 'APPROVED / REJECTED BY',
  ARCHIVE: 'ARCHIVE',
  SAVE: 'Save',
  UPDATE: 'Update',
  DELETE_INQUIRER_PII: 'Delete enquirer personal information',
  MARKED_INQUIRER_PII_DELETE: 'marked enquirer personal information for deletion',
  UNMARKED_INQUIRER_PII_DELETE: 'unmarked enquirer personal information for deletion',
  IMMEDIATE_EFFECT: 'Immediate effect',
  OUT_OF_OFFICE_DEPUTY: 'Out of office deputy',
  AUTO_ASSIGN_TO: 'Auto assign to',
  AUTO_ASSIGN_BY: 'Auto assign by',
  AUTO_ASSIGNMENTS: 'Auto Assignments',
  APPROVAL_ASSIGN_TO: 'Enq. approval assign to',
  APPROVAL_ASSIGN_BY: 'Enq. approval assign by',
  APPROVAL_ASSIGNMENTS: 'Enq. Approval Assignments',
  APPROVAL_ASSIGNMENTS_TO: 'Enq. approval assignments to',
  APPROVAL_ASSIGNMENTS_BY: 'Enq. approval assignments by',
  NO_ONE: 'No One',
  NOT_APPLICABLE: 'Not Applicable',
  GEOGRAPHY: 'GEOGRAPHY',
  ALIGNMENT: 'ALIGNMENT',
  Alignments: 'Alignments',
  Address: 'Address',
  PHONE_FAX: 'Phone / Fax',
  Email: 'Email',
  EMAILS: 'Emails',
  PHONE: 'Phone',
  PHONES: 'Phones',
  FAX: 'Fax',
  LETTER: 'Letter',
  F2F_SITE_VISIT: 'F2F (Site Visit)',
  F2F_CONGRESS: 'F2F (Congress)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (Other)',
  WEBINAR_SITE_VISIT: 'Virtual (Site Visit)',
  WEBINAR_CONGRESS: 'Virtual (Congress)',
  WEBINAR_ADBOARD: 'Virtual (AdBoard)',
  WEBINAR_OTHER: 'Virtual (Other)',
  CHAT: 'Chat',
  COURIER: 'Courier',
  REP_DELIVERY: 'Rep. Delivery',
  SYSTEM: 'System',
  WEB_FORM: 'Web Form',
  Contacts: 'Contacts',
  CONTACT_INFORMATION: 'Contact Information',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'Your changes on the user contact information triggered change of the login email from {{ OLD_USERNAME }} to {{ NEW_USERNAME }}',
  WORK_INFO: 'Work Information',
  LAST_NAME_IS: 'Last name is',
  PERSONAL_INFO: 'Personal Information',
  USER: 'User',
  ID: 'ID',
  UPDATED_FROM: 'updated from',
  ON: 'on',
  CRM: 'CRM',
  IMPORTED_FROM: 'imported from',
  CHANGE_PASS: 'Change Password',
  NOTIFICATION_IN_TEMPLATE: 'Notifications in Template',
  CONTINUE: 'Continue',
  DONT_SHOW_AGAIN: "Don't show this message again",
  USING_THE_TABS_ON_THE: 'using the tabs on the top of the screen',
  PERSONAL_PROFILE_SETTINGS: 'Personal Profile Settings',
  AND_YOUR: 'and your',
  YOU_CAN_SWITCH_BETWEEN: 'You can switch between your',
  WELCOME_TO_YOUR_USER_PROFILE: 'Welcome to your User Profile',
  SAVE_THIS_USER_AS: 'Save this User as Template',
  INCLUDE_NOTIFY_SETTINGS: 'Includes Notification Settings',
  OVERRIDE_NOTIFICATIONS: 'Override Notifications',
  OVERRIDE_NOTIFICATIONS_HINT:
    'When user template with override notifications is set to user, it will disable any preexisting notification settings and enable only the notification settings listed in the user template',
  INCLUDE_ROLES_SETTINGS: 'Includes Roles Settings',
  ACTIVE_NOTIFICATIONS: 'ACTIVE NOTIFICATIONS',
  SET_THIS_USER_AS_A_: 'Set this User Authorization and Notifications as New Template',
  REQUEST_AUTH: 'Request Authorization',
  REQUEST: 'Request',
  FEEDBACK_SUPPORT: 'Feedback and Support',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'If you still cannot find what you are looking for, ask the support team! Also if you have a suggestion for future improvements this is the right place.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Receipt and Sales notifications',
  TEMPLATE: 'Template',
  SIGNATURE: 'Signatures',
  Signature: 'Signature',
  Disclaimers: 'Disclaimers',
  Disclaimer: 'Disclaimer',
  DISCLAIMER_INFO: 'Disclaimer info',
  Greetings: 'Greetings',
  CUSTOMIZE: 'CUSTOMIZE',
  SAVE_UPPERCASE: 'SAVE',
  DELETE: 'DELETE',
  RESET_TO_DEFAULT: 'RESET TO DEFAULT',
  AFTER_SIGNATURE: 'After Signature',
  BEFORE_SIGNATURE: 'Before Signature',
  AFTER_ANSWER: 'After Answer',
  BEFORE_ANSWER: 'Before Answer',
  AFTER_GREET: 'After Greetings',
  BEFORE_GREET: 'Before Greetings',
  BEFORE: 'Before',
  AFTER: 'After',
  SAVED: 'Saved',
  SAVING: 'Saving',
  TYPING: 'Typing',
  Any: 'Any',
  HCP: 'HCP',
  NO_HCP: 'No HCP',
  Position: 'Position',
  ON_LABEL: 'On Label',
  OFF_LABEL: 'Off Label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'You are trying to customize the Template',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    '  You do not have the permission to do it. If you want to proceed, a copy of the template will be created and you will make the changes there.',
  CREATE_COPY: 'Create Copy',
  RECEIVED_ON: 'Received On',
  SENDER_EMAIL: 'Sender E-mail',
  SUBJECT: 'Subject',
  EMAIL_SUBJECT: 'E-mail Subject',
  EMAIL_SUBJECT_TEMPLATE_HINT_1:
    'E-mail subject will be visible in every interaction assigned to team {{ TEAM }}, in cases where the E-mail subject has not been edited manually and the interaction has been created manually.',
  EMAIL_SUBJECT_TEMPLATE_HINT_2:
    'In interactions submitted to EnqMed via E-mail, the E-mail subject will be taken from the E-mail header.',
  UNSPAM: 'Unspam',
  UPLOAD: 'Upload',
  APPROVED: 'Approved',
  DRAFT: 'Draft',
  IN_APPROVAL: 'In approval',
  IN_REVIEW: 'In review',
  CREATED_MANUALLY: 'Created manually',
  SELECT_STATUS: 'Select Status',
  UPLOAD_AS: 'Upload As',
  TITLE: 'Title',
  REPOSITORY: 'Repository',
  BROWSE: 'Browse',
  URL: 'URL',
  APPROVED_ON: 'Approved on',
  AUTH_HOLDER: 'Authorisation holder',
  AUTH_NUMBERS: 'Authorisation numbers',
  APPROVED_FOR: 'Approved for',
  SELECT_ONE_REGION: 'Select One Region',
  OWNED_BY: 'Owned by',
  KEYWORDS: 'Keywords',
  TOPICS: 'Topics',
  CATEGORIES: 'Categories',
  PRODUCTS: 'Products',
  PRODUCT: 'Product',
  PRODUCT_RESOLVED_VIA_AI: 'Product (Resolved via AI)',
  LANGUAGE_IS_REQUIRED: 'Language is required',
  LANGUAGE: 'Language',
  TARGET_CUSTOMERS: 'Target customers',
  TYPE_IS_REQUIRED: 'Type is required',
  TYPE: 'Type',
  TITLE_IS_REQUIRED: ' Title is required',
  DEFAULT_REFERENCE_IS_CREATED_:
    'The default reference is created with the Vancouver style. If you want to override this, enter the entire reference here',
  REFERENCE: 'Reference',
  DOI: 'DOI',
  PUBMED_ID: 'Pubmed ID',
  PAGES: 'Pages',
  ISSUE: 'Issue',
  VOLUME: 'Volume',
  JOURNAL: 'Journal',
  AUTHORS: 'Authors',
  ABSTRACT: 'Abstract',
  REFERENCE_IS_REQUIRED: 'Reference is required',
  THIS_DOC_IS_APPROVED: 'This document is approved. Click here to open the ',
  ORIGINAL_DOCUMENT_AND_: 'original document and insert your comments',
  EDIT_FILE: 'Edit File',
  PROPERTIES: 'Properties',
  OPEN: 'Open',
  LOCALIZED_DOCUMENTS: 'Localized documents',
  ORIGINAL_DOCUMENTS: 'Original document',
  INTERNAL_APPROVAL_NUMBER: 'Internal approval number',
  CREATED_BY: 'Created by',
  EXPIRATION_DATE: 'Expiration date',
  VERSION_NUMBER: 'Version number',
  NO_VERSION_AVAILABLE: 'No Version Available',
  ARCHIVED_IF_NO_DRAFT: 'Archived: if no Draft/Approved versions present. ',
  APPROVED_FOR_USERS: 'Approved: for users. ',
  DRAFT_FOR_AUTHORS_: 'Draft: for authors and editors.',
  VERSION_LEGEND: 'Version legend',
  VERSION: 'version',
  SELECT_ONE_LANG: 'Select one Language.',
  SELECT_ONE_TYPE: 'Select one Type.',
  INTERNAL_NUMBER_REQUIRED: 'Internal approval number is required.',
  FILE: 'File',
  ATTACH_FILE: ' Attach File',
  LITERATURE: 'Literature',
  SLIDE_DECK: 'Slide Deck',
  PIL: 'PIL',
  FAQ: 'FAQ',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'Add approval step',
  ADD_PARALLEL_STEP: 'Add parallel step',
  DELETE_STEP: 'Delete step',
  DELETE_ALL_PARALLEL_STEPS: 'Delete all parallel steps',
  CHANGE_STATUS_NAME: 'Change Status Name',
  SET_REQUIRED_ROLE: 'Set required role',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Please assign roles and notifications.',
  SET_TEAM_MEMBER: 'Set team member',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'You do not have the permissions to change this process.',
  SAVE_ROLE: 'SAVE ROLE',
  CREATE_NEW_WORKFLOW: 'CREATE NEW WORKFLOW',
  CREATE_NEW_STEP: 'CREATE NEW STEP',
  CREATE_NEW_INTERACTION: 'Create New Interaction',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'Please enter the number of parallel approval steps, meaning steps that are approved at the same time (not sequentially). If you do not have any parallel approval steps, enter 1. You can always change the number of approval steps later.',
  APPROVAL_MUST_BE_AT_LEAST: 'Approval must be at least',
  INSERT_THE_NEW_STATUS_NAME: 'Insert the new status name',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Insert the new workflow name.',
  INSERT_THE_NEW_STEP_NAME: 'Insert the new step name.',
  DOCUMENT_EXTERNAL_URL: 'Document external URL',
  NEW_DOCUMENT: 'New Document',
  DOCUMENT_TITLE_FOR_WORD:
    "The title for word documents is usually taken from Title formatted section within the document itself. If the document doesn't contain title you can specify it in here. If you don't specify title here and the word document doesn't contain Title formatted section, then the document ID would be used for title.",
  CREATE: 'Create',
  DOCUMENT_MANAGEMENT: 'Document Management',
  DOCUMENT_APPROVAL: 'Document Approval',
  DOCUMENT_TITLE: 'Document title',
  SEARCH_DOCUMENTS: 'Search documents',
  APPROVAL_INBOX: 'Approval inbox',
  CONFIRM: 'Confirm',
  CONFIRM_PROCESS: 'CONFIRM PROCESS',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'DEFINE APPROVAL STEPS AND TEAM',
  SET_DETAILS: 'SET DETAILS',
  SELECT_TYPE: 'SELECT TYPE',
  COMPLETE_LOCALIZATION_PROCESS: 'Complete Localization Process',
  CREATE_NEW_VERSION: 'Create New Version',
  CREATE_NEW_DOCUMENT: 'Create New Document',
  IS_PRIVATE: ' Is Private',
  SHARED_WITH_OTHER_TEAMS: ' Shared with other teams',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'Please enter the number of sequential approval steps needed. If two approvals are in parallel, it counts as one step. You can always change the number of approval steps later.',
  DONE: 'Done',
  SUBMIT_FOR_APPROVAL: 'Submit For Approval',
  INQUIRY_SUBMIT_HINT: 'Please, fill out all required fields, in order to answer the enquiry',
  REQUIRED_FIELDS_HINT: 'Please, fill out all required fields',
  YOU_HAVE_TO_CONFIRM_THE_: 'You have to confirm the process before the upload.',
  SELECT_YOUR_DEC_TYPE: 'Select your document type',
  DO_YOU_WANT_TO_CONTINUE: 'Do you want to continue?',
  TARGET_AUDIENCE: 'Target Audience',
  PROCESS_DETAILS: 'Process Details',
  START_DATE: 'Start Date',
  DOC_DETAILS: 'Document Details',
  CONFIRMATION: 'Confirmation',
  APPROVAL_PROCESS: 'Approval Process',
  SCHEDULED_EXP_DATE: 'Scheduled Expiration Date',
  SCHEDULED_PUBLISHING_DATE: 'Scheduled Publishing Date',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'Scheduled Publishing to Expiration Date',
  NO_PROCESS_SELECTED: 'No Process Selected',
  COUNTRIES: 'Countries',
  DOC_ID: 'Document ID',
  THERAPEUTIC_AREA: 'Therapeutic Area',
  FILTER: 'Filter',
  FILTER_BY: 'Filter by',
  FILTER_DOCUMENTS_BY: 'Filter documents by',
  ORDER_BY: 'Order by',
  BACK: 'back',
  ATTACH: 'Attach',
  FORMAT: 'Format',
  COPY_ANSWER: 'Copy Answer',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Copy answer to the new interaction',
  COPY_ATTACHMENT_TO_ANSWER: 'Copy Attachment to Answer',
  COPY_TEXT_TO_ANSWER: 'Copy Text to Answer',
  SELECT_INQUIRY: 'Select Enquiry',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'New inquiries have been imported. Be sure to characterize and answer all the inquiries.',
  SELECT_QUESTION: 'Select Question',
  SELECT_QUESTION_FOR_NOTE: 'Select Question for which you want to add note',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'Add Note for whole Enquiry',
  SIMILAR_INQUIRIES: 'Similar Enquiries',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: 'Slide Decks',
  MEDICAL_DOC: 'Medical Document',
  SHOW: 'Show',
  LOAD_MORE: 'Load More',
  NO_RESULT_FOUND: 'No results found.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'can be used to search for an entire sentence, - can be used to exclude from the search results within the specified word',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Search for medical Documents or similar enquiries',
  SEARCH: 'SEARCH',
  Preview: 'Preview',
  PREVIEW_AND_SEND: 'Preview and send',
  PREVIEW_AS_PDF: 'Preview as pdf',
  PUBLISHED_VERSION: 'Published Version',
  NEW_VERSION: 'New Version',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'The document is still in approval, if you want change the document content upload an other file.',
  OUT_TO: 'out to',
  NEXT: 'next',
  SELECTED_SLIDES: 'Selected Slides',
  GO_TO_SLIDE: 'GO TO SLIDE',
  PREV: 'prev',
  SELECT_SLIDE: 'Select Slide',
  ANSWER: 'Answer',
  ANSWER_TEXT: 'Answer text',
  BACK_TO_STANDARD_VIEW: 'Back to the standard view.',
  INQUIRER: 'Enquirer',
  UPLOAD_NEW_FILE: 'Upload New File',
  CREATE_FILE: 'CREATE FILE',
  EDITING_WORD_DOC: 'Editing Word Document',
  INSERT_FILE_NAME: 'Insert file name',
  PROCEED_WITH_THE_LINK_: 'Proceed with the link of the file',
  A_NEW_WINDOW_WILL_BE_:
    ' A new window will be opened with an empty file, write the content and close the tab',
  INSERT_THE_NAME_FOR_:
    'Insert the name for the attachment you want create and proceed with the file creation',
  INSTRUCTIONS: 'Instructions',
  CREATE_NEW_WORD_FILE_FOR: 'Create new word file for',
  FROM: 'from',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'You can find here the last enquiries you have inserted in the system both submitted and not yet submitted. You can reuse enquiries you have inserted to create more similar enquiries or get back to enquiries you have not finished inserting.',
  MY_DRAFT_INQ: 'My Draft Enquiries',
  WARNING_CHANGES_NOT_SAVED: 'Warning: Changes not saved',
  PAGE_THAT_WILL_BE_LOST: 'page that will be lost if you decide to continue.',
  YOU_HAVE_UNUSED: 'You have unsaved changes in ',
  ARE_YOU_SURE_YOU_WANT_TO_: 'Are you sure you want to leave this page without save?',
  LEAVE_THIS_PAGE: 'Leave this page ',
  LEAVE_WITHOUT_SAVING: 'Leave Without Saving',
  KEEP_INQUIRER_DETAILS_OPEN: 'Keep Enquirer Details Open',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'You have unsaved changes, which would be lost if you close the enquirer details.',
  PENDING_CHANGES_MESSAGE: 'Pending changes are {{ CHANGES }}?',
  STAY_ON: 'Stay on',
  ASSIGNED_TO: 'Assigned To',
  REQUESTED_DATE: 'Requested Date',
  REVIEWED_DATE: 'Reviewed Date',
  UNASSIGNED: 'Unassigned',
  BLANK: 'Blank',
  THIS_INQ_HAS_BEEND_INSERTED_: 'This enquiry has been inserted during the event.',
  STATUS_IN_APPROVAL: ' Status: In Approval',
  STATUS_DRAFT: 'Status: Draft',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'If you want create the actual enquiry, go to submit tab and click on create button.',
  INQ_IMPORTED_FROM: 'Enquiry imported from',
  REVERT_TO_IN_PROGRESS: 'REVERT TO IN PROGRESS',
  SEND_NEW_ANSWER: 'SEND NEW ANSWER',
  SEND_EMAIL: 'Send Email',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION: 'Send Email to the user upon request approval/rejection',
  SAVE_WITHOUT_EMAIL: 'Save Without Email',
  RETURN_TO_INBOX: 'RETURN TO INBOX',
  SUBMITTED_FOR_APPROVAL: 'Submitted for Approval',
  ANSWER_SUBMITTED_ON: 'Answer submitted on',
  BY: 'by',
  INQ_HAS_BEEN_CLOSED: 'Enquiry has been closed without an answer.',
  BCC: 'Bcc',
  CC: 'Cc',
  ANSWER_GIVEN_ON: 'Answer given on ',
  ANSWER_SENT_ON: 'Answer sent on ',
  ANSWER_SAVED_WITHOUT_EMAIL: 'Answer saved without email',
  INBOX: 'Inbox',
  SUBMIT_TO_INBOX: 'Submit to Inbox',
  WATCHERS: 'Watchers',
  WATCH_OPTIONS: 'Watch options',
  START_WATCHING: 'Start watching',
  STOP_WATCHING: 'Stop watching',
  UPDATE_WATCHERS: 'updated watchers in the interaction',
  OPEN_ORIGINAL_INQ: 'Open original enquiry',
  CLOSE_ANSWER_NOT_NEEDED: 'Close: Answer not needed',
  HISTORY: 'History',
  ADD_A_NOTE: 'Add a note',
  ADD_A_NOTE_INTO_: 'Add a note into the enquiry history.',
  SHARE_OR_START_A_FOLLOW_UP: 'Share or start a follow up',
  SHARE_FROM_HERE_YOU_CAN_:
    'Share: from here you can forward interaction data to internal/external users or departments.',
  SEE_ALL_INQ_RELATED_TO: 'See all Enquiries related to',
  INQ_RELATED_TO: 'Enquiries related to',
  UPDATED_INQUIRER_DETAILS: 'updated enquirer details',
  CREATED_INQUIRER: 'created enquirer',
  SUGGESTED_INQ: 'Suggested Enquirers',
  SELECT_INQUIRER: 'Select Enquirer',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'ADR obligation consent and enquirer must be selected, in order to submit the interaction',
  SRD_NEEDED: 'SRD Needed',
  SRD_NEEDED_TOOLTIP: 'The answer for this enquiry requires new SRD document',
  ANSWER_UNAVAILABLE: 'Answer unavailable',
  QUESTION: 'Question',
  QUESTIONS: 'Questions',
  SUBMIT: 'SUBMIT',
  Create_my_acc: 'Create my account',
  ANSWER_UPPERCASE: 'ANSWER',
  DETAILS: 'DETAILS',
  SPLIT_INQ: 'Split Question',
  EDIT_INQ: 'Edit Question',
  SPLIT_ANOTHER: 'Split Another',
  DELETE_INQ: 'Delete Enquiry',
  SELECT_TEXT_FOR_SPLIT: 'Add new question in the same interaction',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Generate separate interaction / case',
  QUESTION_TEXT_BEFORE_SPLIT: 'Question text before split',
  QUESTION_TEXT_AFTER_SPLIT: 'Question text after split',
  SPLIT_ORIGINAL_TEXT: 'Original Text (Select text for split)',
  SPLIT_TEXT_TO_REMAIN_: 'Text to remain in initial enquiry',
  SPLIT_TEXT_TO_SPLIT_: 'Text to be split',
  SPLIT_SELECTED_SECTIONS: 'Selected sections for split',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'Are you sure you want to split the selected text in new interaction?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'This will remove the selected text from the interaction and create a new one from it.',
  SPLIT_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'Question split performed, while interaction is in {{ SUBMITTED_FOR_APPROVAL }} status. This action reverts the status back to {{ IN_PROGRESS }} to allow answer preparation for the newly created question/s.',
  MERGE_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'Action {{ MERGE_AS_ADDITIONAL_QUESTION }} performed into the current interaction, while target interaction is in {{ SUBMITTED_FOR_APPROVAL }} status. This action reverts the status back to {{ IN_PROGRESS }} to allow answer preparation for the newly created question/s.',
  SPLIT_NO_MODIFICATIONS_HINT:
    'The text will populate automatically as you select sections from the original text',
  SPLIT_MODIFICATIONS_HINT:
    "The text has been modified manually and any further selection from original text won't be applied. Click here to discard the modifications.",
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'The text will be updated automatically with the changes on the enquiry details.',
  TEMPLATE_MODIFICATIONS_HINT:
    "The text has been modified manually and won't be changed automatically any more. Click here to discard the modifications and re-enable automatic updates.",
  SELECT_DIFFERENT_TEMPLATE: 'Select a different template',
  ADD_QUESTION: 'Add Question',
  NEW_QUESTION: 'New Question',
  ENTER_QUESTION: 'Enter Question',
  MARK_AS_ANSWERED: ' Mark as Answered',
  TO_BE_ANSWERED: 'To be Answered',
  FOLLOW_UP_RECEIVED: 'Follow up received',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'Error! Failed delivery',
  ERROR: 'Error',
  INFO: 'Info',
  DOWNLOAD_ERROR: 'Download Error!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'You are trying to download over 1000 inquiries. The maximal supported number is 1000. Please refine your selection.',
  SHOW_ORIGINAL_MESSAGE: 'Show original message',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'Answer has not been sent to the enquirer due to wrong email address. ',
  ADD_AS_A_NEW_QUESTION: 'Add as a new question',
  SEND: 'Send',
  DO_NO_SEND: 'Do Not Send',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'Thank you! The notification will be send once the enquiry is submitted or answered.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'Thank you! The notification will be send once the enquirer is saved.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'The following GDPR notification will be send to the enquirer via email.',
  NOTIFICATION_SENT_TO_THE_INQ:
    'The following GDPR notification has been sent to the enquirer via email.',
  SEND_A_NOTIFICATION_TO_THE_INQ: 'Send the following GDPR notification to the enquirer via email.',
  OK: 'Ok',
  MAX_LENGTH_FOR_ZIP_CODE: ' Max Length for Zip Code is',
  CHARACTERS: 'characters',
  ADDRESS_1_IS_: 'Address 1 is',
  LABEL_IS: '  Label is',
  LABEL: 'Label',
  SELECT_A_LABEL: 'Select a label',
  NO_QUESTION_INSERTED: 'No question inserted',
  WARNING: 'WARNING',
  INQ_HAS_NO_EMAIL: 'enquirer has no email. ',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'Follow-up is not available for this interaction. ',
  INSERT_INQ_TEXT: 'Insert enquiry text',
  SHOW_MORE: 'Show More',
  OUT_OF: 'out of',
  QUESTIONS_TO_BE_SENT: 'Questions to be sent',
  SHOW_QUESTION: 'Show Questions',
  HIDE_QUESTION: 'Hide Questions',
  SHOW_QUESTION_TEXT: 'Show question text',
  HIDE_QUESTION_TEXT: 'Hide question text',
  SEARCH_RECIPIENTS: 'Search recipients',
  SEARCH_PARTICIPANTS: 'Search participants',
  SEARCH_USER: ' Search user',
  SEARCH_RECIPIENTS_TO_SHARE_THE_ENQUIRY: 'Search recipients to share the enquiry.',
  SEARCH_USERS_WHICH_WILL_PARTICIPATE_TO_THE_EVENT:
    'Search users which will participate to the event.',
  SEARCH_USER_WHICH_WILL_APPROVE_THE_STEP: 'Search user which will approve the step.',
  SHARE: 'Share',
  HCP_PROFILE_OF: 'HCP PROFILE of',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'A notification has NOT been sent to the enquirer that enquiry has been received. ',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Fill in all mandatory fields below and at least one email address and then save to preview GDPR receipt notification.',
  CONFIRMATION_SENT_BY: 'Notification sent by',
  NOTIFICATION_SENT_TO_THE_INQUIRER:
    'Notification sent to the enquirer that enquiry has been received.',
  COUNTRY_IS: 'Country is',
  COUNTRY: 'Country',
  CITY: 'City',
  STATE: 'State',
  AT_LEAST_ONE_CONTACT_IS_: ' At least one contact is',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'At least one contact is required!',
  EMAIL_IS: 'Email is',
  EMAIL_LABEL_IS: 'Email label is',
  PHONE_FAX_IS: 'Phone / Fax label is ',
  PHONE_FAX_NUMBER_IS: ' Phone / Fax number is ',
  ADDRESS: 'Address',
  ADDRESS_1: 'Address 1',
  ADDRESS_2: 'Address 2',
  ZIP_CODE: 'Zip Code',
  DELETE_PERSONAL_DATA: 'Delete Personal Data',
  HCP_VERIFIED: 'HCP verified',
  KEY_OPINION_LEADER: 'Key opinion leader',
  HOSPITAL_BASED: 'Hospital Based',
  HCP_RELATED_INFORMATION: 'HCP RELATED INFORMATION',
  ACCEPT: 'Accept',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'Do you want to accept these suggestions?',
  SALES_REP: 'SALES REP',
  SUGGEST_A_TOPIC: 'Suggest a topic',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'Suggest a new category or select an existing one',
  SUGGEST_CATEGORY_OR_TOPIC: 'Suggest new category or topic',
  TEAM_SPECIFIC_FIELDS: 'TEAM SPECIFIC FIELDS',
  ADR_RELATED: 'ADR Related',
  NOT_ADR_RELATED: 'Not ADR Related',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Please review the message and classify it as Adverse Drug Reaction related or not.',
  ADVERSE_DRUG_REACTION: 'Adverse Drug Reaction',
  CHANGE_DETAILS: 'CHANGE DETAILS',
  YES: 'Yes',
  NO: 'No',
  DUE_DATE: 'Due Date',
  CRITICAL: 'Critical',
  URGENT: 'Urgent',
  HIGH: 'High',
  NORMAL: 'Normal',
  PRIORITY: 'Priority',
  SELECT_DETAILS: 'Select Details',
  QUESTION_CHARACTERISATION: 'Question characterisation',
  SAVE_REVIEW: 'Save Review',
  SET_TO_ALL_INQ: 'SET TO ALL ENQUIRIES',
  SET_TO_ALL_INQ_TOOLTIP:
    'Set the same ADR, PQC, and Off-label characterisation for all questions.',
  PRODUCT_QUALITY_COMPLAINT: 'Product Quality Complaint',
  PQC_PC_A_PRODUCT_:
    'PQC/PC A Product Quality Complaint (QC) or problem is defined as any concern involving a defect or malfunction of any drug product or regarding the quality, performance, safety of any drug product, e.g. altered smell and/or taste, poor or defective packaging, suspected contamination, etc.',
  OFF_LABEL_USE_OF_PHARMA___:
    'Off-label use of pharmaceutical drugs for an unapproved indication or in an unapproved age group, dosage, or route of administration.',
  A_RESPONSE_TO_A_MEDICINAL_:
    ' A response to a medicinal product which is noxious and unintended and causal relationship between a medicinal product and an adverse event is at least a reasonable possibility. Also special situation cases (e.g. pregnancy cases, off label use, overdose) need to be marked as ADR.',
  NORMAL_IS_7_WORKING_DAYS: 'Normal is 7 working days, High is 48 hours and Urgent is 24 hours',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION: 'You are trying to change question characterization!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'Please note changing question characterisation will not allow any changes in previously given answer or allow to send a new answer!',
  CATEGORY_AND_TOPIC_ARE: 'Category and topic are',
  PRODUCT_IS: 'Product is',
  DO_YOU_WANT_TO_ACCEPT_: 'Do you want to accept these suggestions?',
  MERGE: 'Merge',
  SPLIT_MERGE: 'Split and Merge',
  Duplicate: 'Duplicate',
  Other: 'Other',
  Spam: 'Spam',
  CLOSE_INTERACTION: 'Close interaction',
  RE_OPEN_THE_TARGET_: 'Re-open the target enquiry after merge ',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'You are about to re-open a closed interaction to send a new answer.',
  THIS_IS_JUST_A_THANK_YOU:
    'This is just a thank you message from the enquirer of the existing enquiry.',
  MARK_AS_INTERNAL_: 'Mark as an internal message for the existing enquiry.',
  MARK_AS_AN_ERROR_: ' Mark as an error message for the existing enquiry.',
  QUESTION_REQUIRE_ANSWER_MESSAGE:
    'The new question will require an answer in order to close the enquiry.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE:
    'Are you sure to want to convert this Follow up to a Question?',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'This message will be marked as follow up response of an existing enquiry.',
  THANK_YOU: 'Thank you',
  AN_INTERNAL_MESSAGE: 'An internal message',
  AN_ERROR_MESSAGE: 'An error message',
  CLARIFICATION: 'Clarification',
  CLARIFICATION_QUESTION: 'Clarification Question',
  NEW_INQ: 'New question',
  NEW_INTERACTION: 'New interaction',
  A_FOLLOW_UP_RESPONSE: 'A follow up response',
  THIS_INQ_IS: 'This enquiry is',
  SPLIT_INTERACTION: 'Split Interaction',
  SAVE_AND_CLOSE: 'Save and close',
  SAVE_INQ: 'Save Enquiry',
  SAVE_INQUIRER: 'Save Enquirer',
  REVIEW_THE_TEXT_AND: 'Review the text and categorization, and confirm',
  CHOOSE_THE_RELEVANT_PRODUCT:
    'Choose the relevant product, category, and topic for the selected text',
  SELECT_THE_TEXT_THAT_:
    'Select the text that identifies the question about one product/topic (do not include greetings, signatures, disclaimers, or other non-relevant information)',
  REPEAT_THE_PREVIOUS_: 'Repeat the previous steps to add more enquiries',
  READ_MORE: 'Read more',
  READ_LESS: 'Read less',
  DO_NOT_INCLUDE_:
    'do not include greetings, signatures, disclaimers, or other non-relevant information',
  SELECT_THE_TEXT_THAT_IDENT:
    'Select the text that identifies the question about one product/topic',
  IF_YOU_HAVE_MULTIPLE_: 'If you have multiple products or topics, the enquiry has to be split.',
  THE_FOLLOWING_PROCESS_:
    ' The following process will help you identify the relevant text for each product/topic:',
  EXPORT_TO_PDF: 'EXPORT TO PDF',
  Added: 'Added',
  Deleted: 'Deleted',
  From: 'From',
  To: 'To',
  LABEL_FOR: 'Label for',
  BACK_TO_INBOX: 'Back to Inbox',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'You will be redirected to a page for which you hold permissions.',
  PERMISSION_DENIED: 'Permission Denied',
  THIS_INQ_IS_LOCKED_BY: 'This enquiry is locked by',
  FORCE_UNLOCK: 'FORCE UNLOCK',
  INQUIRIES: 'Enquiries',
  Download: 'Download',
  Event: 'Event',
  INSERTED_BY: 'Inserted By',
  LAST_EDITED_ON: 'Last Edited On',
  LAST_EDITED_BY: 'Last Edited By',
  LAST_EDIT_ON: 'Last Edit On',
  LAST_EDIT_BY: 'Last Edit By',
  CREATED_ON: 'Created On',
  ACTIVE_ON: 'Active On',
  EXISTING_CATEGORY: 'Existing Category',
  NEW_CATEGORY_NAME: 'New Category Name',
  NEW_TOPIC_NAME: 'New Topic Name',
  CATEGORY: 'Category',
  TOPIC: 'Topic',
  TABLE_OPTIONS: 'Table Options',
  DEPARTMENT: 'Department',
  User: 'User',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'Expand all therapeutic areas',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'Collapse all therapeutic areas',
  ADD_THERAPEUTIC_AREA: 'Add Therapeutic Area',
  CREATE_THERAPEUTIC_AREA: 'Create new therapeutic area',
  THERAPEUTIC_AREA_PARENT: 'Parent area',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'To set a Therapeutic Area (TA) as main TA, de-select parent TA and save.',
  MAIN_THERAPEUTIC_AREA: 'Main Therapeutic Area',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'The Therapeutic Area is {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', as there are no associated products. Please select at least one product to set the therapeutic area to active.',
  IMAGE: 'Image',
  CHOOSE_AN_IMAGE: 'Choose an image',
  ALLOWED_FORMATS: 'Only {{ FORMATS }} formats allowed',
  REMOVE_IMAGE: 'Remove image',
  ADD_NEW: 'Add New',
  SELECT_TEAM_TO_PROCEED: 'Select Team to proceed',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  MSLA: 'MSLA',
  SALES: 'Sales',
  CALL_CENTER: 'Call Center',
  ALL: 'All',
  Warning: 'Warning',
  clear: 'clear',
  THESE_CHANGES_WILL_BE_: 'These changes will be applied to the selected users',
  INSERT_A_FOLLOW_UP_RESP: 'Insert a Follow Up Response',
  INSERT_A_NOTE: 'Insert a note',
  MERGED_FROM: 'Merged From',
  CHANGES: 'Changes',
  SHOW_LESS: 'show less',
  COMPLETE: 'Complete',
  MISSING: 'Missing',
  FORWARD: 'Forward',
  CLOSE_AS_FORWARDED: 'Close as Forwarded',
  CLOSE_EXT_TEAM: 'Close the enquiry as forwarded to external team',
  THE_INQ_BELONG_TO_A_:
    'This enquiry belongs to a country that is responsability of an external team. The enquiry will then be forwarded to this team automatically.',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'I acknowledge that it is my obligation to report all adverse drug reactions (ADRs) of all medical products according to local requirements. EnqMed is not intended to capture reports about adverse drug reactions - ADRs must be reported to Pharmacovigilance directly according to local requirements',
  RESOLVE: 'Resolve',
  REFRESH_TASK_LOGS: 'Refresh Task Logs list',
  REFRESH_MIGRATION_ERRORS: 'Refresh Migration Errors list',
  TERRITORY: 'Territory',
  TERRITORIES: 'Territories',
  EXTERNAL_COMMUNICATION: 'EXTERNAL COMMUNICATION',
  FOR_INQ_COMING_DIRECTLY_:
    'For enquiries coming directly from the HCP via email, automatically send a notification to inform him that the enquiry has been received.',
  INTERNAL_COMMUNICATION: 'INTERNAL COMMUNICATION',
  INTERNAL_COMM: 'Internal Communication',
  DISMISS: 'Dismiss',
  GO_TO_ENQUIRY: 'Go to Enquiry',
  SALESREP_RECEIVES_: 'Salesrep receives only notifications that enquiry was answered',
  SALESREP_RECEIVES_FULL_ANSWER_: 'Salesrep receives full answer for On Label Enquiries',
  SALESREP_RECEIVES_ONLY_NOTIF_: 'Salesrep receives only notifications that enquiry was answered',
  Name: 'Name',
  Module: 'Module',
  Function: 'Function',
  LAST_RUN: 'Last Run',
  LAST_SUCCESS: 'Last Success',
  LAST_FAILURE: 'Last Failure',
  REPEAT_EVERY: 'Repeat on every',
  End: 'End',
  Every: 'Every',
  Period: 'Period',
  Active: 'Active',
  INACTIVE: 'Inactive',
  NO_TEAMS: 'Without Team',
  NO_TEAMS_TIP:
    'Contains users, which are not assigned to any team. You can check if some of those are from your team and grant them the appropriate role.',
  DEACTIVATE: 'Deactivate',
  DEACTIVATE_ITEM: 'Deactivate {{ ITEM }}',
  Resolved: 'Resolved',
  MARKED_AS_RESOLVED_BY: 'Marked as resolved by',
  MARKED_AS_RESOLVED_ON: 'Marked as resolved on',
  MARK_AS_RESOLVED: 'Mark as resolved',
  ERROR_ON: 'Error on',
  ERROR_PREVIEW: 'Error Preview',
  FAILURE_COUNT: 'Failure Count',
  FAILURE: 'Failure',
  RESET_FAILURE_COUNT: 'Reset Failure Count',
  COMPLETED_ON: 'Completed On',
  STARTED_ON: 'Started On',
  TASK_NAME: 'Task name',
  TASK: 'Task',
  TASKS: 'Tasks',
  TASKS_LIST: 'Tasks List',
  TASK_LOGS: 'Task Logs',
  SELECTED: 'Selected',
  LICENSE_COUNTER: 'License counter',
  RUN_COUNTER: 'Run counter',
  EXPORT_LICENSES: 'Export licenses',
  LICENSES_LIMIT: 'License numbers limit',
  USERS_TO_EXCLUDE: 'Users to exclude',
  ENTER_MAX_NUMBER_OF_LICENSES: 'Contracted license fees (user roles).',
  SELECT_USER_ROLES_TO_COUNT: 'Select user roles applicable for license fees count.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Exclude users from license fees count.',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    'Please confirm, if you would like to proceed with the changes!',
  LICENSE_COUNT_MESSAGE: 'The number of used licenses is:',
  PRODUCT_SPECIFIC: 'PRODUCT SPECIFIC',
  THERAPEUTIC_AREAS: 'THERAPEUTIC AREAS',
  Therapeutic_Areas: 'Therapeutic Areas',
  PRODUCT_GROUPS: 'PRODUCT GROUPS',
  Product_Groups: 'Product Groups',
  LICENSING_PARTNER: 'LICENSING PARTNER',
  Licensing_Partner: 'Licensing Partner',
  PRODUCT_CODE: 'PRODUCT CODE',
  Product_Code: 'Product Code',
  EXTERNAL_ID: 'External ID',
  OBJECT_TYPE: 'Object Type',
  MIGRATION_NAME: 'Migration Name',
  MIGRATION_ERRORS: 'Migration Errors',
  Sales_Representative: 'Sales Representative',
  Other_Commercial: 'Other Commercial',
  ADD_SALES_REP: 'Add Sales Rep',
  ASSIGNED_TO_MSL: 'ASSIGNED TO MSL',
  NOT_ASSIGNED: 'Not assigned',
  ASSIGNED_TO_OTHER_MSL: 'Assigned to other MSL',
  ASSIGNED_TO_THIS_MSL: 'Assigned to this MSL',
  SHOW_ONLY: 'Show only',
  SALES_REP_LIST: 'Sales Rep List',
  ACTIVE_TASK: 'Activate Task',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'Choose user and set the shift for the event',
  MANAGE_SHIFTS: 'MANAGE SHIFTS',
  CREATE_NEW_USER: 'Create New User',
  SELECT_EXISTING_ONE: 'Select Existing One',
  OR: 'or',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'This template will be automatically added to your team member.',
  TEMPLATE_FOR: 'Template for',
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  Today: 'Today',
  Archive: 'Archive',
  ARCHIVED: 'Archived',
  REPUBLISH: 'Republish',
  PARTICIPANTS: 'PARTICIPANTS',
  EVENT_MANAGEMENT: 'EVENT MANAGEMENT',
  MANAGE_FORM: 'Manage Form',
  EVENT_FORM_ADDRESS: 'Event Form Address',
  EVENT_FORM_PREVIEW_WARING:
    "Make sure you have saved any changes before opening the congress form or copying it's address",
  ADD_TASK: 'Add Task',
  EDIT_TASK: 'Edit Task',
  IS_ACTIVE: 'Is Active',
  Minutes: 'Minutes',
  Hours: 'Hours',
  Days: 'Days',
  Weeks: 'Weeks',
  Months: 'Months',
  EDIT: 'Edit',
  HCP_ADMINISTRATION: 'HCP ADMINISTRATION',
  AUTH_REQ_ADMINISTRATION: 'Dashboard / Authorization Requests',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_:
    'The product will be highlighted in the selected Therapeutic Areas.',
  PRODUCT_GROUPS_DEFINE_:
    'Product Groups define the team responsible for answering this product enquiries.',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Please check the existing products in the table behind the dialog as we have already product with the same values.',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Please check the existing categories in the table behind the dialog as we have already category with the same values.',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Please check the existing topics in the table behind the dialog as we have already topic with the same values.',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Please check the existing therapeutic areas in the table behind the dialog as we have already area with the same values.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Please check the existing companies in the table behind the dialog as we have already company with the same values.',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Please check the existing domains in the table behind the dialog as we have already domain with the same values.',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Please check the existing tasks in the table behind the dialog as we have already task with the same values.',
  ROLE_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Please check the existing roles in the table behind the dialog as we have already role with the same name.',
  MAIL_IMPORT_DOMAIN_SETTINGS_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Please check the existing domains regex in the table behind the dialog as we have already tracked domain regex with the same values.',
  USER_ADMINISTRATION: 'Users Administration',
  IS_PRODUCT_SPECIFIC: 'Is Product Specific',
  NOT_PRODUCT_SPECIFIC: 'Not Product Specific',
  USER_TEMPLATES_ADMIN: 'User Templates Administration',
  SET_DEFAULT_VALUES: 'SET DEFAULT VALUES',
  SET_DEFAULT_VALUES_FOR_: 'Set default values for the event',
  INQ_DEFAULT_VAL: 'Enquirer default values',
  INQUIRY_DEFAULT_VAL: 'Enquiry default values',
  CREATE_NEW_EVENT: 'CREATE NEW EVENT',
  EDIT_EVENT: 'EDIT EVENT',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Set details to create new event',
  ADD_DOC_LINKED_TO_: 'Add documents linked to the event',
  INC_CHANNEL: 'Inc. Channel',
  OUT_CHANNEL: 'Outgoing Channel',
  CHANGE_DETAILS_OF_THE_EVENT: 'Change details of the event',
  DESCRIPTION: 'Description',
  CREATE_APPROVAL_PROCESS: 'Choose Approval Process',
  CREATE_NEW: 'Create New',
  CUSTOMIZE_PROCESS: 'Customize Process',
  SELECTED_PROCESS: 'Selected Process',
  CLONE: 'Clone',
  PREFERENCES_TITLE: 'Preferences',
  NOTIFICATIONS_TITLE: 'Notifications',
  TEMPLATES: 'Templates',
  LETTER_TEMPLATES: 'Letter template',
  USER_TEMPLATES: 'User Templates',
  MY_TEMPLATES: 'My Templates',
  TEAM_TEMPLATES: 'Team Templates',
  TEAM_TEMPLATES_HINT: 'Template created by Medical Information Manager for the entire team',
  TEAM_MEMBERS: 'Team Members',
  NEW_PASSWORD: 'New password',
  REPEAT_NEW_PASSWORD: 'Repeat new password',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'At least 1 lower case letter',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'At least 1 upper case letter',
  AT_LEAST_1_DIGIT: 'At least 1 digit',
  AT_LEAST_1_SPECIAL_CHARACTER: 'At least 1 special character',
  MINIMUM_8_CHARACTERS: 'Minimum 8 characters',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'Should not contain e-mail or names',
  SHOULD_MATCH_PASSWORD: 'Should match password',
  USER_MANUAL: 'User Manual',
  SUPPORT: 'Support',
  BACK_TO_LOGIN: 'Back to Login',
  LOGIN_TO_CONTINUE: 'Login to EnqMed',
  PASSWORD_CONFIRMATION: 'Password Confirmation',
  HAVE_AN_ACCOUNT: 'Already have an account? ',
  Submit: 'Submit',
  Gender: 'Gender',
  PROFESSION: 'Profession',
  MEDICAL_INQ_ROLES: 'Medical Enquiries Processing Role',
  MEDICAL_INFO_MANAGER: 'Medical Information Manager',
  MEDICAL_SCIENCE_LIAISON: 'Medical Science Liaison',
  NOT_PROCESSING_INQUIRIES: 'Not Processing Enquiries',
  NOT_APPROVING_INQUIRIES: 'Not Approving Enquiries',
  ACTIVE_HEALTH_CARE_SPEC: 'Active Health Care Specialist',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Not Active Health Care Specialist',
  PASS_IS_REQUIRED: 'Password is Required!',
  EMAIL_IS_REQUIRED: 'Email is Required!',
  VALID_EMAIL: 'Please enter a valid email address!',
  VALID_PHONE_FAX: 'Please enter a valid phone / fax number!',
  Email_address: 'Email address',
  FIELD_IS_REQUIRED: 'This field is Required!',
  ONLY_LETTERS: 'Please enter only letters!',
  ONLY_LETTERS_SPACE_AND_DASH: 'Please enter only letters, space and - symbol!',
  ARE_YOU_daiichi_EMPLOYEE: 'Are you Daiichi Sankyo Employee?',
  here: 'here',
  CREATE_AN_ACCOUNT: 'Create account',
  FORGOT: 'Forgot?',
  FORGOT_PASS: 'Forgot Password?',
  Remember_me: 'Remember me',
  Medical: 'Medical',
  Medical_not_resp: 'Medical - Not responsible to answer enquiries',
  Commercial: 'Commercial',
  Market_access: 'Market Access',
  Pharmacovigilance: 'Pharmacovigilance',
  PR: 'PR',
  Please_specify: 'Please specify',
  Medical_information_manager: 'Medical information manager',
  Medical_advisor: 'Medical advisor',
  Are_Medical_inquiries: 'Are you responsible to answer Medical enquiries?',
  Are_Health_care_professional: 'Are you a practicing Health care professional?',
  Health_care_Industry: 'Health care Industry',
  Health_Authority: 'Health Authority',
  Journalist: 'Journalist',
  Lawyer: 'Lawyer',
  Patient: 'Patient',
  Payor: 'Payor',
  Scientist: 'Scientist',
  Angiologist: 'Angiologist',
  Cardiologist: 'Cardiologist',
  Cardiovascular_Surgeon: 'Cardiovascular Surgeon',
  Dentist: 'Dentist',
  Gastroenterologist: 'Gastroenterologist',
  General_Practitioner: 'General Practitioner',
  Geriatrician: 'Geriatrician',
  Gynecologist: 'Gynecologist',
  Haematologist: 'Haematologist',
  Internist: 'Internist',
  Medical_Student: 'Medical Student',
  Nephrologist: 'Nephrologist',
  Neurologist: 'Neurologist',
  Neurosurgeon: 'Neurosurgeon',
  Nurse: 'Nurse',
  Oncologist: 'Oncologist',
  Oncology_Nurse: 'Oncology Nurse',
  Other_HCP: 'Other HCP',
  Other_Surgeon: 'Other Surgeon',
  Pediatrician: 'Pediatrician',
  Pharmacist: 'Pharmacist',
  Pharmacologist: 'Pharmacologist',
  Pharmacy_Technician: 'Pharmacy Technician',
  Pneumologist: 'Pneumologist',
  Radiologist: 'Radiologist',
  Rheumatologist: 'Rheumatologist',
  USER_PASS_INCORRECT: 'Username or password is incorrect',
  SUBMITTED_REQUEST: 'Your request was submitted successfully.',
  YOU_WILL_BE_NOTIFIED:
    'Confirmation e-mail was sent with further instructions for your registration',
  SUCCESS_EMAIL_CONFIRMATION: 'Your e-mail was confirmed successfully.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'You will be notified when your request had been reviewed',
  FAILED_EMAIL_CONFIRMATION: 'We are not able to confirm your e-mail.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Please retry and if the problem persists write to support with description of the issue',
  Reset: 'Reset',
  RESET_FILTERS: 'Reset Filters',
  Reset_pass: 'Reset Password',
  New_pass: 'New Password',
  RESET_PASS_EMAIL: 'A reset password link had been sent to your email',
  NEW_PASS_SUCCESS: 'Your password had been reset successfully',
  NEW_PASS_RESET_SUCCESS: 'Password reset successfully!',
  SOMETHING_WENT_WRONG: 'Something went wrong, please contact the support.',
  Cardiovascular: 'Cardiovascular',
  Oncology: 'Oncology',
  Due_On: 'Due On',
  Follow_Up_Sent_On: 'Follow Up Sent On',
  Follow_Up_Received_On: 'Follow Up Received On',
  Closed_On: 'Closed On',
  Reopened_On: 'Reopened On',
  Inquiry_ID: 'Enquiry ID',
  Search: 'Search',
  Assigned_to_Now: 'Assigned to now',
  Edited_By: 'Edited By',
  Assigned_to_Any_Time: 'Assigned to any time',
  Closed: 'Closed',
  Foreign: 'Foreign',
  Complex_search_Sentence_: 'Complex search: Sentence: "sentence to search", Not: -word',
  Search_inquiries: 'Search enquiries',
  SEARCH_SAVED_SEARCHES: 'Search saved searches',
  ADVANCED: 'Advanced search',
  GO: 'Go',
  SEARCH_REPOSITORY: 'Search repository',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'Filters',
  Active_Filters: 'Active Filters',
  CONTACT_SUPPORT: 'Contact support',
  MedicalInformationRequests: 'Enquiries (pdf)',
  UserProfileNavigation: 'User Profile (pdf)',
  UserManualChanges: 'User Manual Changes (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Assign Enquiry',
  MedIS_Documentation_Approval_Process: 'Documentation Approval Process',
  MedIS_Documentation_Overview: 'Documentation Overview',
  MedIS_Inquiry_Documentation: 'Enquiry Documentation',
  MedIS_MIR_Inbox_Filters: 'MIR Inbox Filters',
  MedIS_Merge_MIR: 'Merge MIR',
  MedIS_New_Documentation: 'New Documentation',
  MedIS_New_Inquiry: 'New Enquiry',
  MedIS_Out_Of_Office: 'Out Of Office',
  MedIS_Prepare_Custom_Answer: 'Prepare Custom Answer',
  MedIS_Split_MIR_Part_I: 'Split MIR Part I',
  MedIS_Split_MIR_Part_II: 'Split MIR Part II',
  I_AGREE_TO: 'I Agree to',
  TermsAndConditionsLink: 'Terms and Conditions',
  Title: 'Title',
  INTERACTION_TITLE: 'Interaction Title',
  Move: 'Move',
  Left: 'Left',
  Right: 'Right',
  Requestor: 'Requestor',
  Assigned_To: 'Assigned To',
  ASSIGN: 'Assign',
  Status: 'Status',
  Note: 'Note',
  Confirm: 'Confirm',
  ARE_YOU_SURE: 'Are you sure?',
  rejectConfirm: 'Are you sure you want to reject the request?',
  approveConfirm: 'Are you sure you want to approve the request?',
  newUser: 'New User',
  editUser: 'Edit User',
  deleteUser: 'Delete User',
  newProduct: 'New Product',
  editProduct: 'Edit Product',
  deleteProduct: 'Delete Product',
  newCategory: 'New Category',
  editCategory: 'Edit Category',
  deleteCategory: 'Delete Category',
  newTopic: 'New Topic',
  editTopic: 'Edit Topic',
  deleteTopic: 'Delete Topic',
  userRequest: 'User Request',
  productRequest: 'Product Request',
  categoryRequest: 'Category Request',
  categoryTopicRequest: 'Category / Topic Request',
  topicRequest: 'Topic Request',
  Suggestion: 'Suggestion',
  tradeName: 'Trade Name',
  activeSubstance: 'Active Substance',
  productName: 'Product Name',
  productSpecific: 'Is it product specific?',
  EMAIL_IN_USE: 'There is existing account with this e-mail',
  fromDate: 'From Date',
  toDate: 'To Date',
  applyFilter: 'Apply Filter',
  requestReceivedFrom: 'Request received from',
  on: 'on',
  at: 'at',
  to: 'to',
  add: 'add',
  from: 'from',
  approve: 'approve',
  reject: 'reject',
  withTheFollowingRole: 'with the following role',
  forTheFollowingTeam: 'for the following team',
  request: 'request',
  submittedBy: 'submitted by',
  INVALID_OR_EXPIRED_RESET_REQUEST: 'The password reset request is invalid or expired',
  PASS_IS_RECENT: 'Password is recently used',
  Assigned_to_me: 'Assigned to me',
  My_pending_approvals: 'My pending approvals',
  My_drafts: 'My drafts',
  My_open_inquiries: 'My open interactions',
  My_closed_inquiries: 'My closed interactions',
  Filter_inquiries_by: 'Filter interactions by',
  INTERACTIONS_INBOX: 'Interactions Inbox',
  INBOX_NO_FILTERS_HINT:
    "There are no filters selected. If you can't find the interaction you are looking for, check that you are in the correct inbox section.",
  REPORTS: 'Reports',
  My_teams_open_inq: "My team's open interactions",
  My_teams_closed_inq: "My team's closed interactions",
  All_teams_open_inq: 'All teams open interactions',
  All_teams_closed_inq: 'All teams closed interactions',
  Pending_approval: 'Pending approval',
  ADMINISTRATION: 'Administration',
  Show_all: 'Show all',
  In_progress: 'In progress',
  Table_Settings_Columns: 'Table Settings - Columns',
  Change_column_visibility: 'Change column visibility',
  Reassign_to: 'Reassign to',
  set_priority: 'Set Priority',
  set_status: 'Set Status',
  Spam_list: 'Spam list',
  CERTIFICATION: 'Certification',
  Institution: 'Institution',
  COMPANY: 'Company',
  DUE_ON: 'Due on',
  Profile_settings: 'Profile Settings',
  Preferences: 'Preferences',
  Role_settings: 'Role Settings',
  Notification_settings: 'Notification Settings',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'Confirm deactivation of {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: 'Confirm re-activation of {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: 'User {{ USER_INFO }} reactivated successfully!',
  USERS_DEACTIVATED_SUCCESSFULLY: 'User/s deactivated successfully',
  CONFIRM_BULK_DEACTIVATION_OF_USERS: 'Confirm bulk deactivation of users',
  User_profile: 'User Profile',
  Select_person: 'Select person',
  FROM_DATE: 'From date',
  TO_DATE: 'To date',
  JOB_DESC: 'Job Description',
  INQ_Management: 'Enquiry Management',
  DOCS_Management: 'Document Management',
  USER_Management: 'User Management',
  Define_Frequency: 'Define Frequency',
  All_teams_all_inq: 'All enquiries for all teams',
  My_teams_all_inq: 'All enquiries for my team',
  Search_people_here: 'Search people here',
  Search_team_here: 'Search team here',
  people: 'PEOPLE',
  teams: 'TEAMS',
  empty: '(empty)',
  can_not_be_assigned: 'This user has no team and cannot be assigned',
  select_a_team: 'Please select a team for',
  confirm_team_change: 'Confirm Team Change',
  change_the_team_: 'Are you sure you want to change the team to ',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Click on the text and fields below to populate and edit the answer',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: 'The following must be inserted to save the changes:',
  DOCUMENT_VIEW: 'Document View',
  FORM_VIEW: 'Form View',
  PLAIN_TEXT_VIEW: 'Plain Text View',
  RICH_TEXT_VIEW: 'Rich Text View',
  PLEASE_PROVIDE_AN_ANSWER_: 'Please provide an answer (minimum 6 characters or an attachment).',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'You do not have rights to send the answer.',
  INTERACTION_TEMPLATE_HINT:
    'Signatures, greetings and disclaimers are part of written communication, such as email, letter and fax. In order to use them, make sure outgoing channel is set to one of them.',
  can_not_see_it_anymore: "You won't be able to see it in the current",
  filtered_view: ' filtered view.',
  ENABLE_ALL: 'Enable All',
  DISABLE_ALL: 'Disable All',
  CONFIRM_AUTH_REQUEST: 'Confirm Auth Request',
  VIEW_AUTH_REQUEST: 'View Auth Request',
  QUESTION_TITLE: 'Question title:',
  NO_TITLE_INQUIRY: '-no-title-enquiry-',
  EXISTING_CATEGORIES: 'Existing Categories',
  EXISTING_TOPICS: 'Existing Topics',
  DO_NOT_ASSIGN_PRODUCT: 'Do not assign product',
  QUESTION_ID: 'Question ID',
  QUESTION_IDS: 'Question IDs',
  QUESTION_TEXT: 'Question text',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Question text (minimum 6 characters)',
  DATE: 'Date',
  REJECTED: 'Rejected',
  FILTER_AUTH_REQUESTS_BY: 'Filter auth requests by',
  OPEN_REQUESTS: 'Open requests',
  APPROVED_REQUESTS: 'Approved requests',
  REJECTED_REQUESTS: 'Rejected requests',
  MY_TEAM_OPEN_REQUESTS: 'Open requests',
  MY_TEAM_APPROVED_REQUESTS: 'Approved requests',
  MY_TEAM_REJECTED_REQUESTS: 'Rejected requests',
  ALL_REQUESTS_ALL_TEAMS: 'All requests for all teams',
  ALL_TEAMS: 'All teams',
  SHOW_TEAMS: 'Show Teams',
  LAST_POLLING: 'Last Polling',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST: 'You do not have rights to edit Category/Topic requests.',
  SORT: 'Sort',
  ADD_MAILBOX: 'Add Mailbox',
  UNSET_INCOMING_MAILBOX: 'Unset Incoming Mailbox',
  SET_INCOMING_MAILBOX: 'Set Incoming Mailbox',
  UNSET_OUTGOING_MAILBOX: 'Unset Outgoing Mailbox',
  SET_OUTGOING_MAILBOX: 'Set Outgoing Mailbox',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'All Teams With Current Mailbox',
  ALL_TEAMS_WITH_MAILBOX: 'All Teams with Mailbox',
  MAILBOX: 'Mailbox',
  CURR_MAILBOX: 'Current mailbox',
  EMAIL_POLLING: 'Email Polling',
  HOST: 'Host',
  PROTOCOL: 'Protocol',
  FROM_EMAIL: 'From Email',
  ADVANCED_SEARCH: 'Advanced Search',
  SELECT_FIELDS: 'Select Fields',
  SEARCH_TERM: 'Search term',
  COLUMN_INTERACTION_ID: 'Interaction ID',
  COLUMN_QUESTION_ID: 'Question ID',
  COLUMN_CREATED_ON: 'Created on',
  COLUMN_LAST_EDITED_DATE: 'Last edited on',
  COLUMN_ASSIGNED_TO: 'Assign to',
  COLUMN_DUE_DATE: 'Due date',
  COLUMN_FOLLOW_SENT_DATE: 'Follow-up sent date',
  COLUMN_INIT_QUESTION_TEXT: 'Initial question text',
  COLUMN_FOLLOW_RECEIVED_DATE: 'Follow-up received date',
  COLUMN_ANSWER_DATE: 'Answer date',
  COLUMN_CLOSED_ON_DATE: 'Closed on date',
  COLUMN_REOPENED_DATE: 'Re-opened date',
  COLUMN_INSERTED_BY: 'Inserted by',
  COLUMN_LAST_EDITED_BY: 'Last edited by',
  COLUMN_ANSWERED_BY: 'Answered by',
  COLUMN_APPROVED_BY: 'Approved by',
  COLUMN_INT_COMM_USER: 'Internal communication user',
  COLUMN_INT_COMM_DATE: 'Internal communication date',
  COLUMN_MY_TEAM: 'My Team',
  COLUMN_PQC_RELATED: 'PQC related',
  COLUMN_INCOMING_CHANNEL: 'Incoming channel',
  COLUMN_OUTGOING_CHANNEL: 'Outgoing channel',
  COLUMN_OFF_LABEL: 'Off-label',
  COLUMN_HCP_SPEC: 'HCP + specialization',
  COLUMN_NON_HCP_SPEC: 'Non-HCP + profession',
  COLUMN_KEE: 'KEE',
  COLUMN_HOSPITAL_BASED: 'Hospital based',
  COLUMN_CITY: 'City',
  COLUMN_INSTITUTION: 'Institution',
  COLUMN_TERRITORY: 'Territory',
  CASE_ID: 'Case ID',
  OPTION_ADD: 'ADD',
  OPTION_AND: 'AND',
  OPTION_OR: 'OR',
  OPTION_NOT: 'NOT',
  OPTION_YES: 'Yes',
  OPTION_NO: 'No',
  EDIT_ITEM: 'Edit {{ ITEM }}',
  CREATE_NEW_ITEM: 'Create new {{ ITEM }}',
  ADD_NEW_ITEM: 'Add new {{ ITEM }}',
  ADD_ITEM: 'Add {{ ITEM }}',
  QUERY: 'Query',
  EXPORT_LABEL: 'Define Export Columns',
  SAVE_SEARCH: 'Save Search',
  SHARE_SEARCH: 'Share Search',
  SEARCH_BTN: 'Search',
  BACK_ACTION: 'Back',
  SAVE_CHANGES: 'Save Changes',
  SAVED_SEARCH: 'Saved Search',
  NEW: 'New',
  SAVE_AS: 'Save As Name',
  ENTER_RECIPIENTS: 'Enter recipients',
  CLEAR_SAVED_SEARCH: 'Clear saved searches',
  NO_SAVED_SEARCH: 'No saved search found',
  INT_COMM_TEXT: 'Internal communication text',
  FOLLOW_UP_TEXT: 'Follow-up text',
  CONFIRM_DELETION: 'Confirm Deletion',
  ARE_SURE: 'Are you sure you want to delete the saved search',
  PERMANENTLY_DELETED: 'Search will be permanently <b>deleted</b>.',
  NOTHING_SAVE: 'Nothing to save.',
  NOTHING_SEARCH: 'Nothing to search.',
  NOTHING_EXPORT: 'Search has no record for exporting.',
  SHARE_SUCCESS: 'Search has been shared successfully',
  SHARE_FAILURE: 'Search could not be shared',
  DELETED_SUCCESS: 'Successfully deleted saved search',
  ALL_DELETED_SUCCESS: 'Successfully deleted all saved searches',
  DELETE_NOT_POSSIBLE: 'Delete not possible!',
  VIEW_SAVED_SEARCH: 'View saved search',
  EDIT_SAVED_SEARCH: 'Edit saved search',
  EXPORT_SAVED_SEARCH: 'Export saved search',
  EXPORT_SEARCH: 'Export search',
  DELETE_SAVED_SEARCH: 'Delete saved search',
  SELECT_EXPORT_COLUMNS: 'Select columns to export',
  SAVED_SEARCH_ALREADY_EXIST: 'Search with that name already exist.',
  SEARCH_NO_LONGER_EXIST: 'This search no longer exist as it had been deleted by sender.',
  CHARACTER_LIMIT: 'Min 3 characters. Max 35 characters.',
  CHARACTER_LEFT: 'characters is left',
  ACTIVATE: 'Activate',
  ACTIVATE_ITEM: 'Activate {{ ITEM }}',
  INACTIVE_USER: 'This user is no longer active. Please contact your system administrator.',
  FOLLOW_UP_WITH_ENQUIRER: 'Follow-up with enquirer',
  SEND_FOLLOW_UP: 'Send Follow-up',
  FOLLOW_UP: 'Follow-up',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Receipt notification to customer',
  GDPR_DISCLAIMER: 'GDPR disclaimer',
  ACTIONS: 'Actions',
  SUBMISSION_CONDITIONS: 'Submission conditions',
  TYPE_OF_NOTIFICATION: 'Type of notification',
  ENABLE_NOTIFICATION: 'Enable notification',
  NOTIFICATION_SENDER: 'Notification to Sender',
  SALES_REP_DELIVERED_RESPONSE: 'Sales rep for delivered response',
  FULL_ANSWER: 'Full answer',
  NOTIFICATION: 'Notification',
  DIRECT_SUBMISSION: 'Direct submission',
  DIRECT_SUBMISSION_TOOLTIP:
    'For inquiries submitted directly from the customer via email,  send a notification to inform customer  about the handling of their personal data.',
  COMPANY_EMPLOYEE_SUBMISSION: 'Submitted via company employee',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'For inquiries submitted via company representative (sales rep, MSL, or other), notifiy enquirer about the handling of their personal data.',
  NOTIFICATION_DELIVERED_RESPONSE: 'Notification for delivered response',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'For inquiries submitted via company representative (sales rep, MSL, or other), notifiy sender when  customer received an answer to their enquiry',
  USER_NOT_FOUND: 'User not found',
  DEPARTMENTS: 'Departments',
  NAME: 'NAME',
  EMAIL: 'EMAIL',
  ADD_NEW_DEPARTMENT: 'Add New Department',
  DEPARTMENT_COUNTRY: 'COUNTRY',
  DEPARTMENT_COMPANY: 'COMPANY',
  DEPARTMENT_TEAM: 'TEAM',
  DEPARTMENT_TA: 'THERAPEUTIC AREA',
  DEPARTMENT_PRODUCT: 'PRODUCT',
  DEPARTMENT_ACTIONS: 'ACTIONS',
  DEPARTMENT_CONFIRMATION_1: 'Confirm deletion?',
  DEPARTMENT_CONFIRMATION_2: 'This action is irreversible.',
  DEPARTMENT_NAME: 'Department name',
  TYPE_DEPARTMENT: 'Type',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'Country - please select team first',
  DEP_NAME_CHARACTER_LIMIT: 'Max 200 characters.',
  SIMPLE_TEXT:
    'Please enter only letters (a-z, A-Z), numbers (0-9) and special symbols like (dash, period, space and colon).',
  TEXT_WITH_SPECIAL_CHARS:
    'Please enter only letters (a-z, A-Z), numbers (0-9), and special symbols like dash (-), period (.), space, colon (:), comma (,), parentheses (()), forward slash (/), and plus (+).',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'User Request',
      CategoryTopicRequest: 'Category/Topic Request',
      ProductRequest: 'Product Request',
      UserUpdateRequest: 'User Update Request',
    },
    TYPE: {
      NewUser: 'New User',
      NewCategoryTopic: 'New Category/Topic',
      NewUserUpdate: 'User Update',
    },
  },
  NOTIFICATION_ENABLED: 'Notification enabled',
  DEFAULT_TEMPLATE: 'Default template',
  SET_AS_DEFAULT: 'Set as default',
  DEFAULT_TEMPLATE_TOOLTIP: 'Toggle is disabled as there must be at least one default template!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'Default template can not be deleted!',
  BATCH_UPDATE_ONLY: 'Only a batch update is possible through the topmost checkbox.',
  ACCESS_DENIED: 'Access denied!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: "You don't have permission to access this page.",
  USER_ROLES: 'User roles',
  MANAGE_USER_ROLES: 'Manage user roles',
  ARCHIVE_ROLE: 'Archive role',
  ROLE_ARCHIVE_CONFIRMATION: 'Do you really want to archive this role?',
  ROLE_IN_USE_USERS:
    'The role is currently assigned to users and archiving it may limit their access rights',
  ROLE_IN_USE_USER_TEMPLATES:
    'The role is currently used by user templates and archiving it will remove it from them',
  ROLE_IN_USE_LICENSE_COUNTER:
    'The role is currently used in license counter and archiving it will remove it from it.',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'Please be aware you are attempting to archive the last approved role for this user, which will cause user deactivation and prevent their further access to EnqMed.',
  ROLES_FOR: 'Roles for',
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      TYPE: 'Enquirer Type',
      SPECIALIZATION_EXISTS: 'Configuration for "Enquirer Type/Specialization" already exists.',
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: 'Define country specific specialization list',
      IS_COUNTRY_SPECIFIC: 'Is country specific',
    },
    AUTHORIZATION_REQUESTS: {
      GENDER: 'Gender',
      COUNTRY: 'Country',
      TITLE: 'Title',
    },
  },
  hcp: 'HCP',
  emp: 'Employee',
  cons: 'Non-HCP',
  AUDIT_TRAIL: 'Audit trail',
  AUDIT_TRAIL_FOR: 'Audit trail for {{ ITEM }}',
  AUDIT_TRAIL_FOR_ALL: 'Audit trail for all {{ ITEM }}',
  HISTORY_CHANGES_FOR: 'History changes for',
  ACTION_BY_USER: 'Action by user',
  ACTION: 'Action',
  ORIGINAL_VALUE: 'Original value',
  CHANGE: 'Change',
  CONDITION: 'Condition',
  IP: 'IP',
  GEOLOCATION: 'Geolocation',
  OOO_DEPUTY: 'OOO deputy',
  NO_DEPUTY: 'No deputy',
  UNSPECIFIED_END_DATE: 'unspecified end date',
  DELEGATORS: 'Delegators',
  NO_DELEGATORS: 'No delegators',
  PREVIEW_DELEGATORS: 'Preview delegators',
  REMOVE_DEPUTY: 'Remove deputy',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Users who set you as deputy',

  NEW_VALUE: 'New Value',
  FILTER_BY_ACTION: 'Filter by action',
  SELECT_DATE: 'Select date',

  CHANGED_USER: 'Changed user',
  DEFAULT: 'Default',
  SPECIALIZATIONS: 'Specializations',
  ENQUIRERS_LIST: 'Enquirers list',
  TEAMS_DOMAINS: 'Teams domains',
  MAIL_IMPORT_DOMAIN_SETTINGS: 'Mail import domain settings',
  MAIL_IMPORT_DOMAINS_SETTINGS: 'Mail import domains settings',
  THE_ACTION_IS_IRREVERSIBLE: 'The action is irreversible!',
  USER_DOMAINS: 'User domains',
  SPAM_DOMAINS: 'Spam domains',
  DOMAIN_REGEX: 'Domain regex',
  REGEX_PYTHON_INFORMATION: 'Click, for more information on Python supported regex.',
  USER_DOMAINS_TOOLTIP:
    'Defines which domains (emails submitted with specific domain or key word) will leave the enquirer field blank.',
  SPAM_DOMAINS_TOOLTIP:
    'Defines messages with which domains (emails submitted with specific domain or key word) will not be transformed into interactions entering the system but remain flagged as spam.',
  EDIT_THE_RECORD: 'Edit the record',
  DEACTIVATE_THE_RECORD: 'Deactivate the record',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'Out of office',
      HINT: 'Only active users, with enquiry processing role, who are not out of office are available for selection.',
      MODAL_TITLE: 'Confirm out of office settings',
      MODAL_MSG_1:
        'You have set out of office option with immediate effect, all your inquiries are going to be immediately assigned to the deputy.',
      MODAL_MSG_2:
        'You have set out of office option, all your inquiries are going to be assigned to the deputy during the out of office period.',
    },
  },
  GENDER_MALE: 'Mr.',
  GENDER_FEMALE: 'Mrs.',
  male: 'Male',
  female: 'Female',
  TITLE_DR: 'Dr.',
  TITLE_PHD: 'PhD',
  TITLE_PROF: 'Prof.',
  TITLE_PROF_DR: 'Prof. Dr.',
  HOME: 'Home',
  HOSPITAL: 'Hospital',
  OFFICE: 'Office',
  WORK: 'Work',
  INSERT_IMAGE: 'Insert Image',
  USER_ADMIN: 'User Admin',
  MAILBOXES: 'Mailboxes',
  AUTHORIZATION_REQUESTS: 'Authorization Requests',
  METADATA: 'Metadata',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    'By {{ ACTION_TYPE }} the selected {{ METADATA_TYPE }}, the following additional related changes will apply:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'By removing the products, the following additional related changes will apply:',
  USERS: 'Users',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      NEW_INQUIRY: 'New enquiry',
      ASSIGNMENT: 'Assignment',
      FORWARD: 'Forward',
      FOLLOW_UP: 'Follow Up',
      INQUIRY_APPROVAL: 'Enquiry Approval',
      DUE_DATE_SETTINGS: 'Due Date Settings',
      CLOSE: 'Close',
      DELIVERY_ERROR: 'Delivery Error',
      SCHEDULER: 'Scheduler',
      USER_REPORTS: 'User Reports',
      DOCUMENT_APPROVAL: 'Document Approval',
      AUTHORIZATION_REQUEST: 'Authorization Requests',
    },
    TYPES: {
      ANSWER_1: 'Receive copy of the answer in interactions answered by me',
      ANSWER_2: 'Interaction that I was involved in was Answered',
      ANSWER_6: 'Receive copy of the answer in interactions approved by me',
      ASSIGNMENT_1: 'Interaction was assigned to Me',
      ASSIGNMENT_5: 'Interaction was assigned to my deputy',
      AUTHORIZATION_REQUEST_1: 'A Role request was Approved/Rejected',
      AUTHORIZATION_REQUEST_2: 'User requests',
      AUTHORIZATION_REQUEST_3: 'A Role is waiting my approval',
      AUTHORIZATION_REQUEST_4: 'Category/topic suggestions',
      CLOSE_1: 'Interaction was closed',
      DOCUMENT_APPROVAL_1: 'A Document is waiting for your Approval',
      DOCUMENT_APPROVAL_2: 'A Document was Approved/Rejected',
      DOCUMENT_APPROVAL_3: 'A Document was Archived',
      DOCUMENT_APPROVAL_4: 'A Document was approved with Changes',
      DOCUMENT_APPROVAL_5: 'A Document will be archived in',
      DOCUMENT_APPROVAL_6: 'List of approved documents',
      DUE_DATE_1: "Interaction that I'm assigned to is near the due date, inform me",
      DUE_DATE_2: 'Repeat after past due',
      ERROR_1: 'Delivery Notification failure (e.g. incorrect email), merge as an error message',
      FOLLOW_UP_1: 'A Follow up or Reminder was sent to Customer',
      FOLLOW_UP_4: 'A Follow up was Received',
      FOLLOW_UP_7: 'Interaction was lost to Follow up',
      FORWARD_1: 'Interaction forwarded',
      IMPORT_DATA_1: 'A new object has been created during the import',
      IMPORT_DATA_2: 'A new enquirer has been created during the import',
      IMPORT_DATA_3:
        'An enquirer was matching an already existing one but was not coming from a previous import',
      IMPORT_DATA_4: 'A new user has been created during the import',
      IMPORT_DATA_5:
        'A user was matching an already existing one but was not coming from a previous import',
      IMPORT_DATA_6: 'An error occurred during the import of data from an external source',
      INQUIRY_APPROVAL_1: 'Interaction is waiting for your approval',
      INQUIRY_APPROVAL_4: 'Your proposed Answer was Approved',
      INQUIRY_APPROVAL_5: 'Your proposed Answer was Rejected',
      INTERNAL_1: 'A note was added to my assigned enquiry',
      METADATA_MANAGEMENT_1: 'Geography and alignments during CRM import',
      METADATA_MANAGEMENT_2: 'A new product has been created',
      METADATA_MANAGEMENT_3: 'A new category has been created',
      METADATA_MANAGEMENT_4: 'A new topic has been created',
      NEW_DOCUMENT_1: 'New Approved Document is available in my Country',
      NEW_DOCUMENT_2: 'New Document is available for local approval',
      NEW_DOCUMENT_3: 'New Local Document is available for European / Global approval',
      NEW_DOCUMENT_4: 'New Global Document is available',
      NEW_DOCUMENT_5: 'New European Document is available',
      NEW_DOCUMENT_6: 'New European Document is available for Global approval',
      NEW_INQUIRY_1: 'New interaction was received in my Country',
      SCHEDULER_TASK_1: 'An error happened during the execution of a task',
      SPAM_1: 'New interaction received in Spam',
      USER_REPORTS_1: 'List of active users',
      TEXT_GUIDES: {
        ANSWER_2:
          'Receive notifications for inquiries that were answered that I worked as a collaborator, but am not directly assigned to the enquiry',
        AUTHORIZATION_REQUEST_2:
          'Receive notifications when new user was created or when a role was requested by a user in your team',
        AUTHORIZATION_REQUEST_3:
          'Receive notifications informing you when a user role request needs to be approved/rejected',
        AUTHORIZATION_REQUEST_4:
          'Receive notifications when a new topic and/or category was suggested',
        FOLLOW_UP_4:
          'Receive notifications for inquiries I am assigned to that received a Follow up response from the enquirer',
        NEW_INQUIRY_1:
          'Receive notifications for new inquiries received in your country, inserted by an enquirer/user or forwarded from another team',
        DUE_DATE_1:
          'By selecting this option all of the below notifications will be activated. To reduce the number of notifications, deselect those not needed.',
        INQUIRY_APPROVAL_4: 'Your proposed answer was Approved.',
        INQUIRY_APPROVAL_5:
          'Your proposed answer was Rejected or Interaction status was updated from Submitted for Approval to In Progress due to split via interaction, Merge as new enquiry resulting in split, or any change of of team setting in terms of need of subsequent enquiry approval.',
        METADATA_MANAGEMENT_2:
          'Receive notification for newly created product independent from the reason for creation.',
        METADATA_MANAGEMENT_3:
          'Receive notification for newly created category independent from the reason for creation.',
        METADATA_MANAGEMENT_4:
          'Receive notification for newly created topic independent from the reason for creation.',
        FORWARD_1:
          'Interaction data is forwarded to editors and assignee user and/or notification to assignee in case of failed forward to department.',
      },
      FREQUENCIES: {
        INSTANTLY: 'Instantly',
        DAILY: 'Daily',
        WEEKLY: 'Weekly',
        QUARTERLY: 'Quarterly',
        MONTHLY: 'Monthly',
        SECOND_DAY: 'Every Second Day',
        THIRD_DAY: 'Every Third Day',
        ONE_WEEK: 'One Week',
        ONE_MONTH: 'One Month',
        TWO_MONTHS: 'Two Months',
        THREE_MONTHS: 'Three Months',
        SIX_MONTHS: 'Six Months',
        NINE_MONTHS: 'Nine Months',
        EVERY_TWO_WEEKS: 'Every Two Weeks',
        EVERY_THREE_WEEKS: 'Every Three Weeks',
        EVERY_FOUR_WEEKS: 'Every Four Weeks',
      },
      OPTIONS: {
        ALMOST_DUE: 'Almost Due',
        DUE_NOW: 'Due Now',
        DUE_SOON: 'Due Soon',
        EARLY_ON: 'Early On',
        TEXT_GUIDES: {
          EARLY_ON:
            'Receive notifications early on for enquiries that are due: Normal 4 days - High 2 days - Critical 24 hours before the due date',
          DUE_SOON:
            'Receive notifications for enquiries that are due soon: Normal 48 hours - High 12 hours - Critical 6 hours before the due date',
          ALMOST_DUE:
            'Receive notifications for enquiries that are almost due: Normal 24 hours - High 6 hours - Critical 3 hours before the due date',
          DUE_NOW: 'Receive notifications when the enquiry is due',
        },
      },
    },
  },
  NEW_TEAM: 'New team',
  SmPc: 'SmPC',
  PAGINATOR_NEXT_PAGE_LABEL: 'Next page',
  PAGINATOR_PREV_PAGE_LABEL: 'Previous page',
  PAGINATOR_FIRST_PAGE_LABEL: 'First page',
  PAGINATOR_LAST_PAGE_LABEL: 'Last page',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'Items per page',
  Date: 'Date',
  Action: 'Action',
  'Action by user': 'Action by user',
  'Changed user': 'Changed user',
  'Changed inquirer': 'Changed enquirer',
  'Original value': 'Original value',
  'New value': 'New value',
  REGION: 'Region',
  TEAM_REGION_LABEL: 'Region - please select company first',
  TEAM_THERAPEUTIC_AREA_LABEL: 'Therapeutic areas - please select company first',
  TEAM_PRODUCT_LABEL: 'Products - please select therapeutic area first',
  TEAM_COUNTRIES_LABEL: 'Countries - please select region first',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Define products for each country',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Please select at least one product for',
  VIEW_CHANGES: 'View changes',
  REGIONS: 'Regions',
  MAIN_CLIENT: 'Main client',
  LICENSE_PARTNER: 'License partner',
  TEAM_SET_UP: 'Team set up',
  LIST_TEAMS: 'List teams',
  COMPANIES: 'Companies',
  DOMAINS: 'Domains',
  DOMAIN: 'Domain',
  PLEASE_ENTER_MAX: 'Please enter maximum ',
  PLEASE_ENTER_MAX_CHARACTERS: 'Please enter maximum {{ MAX_CHARS }} characters.',
  PLEASE_ENTER_MIN_NUMBER: 'Please enter number bigger than {{ MIN_NUMBER }}.',
  TOKEN_REFRESH_NOTICE:
    'You requested a new confirmation email for your EnqMed account registration.',
  TOKEN_REFRESH_SUCCESS: 'A new confirmation link has been sent to your email.',
  TOKEN_REFRESH_ERROR: 'An error occured. Please contact support if you need further assistance.',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'Please review the changes below, if any, and confirm if the company should be changed.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Please proceed with caution because the changes will affect the teams',
  THERE_ARE_NO_CHANGES: 'There are no changes.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: 'Please select at least one country for region',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'Please select at least one product for therapeutic area',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'You have selected product/s which belongs to more than one therapeutic area, please select all needed therapeutic areas.',
  HTML_CHANGES: 'HTML changes',
  SIDE_BY_SIDE_VIEW: 'Side by side view',
  APPROVE_REQUEST_QUESTION: 'Do you want to approve the request?',
  ENTER_USER_COMMENT_OPTIONAL: 'Enter comment to user (optional)',
  SELECT_ANOTHER_CATEGORY: 'Select another Category',
  SELECT_ANOTHER_PRODUCT: 'Select another Product',
  SUGGESTED_TOPIC: 'Suggested topic',
  NEW_USER_REQUEST: 'New User request',
  EDIT_USER_REQUEST: 'Edit User request',
  NEW_TOPIC_REQUEST: 'New Topic Suggested',
  EDIT_TOPIC_REQUEST: 'Edit Suggested Topic',
  NEW_CATEGORY_REQUEST: 'New Category Suggested',
  EDIT_CATEGORY_REQUEST: 'Edit Suggested Category',
  REVIEW_COMMENT: 'Review comment',
  OUT_CHANNEL_ERROR_MSG: 'Outgoing channel letter enquirer’s postal address',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'Outgoing channel selected as letter. Please enter Enquirer’s postal address within Enquirer area',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'You do not have rights to archive this role. Only <b>MIMs</b> for the team can archive roles for their team members.',
  USER_REQUESTS: 'User requests',
  REQUEST_FOR: 'Request for',
  CATEGORY_TOPIC_REQUESTS: 'Category/Topic requests',
  WITH_THE_FOLLOWING_PAIRS: 'with the following pairs:',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'Document approval within EnqMed',
  ENQUIRY_ANSWER_APPROVAL: 'Enquiry answer approval',
  DOCUMENTS_APPROVAL: 'Documents approval',
  DOCUMENTS_APPROVAL_TITLE: 'Settings for documents approval process for the selected team',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'Outgoing channel selected as fax. Please enter Enquirer’s Fax number within Enquirer area',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'Outgoing channel selected as phone. Please enter Enquirer’s Phone number within Enquirer area',
  OUT_CHANNEL_FAX_ERROR_MSG: 'Outgoing channel fax enquirer’s value',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'Outgoing channel phone enquirer’s value',
  COPYRIGHT_PART_ONE: 'Copyright',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. All rights reserved.',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'Outgoing channel selected as email. Please enter Enquirer’s Email within Enquirer area',
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'Outgoing channel email enquirer’s value',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Document approval workflows',
    CREATE_NEW: 'Create new workflow',
    CUSTOMIZE: 'Customize workflow',
    NAME: 'Workflow name',
    ERROR_MSG_DUPLICATE_NAME: 'Workflow with this name already exists.',
    SAVE: 'Save workflow',
    DELETE: 'Delete workflow',
    STEP: 'Step',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'All users with Document author role.',
    APPROVAL_DEADLINE: 'Approval deadline',
    SELECT_APPROVAL_TYPE: 'Select approval type',
    SELECT_APPROVAL_TYPE_FIRST: 'Select approval type first',
    SELECT_REVIEWERS: 'Select reviewers',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Action on approve with changes',
    ACTION_ON_REJECT: 'Action on reject',
    FILL_ALL_REQUIRED_FIELDS: 'Please fill all required fields in order to add new step.',
    STEP_LIMIT_REACHED: 'You have reached the limit of {{ MAX_STEPS }} approval steps.',
    ALL_APPROVAL_TYPES_USED:
      'You cannot add more approval sub steps as you used all types available for the current step.',
    CONFIRMATION_ROW_1: 'You are trying to delete document approval workflow for team {{ NAME }}.',
    CONFIRMATION_ROW_2: 'Do you really want to delete the approval workflow?',
    CONFIRMATION_TITLE: 'Document approval workflow deletion',
    TEAM_VIEW_SELECT_EXISTING:
      'Select an existing document approval workflow from the list below or ',
    DOC_VIEW_SELECT_EXISTING: 'Select an existing document approval workflow',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'Only single approval step can be listed as final.',
    APPROVAL_TYPE_TOOLTIP:
      'There are no users with document approval role for this team. Please add approval roles first.',
    INFO_DIALOG_TITLE: 'There is no document approval workflow set for this team.',
    INFO_DIALOG_MSG:
      'Please contact your local MIM to create document approval workflow for the team.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'Document types which do not require approval',
  PLEASE_SELECT_TYPE: 'Please select type',
  ABORT_FILE_UPLOAD: 'Abort file upload',
  USERS_REPORT: 'Users report',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'By closing this window you will loose the information already filled in.',
  DO_YOU_WANT_TO_PROCEED: 'Do you want to proceed?',
  USERS_REPORT_DESCRIPTION:
    'List of users of all additional teams where MIM for Daiichi Sankyo Europe Oncology and Cardiovascular have a MIM role.',
  TEMPLATE_CONFIRM_MSG: "Once the template is deleted, it can't be restored!",
  EVENT_CONFIRM_MSG: "Once the event is archived, it can't be restored!",
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'Change in enquirer country will change assignment of the interaction from team {{ CURRENT_TEAM }} to team {{ FUTURE_TEAM }}.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'Change in enquirer country will change assignment of the interaction from team {{ CURRENT_TEAM }} to team {{ FUTURE_TEAM }}. User {{ ASSIGNED_TO }} does not have permissions for the {{ FUTURE_TEAM }} team and their personal assignment will be removed.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'The change will affect team assignments in the following additional interactions, where personal assignments will be removed: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Change in enquirer country will change team assignments in the following additional interactions, where personal assignment will be removed. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'Interaction ID: {{ INTERACTION_ID }} from {{ CURRENT_TEAM }} to {{ FUTURE_TEAM }}, removed personal assignment from user {{ ASSIGNED_TO }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'User {{ EDITING_USER }} changed enquirer {{ ENQUIRER }} country in interaction ID: {{ UPDATED_INTERACTION_ID }}, which changed assignment of the current interaction from team {{ OLD_TEAM }} to team {{ NEW_TEAM }}.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'User {{ EDITING_USER }} changed enquirer {{ ENQUIRER }} country in HCP administration, which changed assignment of the current interaction from team {{ OLD_TEAM }} to team {{ NEW_TEAM }}.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'User {{ INTERACTION_ASSIGN_TO }} does not have permissions for the {{ NEW_TEAM }} team and their personal assignment is removed.',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'Archiving the user role will change assignments in the following additional interactions, where personal assignments will be removed: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'User {{ EDITING_USER }} edited the {{ INTERACTION_ASSIGN_TO }} user, archiving their role as {{ USER_ROLE }} in team {{ TEAM }}. User {{ INTERACTION_ASSIGN_TO }} does not have permissions for the {{ TEAM }} team and their personal assignment is removed.',

  PERSONAL_ASSIGNMENT: 'Personal assignment',
  ASSIGNMENT: 'Assignment',
  DEFAULT_CONFIRMATION_QUESTION: 'Please confirm if you would like to proceed.',
  CREATE_DRAFT: 'Create draft',
  CREATE_USER: 'Create user',
  ADD_ROLE: 'Add role',
  DOMAIN_ADDRESS_INVALID_FOR: 'Domain address invalid for',
  AVAILABLE_ADDRESSES: 'Available addresses',
  IN_TEAM: 'in team',
  TO_THE_SELECTED_USER: 'to the selected user',
  USER_INFORMATION: 'User information',
  LOGIN_CREDENTIALS_CHANGED: 'Login Credentials Changed!',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'Incorrect email address!',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'The email address you are using needs to be updated since the domain address does not correspond to the team/s your profile is associated with.<br>Please consult your system administrator for assistance or contact the support team.',
  MESSAGE: 'Message',
  submit_to_approval: 'Submit To Approval',
  create_document_metadata: 'Create Document Metadata',
  archive: 'Archive',
  VERSION_DIALOG: {
    TITLE: 'New Version Available',
    MESSAGE:
      'There is new version of the software available! \n You need to get the new version, in order to benefit from improvements in it and avoid issues from using the old version, you need to get it.',
    SAVE_BUTTON: 'Get The New Version',
  },
  SELECT_PREFERRED_CONTACT_ERROR: 'You must select a preferred contact for the enquirer.',
  ACTIVE_INACTIVE: 'Active / Inactive',
  VIA: 'via',
  INSERTED_MANUALLY: 'Inserted manually',
  AS_A: 'as a',
  AS: 'as',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Follow-up initiated by enquirer',
  THANK_YOU_MESSAGE: 'Thank you message',
  AND_HENCE_STATUS_CHANGED_FROM: 'and hence the status changed from',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'Follow-up is not possible for a closed interaction.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'Interaction in review status. Please save review to enable this menu.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'Interaction in "Submitted for Approval" status. Please Approve/Reject or revert status to "In Progress" to enable this menu.',
  EDIT_TEXT: 'Edit text',
  FOLLOW_UP_ATTEMPTS: 'Follow-up attempts',
  FIRST_FOLLOW_UP_INFO_TEXT: 'First follow-up sent within individual interaction',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'If any of the below are deactivated after initial request the process will transition to the next step',
  SECOND_ATTEMPT: 'Second attempt (1st reminder)',
  THIRD_ATTEMPT: 'Third attempt (1st reminder)',
  FINAL_ATTEMPT: 'Lost to follow-up (close)',
  REMINDER_FREQUENCY: 'Reminder frequency',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Apply automatic translation into language used to contact the enquirer',
  ON_OFF_SWITCH: 'On/Off',
  DAY: 'Day',
  DAYS: 'Days',
  MONTH: 'Month',
  MONTHS: 'Months',
  RESET_TEMPLATES_TO_DEFAULTS: 'Reset templates to defaults',
  SAVE_FREQUENCY: 'Save frequency',
  SELECT_FREQUENCY: 'Select frequency',
  HOLIDAY_GDPR_DISCLAIMER: 'Holiday GDPR disclaimer',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: 'Manage holiday GDPR disclaimer',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS: 'Missing holiday GDPR template for the following team/s',
  BULK_CREATE: 'Bulk create',
  BULK_EDIT: 'Bulk edit',
  HOW_TO_ENABLE_BULK_EDIT:
    'At least two templates in the same language must be selected in order to do a bulk update.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'Selected target interaction for merge is already closed. This enquiry is a new interaction and no need to be merged.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'A new question/s will be added in the existing enquiry, all questions must be answered. Please select questions for merge.',
    VALIDATION_MSG:
      'Please select at least one question from the merged interaction to proceed with the merge.',
  },
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      'Please select at least one question in the target interaction to proceed with the merge.',
    DESCRIPTION:
      'The clarification question will be added under the selected question(s) in the target interaction.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION: 'Select this option to flag interaction as a duplicate of existing interaction.',
  },
  MERGED_INTERACTION: 'Merged interaction',
  TARGET_INTERACTION: 'Target interaction',
  MERGE_SUGGESTION: 'Merge suggestion',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Please review the merge suggestion below',
  INQUIRERS_COMPARISON_TITLE:
    'Please note different enquirers for the merged interaction and the target interaction',
  INQUIRERS_COMPARISON_QUESTION:
    'Please confirm if you would like to proceed despite this difference.',
  NO_INQUIRER: 'No enquirer',
  IN: 'in',
  TEAM_GROUP_FILTER: {
    TITLE: 'Define permissions to view additional teams',
    SAVE_BTN_TOOLTIP: 'Please enter all mandatory fields for team and save team first.',
  },
  REFERENCE_SOURCE: 'Reference source',
  REFERENCE_ID: 'Reference ID',
  REFERENCE_TYPE: 'Reference type',
  SAVE_REFERENCE_ID: 'Save the reference ID',
  ADR_REF_ID: 'ADR Reference ID',
  PQC_REF_ID: 'PQC Reference ID',
  ADDITIONAL_REF_ID: '{{ TYPE }} Reference ID',
  ADDITIONAL_REF_IDS_TITLE: '{{ TYPE }} reference IDs',
  ADD_ADDITIONAL_REF_IDS_TOOLTIP: 'Edit / Add / Delete an additional {{ TYPE }} reference IDs',
  ADD_ADDITIONAL_REF_IDS: 'Add {{ TYPE }} reference IDs',
  INTERACTION_REFERENCES: 'Interaction references',
  INTERACTION_REFERENCE_ID: 'Interaction reference ID',
  INSERT_NEW_REF_SOURCE: 'Insert new reference source',
  BACK_TO_AVAILABLE_SOURCE: 'Go back to available source',
  CLICK_TO_ENABLE_REF_ID_EDIT: 'Click to enable editing of the reference ID',
  DELETE_REF_ID: 'Delete reference ID',
  DELETE_REF_ID_CONFIRMATION_QUESTION:
    'Are you sure you want to delete the interaction reference ID?',
  DUPLICATED_REF_ID_SOURCE_VALUE_WARNING:
    'Please verify the existing reference sources, as a reference source with the same value already exists.',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS:
    'This attachment is present in multiple interactions',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'Please select the check-box option if you would like to delete the attachment from all interactions.',
  CONFIRM_DELETE_ATTACHMENT: 'Please confirm if you would like to delete this attachment.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: 'Delete from all interactions mentioned above',
  ATTACHMENT_DELETED: 'Attachment deleted',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: 'Manual archival not allowed for deputised roles.',
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'Previous merge as clarification question detected. All selected answer fields in addition to previously selected answer fields in the target interaction will be re-opened for answer.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'Interaction re-opened for providing answer on another question. Answer for this question was already provided previously.',
  TIP: 'Tip',
  MERGE_TIP_TEXT:
    'If you wish to re-open interaction {{ MERGED_INTO_ID }} instead, please press no and initiate a new merge process indicating target interaction {{ MERGED_INTO_ID }}.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'You are selecting a merge operation without opening of the target interaction.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'You are selecting a merge operation into an invalid target interaction {{ TARGET_INTERACTION_ID }}.<br>Interaction {{ TARGET_INTERACTION_ID }} was closed with status {{ STATUS }}{{ LAST_MERGE_INFO }}.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'This will result in closed interaction and inability to provide an answer.',
  INTO_INTERACTION: 'into interaction',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'Please select interaction for merge.',
  PLEASE_SELECT_MERGE_REASON: 'Please select merge reason.',
  QUESTION_CANNOT_BE_DELETED:
    'This question cannot be deleted as there is an associated unanswered clarification question.',
  MERGE_REASON: 'Merge reason',
  REASON_FOR_INCLUSION: 'Reason for inclusion',
  REASON_FOR_REMOVAL: 'Reason for removal',
  REASON_FOR_UPDATE: 'Reason for update',
  REASON_FOR_REPLACEMENT: 'Reason for replacement',
  WITH_REOPEN: 'with re-open',
  MERGED_INTO_QUESTION_IDS: 'merged into question ID(s)',
  MERGED_INTO_QUESTIONS: 'merged into question(s)',
  MERGED_PREVIOUSLY_FROM: 'merged previously from',
  FROM_INTERACTION_ID: 'from interaction ID',
  IN_INTERACTION_ID: 'in interaction ID',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'You are about to cancel merge suggestion.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'Do not show merge suggestion again?',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'A closed interaction is not editable.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'Interaction has more than one question. Editing possible from open interaction only.',
  FOLLOW_UP_RESEND: 'Follow-up re-sent',
  SENT_FOLLOW_UP_REMINDER: 'sent follow-up reminder',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'closed interaction and sent lost to follow-up notification',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'Please enter text with a minimum length of 15 characters.',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    'Please enter text with a minimum length of 15 characters or an attachment.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST: 'Follow-up disabled! Please insert the enquirer first.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    "Follow-up request disabled! Please insert valid enquirer's email first.",
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'The number for the selected frequency type should be between 1 and {{ MAX_ALLOWED_FREQUENCY }}.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Please select frequency type.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'Count starts from the date and time of initial follow-up request. For conversion days to weeks multiply by 7, e.g. 2 weeks = 14 days, for days to months multiply by 30, e.g. 1 month = 30 days.',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Select a language to view and manage the translated templates',
  RETRANSLATE: 'Retranslate',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'The templates below are in English language only, translations are managed below',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'All templates above will be reset to defaults (manual modification will be removed, frequency reset to 14 days, and translation checkbox deselected), retranslated to all languages and will clear is_manually_modified.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Edit template text and retranslate all translations which are not manually modified.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Retranslate original template text, including those manually modified.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Follow-up reminder templates',
  MANUALLY_MODIFIED: 'Manually modified',
  SYNC: 'Sync',
  DIFFERENT: 'Different',
  SEND_WELCOME_MAIL_: 'Send welcome email with password reset',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'I confirm a new follow-up information was received by the enquirer and that I would like to re-open the interaction for answer provision.',
  DUE_TO_FU_REQUEST: 'due to follow-up request',
  DUE_TO_FU_RESPONSE: 'due to follow-up response',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE: 'due to update of follow-up reminders frequency from admin page',
  UPDATED_FROM_INBOX: 'updated from inbox',
  PERIOD_TO_PERIOD_OVERLAP: 'Period and To Period are overlapping',
  PERIOD_TO_PERIOD_MISMATCH:
    'Period and To Period have different duration - {{ PERIOD }} {{ INTERVAL }} and {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'created question from follow-up response',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'This option allows creating a new question from a follow-up.',
  ATTEMPTED_FOLLOW_UP: 'attempted a follow-up',
  FAILED_FU_ATTEMPT: 'Failed follow-up attempt',
  UNABLE_TO_REACH_ENQUIRER: 'Unable to reach the enquirer.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'Select this option if attempt to reach the enquirer was made but was not successful, e.g. unable to reach via phone, failed F2F or other appointment.\nThe attempt will be recorded in interaction history.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE:
    'due to the follow-up process being stopped as a result of changing the enquirer',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'You are about to switch the enquirer, which will stop the follow-up process.',
  NEW_ENQUIRER: 'New enquirer',
  NEW_ENQUIRER_TOOLTIP:
    'Use this option to create a completely new enquirer. If instead editing the existing enquirer data is required do not use this option.',
  CONFIRM_SET_NEW_ENQUIRER:
    'Please confirm you wish to replace the existing enquirer with a new one.<br>All information entered will be lost.<br>If you wish instead to edit the data of the existing enquirer, modify the data directly in the form and press Save.',
  EXTRA_STEP_REQUIRED_TO_LOGIN: 'Extra step required to login',
  EXTRA_STEP_DESCRIPTION:
    'Login confirmation has been sent to your email. Please, follow the steps in it, in order to continue to the system.',
  REASON_FOR_REJECTION: 'Reason for rejection',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: 'Note has been properly inserted in history.',
  RECEIVES_THE_FOLLOWING_NOTIFICATION: 'receives the following notification',
  DEACTIVATE_SELECTED_USERS: 'Deactivate selected users',
  SELECT_USERS_TO_DEACTIVATE: 'Select users to deactivate',
  ARCHIVE_FOLDER: 'Archive folder',
  EXTRA_PARAMS:
    "Extra parameters (k1=v1&k2=v2, 'folders' and 'archive' are reserved and cannot be used)",
  POLL_FOLDER: 'Poll folder',
  POLL_FOLDERS_TEXT:
    'Poll folders can only be set for protocols imap or gmail. For all other protocols, the default is the inbox.',
  POLL_FOLDERS_TOOLTIP_1:
    'Type the folder name and press Enter or use a comma to add it for polling from the mailbox (If no folder is added, the Inbox will be polled by default).',
  POLL_FOLDERS_TOOLTIP_2: 'Folder names listed here will be polled from the mailbox.',
  FOLDERS_TO_POLL_FROM_MAILBOX: 'Folders to poll from mailbox',
  VERIFY_SETUP: 'Verify setup',
  HTML_PREVIEW: 'HTML Preview',
  IN_REPLY_TO_ID: 'In reply to id',
  INCOMING: 'Incoming',
  OUTGOING: 'Outgoing',
  VIEW: 'View',
  ENQUIRY_ANSWER_APPROVAL_TITLE: 'Set enquiry answer approval for the selected teams',
  RULE: 'Rule',
  RULES: 'Rules',
  FIELD: 'Field',
  CRITERIA: 'Criteria',
  NUMBER: 'Number',
  MIN: 'Min',
  MAX: 'Max',
  ADD_GROUP: 'Add group',
  DELETE_GROUP: 'Delete group',
  VALID_FROM_TO: 'Valid from/to',
  VALID_FROM: 'Valid from',
  VALID_TO: 'Valid to',
  ACTIVATE_PERMISSION_EDIT: 'Activate permission edit',
  DEACTIVATE_PERMISSION_EDIT: 'Deactivate permission edit',
  PERMISSION_EDIT_TOOLTIP: 'Click to edit permission {{name}}',
  RULE_ENQ_ANSWER_APPROVAL_MSG: 'Enquiry answer approval is already enabled in team(s)',
  FWD_TO_DEPT_FAILED: 'Forward to department failed.',
  RESEND: 'Resend',
  GDPR_NOTIF_FAILED: 'Receipt notification/GDPR failed.',
};
